import { BackgroundJobStatusCode, BackgroundJobTypeCode, WebSocketMessageTypeCode } from "@odata/GeneratedEnums";
import { WithOData, withOData } from "@odata/withOData";
import {
    createDemoTenant,
    DemoTenantStatusCode,
    getDemoTenantInfo,
    IDemoTenantInfo,
    switchToDemoTenant
} from "@pages/userSetting/UserSetting.utils";
import { isBackgroundJobWebsocketMessage, TWebsocketMessage } from "@utils/websocketManager/Websocket.utils";
import React, { memo, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
    getBackgroundJobFromWebsocketMessage
} from "../../../contexts/backgroundJobsContext/BackgroundJobsContext.utils";
import WebsocketManager from "../../../utils/websocketManager/WebsocketManager";
import { IDashboardTileComponentProps } from "../../dashboard";
import { DemoTenantIcon } from "../../icon";
import { IconHeight, IconWidth, StyledLinkTileTitle, StyledLinkTileTitleWrapper } from "../linkTile/LinkTile.styles";
import { CreateDemoTenant, StyledDemoTenantTile } from "./DemoTenant.styles";

interface IProps extends IDashboardTileComponentProps, WithOData {
}

const DemoTenant: React.FC<IProps> = ({ inEditMode }) => {
    const { t } = useTranslation("Home");

    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [hasError, setHasError] = React.useState<boolean>(false);
    // initial state - unknown status
    const [info, setInfo] = React.useState<IDemoTenantInfo | null>(undefined);

    const _getTenantInfo = useCallback(async () => {
        const info = await getDemoTenantInfo();

        if (info === false) {
            setHasError(true);
        } else {
            setInfo(info);
            setIsLoading(info?.DemoTenantStatus === DemoTenantStatusCode.Creating);
        }
    }, []);

    const handleMessage = useCallback((message: TWebsocketMessage) => {
        if (!isBackgroundJobWebsocketMessage(message)) {
            return;
        }

        const backgroundJob = getBackgroundJobFromWebsocketMessage(message);

        if (backgroundJob.TypeCode === BackgroundJobTypeCode.CreatingDemoCompany && backgroundJob.StatusCode === BackgroundJobStatusCode.Finished) {
            _getTenantInfo();
        }
    }, [_getTenantInfo]);

    useEffect(() => {
        _getTenantInfo();
    }, [_getTenantInfo]);

    useEffect(() => {
        return WebsocketManager.subscribe({
            callback: handleMessage,
            types: [WebSocketMessageTypeCode.BackgroundJob]
        });
    }, [handleMessage]);

    const hasDemoTenant = info && info.DemoTenantStatus !== DemoTenantStatusCode.NotCreated;
    // note, it's not the same as !hasDemoTenant, because we don't want to show the button when we don't know the status
    const showCreateDemoTenantButton = info && info.DemoTenantStatus === DemoTenantStatusCode.NotCreated;

    const handleClick = useCallback(async () => {
        if (isLoading) {
            return;
        }
        setIsLoading(true);
        setHasError(false);
        if (!hasDemoTenant) {
            const data = await createDemoTenant();
            if (data === false) {
                setHasError(true);
                setIsLoading(false);
            } else {
                setInfo(data);
                setIsLoading(data.DemoTenantStatus === DemoTenantStatusCode.Creating);
            }
        } else {
            const succ = await switchToDemoTenant(info.DemoTenantId);
            setHasError(!succ);
            setIsLoading(false);
        }
    }, [info, hasDemoTenant, isLoading]);

    return (
            <StyledDemoTenantTile inEditMode={inEditMode}
                                  onClick={handleClick}
                                  isLoading={isLoading || hasError}>
                {showCreateDemoTenantButton ? (
                        <CreateDemoTenant>{t("DemoTenant.Create")}</CreateDemoTenant>
                ) : (<>
                    <DemoTenantIcon width={IconWidth} height={IconHeight}/>
                    <StyledLinkTileTitleWrapper>
                        <StyledLinkTileTitle>{t("DemoTenant.Title")}</StyledLinkTileTitle>
                    </StyledLinkTileTitleWrapper>
                </>)}
            </StyledDemoTenantTile>
    );
};

export default memo(withOData(DemoTenant));
