import { getIntentNavParams, getNavLinkComponent } from "@components/drillDown/DrillDown.utils";
import { IInputOnBlurEvent } from "@components/inputs/input";
import { IMultiSelectionChangeArgs } from "@components/inputs/select/MultiSelect";
import { ISelectItem } from "@components/inputs/select/Select.types";
import { SwitchType } from "@components/inputs/switch/Switch";
import { IReadOnlyListItem } from "@components/readOnlyList/ReadOnlyListItem";
import { ifAny, IGetValueArgs, TGetValueFn, TInfoValue } from "@components/smart/FieldInfo";
import { AttachmentsAdditionalProperties } from "@components/smart/GeneralFieldDefinition";
import { ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { IFormGroupDef } from "@components/smart/smartFormGroup/SmartFormGroup";
import { transformPairingSelectItems } from "@components/smart/smartPairingSelect/SmartPairingSelect.utils";
import { TSmartODataTableStorage } from "@components/smart/smartTable/SmartODataTableBase";
import { getRow } from "@components/smart/smartTable/SmartTable.utils";
import { IRow, TId } from "@components/table";
import { setDirtyFlag } from "@odata/Data.utils";
import { getEntitySetByDocumentType, getRouteByDocumentType } from "@odata/EntityTypes";
import {
    BankAccountEntity,
    BusinessPartnerEntity,
    CorrectiveInvoiceReceivedEntity,
    CountryEntity,
    DocumentDraftEntity,
    DocumentEntity,
    DocumentLockEntity,
    EntitySetName,
    EntityTypeName,
    ICorrectiveInvoiceIssuedEntity,
    ICorrectiveInvoiceReceivedEntity,
    IDocumentDraftEntity,
    IDocumentEntity,
    IDocumentLinkEntity,
    IInvoiceIssuedEntity,
    IPayingDocumentEntity,
    PaymentDocumentEntity
} from "@odata/GeneratedEntityTypes";
import {
    ClearedStatusCode,
    DocumentLinkTypeCode,
    DocumentTypeCode,
    ExternalCorrectedDocumentTypeCode,
    PostedStatusCode
} from "@odata/GeneratedEnums";
import { prepareQueryForBatch, transformToODataString } from "@odata/OData.utils";
import { ODataQueryResult } from "@odata/ODataParser";
import { SAVED_VATS_PATH } from "@pages/admin/vatRules/VatRules.utils";
import { CREATE_RECEIPT_PATH } from "@pages/banks/bankAccounts/BankAccounts.utils";
import {
    getDefinition,
    getIssuedDocsWithoutCorrectionFilter,
    getReceivedDocsWithoutCorrectionFilter
} from "@pages/documents/corrective/CorrectiveDocumentSharedPairDef";
import {
    correctiveDocumentLinkTypes,
    getDocumentPairedTable,
    setNotPostedStatusFilter
} from "@pages/documents/Document.utils";
import { draftInfoPath } from "@pages/documents/DocumentCommonDefs";
import { IDefinition } from "@pages/PageUtils";
import { DocumentStatusLocalPath } from "@pages/reports/CommonDefs";
import { isCashBasisAccountingCompany } from "@utils/CompanyUtils";
import { getValue, isDefined } from "@utils/general";
import i18next from "i18next";
import { cloneDeep } from "lodash";
import React from "react";

import Field from "../../../components/inputs/field";
import SmartPairingMultiSelect from "../../../components/smart/smartPairingSelect/SmartPairingMultiSelect";
import { IAppContext } from "../../../contexts/appContext/AppContext.types";
import {
    BasicInputSizes,
    EditableTextSizes,
    FieldType,
    NavigationSource,
    QueryParam,
    RowAction,
    ValueType
} from "../../../enums";
import { TValue } from "../../../global.types";
import { ModelEvent } from "../../../model/Model";
import BindingContext, { createBindingContext, createPath, IEntity } from "../../../odata/BindingContext";
import { getQueryParameters } from "../../../routes/Routes.utils";
import DateType from "../../../types/Date";
import { FormStorage, IFormStorageDefaultCustomData } from "../../../views/formView/FormStorage";
import {
    reloadAccountsAndAssignments,
    setCorrectSpecialItemsForAccountAssignment
} from "../../accountAssignment/AccountAssignment.utils";


export const clearOriginalDocumentSwitchPath = "##clearOriginalDocumentSwitch##";
export type TCorrectiveDocumentEntity = (ICorrectiveInvoiceReceivedEntity | ICorrectiveInvoiceIssuedEntity) & {
    [clearOriginalDocumentSwitchPath]: boolean;
};

export interface ICorrectiveFormCustomData extends IFormStorageDefaultCustomData {
    // set to true after the first time fetchAndApplyCorrectedDocuments was called
    wasCorrectedDocumentFetched: boolean;
}

export const ADD_EXTERNAL_CORRECTIVE_DOC_ACTION_ID = "AddExternalCorrectiveDoc";

const DOC_ITEMS_PROPERTIES = [
    "DocumentItems/Amount",
    "DocumentItems/AmountNet",
    "DocumentItems/AmountVat",
    "DocumentItems/TransactionAmount",
    "DocumentItems/TransactionAmountNet",
    "DocumentItems/TransactionAmountVat"
];

export const amountFields = ["Amount", "AmountNet", "AmountVat", "TransactionAmount", "TransactionAmountNet", "TransactionAmountVat"];

export const fetchAndApplyCorrectedDocuments = async (storage: FormStorage, partialCorrectedDocs: IDocumentEntity[]): Promise<void> /*Promise<IDocumentEntity>*/ => {
    // should only apply  for new document and only the first time
    // https://solitea-cz.atlassian.net/browse/DEV-24313
    // if user changes the corrected document to something else, it should not be replaced again
    const corrDocStorage = storage as unknown as FormStorage<TCorrectiveDocumentEntity, ICorrectiveFormCustomData>;
    const shouldApplyFetchedData = storage.data.bindingContext.isNew() && !corrDocStorage.getCustomData().wasCorrectedDocumentFetched;


    if (partialCorrectedDocs.length === 0) {
        return;
    }

    corrDocStorage.setCustomData({
        wasCorrectedDocumentFetched: true
    });
    storage.setBusy(true);

    // todo some better way? allowList vs blockList, same problem in DocumentFormView
    // fields that are not even download for the corrected document
    const EXCLUDED_FIELDS = [
        CorrectiveInvoiceReceivedEntity.CorrectedDocuments, "Explanation", "Id",
        DocumentEntity.Locks, ...Object.values(DocumentLockEntity).map(val => `${DocumentEntity.Locks}/${val}`),
        "DocumentTypeCode", "NumberOurs",
        "NumberRange", "NumberRange/Definition/Name", "NumberRange/NextNumber", "NumberRange/Definition",
        `${CorrectiveInvoiceReceivedEntity.CorrectedDocuments}/${DocumentEntity.ExchangeRatePerUnit}`,
        ...(DOC_ITEMS_PROPERTIES.map(prop => `${CorrectiveInvoiceReceivedEntity.CorrectedDocuments}/${prop}`)),
        CorrectiveInvoiceReceivedEntity.ExternalCorrectedDocumentNumber,
        ...AttachmentsAdditionalProperties[0].additionalProperties.filter(property => property.id.startsWith("File")).map(property => `Attachments/File/${property.id}`)
    ];

    // fields that are downloaded for the corrected document,
    // but not copied over to the corrective document
    const NOT_COPIED_FIELDS: (keyof IDocumentEntity)[] = [
        DocumentEntity.ClearedStatus, DocumentEntity.ClearedStatusCode,
        DocumentEntity.DocumentStatus, DocumentEntity.DocumentStatusCode,
        DocumentEntity.PostedStatus, DocumentEntity.PostedStatusCode,
        DocumentEntity.DocumentVatStatementStatus, DocumentEntity.DocumentVatStatementStatusCode
    ];

    if (storage.data.bindingContext.getEntityType().getName() === EntityTypeName.CorrectiveInvoiceReceived) {
        // NumberTheirs of corrective document is different from NumberTheir of the corrected document
        // => don't copy it
        EXCLUDED_FIELDS.push(DocumentEntity.NumberTheirs);
    }

    const corrInvMergedDef = cloneDeep((storage as FormStorage).data.mergedDefinition);

    for (const ignoredField of EXCLUDED_FIELDS) {
        delete corrInvMergedDef[ignoredField];
    }

    for (const key of Object.keys(corrInvMergedDef)) {
        if (key.startsWith("DeferredPlans") || key.startsWith(DocumentEntity.Attachments)) {
            // maybe this would be more efficient than excluded fields approach? for every collection at least
            delete corrInvMergedDef[key];
        }
    }

    // don't copy any of the date fields
    const dateGroup = storage.data.definition.groups.find(group => group.id === "date");

    for (const row of dateGroup.rows) {
        for (const field of row) {
            delete corrInvMergedDef[field.id];
        }
    }

    // do we want to keep items if user already made some or not?
    // behavior kind of changed when multi pairing was introduced

    // for (const vals of storage.data.bindingContext.iterateNavigation("Items", storage.data.entity.Items)) {
    //     if (storage.isDirty(vals.bindingContext)) {
    //         for (const key of Object.keys(corrInvMergedDef)) {
    //             if (key.startsWith("Items/")) {
    //                 delete corrInvMergedDef[key];
    //             }
    //         }
    //
    //         break;
    //     }
    // }

    // BindingContext.each(storage.data.entity, storage.data.bindingContext, (value, bc) => {
    //     if (storage.isDirty(bc) && !bc.isNavigation()) {
    //         delete corrInvMergedDef[bc.getNavigationPath()];
    //     }
    //
    //     return true;
    // });


    const columns = (storage as FormStorage).convertMergedDefToColumns(corrInvMergedDef);
    // documents of multiple different document types can be paired
    // => use batch and fetch documents of each type in one request
    const documentsByType: Partial<Record<DocumentTypeCode, IDocumentEntity[]>> = {};

    for (const doc of partialCorrectedDocs) {
        const docType = doc.DocumentTypeCode as DocumentTypeCode;

        if (!documentsByType[docType]) {
            documentsByType[docType] = [];
        }

        documentsByType[docType].push(doc);
    }

    const batch = storage.oData.batch();

    batch.beginAtomicityGroup("group1");

    for (const [docType, documents] of Object.entries(documentsByType)) {
        const entitySetName = getEntitySetByDocumentType(docType as DocumentTypeCode);
        const bc = createBindingContext(entitySetName, storage.oData.getMetadata());

        prepareQueryForBatch({
            batch,
            bindingContext: bc,
            fieldDefs: columns,
            settings: {
                "": {
                    filter: `Id in (${transformToODataString(documents.map(doc => doc.Id), ValueType.Number)})`
                }
            }
        });
    }

    const results = await batch.execute();
    const correctedDocuments = (results.flatMap(res => (res.body as ODataQueryResult).value) as IDocumentDraftEntity[])
        .map(fetchedDoc => {
            return {
                ...fetchedDoc,
                // we need to copy _metadata to get correct type of the document
                _metadata: partialCorrectedDocs.find(doc => doc.Id === fetchedDoc.Id)?._metadata
            };
        });

    if (shouldApplyFetchedData) {
        let correctiveDocument: Partial<TCorrectiveDocumentEntity> = {};

        // if only one doc is paired, use its data
        if (correctedDocuments.length === 1) {
            correctiveDocument = cloneDeep(correctedDocuments[0]);

            for (const key of NOT_COPIED_FIELDS) {
                delete correctiveDocument[key];
            }

        } else {
            // if multiple - apply data from the corrected documents
            // only transfer BusinessPartner and TransactionCurrency
            // https://solitea-cz.atlassian.net/browse/DEV-23122?focusedCommentId=25904
            correctiveDocument[DocumentEntity.BusinessPartner] = cloneDeep(correctedDocuments[0].BusinessPartner);
            correctiveDocument[DocumentEntity.TransactionCurrency] = cloneDeep(correctedDocuments[0].TransactionCurrency);
            correctiveDocument[DocumentEntity.TransactionCurrencyCode] = correctedDocuments[0].TransactionCurrencyCode;
        }

        delete correctiveDocument.Id;
        delete correctiveDocument.DocumentDraft;

        // amounts should be applied as negative values
        let amountSum = 0;
        let transactionAmountSum = 0;

        for (const item of ((correctiveDocument.Items ?? []) as IEntity[])) {
            for (const amountField of amountFields) {
                if (isDefined(item[amountField])) {
                    item[amountField] = item[amountField] !== 0 ? -1 * item[amountField] : 0;

                    if (amountField === "Amount") {
                        amountSum += item[amountField];
                    } else if (amountField === "TransactionAmount") {
                        transactionAmountSum += item[amountField];
                    }
                }
            }
        }

        // apply the correct value even for summary items (TransactionAmount, Amount)
        // to prevent flickering of the summery item value
        correctiveDocument.Amount = amountSum;
        correctiveDocument.TransactionAmount = transactionAmountSum;

        // prevent DocumentFormView.correctVatSelectionFields
        // from changing the vats retrieved from the corrected document
        // DEV-24261
        setDirtyFlag(storage, storage.data.bindingContext.navigate(SAVED_VATS_PATH));

        storage.data.entity = {
            ...storage.data.entity,
            ...correctiveDocument
        };

        delete storage.data.entity[CorrectiveInvoiceReceivedEntity.CorrectedDocuments];

        // this has to be called, to prevent validation errors on item account assignment fields
        setCorrectSpecialItemsForAccountAssignment(storage);

        // prevent navigation errors in BindingContext.each
        // CorrectedDocument is Document type, but there can be properties of some extended document that would cause "navigate" to fail
        delete storage.data.entity[CorrectiveInvoiceReceivedEntity.CorrectedDocuments];

        // then remove errors if some present
        BindingContext.each(storage.data.entity, storage.data.bindingContext, (value, bc) => {
            // only validate fields, not their "parent" bc
            if (!bc.getKey()) {
                storage.validateField(bc);
                setDirtyFlag(storage, bc);
            }

            return true;
        });
    }

    // then add the CorrectedDocument to again
    // prevent navigation errors in BindingContext.each
    // CorrectedDocument is Document type, but there can be properties of some extended document that would cause "navigate" to fail
    storage.data.entity[CorrectiveInvoiceReceivedEntity.CorrectedDocuments] = correctedDocuments;
    storage.clearErrorByPath(CorrectiveInvoiceReceivedEntity.CorrectedDocuments);

    // call DocumentFormView.onAfterLoad to call all necessary handlers
    // it should remove the storage busy state as well
    storage.emitter.emit(ModelEvent.AfterLoad, true);
};

export const isExternalCorrectiveDoc = (storage: FormStorage): boolean => {
    return !!(storage.data?.entity as TCorrectiveDocumentEntity)?.ExternalCorrectedDocumentType?.Code;
};

export const onAfterCorrectiveInvoiceInitialLoad = async (storage: FormStorage): Promise<void> => {
    // BE managed to provide items on CorrectedDocument, so we can retrieve them in the original request,
    // but for some reason, the prop is called DocumentItems instead of Items
    // => rename it, so that ItemsSummary can retrieve data from where it expects them
    if (storage.data.entity.CorrectedDocument?.DocumentItems) {
        storage.data.entity.CorrectedDocument.Items = storage.data.entity.CorrectedDocument.DocumentItems;
        delete storage.data.entity.CorrectedDocument.DocumentItems;
        storage.refresh();
    }


    const entity = storage.getEntity<TCorrectiveDocumentEntity>();

    // Set correct value and editability of the ClearOriginalDocumentSwitch based on existing CorrectiveWithClearing link,
    // and clear status of the both corrective a corrected document
    if (entity.CorrectedDocuments?.length > 1) {
        storage.setValueByPath(clearOriginalDocumentSwitchPath, false);
    } else {
        const hasCorrectiveWithClearingLink = entity.DocumentLinks?.some(link => link.Type.Code === DocumentLinkTypeCode.CorrectiveWithClearing);

        storage.setValueByPath(clearOriginalDocumentSwitchPath, storage.data.bindingContext.isNew() || hasCorrectiveWithClearingLink);
        storage.setValueByPath(CREATE_RECEIPT_PATH, false);

    }

    const corrDocStorage = storage as unknown as FormStorage<TCorrectiveDocumentEntity, ICorrectiveFormCustomData>;
    corrDocStorage.setCustomData({
        wasCorrectedDocumentFetched: false
    });

    const isExternal = isExternalCorrectiveDoc(storage);
    const correctionGroup = storage.data.definition.groups.find(group => group.id === "correction");

    correctionGroup.title = i18next.t(`CorrectiveInvoicesShared:FormGroup.${isExternal ? "ExternalCorrectiveDoc" : "Correction"}`);
};

/** Called after the selection and load of the corrected document */
export const onAfterCorrectiveInvoiceCorrectedDocumentLoad = async (storage: FormStorage, refreshCurrencyExchangeRateAfterDateChange: (withoutConfirmation?: boolean) => Promise<void>): Promise<void> => {
//https://solitea-cz.atlassian.net/browse/DEV-23516
    if (storage.getEntity<TCorrectiveDocumentEntity>().CorrectedDocuments?.length > 1) {
        refreshCurrencyExchangeRateAfterDateChange(true);
        storage.refresh();
    }

    if (storage.getValueByPath(clearOriginalDocumentSwitchPath)) {
        storage.setValueByPath(CREATE_RECEIPT_PATH, false);
    }

    // this is most likely called via emitter from CommonCorrectiveSharedUtils
    // => the loaded account assignment/documents doesn't have to correspond to the current fiscal year => make them correct
    await reloadAccountsAndAssignments(storage, storage.data.entity.FiscalYear);
};

export const handleClearOriginalDocumentSwitchChange = (e: ISmartFieldChange, storage: FormStorage): void => {
    if (e.bindingContext.getPath() !== clearOriginalDocumentSwitchPath) {
        return;
    }

    if (e.value) {
        const currentExRate = storage.getValueByPath(CorrectiveInvoiceReceivedEntity.ExchangeRatePerUnit);
        if (!currentExRate || !isCashBasisAccountingCompany(storage.context)) {
            const correctiveDocument = storage.getEntity<TCorrectiveDocumentEntity>();
            const exRate = correctiveDocument.CorrectedDocuments[0]?.ExchangeRatePerUnit;

            if (exRate) {
                storage.setValueByPath(CorrectiveInvoiceReceivedEntity.ExchangeRatePerUnit, exRate);
            }
        }

        storage.setValueByPath(CREATE_RECEIPT_PATH, false);
    }
};

export const addCorrectionDefinition = (definition: IDefinition, context: IAppContext, isReceivableDocType?: boolean) => {
    definition.table.columnDefinition = {
        ...definition.table.columnDefinition,
        [CorrectiveInvoiceReceivedEntity.CorrectedDocuments]: {
            formatter: (val: TValue, args) => {
                const entity = args.entity as TCorrectiveDocumentEntity;

                if (entity.ExternalCorrectedDocumentNumber) {
                    return entity.ExternalCorrectedDocumentNumber;
                }

                const correctedDocs = entity.CorrectedDocuments;
                const value: React.ReactNode[] = [];

                for (let i = 0; i < correctedDocs.length; i++) {
                    const correctedDoc = correctedDocs[i];
                    value.push(
                        <React.Fragment key={correctedDoc.Id}>
                            {getNavLinkComponent(correctedDoc.NumberOurs, {
                                route: `${getRouteByDocumentType(correctedDoc.DocumentTypeCode as DocumentTypeCode)}/${correctedDoc.Id}`,
                                context: args.storage.context,
                                storage: args.storage
                            }, getIntentNavParams)}
                            {i < correctedDocs.length - 1 ? ", " : ""}
                        </React.Fragment>
                    );
                }

                return {
                    value: value,
                    tooltip: correctedDocs.map(correctedDoc => correctedDoc.NumberOurs).join(", ")
                };
            },
            additionalProperties: [
                { id: DocumentEntity.NumberOurs },
                { id: DocumentEntity.DocumentTypeCode },
                { id: `/${CorrectiveInvoiceReceivedEntity.ExternalCorrectedDocumentNumber}` }
            ]
        }
    };

    definition.table.columns = [draftInfoPath, "NumberOurs", CorrectiveInvoiceReceivedEntity.CorrectedDocuments, DocumentStatusLocalPath, "BusinessPartner", "DateIssued", "DateDue", "Amount",
        "TransactionAmountToReceive", "TransactionAmountToPay", "Note"];

    // combined filter for ExternalCorrectedDocumentNumber and CorrectedDocuments/NumberOurs
    // use ExternalCorrectedDocumentNumber instead of CorrectedDocuments/NumberOurs for the filter definition,
    // because the way TableStorage.createFilterQueryRecursive works for collection filters,
    // it is not possible to use "/" in filterName to get OUTSIDE of the collection filter, everything is always nested in it.
    definition.table.filterBarDef[0].filterDefinition[createPath(CorrectiveInvoiceReceivedEntity.ExternalCorrectedDocumentNumber)] = {
        label: i18next.t("CorrectiveInvoicesShared:Form.CorrectedDocuments"),
        isValueHelp: false,
        filterName: ["", `/${createPath(CorrectiveInvoiceReceivedEntity.CorrectedDocuments, DocumentEntity.NumberOurs)}`]
    };
    definition.table.filterBarDef[0].defaultFilters.push(CorrectiveInvoiceReceivedEntity.ExternalCorrectedDocumentNumber);

    definition.table.additionalProperties = [
        ...definition.table.additionalProperties,
        { id: "TransactionAmountToReceive" },
        { id: "TransactionAmountToPay" },
        { id: createPath(DocumentEntity.BankAccount, BankAccountEntity.Country, CountryEntity.Code) },
        { id: createPath(DocumentEntity.BankAccount, BankAccountEntity.Country, CountryEntity.IsSepa) }
    ];

    definition.table.draftDef.draftPropsBlacklist = [
        ...definition.table.draftDef.draftPropsBlacklist,
        "TransactionAmountToReceive", "TransactionAmountToPay"
    ];

    const itemsGroup = definition.form.groups.find(group => group.id === "Items");

    itemsGroup.lineItems.actions = null;

    definition.form.groups.unshift({
        id: "correction",
        title: i18next.t("CorrectiveInvoicesShared:FormGroup.Correction"),
        rows: [
            [{ id: CorrectiveInvoiceReceivedEntity.CorrectedDocuments }, { id: clearOriginalDocumentSwitchPath }],
            [{ id: CorrectiveInvoiceReceivedEntity.ExternalCorrectedDocumentNumber }],
            [{ id: "Explanation" }]
        ]
    });

    if (definition.form.fieldDefinition?.[DocumentDraftEntity.DateReceived]) {
        // https://solitea-cz.atlassian.net/browse/DEV-24390
        definition.form.fieldDefinition[DocumentDraftEntity.DateReceived].isRequired = true;
    }

    // definition.form

    // disable some fields if CorrectedDocument is selected
    const fnEnhanceIsDisabled = (fieldName: CorrectiveInvoiceReceivedEntity, newIsDisabled: TInfoValue<boolean>) => {
        if (definition.form.fieldDefinition[fieldName]) {
            const originalIsDisabled = definition.form.fieldDefinition[fieldName].isDisabled;

            definition.form.fieldDefinition[fieldName].isDisabled = (args: IGetValueArgs) => {
                let isDisabled: boolean;

                if (originalIsDisabled) {
                    isDisabled = getValue(originalIsDisabled, args);
                }

                isDisabled = isDisabled || getValue(newIsDisabled, args);

                return isDisabled;
            };
        }
    };

    fnEnhanceIsDisabled(CorrectiveInvoiceReceivedEntity.TransactionCurrency, (args: IGetValueArgs) => {
        return args.storage.getEntity<TCorrectiveDocumentEntity>().CorrectedDocuments?.length > 0;

    });

    if (!isCashBasisAccountingCompany(context)) {
        fnEnhanceIsDisabled(CorrectiveInvoiceReceivedEntity.ExchangeRatePerUnit, (args: IGetValueArgs) => {
            const correctiveDocument = args.storage.getEntity<TCorrectiveDocumentEntity>();

            return !!correctiveDocument[clearOriginalDocumentSwitchPath] && !isExternalCorrectiveDoc(args.storage as FormStorage);
        });
    }

    definition.form.fieldDefinition.Explanation = {
        type: FieldType.EditableText,
        isConfirmable: true,
        width: EditableTextSizes.M,
        fieldSettings: {
            placeholder: i18next.t("CorrectiveInvoicesShared:Form.ExplanationPlaceholder")
        }
    };

    // we need to fetch existing Corrective document link, to remove it
    // if the user changes the CorrectedDocument
    definition.form.additionalProperties = [
        ...definition.form.additionalProperties,
        { id: "DocumentLinks/Type" },
        { id: "DocumentLinks/TargetDocument" }
    ];

    definition.form.querySettings = {
        ...definition.form.querySettings,
        "OppositeDocumentLinks": {
            filter: `TypeCode in (${transformToODataString(correctiveDocumentLinkTypes, ValueType.String)})`
        }
    };

    definition.form.fieldDefinition[CorrectiveInvoiceReceivedEntity.CorrectedDocuments] = {
        label: i18next.t("CorrectiveInvoicesShared:Form.CorrectedDocuments"),
        width: BasicInputSizes.XL,
        isRequired: true,
        isReadOnly: false,
        isVisible: (args: IGetValueArgs) => {
            return !isExternalCorrectiveDoc(args.storage as FormStorage);
        },
        fieldSettings: {
            entitySet: EntitySetName.PayingDocuments,
            displayName: "NumberOurs",
            keyPath: "Id",
            transformFetchedItems: transformPairingSelectItems,
            /** We need the items preloaded for:
             *  1. transformFetchedItems to work for the initial value
             *  2. to have items ready when the value changes from the pairing dialog */
            preloadItems: true,
            showTabularHeader: true,
            additionalProperties: [
                { id: "DocumentType" },
                { id: "DocumentStatus" },
                { id: "ClearedStatus" },
                ...correctedDocAdditionalProperties
            ],
            itemsForRender: (items: ISelectItem[], args: IGetValueArgs) => {
                const selectedItems = args.storage.getEntity<TCorrectiveDocumentEntity>().CorrectedDocuments;

                if (!items || !selectedItems || selectedItems.length === 0) {
                    return items;
                }

                const selectedDoc = items.find(item => item.id === selectedItems[0].Id)?.additionalData;


                if (!selectedDoc) {
                    return items;
                }

                return items.filter(item => {
                    return areDocumentsApplicableForSameCorrectiveDoc(item.additionalData, selectedDoc);
                });
            },
            // on select change, we want to propagate _metadata object so that we know the correct document type when saving
            localDependentFields: [
                {
                    from: { id: "_metadata" },
                    to: { id: "_metadata" },
                    navigateFrom: NavigationSource.Itself
                },
                {
                    from: { id: DocumentEntity.DocumentTypeCode },
                    to: { id: DocumentEntity.DocumentTypeCode },
                    navigateFrom: NavigationSource.Itself
                }
            ]
        },
        columns: [
            { id: "NumberOurs", label: i18next.t("CorrectiveInvoicesShared:Form.MultiSelectNumberOursColumnLabel") },
            // allow filtering over NumberTheirs for corrective invoices RECEIVED
            isReceivableDocType ? {
                id: "NumberTheirs"
            } : null,
            { id: "TransactionAmount" },
            {
                id: "BusinessPartner",
                fieldSettings: {
                    displayName: BusinessPartnerEntity.Name
                }
            }
        ].filter(val => val),
        filter: {
            select: isReceivableDocType ? getReceivedDocsWithoutCorrectionFilter : getIssuedDocsWithoutCorrectionFilter
        },
        additionalProperties: [
            { id: DocumentEntity.ExchangeRatePerUnit },
            ...DOC_ITEMS_PROPERTIES.map(prop => ({ id: prop }))
        ],
        type: FieldType.Custom,
        render: ({ storage, props, events }) => {
            const headerData = (): IReadOnlyListItem[] => {
                const entity = storage.data.entity as IInvoiceIssuedEntity;
                const rootBc = storage.data.bindingContext;

                return [
                    {
                        label: storage.getInfo(rootBc.navigate("NumberOurs")).label,
                        value: entity.NumberOurs
                    },
                    {
                        label: storage.getInfo(rootBc.navigate("DateIssued")).label,
                        value: DateType.format(entity.DateIssued)
                    }
                ];
            };

            const updateOriginalDocumentSwitchValue = (correctedDocuments: IDocumentEntity[]): void => {
                if (correctedDocuments.length > 1) {
                    storage.setValueByPath(clearOriginalDocumentSwitchPath, false);
                } else {
                    const entity = storage.getEntity<TCorrectiveDocumentEntity>();
                    const isCorrectedDocumentCleared = entity.CorrectedDocuments?.[0]?.ClearedStatusCode === ClearedStatusCode.Cleared;
                    const isCorrectiveDocumentCleared = entity.ClearedStatusCode === ClearedStatusCode.Cleared;

                    storage.setValueByPath(clearOriginalDocumentSwitchPath, !isCorrectedDocumentCleared && !isCorrectiveDocumentCleared);
                }

            };

            const handleChange = async (args: IMultiSelectionChangeArgs) => {
                events.onSelectChange(args);
            };
            const handleBlur = async (e: IInputOnBlurEvent) => {
                const correctedDocuments = storage.getEntity<TCorrectiveDocumentEntity>().CorrectedDocuments ?? [];

                if (e.wasChanged && correctedDocuments.length > 0) {
                    await fetchAndApplyCorrectedDocuments(storage as FormStorage, correctedDocuments);

                    if (correctedDocuments.length > 1) {
                        storage.setValueByPath(clearOriginalDocumentSwitchPath, false);
                    }

                    updateOriginalDocumentSwitchValue(correctedDocuments);
                } else {
                    events.onBlur(e);
                }
            };

            const handlePairing = async (selected: BindingContext[], entities: IEntity[]) => {
                if (selected?.length > 0) {
                    await fetchAndApplyCorrectedDocuments(storage as FormStorage, entities);

                    storage.clearAndSetValue(props.parentProps.bindingContext, entities);
                } else {
                    storage.clearValue((props.parentProps.bindingContext));
                }

                updateOriginalDocumentSwitchValue(entities);

                storage.refreshFields();
            };

            const getFirstSelectedRow = (tableStorage: TSmartODataTableStorage) => {
                const activeRows = tableStorage.tableAPI.getState()?.activeRows;

                if (activeRows.size === 0) {
                    return null;
                }

                const firstRowKey = Array.from(activeRows)[0];
                const rowBc = createBindingContext(firstRowKey, tableStorage.oData.metadata);

                return getRow(tableStorage.tableAPI.getState().rows, rowBc);
            };
            // disable row action if some row is already selected,
            // and has different BusinessPartner,TransactionCurrency or ExchangeRate
            const isRowWithoutAction = (tableStorage: TSmartODataTableStorage, rowId: TId, action: RowAction, row: IRow): boolean => {
                const selectedRow = getFirstSelectedRow(tableStorage);


                const currentDocument = row.customData.entity as IDocumentEntity;
                const isNotPosted = currentDocument.PostedStatusCode === PostedStatusCode.NotPosted;

                if (!selectedRow) {
                    return isNotPosted;
                }

                const selectedDocument = selectedRow.customData.entity as IDocumentEntity;

                return isNotPosted || !areDocumentsApplicableForSameCorrectiveDoc(selectedDocument, currentDocument);
            };

            const presetDefaultFilters = (tableStorage: TSmartODataTableStorage): void => {
                setNotPostedStatusFilter(tableStorage);
                tableStorage.applyFilters();
            };

            return (
                <Field label={props.info.label}
                       isRequired={props.isRequired}
                       isDisabled={props.isDisabled}
                       isReadOnly={props.isReadOnly}
                       name={props.name}
                       auditTrailData={props.auditTrailData}>
                    <SmartPairingMultiSelect storage={storage}
                                             bindingContext={props.parentProps.bindingContext}
                                             fieldInfo={props.info}
                                             auditTrailData={props.auditTrailData}
                                             {...props}
                                             {...events}
                                             onChange={handleChange}
                                             onBlur={handleBlur}
                                             onPair={handlePairing}
                                             withoutMainToggle={true}
                                             dialogTableViewProps={{
                                                 title: storage.t(`CorrectiveInvoices${isReceivableDocType ? "Received" : "Issued"}:TitleSingular`),
                                                 secondaryTitle: storage.t(`CorrectiveInvoices${isReceivableDocType ? "Received" : "Issued"}:Form.SecondaryTitle`),
                                                 headerData: headerData,
                                                 confirmText: storage.t("Document:Pair.ToPair"),
                                                 successMessage: storage.t("CorrectiveInvoicesShared:PairingDialog.SuccessSubtitle"),
                                                 isRowWithoutAction,
                                                 presetDefaultFilters,
                                                 withoutMainToggle: true
                                             }}
                                             getDefinition={getDefinition.bind(this, isReceivableDocType)}
                                             {...props.parentProps.fieldProps}
                    />
                </Field>
            );
        }
    };

    definition.form.fieldDefinition[clearOriginalDocumentSwitchPath] = {
        type: FieldType.Switch,
        label: i18next.t("CorrectiveInvoicesShared:Form.ClearOriginalDocument"),
        affectedFields: [{ id: DocumentDraftEntity.ExchangeRatePerUnit }],
        isVisible: (args: IGetValueArgs) => {
            return !isExternalCorrectiveDoc(args.storage as FormStorage);
        },
        fieldSettings: {
            type: SwitchType.YesNo
        },
        isDisabled: (args: IGetValueArgs) => {
            const entity = args.storage.getEntity<TCorrectiveDocumentEntity>();

            // if multiple CorrectedDocuments documents => switch always disabled
            if (entity.CorrectedDocuments?.length > 1) {
                return true;
            }

            // for one CorrectedDocument,
            // it is disabled if either the CorrectedDocuments is fully cleared or the CorrectiveDocument is fully cleared,
            // and the is not DocumentLinkTypeCode.CorrectiveWithClearing link between them
            const isCorrectedDocumentCleared = entity.CorrectedDocuments?.[0]?.ClearedStatusCode === ClearedStatusCode.Cleared;
            const isCorrectiveDocumentCleared = entity.ClearedStatusCode === ClearedStatusCode.Cleared;
            const hasCorrectiveWithClearingLink = entity.DocumentLinks?.some(link => link.Type.Code === DocumentLinkTypeCode.CorrectiveWithClearing);

            return !hasCorrectiveWithClearingLink && (isCorrectedDocumentCleared || isCorrectiveDocumentCleared);
        }
    };

    definition.form.fieldDefinition[CorrectiveInvoiceReceivedEntity.ExternalCorrectedDocumentType] = {
        defaultValue: (args: IGetValueArgs) => {
            const type = getQueryParameters()[QueryParam.Type];

            if (type) {
                if (type === ExternalCorrectedDocumentTypeCode.DontClear) {
                    return ExternalCorrectedDocumentTypeCode.DontClear;
                } else {
                    return ExternalCorrectedDocumentTypeCode.Clear;
                }
            }

            return null;
        }
    };
    definition.form.fieldDefinition[CorrectiveInvoiceReceivedEntity.ExternalCorrectedDocumentNumber] = {
        label: i18next.t("CorrectiveInvoicesShared:Form.ExternalDocument"),
        isVisible: (args: IGetValueArgs) => {
            return isExternalCorrectiveDoc(args.storage as FormStorage);
        },
        isRequired: (args: IGetValueArgs) => {
            return isExternalCorrectiveDoc(args.storage as FormStorage);
        },
        width: BasicInputSizes.XXL,
        additionalProperties: [
            { id: `/${CorrectiveInvoiceReceivedEntity.ExternalCorrectedDocumentType}` }
        ]
    };

    // change behavior of CREATE_RECEIPT_PATH
    const createReceiptDef = definition.form.fieldDefinition[CREATE_RECEIPT_PATH];
    const isCreateReceiptDisabled: TGetValueFn<boolean> = (args: IGetValueArgs) => {
        const entity = args.storage.getEntity<TCorrectiveDocumentEntity>();

        if (isExternalCorrectiveDoc(args.storage as FormStorage)) {
            // only allow clearing for the DontClear type
            return getQueryParameters()[QueryParam.Type] !== ExternalCorrectedDocumentTypeCode.DontClear;
        }

        return !!entity[clearOriginalDocumentSwitchPath];
    };
    createReceiptDef.isDisabled = createReceiptDef.isDisabled ? ifAny(createReceiptDef.isDisabled, isCreateReceiptDisabled) : isCreateReceiptDisabled;

    const tabsGroup: IFormGroupDef = definition.form.groups.find(group => group.id === "Tabs");
    const tabTable = getDocumentPairedTable({
        selectQuery: `TypeCode in (${transformToODataString(correctiveDocumentLinkTypes, ValueType.String)})`,
        isCbaCompany: isCashBasisAccountingCompany(context)
    });

    if (isReceivableDocType) {
        const numberTheirCol = {
            id: "PairedDocument/NumberTheirs"
        };
        const pairedDocIndex = tabTable.columns.findIndex(col => col.id === "PairedDocument");

        if (pairedDocIndex >= 0) {
            tabTable.columns.splice(pairedDocIndex + 1, 0, numberTheirCol);
        } else {
            tabTable.columns.push(numberTheirCol);
        }
    }

    tabsGroup.tabs.push({
        id: "correctedDocs",
        title: i18next.t("Document:FormTab.Corrections"),
        table: tabTable
    });
};

export const onBeforeCorrectiveFormSave = (storage: FormStorage): TCorrectiveDocumentEntity => {
    const clonedEntity: TCorrectiveDocumentEntity = cloneDeep(storage.data.entity) as TCorrectiveDocumentEntity;
    const origEntity: TCorrectiveDocumentEntity = cloneDeep(storage.data.origEntity) as TCorrectiveDocumentEntity;
    const correctedDocuments = clonedEntity[CorrectiveInvoiceReceivedEntity.CorrectedDocuments] ?? [];
    const shouldClearOriginalDoc = clonedEntity[clearOriginalDocumentSwitchPath];
    const docLinkType = shouldClearOriginalDoc ? DocumentLinkTypeCode.CorrectiveWithClearing : DocumentLinkTypeCode.CorrectiveWithoutClearing;

    clonedEntity.DocumentLinks = [];

    for (const correctedDocument of correctedDocuments) {
        let link: IDocumentLinkEntity;
        const oldLink = origEntity.DocumentLinks?.find(link => link.TargetDocument.Id === correctedDocument.Id);

        if (oldLink) {
            // copy the link if already exists
            link = oldLink;
        } else {
            // add the link if it didn't exist before
            link = {
                TargetDocument: {
                    Id: correctedDocument.Id,
                    _metadata: correctedDocument?._metadata
                }
            };
        }

        // set Type based on the current clearOriginalDocumentSwitchPath
        delete link.Type;
        link = {
            ...link,
            TypeCode: docLinkType
        };
        clonedEntity.DocumentLinks.push(link);
    }

    // again, prevent bc navigation errors
    delete clonedEntity[CorrectiveInvoiceReceivedEntity.CorrectedDocuments];

    if (isExternalCorrectiveDoc(storage)) {
        // otherwise, yup validation would fail on the null value
        delete storage.data.entity[CorrectiveInvoiceReceivedEntity.CorrectedDocuments];
    }

    return clonedEntity;
};

// ! needed so that we can check which rows to disable when pairing
export const correctedDocAdditionalProperties = [
    { id: createPath(DocumentDraftEntity.BusinessPartner, BusinessPartnerEntity.Name) },
    { id: createPath(DocumentDraftEntity.BusinessPartner, BusinessPartnerEntity.LegalNumber) },
    { id: DocumentDraftEntity.TransactionCurrency },
    { id: DocumentDraftEntity.Currency },
    { id: PaymentDocumentEntity.PostedStatus },
    { id: DocumentDraftEntity.DocumentStatus },
    { id: DocumentDraftEntity.ExchangeRatePerUnit }
];

/** Checks whether two different documents are applicable to be paired with one corrective documents.
 * Compares BusinessPartner,TransactionCurrency or ExchangeRate. If same, then applicable. */
export const areDocumentsApplicableForSameCorrectiveDoc = (doc1: IDocumentEntity, doc2: IDocumentEntity) => {
    const transactionCurrencyIsSame = doc1.TransactionCurrencyCode === doc2.TransactionCurrencyCode;
    const businessPartnerIsSame = doc1.BusinessPartner.Name === doc2.BusinessPartner.Name
        && doc1.BusinessPartner.LegalNumber === doc2.BusinessPartner.LegalNumber;

    return transactionCurrencyIsSame && businessPartnerIsSame;
};