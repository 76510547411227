import { AppContext } from "../../../contexts/appContext/AppContext.types";
import { FormViewForExtend, IFormViewProps } from "../../../views/formView/FormView";
import { IPrAttendanceEntity } from "@odata/GeneratedEntityTypes";
import { ISmartFieldChange } from "@components/smart/smartField/SmartField";
import React, { ReactElement } from "react";
import Switch, { SwitchType } from "../../../components/inputs/switch/Switch";
import { ButtonGroup } from "react-bootstrap";
import { PrAttendanceStatusCode } from "@odata/GeneratedEnums";
import { getMonthDays, isSameDay } from "@components/inputs/date/utils";
import { IFormStorageDefaultCustomData, IFormStorageSaveResult, ISaveArgs } from "../../../views/formView/FormStorage";
import {
    getDaySaldo,
    getMonthHolidays, getWorkingPattern,
    getWorkingPatternDaysMap,
    parseDayActionsFromData,
    refreshBalance,
    transformWorkIntervalsBeforeSave
} from "./Attendance.utils";
import { IDayAction } from "@components/calendar/Calendar.utils";
import { cloneDeep, debounce } from "lodash";
import { Status } from "../../../enums";
import { tWithFallback } from "../../documents/Document.utils";
import { SAVE_DRAFT_DELAY } from "../../../constants";
import { UnregisterCallback } from "history";
import { withPermissionContext } from "../../../contexts/permissionContext/withPermissionContext";
import { getUtcDayjs } from "../../../types/Date";

export interface IAttendanceCustomData extends IFormStorageDefaultCustomData {
    selectedDays: Set<number>;
    saldoMap: Record<number, number>;
    hoursFundMap: Record<number, number>;
    actions?: Record<number, IDayAction[]>;
    etag?: string;
    holidays?: Date[];
}

interface IProps extends IFormViewProps<IPrAttendanceEntity, IAttendanceCustomData> {
}

class AttendanceFormView extends FormViewForExtend<IPrAttendanceEntity, IProps> {
    static contextType = AppContext;
    static defaultProps = {
        title: "Attendance:FormTitle"
    };

    _unblockUrlChanges: UnregisterCallback;
    _ignoreNextBlock = false;

    componentDidMount(): void {
        super.componentDidMount();
        this.catchUrlChanges();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this.flushSave();
        // can be used inside other contexts like audit trail or recurring tasks, which doesn't have history object, only call conditionally
        this._unblockUrlChanges?.();
    }

    catchUrlChanges = (): void => {
        this._unblockUrlChanges = this.props.storage.history?.block((args) => {
            if (!this._ignoreNextBlock) {
                const isDifferentPath = args.pathname !== this.props.storage.history.location.pathname;

                if (isDifferentPath) {

                    this.debouncedSave.cancel();
                    const savePromise = this.syncSave(true);

                    if (savePromise) {
                        this.props.storage.setBusy(true);
                        // wait until the draft is saved
                        savePromise.then((res: IFormStorageSaveResult) => {
                            this._ignoreNextBlock = true;
                            this.props.storage.history.push(args);
                            this.props.storage.setBusy(false);
                        });
                        // block url change until attendance is saved
                        return false;
                    }
                }
            } else {
                this._ignoreNextBlock = false;
            }

            // don't actually block the url change
            return null;
        });
    };

    async onAfterLoad(){

        const storage = this.props.storage;
        const monthDate = getUtcDayjs().set("month", this.entity.Month - 1).set("year", this.entity.Year);

        const holidays = await getMonthHolidays(monthDate, this.entity.Year, this.context.getCompany().CountryCode);
        const workingPattern = getWorkingPattern(this.entity);

        const hoursFundMap = getWorkingPatternDaysMap(workingPattern, monthDate, holidays);

        const monthDays = getMonthDays(monthDate);
        const saldoMap = monthDays.reduce((map: Record<number, number>, day) => {
            const dayIndex = day.get("date");
            const dayHoursFund = hoursFundMap[dayIndex] ?? 0;
            const attendanceDay = this.entity.Days?.find(d => isSameDay(day, getUtcDayjs(d.Date)));
            if (attendanceDay) {
                map[dayIndex] = getDaySaldo(attendanceDay, dayHoursFund);
            } else {
                map[dayIndex] = 0;
            }
            return map;
        }, {});

        const actions = parseDayActionsFromData(this.entity.Days);

        storage.setCustomData({
            holidays,
            saldoMap,
            hoursFundMap,
            actions,
            selectedDays: null,
            etag: null
        });
        refreshBalance(storage);

        return super.onAfterLoad();
    }

    handleChange = (e: ISmartFieldChange): void => {
        this.props.storage.handleChange(e);
        this.props.storage.refresh();
        this.debouncedSave();
    };

    handleLock = async (checked: boolean): Promise<void> => {
        this.entity.StatusCode = checked ? PrAttendanceStatusCode.Locked : PrAttendanceStatusCode.ToProcess;

        this.debouncedSave.cancel();
        this.props.storage.setBusy(true);
        await this.save();
    };

    flushSave = async (): Promise<void> => {
        await this.debouncedSave.flush();
    };

    debouncedSave = debounce(() => {
        return this.syncSave(false);
    }, SAVE_DRAFT_DELAY);

    syncSave = async (skipReload?: boolean): Promise<IFormStorageSaveResult> => {
        const storage = this.props.storage;
        if (storage.isDirty()) {
            const savePromise = this.save({
                queryParams: {
                    skipStrictAttendanceValidation: true
                },
                skipLoad: true
            });

            if (!skipReload) {
                savePromise.then(() => {
                    const selectedDays = this.props.storage.getCustomData().selectedDays;
                    this.props.storage.reload({ preserveInfos: true, withoutBusy: true })
                            .then(() => {
                                this.props.storage?.setCustomData({ selectedDays });
                            });
                });
            }
            return savePromise;
        }
        return null;
    };

    onBeforeSave = (): IPrAttendanceEntity => {
        const entity = cloneDeep(this.entity);
        entity.Overtime = Math.max(entity.Balance, 0);
        for (const day of entity.Days) {
            day.Intervals = transformWorkIntervalsBeforeSave(day.Intervals);
        }
        return entity;
    };

    renderButtons = (): ReactElement => {
        const isClosed = this.entity.Status?.Code !== PrAttendanceStatusCode.ToProcess;
        const isProcessed = this.entity.Status?.Code === PrAttendanceStatusCode.Processed;
        return <ButtonGroup>
            <Switch
                    label={this.props.storage.t("Attendance:Form.CheckedAndLocked")}
                    checked={isClosed}
                    isDisabled={isProcessed}
                    onChange={this.handleLock}
                    type={SwitchType.Icons}
            />
        </ButtonGroup>;
    };

    async save(saveArgs?: Partial<ISaveArgs>): Promise<IFormStorageSaveResult> {
        let data;
        const storage = this.props.storage;
        try {
            data = this.onBeforeSave();
        } catch (e) {
            storage.setFormAlert({
                status: Status.Error,
                title: storage.t("Common:General.FormValidationErrorTitle"),
                subTitle: e.message
            });
            this.props.onSaveFail?.();
            this.forceUpdate(this.scrollPageUp);
            return null;
        }

        const isNew = storage.data.bindingContext.isNew();

        if (storage.getCustomData().etag) {
            storage.data.metadata.etag = storage.getCustomData().etag;
        }

        const result = await storage.save({
            data,
            successSubtitle: tWithFallback(storage.data.definition?.translationFiles?.[0], "Validation.Saved", "Common"),
            ...saveArgs
        });

        if (!this._isMounted) {
            return null;
        }

        if (result) {
            const newEtag = result.response[0]?.body?._metadata.etag;
            storage.setCustomData({ etag: newEtag });
        }

        if (!saveArgs?.skipLoad) {
            if (!result) {
                this.props.onSaveFail?.();
                this.forceUpdate(this.scrollPageUp);
                return result;
            }

            this.onAfterSave(isNew, false);
            this.forceUpdate(isNew ? this.scrollPageDown : undefined);
        }

        return result;
    }
}

export default withPermissionContext(AttendanceFormView);