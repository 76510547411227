import { TimeDependentIcon } from "@components/icon";
import { DateRangeSpecialValue, TDisabledDateUnit } from "@components/inputs/date/popup/Calendar.utils";
import { SwitchType } from "@components/inputs/switch/Switch";
import { getSimpleBoolSelectItems, ifAll, IFieldDefFn, IGetValueArgs } from "@components/smart/FieldInfo";
import {
    BirthNumberFieldDef,
    BirthNumberFormatter,
    countryDef,
    CurrencyDef,
    emailFormatter,
    getAddressFields,
    getLabelsDef,
    getLabelsFilterDef,
    PhoneNumberDef,
    phoneNumberFormatter
} from "@components/smart/GeneralFieldDefinition";
import { FilterBarGroup, getDefaultFilterGroupDef } from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { ISummaryItem } from "@components/smart/smartSummaryItem/SmartSummaryItem";
import { IconButtonStyled } from "@components/smart/smartTemporalPropertyButton/SmartTemporalPropertyButton.styles";
import {
    EntitySetName,
    EntityTypeName,
    IPrEmployeeEntity,
    IPrEmploymentEntity,
    PrEmployeeCarEntity,
    PrEmployeeContactAddressEntity,
    PrEmployeeDependentPersonEntity,
    PrEmployeeEntity,
    PrEmployeeForeignAddressEntity,
    PrEmployeeLocalAddressEntity,
    PrEmployeeTemporalEntity,
    PrEmploymentEntity,
    PrEmploymentTemplateEntity,
    PrEmploymentTypeEntity,
    PrIdentityDocumentTypeEntity,
    PrInsuranceCompanyPredefinedEntity,
    PrSexEntity
} from "@odata/GeneratedEntityTypes";
import { CountryCode, PrEmployeeStateCode, PrInsuranceCompanyTypeCode } from "@odata/GeneratedEnums";
import { getEnumDisplayValue, getEnumNameSpaceName } from "@odata/GeneratedEnums.utils";
import { IFormatOptions } from "@odata/OData.utils";
import {
    getTemporalAdditionalProperties,
    getTemporalPropertyFieldDefinition,
    ISmartTemporalPropertyDialogCustomData,
    TemporalValidityFieldsDef,
    TTemporal
} from "@odata/TemporalUtils";
import { getCompanyCurrency } from "@utils/CompanyUtils";
import { isNotDefined } from "@utils/general";
import i18next from "i18next";
import React from "react";

import Field from "../../../components/inputs/field";
import FieldsWrapper from "../../../components/inputs/field/FieldsWrapper";
import SmartField from "../../../components/smart/smartField";
import { IAppContext } from "../../../contexts/appContext/AppContext.types";
import {
    BasicInputSizes,
    FastEntryInputSizes,
    FieldType,
    GroupedField,
    LabelStatus,
    Sort,
    TextAlign,
    ValidatorType
} from "../../../enums";
import { ColoredText } from "../../../global.style";
import { TValue } from "../../../global.types";
import { Model } from "../../../model/Model";
import BindingContext, { createPath } from "../../../odata/BindingContext";
import CurrencyType from "../../../types/Currency";
import { DATE_MAX, DATE_MIN, getUtcDate, getUtcDayjs } from "../../../types/Date";
import { IFormDef } from "../../../views/formView/Form";
import { FormStorage } from "../../../views/formView/FormStorage";
import { ISplitPageTableDef } from "../../../views/table/TableView.utils";
import { userNameWithAvatarFormatter, userNameWithAvatarTableFormatter } from "../../admin/users/UsersDef";
import {
    getBankAccountFieldsDef,
    paymentDetailFields,
    SAVED_ACCOUNTS_PATH
} from "../../banks/bankAccounts/BankAccounts.utils";
import { setDefByEntityType } from "../../getDefByEntityType";
import {
    getNumberOursSummaryDef,
    getNumberRangeFieldDefs,
    NumberRangeAdditionalProperties
} from "../../numberRange/NumberRange.utils";
import { getItemBreadCrumbsText, IDefinition, IGetDefinition } from "../../PageUtils";
import {
    IEmployeeFormCustomData,
    PreviousEmploymentDurationDaysPath,
    PreviousEmploymentDurationYearsPath,
    ShowContactAddressPath,
    ShowDisabilityPath,
    ShowOtherIdentifiers,
    ShowPensionPath,
    ShowStudyPath
} from "./Employee.utils";
import EmployeeFormView from "./EmployeeFormView";

const isContactAddressVisible = (args: IGetValueArgs) => {
    return args.storage.getValueByPath(ShowContactAddressPath);
};
const isDisabilityVisible = (args: IGetValueArgs) => {
    return args.storage.getValueByPath(ShowDisabilityPath);
};
const isStudyVisible = (args: IGetValueArgs) => {
    return args.storage.getValueByPath(ShowStudyPath);
};
const isPensionVisible = (args: IGetValueArgs) => {
    return args.storage.getValueByPath(ShowPensionPath);
};
const isForeigner = (args: IGetValueArgs) => {
    return args.storage.getValueByPath(PrEmployeeEntity.CitizenshipCode) !== CountryCode.CzechRepublic;
};
const isOtherIdentifierVisible = (args: IGetValueArgs) => {
    return args.storage.getValueByPath(ShowOtherIdentifiers);
};

export const getDefinitions: IGetDefinition = (context: IAppContext): IDefinition => {
    const table: ISplitPageTableDef = {
        id: `${EntityTypeName.PrEmployee}Table`,
        filterBarDef: [{
            ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
            defaultFilters: [
                PrEmployeeEntity.NumberOurs,
                PrEmployeeEntity.FirstName,
                PrEmployeeEntity.LastName,
                PrEmployeeEntity.ExternalNumber,
                PrEmployeeEntity.PersonalIdentificationNumber,
                PrEmployeeEntity.Email,
                PrEmployeeEntity.PhoneNumber,
                createPath(PrEmployeeEntity.CurrentTemporalPropertyBag, PrEmployeeTemporalEntity.IsDepositing),
                createPath(PrEmployeeEntity.CurrentTemporalPropertyBag, PrEmployeeTemporalEntity.HealthInsurance)
            ],
            filterDefinition: {
                [PrEmployeeEntity.FirstName]: {},
                [PrEmployeeEntity.LastName]: {},
                [PrEmployeeEntity.NumberOurs]: {},
                [PrEmployeeEntity.ExternalNumber]: {},
                [PrEmployeeEntity.PersonalIdentificationNumber]: {},
                [PrEmployeeEntity.Email]: {},
                [PrEmployeeEntity.PhoneNumber]: {},
                [createPath(PrEmployeeEntity.CurrentTemporalPropertyBag, PrEmployeeTemporalEntity.IsDepositing)]: {},
                ...getLabelsFilterDef("Labels"),
                [createPath(PrEmployeeEntity.CurrentTemporalPropertyBag, PrEmployeeTemporalEntity.HealthInsurance)]: {
                    fieldSettings: {
                        displayName: "Name"
                    }
                }

            },
            isValueHelp: true
        }],
        initialSortBy: [{ id: PrEmployeeEntity.NumberOurs, sort: Sort.Desc }],
        columns: [
            PrEmployeeEntity.NumberOurs,
            PrEmployeeEntity.FirstName,
            PrEmployeeEntity.PrEmployeeStateCode,
            PrEmployeeEntity.ExternalNumber,
            PrEmployeeEntity.PersonalIdentificationNumber,
            PrEmployeeEntity.Email,
            PrEmployeeEntity.PhoneNumber,
            createPath(PrEmployeeEntity.CurrentTemporalPropertyBag, PrEmployeeTemporalEntity.IsDepositing)
        ],
        columnDefinition: {
            [PrEmployeeEntity.NumberOurs]: {
                label: i18next.t("Employee:Table.Number")
            },
            [PrEmployeeEntity.FirstName]: {
                label: i18next.t("Employee:Table.EmployeeName"),
                width: BasicInputSizes.L,
                formatter: userNameWithAvatarTableFormatter,
                additionalProperties: [{ id: `/${PrEmployeeEntity.LastName}` }]
            },
            [PrEmployeeEntity.BornLastName]: {},
            [PrEmployeeEntity.PrEmployeeStateCode]: {
                label: i18next.t("Employee:Table.EmployeeStatus"),
                formatter: (val: TValue) => {
                    const code = val as PrEmployeeStateCode;
                    const translatedValue = getEnumDisplayValue(EntityTypeName.PrEmployeeState, val as string);

                    if (code === PrEmployeeStateCode.Current) {
                        return {
                            tooltip: code,
                            value: (
                                    <ColoredText color={"C_SEM_text_good"}>
                                        {translatedValue}
                                    </ColoredText>
                            )
                        };
                    }

                    return translatedValue;
                }
            },
            [PrEmployeeEntity.Email]: {
                formatter: emailFormatter
            },
            [PrEmployeeEntity.PhoneNumber]: {
                formatter: phoneNumberFormatter
            },
            [PrEmployeeEntity.TitleBeforeName]: {},
            [PrEmployeeEntity.TitleAfterName]: {},
            [PrEmployeeEntity.DateOfBirth]: {},
            [createPath(PrEmployeeEntity.CurrentTemporalPropertyBag, PrEmployeeTemporalEntity.HealthInsurance)]: {
                fieldSettings: {
                    displayName: "Name"
                }
            },
            [PrEmployeeEntity.ExternalNumber]: {},
            [PrEmployeeEntity.PersonalIdentificationNumber]: {
                label: i18next.t("Employee:Table.BirthNumber"),
                formatter: BirthNumberFormatter
            },
            [createPath(PrEmployeeEntity.CurrentTemporalPropertyBag, PrEmployeeTemporalEntity.IsDepositing)]: {}
        },
        title: i18next.t("Employee:Title"),
        tabs: [
            { id: PrEmployeeStateCode.Current, title: i18next.t("Employee:Table.CurrentEmployeesTab") },
            { id: PrEmployeeStateCode.Former, title: i18next.t("Employee:Table.ExEmployeesTab") },
            { id: null, title: i18next.t("Employee:Table.AllEmployeesTab") }
        ],
        tabsSettings: {
            filterFieldName: "PrEmployeeStateCode"
        },
        additionalProperties: [
            {
                id: PrEmployeeEntity.TemporalPropertyBag,
                additionalProperties: [
                    { id: PrEmployeeTemporalEntity.PrDisabilityTypeCode },
                    { id: PrEmployeeTemporalEntity.PrFormOfStudyTypeCode },
                    { id: PrEmployeeTemporalEntity.DateValidFrom },
                    { id: PrEmployeeTemporalEntity.DateValidTo }
                ]
            }
        ]
    };

    const summary: ISummaryItem[] = [
        {
            id: PrEmployeeEntity.FirstName,
            formatter: (val, args) => {
                const formatted = userNameWithAvatarFormatter(val, args, {
                    isBig: true
                });

                if (typeof formatted === "string") {
                    return formatted;
                }

                return formatted.value;
            }
        },
        { ...getNumberOursSummaryDef("Employee") },
        {
            id: createPath(PrEmployeeEntity.CurrentTemporalPropertyBag, PrEmployeeTemporalEntity.IsDepositing),
            label: i18next.t("Employee:Form.Depositing"),
            formatter: (val, args) => {
                return args.storage.t(`Common:General.${!!val ? "Yes" : "No"}`).toUpperCase();
            }
        }
    ];

    const localAddressFields = getAddressFields(PrEmployeeEntity.LocalAddress);
    const contactAddressFields = getAddressFields(PrEmployeeEntity.ContactAddress);
    const foreignAddressFields = getAddressFields(PrEmployeeEntity.ForeignAddress);
    const bankAccountFields = { ...getBankAccountFieldsDef({ type: null, addPaymentMethod: false }) };

    for (const field of Object.keys(contactAddressFields)) {
        contactAddressFields[field].isVisible = isContactAddressVisible;
    }

    for (const field of Object.keys(foreignAddressFields)) {
        foreignAddressFields[field].isVisible = isForeigner;
    }


    const tmpFieldsDef = { ...TemporalValidityFieldsDef };

    tmpFieldsDef["TemporalPropertyBag/DateValidFrom"].isReadOnly = (args: IGetValueArgs) => {
        const storage = args.storage as FormStorage<IPrEmployeeEntity, ISmartTemporalPropertyDialogCustomData>;
        const dialogBc = storage.getCustomData().openedDialogBindingContext;
        const key = args.bindingContext.getParent().getKey();

        if (dialogBc?.getPath() !== PrEmployeeTemporalEntity.HealthInsurance
                // we are only interested in the calls made for FastEntry items in SmartTemporalPropertyDialog => those have key defined
                || isNotDefined(key)) {
            return false;
        }

        const item = storage.getValue(args.bindingContext.getParent());
        const itemIndex = storage.getEntity().TemporalPropertyBag?.findIndex(i => i === item);

        // first item in HealthInsurance should have DateValidFrom disabled
        return itemIndex === 0;
    };

    const form: IFormDef = {
        id: `${EntityTypeName.PrEmployee}Form`,
        translationFiles: getDefinitions.translationFiles,
        getItemBreadCrumbText: (storage: Model) =>
                getItemBreadCrumbsText(storage, i18next.t("Employee:NewEmployee"), storage.data.entity?.FirstName ? `${storage.data.entity.FirstName} ${storage.data.entity.LastName ?? ""}` : ""),
        isDeletable: true,
        formControl: EmployeeFormView,
        summary,
        additionalProperties: [
            ...getTemporalAdditionalProperties(PrEmployeeTemporalEntity, PrEmployeeEntity),
            ...NumberRangeAdditionalProperties
        ],
        fieldDefinition: {
            ...getNumberRangeFieldDefs("Employee"),
            [PrEmployeeEntity.FirstName]: {},
            [PrEmployeeEntity.LastName]: {},
            [PrEmployeeEntity.BornLastName]: {},
            [PrEmployeeEntity.PrSex]: {
                type: FieldType.ComboBox,
                fieldSettings: {
                    displayName: PrSexEntity.Name
                },
                width: BasicInputSizes.S
            },
            [PrEmployeeEntity.TitleBeforeName]: {
                width: BasicInputSizes.XS,
                groupedField: GroupedField.MultiStart,
                textAlign: TextAlign.Right
            },
            [PrEmployeeEntity.TitleAfterName]: {
                width: BasicInputSizes.XS,
                groupedField: GroupedField.MultiEnd,
                textAlign: TextAlign.Left
            },
            [PrEmployeeEntity.DateOfBirth]: {},
            [PrEmployeeEntity.PhoneNumber]: {
                ...PhoneNumberDef
            },
            [PrEmployeeEntity.Email]: {
                width: BasicInputSizes.XL,
                validator: {
                    type: ValidatorType.Email
                }
            },
            [PrEmployeeEntity.ExternalNumber]: {},
            [PrEmployeeEntity.Labels]: getLabelsDef("Employee"),
            [PrEmployeeEntity.Citizenship]: {
                ...countryDef,
                width: BasicInputSizes.L,
                affectedFields: [
                    ...Object.keys(foreignAddressFields).map(key => ({ id: key })),
                    { id: PrEmployeeEntity.ForeignPersonalIdentificationNumber },
                    { id: PrEmployeeEntity.EuHealthCareNumber },
                    { id: PrEmployeeEntity.EuSocialInsuranceNumber },
                    { id: ShowOtherIdentifiers }
                ]
            },
            [PrEmployeeEntity.PrIdentificationDocument]: {
                type: FieldType.ComboBox,
                fieldSettings: {
                    displayName: PrIdentityDocumentTypeEntity.Name
                },
                groupedField: GroupedField.MultiStart
            },
            [PrEmployeeEntity.IDCountryOfIssue]: {
                ...countryDef,
                fieldSettings: {
                    ...countryDef.fieldSettings,
                    shouldDisplayAdditionalColumns: false
                }
            },
            [PrEmployeeEntity.IDNumber]: {
                width: BasicInputSizes.S
            },
            [PrEmployeeEntity.DateIDEndOfValidity]: {
                groupedField: GroupedField.MultiEnd
            },
            [PrEmployeeEntity.PersonalIdentificationNumber]: {
                ...BirthNumberFieldDef
            },
            [ShowOtherIdentifiers]: {
                type: FieldType.Switch,
                label: i18next.t("Employee:Form.ShowOtherIdentifiers"),
                isVisible: isForeigner,
                affectedFields: [
                    { id: PrEmployeeEntity.ForeignPersonalIdentificationNumber },
                    { id: PrEmployeeEntity.EuHealthCareNumber },
                    { id: PrEmployeeEntity.EuSocialInsuranceNumber }
                ],
                defaultValue: false
            },
            [PrEmployeeEntity.ForeignPersonalIdentificationNumber]: {
                isVisible: ifAll(isForeigner, isOtherIdentifierVisible)
            },
            [PrEmployeeEntity.EuHealthCareNumber]: {
                isVisible: ifAll(isForeigner, isOtherIdentifierVisible)
            },
            [PrEmployeeEntity.EuSocialInsuranceNumber]: {
                isVisible: ifAll(isForeigner, isOtherIdentifierVisible)
            },
            ...localAddressFields,
            [createPath(PrEmployeeEntity.LocalAddress, PrEmployeeLocalAddressEntity.Country)]: {
                ...localAddressFields[createPath(PrEmployeeEntity.LocalAddress, PrEmployeeLocalAddressEntity.Country)],
                width: BasicInputSizes.L,
                fieldSettings: {
                    ...localAddressFields[createPath(PrEmployeeEntity.LocalAddress, PrEmployeeLocalAddressEntity.Country)].fieldSettings,
                    shouldDisplayAdditionalColumns: false
                }
            },
            ...contactAddressFields,
            [createPath(PrEmployeeEntity.ContactAddress, PrEmployeeContactAddressEntity.Country)]: {
                ...contactAddressFields[createPath(PrEmployeeEntity.ContactAddress, PrEmployeeContactAddressEntity.Country)],
                width: BasicInputSizes.L,
                fieldSettings: {
                    ...contactAddressFields[createPath(PrEmployeeEntity.ContactAddress, PrEmployeeContactAddressEntity.Country)].fieldSettings,
                    shouldDisplayAdditionalColumns: false
                }
            },
            ...foreignAddressFields,
            [createPath(PrEmployeeEntity.ForeignAddress, PrEmployeeLocalAddressEntity.Country)]: {
                ...foreignAddressFields[createPath(PrEmployeeEntity.ForeignAddress, PrEmployeeLocalAddressEntity.Country)],
                width: BasicInputSizes.L,
                fieldSettings: {
                    ...foreignAddressFields[createPath(PrEmployeeEntity.ForeignAddress, PrEmployeeLocalAddressEntity.Country)].fieldSettings,
                    shouldDisplayAdditionalColumns: false
                }
            },
            ...bankAccountFields,
            [createPath(PrEmployeeEntity.Dependents, PrEmployeeDependentPersonEntity.FirstName)]: {},
            [createPath(PrEmployeeEntity.Dependents, PrEmployeeDependentPersonEntity.LastName)]: {},
            [createPath(PrEmployeeEntity.Dependents, PrEmployeeDependentPersonEntity.DateOfBirth)]: {},
            [createPath(PrEmployeeEntity.Dependents, PrEmployeeDependentPersonEntity.PersonalIdentificationNumber)]: {
                ...BirthNumberFieldDef,
                width: FastEntryInputSizes.S
            },
            [createPath(PrEmployeeEntity.Dependents, PrEmployeeDependentPersonEntity.DateValidFrom)]: {
                defaultValue: DATE_MIN
            },
            [createPath(PrEmployeeEntity.Dependents, PrEmployeeDependentPersonEntity.DateValidTo)]: {
                defaultValue: DATE_MAX,
                showSpecialValue: DateRangeSpecialValue.WithoutEnd
            },
            [createPath(PrEmployeeEntity.Cars, PrEmployeeCarEntity.Name)]: {},
            [createPath(PrEmployeeEntity.Cars, PrEmployeeCarEntity.PurchasePrice)]: {
                fieldSettings: {
                    unit: CurrencyType.getCurrencyUnit(getCompanyCurrency(context))
                }
            },
            [createPath(PrEmployeeEntity.Cars, PrEmployeeCarEntity.LowEmission)]: {
                type: FieldType.ComboBox,
                width: BasicInputSizes.S,
                fieldSettings: {
                    items: getSimpleBoolSelectItems()
                },
                // custom "temporal property dialog"
                extraFieldContentAfter: (args: IGetValueArgs) => {
                    const storage = args.storage as FormStorage<IPrEmployeeEntity, IEmployeeFormCustomData>;
                    const carsDialogBc = storage.getCustomData().carsDialogBc;

                    if (!!carsDialogBc) {
                        // don't render the button when the temporal dialog is opened
                        return null;
                    }

                    return (
                            <IconButtonStyled title={storage.t("Components:TemporalProperty.OpenDialog")}
                                              id={`${args.bindingContext.toString()}-tmpDialogOpener`}
                                              isTransparent
                                              onClick={() => {
                                                  storage.setCustomData({
                                                      carsDialogBc: args.bindingContext.getParent()
                                                  });
                                                  storage.refresh();

                                              }}>
                                <TimeDependentIcon/>
                            </IconButtonStyled>
                    );
                }
            },
            [createPath(PrEmployeeEntity.Cars, PrEmployeeCarEntity.DateValidFrom)]: {
                fieldSettings: {
                    showSpecialValue: DateRangeSpecialValue.WithoutEnd
                }
            },
            [createPath(PrEmployeeEntity.Cars, PrEmployeeCarEntity.DateValidTo)]: {
                fieldSettings: {
                    showSpecialValue: DateRangeSpecialValue.WithoutEnd
                }
            },
            ...getTemporalPropertyFieldDefinition({
                propName: PrEmployeeTemporalEntity.HealthInsurance,
                definition: {
                    type: FieldType.ComboBox,
                    label: i18next.t("Employee:Form.HealthInsuranceCompany"),
                    fieldSettings: {
                        displayName: PrInsuranceCompanyPredefinedEntity.ShortName,
                        shouldDisplayAdditionalColumns: true,
                        temporalDialog: {
                            dialogTitle: i18next.t("Employee:Form.PlanHealthInsuranceTitle"),
                            dateType: FieldType.MonthYear,
                            onlyOneFuturePlan: true,
                            withoutGaps: true,
                            isCalendarDateDisabledDateValidFrom: (date: Date, unit: TDisabledDateUnit) => {
                                if (unit !== "month") {
                                    return false;
                                }

                                // only allow January and June to be selected
                                return ![0, 5].includes(date.getMonth());
                            },
                            isCalendarDateDisabledDateValidTo: (date: Date, unit: TDisabledDateUnit) => {
                                if (unit !== "month") {
                                    return false;
                                }

                                // only allow January and June to be selected
                                return ![11, 4].includes(date.getMonth());
                            },
                            customDateValidFrom: (items?: TTemporal[]) => {
                                if (!items || [0, 1].includes(items.length)) {
                                    // first item should start from DATE_MIN
                                    return DATE_MIN;
                                }

                                const rootDay = getUtcDayjs(items?.find(item => getUtcDayjs().isSameOrBefore(item.DateValidFrom, "date"))?.DateValidFrom ?? getUtcDate());
                                const thisYear = rootDay.year();
                                const nextYear = getUtcDayjs().add(1, "year").year();
                                const nextJanuary = getUtcDayjs(`01/01/${nextYear}`);
                                const nextJune = rootDay.month() >= 5 ? getUtcDayjs(`06/01/${nextYear}`) : getUtcDayjs(`06/01/${thisYear}`);

                                return nextJune.toDate() < nextJanuary.toDate() ? nextJune.toDate() : nextJanuary.toDate();
                            }
                        }
                    },
                    filter: {
                        select: `${PrInsuranceCompanyPredefinedEntity.PrInsuranceCompanyTypeCode} eq '${PrInsuranceCompanyTypeCode.HealthInsuranceCompany}'`
                    },
                    columns: [
                        { id: PrInsuranceCompanyPredefinedEntity.HealthInsuranceCompanyCode },
                        {
                            id: PrInsuranceCompanyPredefinedEntity.ShortName,
                            label: i18next.t("Employee:Form.Shortcut")
                        },
                        { id: PrInsuranceCompanyPredefinedEntity.Name }
                    ],
                    additionalProperties: [
                        { id: PrInsuranceCompanyPredefinedEntity.HealthInsuranceCompanyCode }
                    ],
                    width: BasicInputSizes.S
                }
            }),
            ...getTemporalPropertyFieldDefinition({
                propName: PrEmployeeTemporalEntity.PrDisabilityType,
                definition: {
                    type: FieldType.ComboBox,
                    fieldSettings: {
                        displayName: "Name",
                        temporalDialog: {
                            dialogTitle: i18next.t("Employee:Form.PlanDisabilityTitle"),
                            onlyOneFuturePlan: true,
                            granularity: "day"
                        },
                        noRecordText: i18next.t("Common:Select.NoRecord")
                    }
                },
                currentDefinition: {
                    isVisible: isDisabilityVisible
                }
            }),
            ...getTemporalPropertyFieldDefinition({
                propName: PrEmployeeTemporalEntity.PrFormOfStudyType,
                definition: {
                    type: FieldType.ComboBox,
                    fieldSettings: {
                        displayName: "Name",
                        temporalDialog: {
                            dialogTitle: i18next.t("Employee:Form.PlanStudyTitle"),
                            onlyOneFuturePlan: true,
                            granularity: "day"
                        },
                        noRecordText: i18next.t("Common:Select.NoRecord")
                    }
                },
                currentDefinition: {
                    isVisible: isStudyVisible
                }
            }),
            [PrEmployeeEntity.DatePensionFrom]: {
                isVisible: isPensionVisible,
                label: i18next.t("Employee:Form.DatePensionFrom")
            },
            [PrEmployeeEntity.PerformAnnualAccounting]: {
                type: FieldType.Switch,
                fieldSettings: {
                    type: SwitchType.YesNo
                }
            },
            ...getTemporalPropertyFieldDefinition({
                propName: PrEmployeeTemporalEntity.IsDepositing,
                definition: {
                    type: FieldType.Switch,
                    fieldSettings: {
                        type: SwitchType.YesNo
                    }
                },
                withOpeningButton: false
            }),
            [PreviousEmploymentDurationYearsPath]: {
                type: FieldType.NumberInput,
                width: BasicInputSizes.S,
                fieldSettings: {
                    unit: (args: IGetValueArgs) => {
                        const previousEmploymentDurationDays = args.storage.getValueByPath(PrEmployeeEntity.PreviousEmploymentDurationDays) as number;
                        const count = isNotDefined(previousEmploymentDurationDays) ? 0 : Math.floor(previousEmploymentDurationDays / 365);

                        return args.storage.t("Common:Time.Year", { count });
                    }
                },
                labelStatus: LabelStatus.Removed,
                useForValidation: true,
                affectedFields: [
                    { id: PreviousEmploymentDurationDaysPath }
                ]
            },
            [PreviousEmploymentDurationDaysPath]: {
                type: FieldType.NumberInput,
                width: BasicInputSizes.S,
                fieldSettings: {
                    unit: (args: IGetValueArgs) => {
                        const previousEmploymentDurationDays = args.storage.getValueByPath(PrEmployeeEntity.PreviousEmploymentDurationDays) as number;
                        const count = isNotDefined(previousEmploymentDurationDays) ? 0 : previousEmploymentDurationDays % 365;

                        return args.storage.t("Common:Time.Days", { count });
                    }
                },
                labelStatus: LabelStatus.Removed,
                useForValidation: true,
                affectedFields: [
                    { id: PreviousEmploymentDurationYearsPath }
                ]
            },
            [PrEmployeeEntity.PreviousEmploymentDurationDays]: {
                render: (args: IFieldDefFn) => {
                    return (
                            <Field label={i18next.t("Employee:Form.PreviousEmploymentDuration")}>
                                <FieldsWrapper isWithoutWrap smallerGap>
                                    <SmartField
                                            storage={args.storage}
                                            bindingContext={args.storage.data.bindingContext.navigate(PreviousEmploymentDurationYearsPath)}
                                            onChange={args.props.parentProps.onChange}
                                            onBlur={args.props.parentProps.onBlur}
                                            fieldProps={{
                                                isSharpRight: true
                                            }}
                                    />
                                    <SmartField
                                            storage={args.storage}
                                            onChange={args.props.parentProps.onChange}
                                            onBlur={args.props.parentProps.onBlur}
                                            bindingContext={args.storage.data.bindingContext.navigate(PreviousEmploymentDurationDaysPath)}
                                            fieldProps={{
                                                isSharpLeft: true
                                            }}/>
                                </FieldsWrapper>
                            </Field>
                    );
                }
            },
            [PrEmployeeEntity.SalaryCurrency]: {
                ...CurrencyDef
            },
            [PrEmployeeEntity.PasswordForPayslip]: {
                type: FieldType.Password
            },
            [ShowContactAddressPath]: {
                type: FieldType.Switch,
                fieldSettings: {
                    type: SwitchType.YesNo
                },
                affectedFields: Object.keys(contactAddressFields).map(key => ({ id: key }))
            },
            [ShowDisabilityPath]: {
                type: FieldType.Switch,
                fieldSettings: {
                    type: SwitchType.YesNo
                },
                affectedFields: [
                    { id: createPath(PrEmployeeEntity.CurrentTemporalPropertyBag, PrEmployeeTemporalEntity.PrDisabilityType) }
                ]
            },
            [ShowStudyPath]: {
                type: FieldType.Switch,
                fieldSettings: {
                    type: SwitchType.YesNo
                },
                affectedFields: [
                    { id: createPath(PrEmployeeEntity.CurrentTemporalPropertyBag, PrEmployeeTemporalEntity.PrFormOfStudyType) }
                ]
            },
            [ShowPensionPath]: {
                type: FieldType.Switch,
                fieldSettings: {
                    type: SwitchType.YesNo
                },
                affectedFields: [
                    { id: createPath(PrEmployeeEntity.DatePensionFrom) }
                ]
            },
            ...tmpFieldsDef
        },
        groups: [
            {
                id: "main",
                rows: [
                    [{ id: PrEmployeeEntity.TitleBeforeName }, { id: PrEmployeeEntity.FirstName }, { id: PrEmployeeEntity.LastName }, { id: PrEmployeeEntity.TitleAfterName }],
                    [{ id: PrEmployeeEntity.BornLastName }, { id: PrEmployeeEntity.PrSex }, { id: PrEmployeeEntity.DateOfBirth }],
                    [{ id: PrEmployeeEntity.PhoneNumber }, { id: PrEmployeeEntity.Email }],
                    [{ id: PrEmployeeEntity.ExternalNumber }, { id: PrEmployeeEntity.Labels }]
                ]
            },
            {
                id: "identification",
                title: i18next.t("Employee:Form.IdentificationGroup"),
                rows: [
                    [{ id: PrEmployeeEntity.Citizenship }],
                    [
                        { id: PrEmployeeEntity.PrIdentificationDocument }, { id: PrEmployeeEntity.IDCountryOfIssue },
                        { id: PrEmployeeEntity.IDNumber }, { id: PrEmployeeEntity.DateIDEndOfValidity }
                    ],
                    [
                        { id: PrEmployeeEntity.PersonalIdentificationNumber },
                        { id: ShowOtherIdentifiers }
                    ],
                    [
                        { id: PrEmployeeEntity.ForeignPersonalIdentificationNumber },
                        { id: PrEmployeeEntity.EuHealthCareNumber },
                        { id: PrEmployeeEntity.EuSocialInsuranceNumber }
                    ]
                ]
            },
            {
                id: "addressForeign",
                title: i18next.t("Employee:Form.AddressForeignGroup"),
                isVisible: isForeigner,
                rows: [
                    [{ id: createPath(PrEmployeeEntity.LocalAddress, PrEmployeeForeignAddressEntity.Street) }],
                    [
                        { id: createPath(PrEmployeeEntity.LocalAddress, PrEmployeeForeignAddressEntity.City) },
                        { id: createPath(PrEmployeeEntity.LocalAddress, PrEmployeeForeignAddressEntity.PostalCode) },
                        { id: createPath(PrEmployeeEntity.LocalAddress, PrEmployeeForeignAddressEntity.Country) }
                    ]
                ]
            },
            {
                id: "addressCzech",
                title: i18next.t("Employee:Form.AddressCzechGroup"),
                rows: [
                    [{ id: createPath(PrEmployeeEntity.LocalAddress, PrEmployeeLocalAddressEntity.Street) }],
                    [
                        { id: createPath(PrEmployeeEntity.LocalAddress, PrEmployeeLocalAddressEntity.City) },
                        { id: createPath(PrEmployeeEntity.LocalAddress, PrEmployeeLocalAddressEntity.PostalCode) },
                        { id: createPath(PrEmployeeEntity.LocalAddress, PrEmployeeLocalAddressEntity.Country) }
                    ]
                ]
            },
            {
                id: "addressContact",
                title: i18next.t("Employee:Form.AddressContactGroup"),
                togglePropPath: ShowContactAddressPath,
                rows: [
                    [{ id: createPath(PrEmployeeEntity.ContactAddress, PrEmployeeContactAddressEntity.Street) }],
                    [
                        { id: createPath(PrEmployeeEntity.ContactAddress, PrEmployeeContactAddressEntity.City) },
                        { id: createPath(PrEmployeeEntity.ContactAddress, PrEmployeeContactAddressEntity.PostalCode) },
                        { id: createPath(PrEmployeeEntity.ContactAddress, PrEmployeeContactAddressEntity.Country) }
                    ]
                ]
            },
            {
                // id "payment" is expected in the change handlers
                id: "payment",
                title: i18next.t("Employee:Form.BankAccountGroup"),
                rows: [
                    [{ id: SAVED_ACCOUNTS_PATH }],
                    [...paymentDetailFields]
                ]
                // todo: what about ABA number field?? It was removed here, but is it correct??
            },
            {
                id: "healthInsurance",
                title: i18next.t("Employee:Form.HealthInsuranceGroup"),
                rows: [
                    [{ id: createPath(PrEmployeeEntity.CurrentTemporalPropertyBag, PrEmployeeTemporalEntity.HealthInsurance) }]
                ]
            },
            {
                id: "dependents",
                title: i18next.t("Employee:Form.Dependents"),
                lineItems: {
                    collection: PrEmployeeEntity.Dependents,
                    order: "Id",
                    canReorder: false,
                    isItemCloneable: false,
                    isGroupToggleable: true,
                    columns: [
                        { id: PrEmployeeDependentPersonEntity.FirstName }, { id: PrEmployeeDependentPersonEntity.LastName },
                        { id: PrEmployeeDependentPersonEntity.DateOfBirth }, { id: PrEmployeeDependentPersonEntity.PersonalIdentificationNumber }
                    ]
                }
            },
            {
                id: "disabilityType",
                title: i18next.t("Employee:Form.DisabilityGroup"),
                togglePropPath: ShowDisabilityPath,
                rows: [
                    [{ id: createPath(PrEmployeeEntity.CurrentTemporalPropertyBag, PrEmployeeTemporalEntity.PrDisabilityType) }]
                ]
            },
            {
                id: "studyType",
                title: i18next.t("Employee:Form.StudyGroup"),
                togglePropPath: ShowStudyPath,
                rows: [
                    [{ id: createPath(PrEmployeeEntity.CurrentTemporalPropertyBag, PrEmployeeTemporalEntity.PrFormOfStudyType) }]
                ]
            },
            {
                id: "pensionType",
                title: i18next.t("Employee:Form.PensionGroup"),
                togglePropPath: ShowPensionPath,
                rows: [
                    [{ id: createPath(PrEmployeeEntity.DatePensionFrom) }]
                ]
            },
            {
                id: "cars",
                title: i18next.t("Employee:Form.Cars"),
                lineItems: {
                    collection: PrEmployeeEntity.Cars,
                    order: "Id",
                    canReorder: false,
                    isItemCloneable: false,
                    isGroupToggleable: true,
                    columns: [
                        { id: PrEmployeeCarEntity.Name },
                        { id: PrEmployeeCarEntity.PurchasePrice },
                        { id: PrEmployeeCarEntity.LowEmission }
                        // { id: PrEmployeeCarEntity.DateValidFrom },
                        // { id: PrEmployeeCarEntity.DateValidTo }
                    ]
                }
            },
            {
                // temporary, will be removed
                id: "bordel",
                title: "Nastavení",
                rows: [
                    [{ id: PrEmployeeEntity.PerformAnnualAccounting }, { id: createPath(PrEmployeeEntity.CurrentTemporalPropertyBag, PrEmployeeTemporalEntity.IsDepositing) }],
                    [{ id: PrEmployeeEntity.PreviousEmploymentDurationDays }],
                    [{ id: PrEmployeeEntity.SalaryCurrency }, { id: PrEmployeeEntity.PasswordForPayslip }]
                ]
            },
            {
                id: "Tabs",
                isVisible: (args: IGetValueArgs) => {
                    return !args.storage.data.bindingContext.isNew();
                },
                tabs: [
                    {
                        id: "employment",
                        title: i18next.t("Employee:FormTab.Employments"),
                        table: {
                            id: "employeeEmploymentTable",
                            entitySet: EntitySetName.PrEmployments,
                            filter: (args: IGetValueArgs) => {
                                return `${PrEmploymentEntity.Employee}/Id eq ${args.storage.data.entity.Id}`;
                            },
                            initialSortBy: [{ id: PrEmploymentEntity.DateStart, sort: Sort.Asc }],
                            columns: [
                                { id: PrEmploymentEntity.NumberOurs },
                                {
                                    id: PrEmploymentEntity.Template,
                                    fieldSettings: {
                                        displayName: PrEmploymentTemplateEntity.Name
                                    }
                                },
                                {
                                    id: PrEmploymentEntity.Type,
                                    fieldSettings: {
                                        displayName: PrEmploymentTypeEntity.Name
                                    }
                                },
                                {
                                    id: BindingContext.localContext("Status"),
                                    label: i18next.t("Employee:FormTab.StatusColumn"),
                                    fieldSettings: {
                                        disableSort: true
                                    },
                                    formatter: (val: TValue, args: IFormatOptions) => {
                                        const employment = args.entity as IPrEmploymentEntity;
                                        const dateStart = employment.DateStart;
                                        const dateEnd = employment.DateEnd;
                                        const isActive = !dateEnd || getUtcDayjs().isBetween(dateStart, dateEnd, "day", "[]");
                                        const text = i18next.t(`Employee:FormTab.${isActive ? "Active" : "Inactive"}`);
                                        const color = isActive ? "C_SEM_text_good" : "C_TEXT_primary";

                                        return {
                                            tooltip: text,
                                            value: (
                                                    <ColoredText color={color}>
                                                        {text}
                                                    </ColoredText>
                                            )
                                        };
                                    }
                                },
                                { id: PrEmploymentEntity.DateStart },
                                { id: PrEmploymentEntity.DateEnd }
                            ]
                        }
                    }
                ]
            }
        ]
    };

    return {
        entitySet: EntitySetName.PrEmployees,
        table,
        form
    };
};

getDefinitions.translationFiles = ["Employee", "Common", "NumberRange", "Components", getEnumNameSpaceName(EntityTypeName.PrEmployeeState)];
setDefByEntityType(EntityTypeName.PrEmployee, getDefinitions);