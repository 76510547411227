import { IFieldInfoProperties } from "@components/smart/FieldInfo";
import {
    FilterBarGroup,
    getDefaultFilterGroupDef,
    IFilterGroupDef
} from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { IReportHierarchy, IReportRowDef, IReportVariantDef, ISmartReportTableRow } from "@components/smart/smartTable";
import { ISort } from "@components/table";
import { getNestedValue } from "@odata/Data.utils";
import { IFormatOptions } from "@odata/OData.utils";
import { getCompanyCurrency, isVatRegisteredCompany } from "@utils/CompanyUtils";
import { isDefined, isNotDefined } from "@utils/general";
import i18next from "i18next";
import React from "react";

import { IAppContext } from "../../../contexts/appContext/AppContext.types";
import { Sort, Status } from "../../../enums";
import { ColoredText } from "../../../global.style";
import { TValue } from "../../../global.types";
import CurrencyType from "../../../types/Currency";
import { CommonReportProps, getDocumentNumberOursOverride } from "../CommonDefs";
import { composedDateRangeOnBeforeLoadCallback } from "../customFilterComponents/ComposedDateRange";
import {
    cbaReportCommonParams,
    getCbaReportParamsDef,
    IGetReportDefVal,
    IReportTableDefinition
} from "../Report.utils";
import { ReportId } from "../ReportIds";
import { ReportStorage } from "../ReportStorage";


export enum CbaIncomeExpenseVariants {
    CZK = "-90",
    Transaction = "-86"
}

export const getDefinition = (context: IAppContext): IReportTableDefinition => {
    const tableId = ReportId.CbaIncomeExpense;
    const title = i18next.t("Reporting:CbaIncomeExpense.Title");
    const path = "CbaIncomeExpenseOverview";
    const initialSortBy = [
        { id: "CbaIncomeExpenseOverview_DocumentDate", sort: Sort.Desc }
    ] as ISort[];
    const filterBarDef: IFilterGroupDef[] = [
        {
            ...getDefaultFilterGroupDef(FilterBarGroup.Parameters),
            defaultFilters: [...cbaReportCommonParams],
            filterDefinition: {
                ...getCbaReportParamsDef(tableId)
            }
        },
        {
            ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
            allowCustomFilters: false,
            defaultFilters: [],
            filterDefinition: {}
        }];
    const onBeforeLoad = async (storage: ReportStorage) => {
        await composedDateRangeOnBeforeLoadCallback(storage);
    };
    const parameters: string[] = [...cbaReportCommonParams];

    const isVatRegistered = isVatRegisteredCompany(context);

    const defaultReportVariants: Record<CbaIncomeExpenseVariants, IReportVariantDef> = {
        [CbaIncomeExpenseVariants.Transaction]: {
            ReportHierarchy: {
                "Aggregate": false,
                "Groups": [],
                "Columns": [
                    { "ColumnAlias": "CbaIncomeExpenseOverview_DocumentDate" },
                    { "ColumnAlias": "CbaIncomeExpenseOverview_NumberOurs" },
                    { "ColumnAlias": "CbaIncomeExpenseOverview_Description" },
                    { "ColumnAlias": "CbaIncomeExpenseOverview_TransactionBankAmount" },
                    { "ColumnAlias": "CbaIncomeExpenseOverview_TransactionCashBoxAmount" },
                    { "ColumnAlias": "CbaIncomeExpenseOverview_TransactionAmountTaxed" },
                    { "ColumnAlias": "CbaIncomeExpenseOverview_TransactionAmountNonTaxed" },
                    ...(isVatRegistered ? [{ "ColumnAlias": "CbaIncomeExpenseOverview_AmountVat" }] : []),
                    { "ColumnAlias": "CbaIncomeExpenseOverview_TransactionAmountRemaining" }
                ],
                "Aggregations": []
            }
        }, [CbaIncomeExpenseVariants.CZK]: {
            ReportHierarchy: {
                "Aggregate": false,
                "Groups": [],
                "Columns": [
                    { "ColumnAlias": "CbaIncomeExpenseOverview_DocumentDate" },
                    { "ColumnAlias": "CbaIncomeExpenseOverview_NumberOurs" },
                    { "ColumnAlias": "CbaIncomeExpenseOverview_Description" },
                    { "ColumnAlias": "CbaIncomeExpenseOverview_BankAmount" },
                    { "ColumnAlias": "CbaIncomeExpenseOverview_CashBoxAmount" },
                    { "ColumnAlias": "CbaIncomeExpenseOverview_AmountTaxed" },
                    { "ColumnAlias": "CbaIncomeExpenseOverview_AmountNonTaxed" },
                    ...(isVatRegistered ? [{ "ColumnAlias": "CbaIncomeExpenseOverview_AmountVat" }] : []),
                    { "ColumnAlias": "CbaIncomeExpenseOverview_AmountRemaining" }
                ],
                "Aggregations": []
            }
        }
    };

    const defaultReportHierarchy: IReportHierarchy = defaultReportVariants[CbaIncomeExpenseVariants.Transaction].ReportHierarchy;

    const currencyUnit = (args: IGetReportDefVal) => {
        return getNestedValue(CommonReportProps.units, args.storage.data.entity);
    };

    const columnOverrides = (columnId: string): IFieldInfoProperties => {
        const colId = columnId.replace("CbaIncomeExpenseOverview_", "");
        const formatCurrency = (val: number, args: IFormatOptions, columnId: string) => {
            const storage = args.storage as ReportStorage;
            const currencyCode = args.entity.CbaIncomeExpenseOverview_TransactionCurrencyCode;
            return CurrencyType.format(val, {
                currency: columnId.startsWith("Transaction") ? currencyCode : getCompanyCurrency(args.context),
                scale: currencyUnit({ storage, settings: {} })
            });
        };

        if (colId === "AmountRemaining" || colId === "TransactionAmountRemaining") {
            return {
                formatter: (val: TValue, args: IFormatOptions) => {
                    if (isNotDefined(val)) {
                        return "";
                    }
                    const formattedVal = formatCurrency(val as number, args, colId);
                    return {
                        value: (<ColoredText color={"C_SEM_text_warning"}>{formattedVal}</ColoredText>),
                        tooltip: formattedVal
                    };
                }
            };
        } else if (["AmountTaxed", "AmountNonTaxed", "AmountVat", "TransactionAmountTaxed", "TransactionAmountNonTaxed", "TransactionAmountVat"].includes(colId)) {
            return {
                formatter: (val: TValue, args: IFormatOptions) => {
                    const formattedVal = formatCurrency(val as number, args, colId);
                    if (val > 0) {
                        return {
                            value: (<ColoredText color={"C_SEM_text_good"}>{formattedVal}</ColoredText>),
                            tooltip: formattedVal
                        };
                    }
                    return formattedVal;
                }
            };
        } else if (["BankAmount", "CashBoxAmount", "TransactionBankAmount", "TransactionCashBoxAmount"].includes(colId)) {
            return {
                formatter: (val: TValue, args: IFormatOptions) => {
                    const formattedVal = formatCurrency(val as number, args, colId);
                    if (val > 0) {
                        return {
                            value: (<ColoredText color={"C_SEM_text_good"}><b>{formattedVal}</b></ColoredText>),
                            tooltip: formattedVal
                        };
                    } else if (val < 0) {
                        return {
                            value: (<b>{formattedVal}</b>),
                            tooltip: formattedVal
                        };
                    }
                    return formattedVal;
                }
            };
        } else if (colId === "NumberOurs") {
            return getDocumentNumberOursOverride({
                documentTypeProperty: "CbaIncomeExpenseOverview_DocumentTypeCode",
                documentIdProperty: "CbaIncomeExpenseOverview_DocumentId"
            });
        }
        return null;
    };

    const rowFormatter = (rowDef: IReportRowDef): Partial<ISmartReportTableRow> => {
        const newRow: Partial<ISmartReportTableRow> = {};
        const amountRemaining = rowDef.Value.CbaIncomeExpenseOverview_AmountRemaining;
        const transAmountRemaining = rowDef.Value.CbaIncomeExpenseOverview_TransactionAmountRemaining;
        if ((isDefined(amountRemaining) && amountRemaining !== 0) ||
            (isDefined(transAmountRemaining) && transAmountRemaining !== 0)) {
            newRow.statusHighlight = Status.Warning;
        }

        return newRow;
    };

    return {
        title, path, id: tableId, initialSortBy, filterBarDef, currencyUnit,
        onBeforeLoad, columnOverrides, parameters, defaultReportHierarchy, defaultReportVariants,
        rowFormatter
    };
};