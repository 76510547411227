import { isBankApiConnected } from "@pages/banks/bankAccounts/Api.utils";
import { refreshStatementsAPI } from "@pages/banks/bankAccounts/BankAccounts.utils";
import { getUploadErrorMessage } from "@pages/banks/bankStatements/BankStatements.utils";
import React from "react";

import { BANK_STATEMENTS, FILES_API_URL } from "../../../constants";
import { AppContext } from "../../../contexts/appContext/AppContext.types";
import { Status } from "../../../enums";
import BindingContext from "../../../odata/BindingContext";
import FileStorage from "../../../utils/FileStorage";
import BankAccountCheckbox from "../../bankAccountCheckbox/BankAccountCheckbox";
import { SmartBankAccountFilterStyledWrapper } from "../../bankAccountCheckbox/BankAccountCheckbox.styles";
import { fileNameToMime, isFileCsv } from "../../fileUploader/File.utils";
import { ICheckboxChange } from "../../inputs/checkbox";
import { ISmartFieldChange } from "../smartField/SmartField";
import { TSmartODataTableStorage } from "../smartTable/SmartODataTableBase";
import {
    getCustomizedBankAccountsSync,
    ISmartBankAccountFilterCustomData,
    toggleCompanyFilterValue
} from "./SmartBankAccountFilter.utils";

interface IProps {
    storage: TSmartODataTableStorage<ISmartBankAccountFilterCustomData>;
    className?: string;
    onChange: (args: ISmartFieldChange) => void;
    isDisabled?: boolean;
    bindingContext?: BindingContext;
    onCreateStatement?: (accId: number) => void;
}

export default class SmartBankAccountFilter extends React.Component<IProps> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;

    handleCsvImport = async (file: File, uploadedFileId: number, bankAccountId: number): Promise<void> => {
        this.props.storage.setCustomData({ csvFile: file, bankAccountId, uploadedFileId, uploadCsv: true });
        this.props.storage.refresh();
    };

    refresh = async (id: number): Promise<void> => {
        await refreshStatementsAPI(id);
    };

    uploadFile = async (files: FileList, id: number): Promise<void> => {
        const companyId = this.context.getCompany().Id;
        const url = `${BANK_STATEMENTS}/PostFileAsync/${id}?CompanyId=${companyId}`;
        let errorMessage = "";

        if (files.length > 1) {
            this.props.storage.data.alert = {
                status: Status.Error,
                title: this.props.storage.t("Banks:Validation.FileCantBeUploaded"),
                subTitle: this.props.storage.t("Banks:Validation.OnlySingleFileSupported")
            };

            this.props.storage.refresh();
            return;
        }

        for (const file of files) {
            try {
                const fileMime = fileNameToMime(file?.name);
                const isCsv = isFileCsv(fileMime);

                const ret = await FileStorage.upload({
                    file,
                    url: isCsv ? FILES_API_URL : url,
                    name: file.name
                });

                if (isCsv) {
                    await this.handleCsvImport(file, ret?.Id, id);
                    return;
                }
            } catch (error) {
                errorMessage = getUploadErrorMessage(error);
            }
        }

        if (errorMessage) {
            this.props.storage.data.alert = {
                status: Status.Error,
                title: this.props.storage.t("Banks:Validation.FileCantBeUploaded"),
                subTitle: errorMessage
            };
            this.props.storage.refresh();
        } else if (this.props.storage.data.alert) {
            delete this.props.storage.data.alert;
            this.props.storage.refresh();
        }

        this.forceUpdate();
    };

    handleChange = (id: number): void => {
        const values = this.props.storage.getValue(this.props.bindingContext, { useDirectValue: false }) || [];

        this.props.onChange?.({
            value: toggleCompanyFilterValue(values, id),
            bindingContext: this.props.bindingContext
        });
    };

    isDisabled = (): boolean => {
        return this.props.storage.isDisabled || this.props.isDisabled;
    };

    render() {
        const { storage } = this.props;
        const values: number[] = storage.getValue(this.props.bindingContext, { useDirectValue: false }) || [];
        const visibleBankAccounts = getCustomizedBankAccountsSync(storage);

        return (
            <SmartBankAccountFilterStyledWrapper className={this.props.className}>
                {visibleBankAccounts.map((acc) => {
                    const isChecked = !values.find((val: number) => val === acc.Id);

                    return (
                        <BankAccountCheckbox
                            key={acc.Id}
                            {...acc}
                            lastUpdateDate={acc.Balance?.DateBalanceChanged}
                            onUploadFile={this.uploadFile}
                            onRefresh={this.refresh}
                            checked={isChecked}
                            onCreateStatement={() => {
                                this.props.onCreateStatement(acc.Id);
                            }}
                            isActive={acc.IsActive}
                            hasApiToken={isBankApiConnected(acc)}
                            isDisabled={this.isDisabled()}
                            onChange={(args: ICheckboxChange) => {
                                this.handleChange(acc.Id);
                            }}
                        />
                    );
                })}
            </SmartBankAccountFilterStyledWrapper>
        );
    }
}
