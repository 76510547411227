import { ActionType, ISmartFastEntriesActionEvent } from "@components/smart/smartFastEntryList";
import { ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { setBoundValue } from "@odata/Data.utils";
import {
    IBankAccountEntity,
    IPrEmployeeCarEntity,
    IPrEmployeeDependentPersonEntity,
    PrEmployeeCarEntity,
    PrEmployeeEntity,
    PrEmployeeTemporalEntity
} from "@odata/GeneratedEntityTypes";
import { CountryCode } from "@odata/GeneratedEnums";
import {
    getCurrentRangeIndex,
    getDefaultTemporalDateValidFrom,
    getDefaultTemporalDateValidTo,
    getGroupingProperties,
    groupAndOptimizeRanges,
    handleCurrentTemporalPropChange,
    TTemporal,
    ungroupTemporalRanges
} from "@odata/TemporalUtils";
import { isNotDefined } from "@utils/general";
import { cloneDeep } from "lodash";
import React from "react";

import { AppContext } from "../../../contexts/appContext/AppContext.types";
import { withPermissionContext } from "../../../contexts/permissionContext/withPermissionContext";
import { FormMode } from "../../../enums";
import { DATE_MAX, getUtcDate, getUtcDayjs } from "../../../types/Date";
import { FormStorage } from "../../../views/formView/FormStorage";
import { FormViewForExtend, IFormViewProps } from "../../../views/formView/FormView";
import {
    handleBankFieldChange,
    setSavedBankAccount,
    setSavedBankAccountItems
} from "../../banks/bankAccounts/BankAccounts.utils";
import { loadAndStoreRanges, setMatchingNumberRange } from "../../numberRange/NumberRange.utils";
import CarsDialog from "./CarsDialog";
import {
    IEmployeeFormCustomData,
    IPrEmployeeEntityExtended,
    PreviousEmploymentDurationDaysPath,
    PreviousEmploymentDurationYearsPath,
    ShowContactAddressPath,
    ShowDisabilityPath,
    ShowOtherIdentifiers,
    ShowPensionPath,
    ShowStudyPath
} from "./Employee.utils";

interface IProps extends IFormViewProps<IPrEmployeeEntityExtended, IEmployeeFormCustomData> {
}

class EmployeeFormView extends FormViewForExtend<IPrEmployeeEntityExtended, IProps> {
    static contextType = AppContext;

    static defaultProps = {
        title: "Employee:FormTitle"
    };

    async onAfterLoad() {
        const { storage } = this.props;
        const isAuditTrail = storage.formMode === FormMode.AuditTrail;


        if (!isAuditTrail) {
            const promises: Promise<unknown>[] = [];

            if (!storage.data.entity.NumberOurs) {
                promises.push(setMatchingNumberRange(storage.getThis(), true));
            } else {
                promises.push(loadAndStoreRanges(storage));
            }

            await Promise.all(promises);
        }

        this.prepareBankAccount();
        this.setSpecialGroupsVisibility();
        this.preparePreviousEmploymentDurationValues();
        return super.onAfterLoad();
    }

    prepareBankAccount = (): void => {
        const accounts: IBankAccountEntity[] = [];
        const currentAccount: IBankAccountEntity = this.entity.BankAccount ? cloneDeep(this.entity.BankAccount) : null;

        if (currentAccount?.AccountNumber) {
            accounts.push(currentAccount);
        }

        const storage = this.props.storage.getThis();

        if (accounts.length > 0) {
            setSavedBankAccountItems(storage, accounts);
        }

        setSavedBankAccount({
            storage,
            isEntityWithoutBP: true,
            isInit: true,
            account: currentAccount
        }, accounts);
    };
    /** Several form groups has yes/no visibility switch. Set its default state based on the current form data. */
    setSpecialGroupsVisibility = (): void => {
        this.props.storage.setValueByPath(ShowContactAddressPath, !!this.entity.ContactAddress?.Street);
        this.props.storage.setValueByPath(ShowDisabilityPath, !!this.entity.CurrentTemporalPropertyBag?.PrDisabilityTypeCode);
        this.props.storage.setValueByPath(ShowStudyPath, !!this.entity.CurrentTemporalPropertyBag?.PrFormOfStudyTypeCode);
        this.props.storage.setValueByPath(ShowPensionPath, !!this.entity.DatePensionFrom);
        this.props.storage.setValueByPath(ShowOtherIdentifiers, this.entity.CitizenshipCode !== CountryCode.CzechRepublic);
    };

    preparePreviousEmploymentDurationValues = (): void => {
        const sumDays = this.props.storage.getValueByPath(PrEmployeeEntity.PreviousEmploymentDurationDays);

        if (isNotDefined(sumDays)) {
            return;
        }

        const years = Math.floor(sumDays / 365);
        const days = sumDays % 365;

        this.props.storage.setValueByPath(PreviousEmploymentDurationYearsPath, years);
        this.props.storage.setValueByPath(PreviousEmploymentDurationDaysPath, days);
    };

    handleCitizenshipChange = (e: ISmartFieldChange): void => {
        if (e.bindingContext.getPath() !== PrEmployeeEntity.Citizenship) {
            return;
        }

        this.props.storage.refresh();
    };

    handleChange = (e: ISmartFieldChange): void => {
        this.props.storage.handleChange(e);

        handleCurrentTemporalPropChange(e, this.props.storage as FormStorage<any>);
        this.handleCitizenshipChange(e);
        handleBankFieldChange({
            storage: this.props.storage as FormStorage<any>,
            isEntityWithoutBP: true,
            businessPartner: null,
            type: null
        }, e);

        // triggerAdditionalTasks === true for select like components has same
        // meaning as triggerAdditionalTasks === undefined for the rest types
        const shouldUpdate = e.triggerAdditionalTasks !== false;
        this.props.storage.refreshFields(shouldUpdate);
    };

    handleLineItemsAction = (args: ISmartFastEntriesActionEvent): void => {
        this.props.storage.handleLineItemsAction(args);

        if (args.actionType === ActionType.Add) {
            const [item] = args.affectedItems;
            if (args.bindingContext.getPath() === PrEmployeeEntity.Dependents) {
                const dependent = item as IPrEmployeeDependentPersonEntity;

                dependent.DateValidFrom = getUtcDate();
                dependent.DateValidTo = DATE_MAX;
            } else if (args.bindingContext.getPath() === PrEmployeeEntity.Cars) {
                const dependent = item as IPrEmployeeCarEntity;
                const info = this.props.storage.getInfo(this.props.storage.data.bindingContext.navigate(PrEmployeeEntity.Cars).navigate(PrEmployeeCarEntity.LowEmission));
                const dialogSettings = info.fieldSettings.temporalDialog;

                dependent.DateValidFrom = getDefaultTemporalDateValidFrom(dialogSettings);
                dependent.DateValidTo = getDefaultTemporalDateValidTo(dialogSettings);
            }
        }

        this.props.storage.refresh();
    };

    /** Take ranges of one temporal property group,
     * set DateValidTo to today, if current range exists
     * and remove any planned items. */
    finishPropertyToday = (propName: string, entity: typeof this.entity): typeof this.entity => {
        const temporalPropertyBagBc = this.props.storage.data.bindingContext.navigate("TemporalPropertyBag");
        const bc = temporalPropertyBagBc.navigate(propName);
        const properties = getGroupingProperties(bc, this.props.storage.getThis());
        const temporalBag = this.props.storage.getValue(temporalPropertyBagBc, { dataStore: entity }) as TTemporal[] ?? [];
        let groupedRanges = groupAndOptimizeRanges(temporalBag, properties);

        if (!groupedRanges || groupedRanges.length === 0) {
            return entity;
        }

        const today = getUtcDayjs();

        // remove all future planned items
        groupedRanges = groupedRanges.filter(range => today.isAfter(range.DateValidFrom));

        const currentRangeIndex = getCurrentRangeIndex(groupedRanges);
        const currentRange = groupedRanges[currentRangeIndex];

        if (currentRange) {
            // otherwise, finish the group today
            currentRange.DateValidTo = today.toDate();
        }

        const ungroupedRanges = ungroupTemporalRanges(groupedRanges, temporalBag, properties);

        return setBoundValue({
            bindingContext: temporalPropertyBagBc,
            dataBindingContext: bc.getRootParent(),
            data: entity,
            newValue: ungroupedRanges
        });
    };

    onBeforeSave(): IPrEmployeeEntityExtended {
        let data = cloneDeep(this.entity);

        const years = this.props.storage.getValueByPath(PreviousEmploymentDurationYearsPath) ?? 0;
        const days = this.props.storage.getValueByPath(PreviousEmploymentDurationDaysPath) ?? 0;

        data.PreviousEmploymentDurationDays = years * 365 + days;

        if (!this.entity[ShowContactAddressPath]) {
            data.ContactAddress = null;
        }

        if (this.entity.CitizenshipCode === CountryCode.CzechRepublic) {
            data.ForeignAddress = null;
        }

        // for hidden form groups,
        // end current ranges today and remove any planned values
        if (!this.entity[ShowDisabilityPath]) {
            data = this.finishPropertyToday(PrEmployeeTemporalEntity.PrDisabilityType, data);
        }

        if (!this.entity[ShowStudyPath]) {
            data = this.finishPropertyToday(PrEmployeeTemporalEntity.PrFormOfStudyType, data);
        }

        return data;
    }

    renderCarsDialog = () => {
        const carsDialogBc = this.props.storage.getCustomData().carsDialogBc;

        if (!carsDialogBc) {
            return null;
        }

        return <CarsDialog storage={this.props.storage}
                           bindingContext={carsDialogBc}
                           onLineItemsTemporalChange={this.handleTemporalChange}
        />;
    };

    render() {
        return (
            <>
                {super.render()}
                {this.renderCarsDialog()}
            </>
        );
    }
}

export default withPermissionContext(EmployeeFormView);