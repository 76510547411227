import { TEntityKey } from "@odata/BindingContext";
import {
    EntitySetName,
    INotificationSettingEntity,
    IUserNotificationEntity,
    OdataActionName
} from "@odata/GeneratedEntityTypes";
import { WebSocketMessageTypeCode } from "@odata/GeneratedEnums";
import { ODataQueryResult } from "@odata/ODataParser";
import { WithOData, withOData } from "@odata/withOData";
import { getCompanyLogoUrl } from "@utils/CompanyUtils";
import { isObjectEmpty } from "@utils/general";
import { TWebsocketMessage } from "@utils/websocketManager/Websocket.types";
import { isNotificationWebsocketMessage } from "@utils/websocketManager/Websocket.utils";
import i18next from "i18next";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { withTheme } from "styled-components";

import { AppContext, IAppContext } from "../contexts/appContext/AppContext.types";
import { toTuple } from "../global.types";
import evalaLogo from "../static/images/evalaLogo.png";
import { PropsWithTheme } from "../theme";
import memoizeOne from "../utils/memoizeOne";
import WebsocketManager from "../utils/websocketManager/WebsocketManager";
import {
    fetchNotificationsMemoized,
    getNotificationArguments,
    getNotificationUrl,
    redrawFavicon
} from "./Notification.utils";
import { INotification } from "./Notifications";
import PopperNotificationAlert from "./PopperNotificationAlert";

export const NotificationsContext = React.createContext<INotificationsContext>(undefined);

export interface INotificationsContext {
    notifications: INotification[];
    onlyShowUnread: boolean;
    setOnlyShowUnread: (onlyShowUnread: boolean) => void;
    unreadNotificationsCount: number;
    markAllAsRead: () => void;
    updateNotification: (notification: Partial<IUserNotificationEntity>) => void;
    setIsBannerEnabled: (isBannerEnabled: boolean) => void;
    refreshNotifications: () => void;
}

interface IState extends Pick<INotificationsContext, "onlyShowUnread"> {
    // store notification entities instead of transformed INotification
    // and create INotification on demand,
    // otherwise, translated texts would be stored in state and would not update on language change
    notifications: IUserNotificationEntity[];
    newNotification: INotification;
    isBannerEnabled: boolean;
}

interface IProps {

}

class NotificationsProvider extends React.PureComponent<IProps & WithOData & PropsWithTheme & WithTranslation, IState> {
    static contextType = AppContext;

    _unsubscribeWebsocket: () => void;

    state: IState = {
        // todo move onlyShowUnread to p13n? currently not stored anywhere
        onlyShowUnread: false,
        notifications: [],
        isBannerEnabled: false,
        newNotification: null
    };

    componentDidMount() {
        this._unsubscribeWebsocket = WebsocketManager.subscribe({
            callback: this.handleWebsocketMessage,
            types: [WebSocketMessageTypeCode.Notification]
        });

        if (this.props.oData) {
            this.initNotifications();
        }
    }

    componentDidUpdate(prevProps: IProps & WithOData & PropsWithTheme, prevState: IState) {
        if (!prevProps.oData && this.props.oData) {
            this.initNotifications();
        }
    }

    componentWillUnmount() {
        this._unsubscribeWebsocket();
    }

    get unreadNotificationsCount(): number {
        return this.state.notifications.filter((notification) => !notification.IsRead).length;
    }

    /**
     * Transform notifications from entity on demand,
     * to ensure that texts are translated on language change
     * */
    get notifications(): INotification[] {
        return this.state.notifications.map((notification: IUserNotificationEntity) => this.transformNotificationEntityToINotification(notification));
    }

    replaceTemplateText = (template: string, args: string[] = []) => {
        if (!template) {
            return "";
        }

        // templates can include replaceable variables like {0}
        const variablesRegex = /{(\d+)}/g;

        return template.replace(variablesRegex, (match, number) => {
            return args[number] !== undefined
                ? args[number]
                : match;
        });
    };

    transformNotificationEntityToINotification = (userNotification: IUserNotificationEntity): INotification => {
        const notification = userNotification.Notification;
        const isCompany = !isObjectEmpty(notification.Company);
        const avatarSrc = isCompany ? getCompanyLogoUrl(notification.Company) : evalaLogo;
        const title = isCompany ? notification.Company.Name : "Evala";
        const textArguments = getNotificationArguments(notification);
        const i18nPrefix = `Notifications:${notification.NotificationTypeCode}`;
        let description: string;

        if (textArguments?.ErrorCode) {
            description = this.props.t(`Error:${textArguments.ErrorCode}`, textArguments.ErrorParameters);
        } else {
            description = i18next.exists(`${i18nPrefix}.Text`) ? this.props.t(`${i18nPrefix}.Text`, textArguments) : "";
        }

        return {
            id: userNotification.Id.toString(),
            isUnread: !userNotification.IsRead,
            time: notification.DateCreated,
            avatarSrc: avatarSrc,
            title: title,
            subtitle: this.props.t(`${i18nPrefix}.Title`, textArguments),
            info: i18next.exists(`${i18nPrefix}.Info`) ? i18next.t(`${i18nPrefix}.Info`, textArguments) : notification.NotificationType?.Name,
            description,
            url: getNotificationUrl(notification)
        };
    };


    async fetchNotifications(): Promise<ODataQueryResult<IUserNotificationEntity[]>>
    async fetchNotifications(key: TEntityKey): Promise<ODataQueryResult<IUserNotificationEntity>>

    fetchNotifications(key?: TEntityKey): Promise<ODataQueryResult<IUserNotificationEntity | IUserNotificationEntity[]>> {
        return fetchNotificationsMemoized(this.props.oData, key);
    }

    initNotifications = async (): Promise<void> => {
        const context = this.context as IAppContext;

        if (context.hasLimitedAccess()) {
            // most odata requests will fail for canceled tenant
            return;
        }

        const settingsId = context.getData().userSettings.NotificationSetting.Id.toString();
        const promises = toTuple([
            await this.fetchNotifications(),
            await this.props.oData.getEntitySetWrapper(EntitySetName.NotificationSettings)
                .query(settingsId)
                .fetchData<INotificationSettingEntity[]>()
        ]);

        const [notificationsRes, notificationSettingRes] = await Promise.all(promises);
        const notifications = notificationsRes.value;

        this.setState({
            notifications: notifications,
            isBannerEnabled: !notificationSettingRes.value[0]?.IsBannerDisabled
        }, () => {
            // this.redrawFavicon();
        });
    };

    redrawFavicon = () => {
        redrawFavicon(this.unreadNotificationsCount, this.props.theme);
    };

    handleWebsocketMessage = async (message: TWebsocketMessage) => {
        if (!isNotificationWebsocketMessage(message)) {
            return;
        }

        const res = await this.fetchNotifications(message.Parameters.Id);
        const userNotification = res.value;

        this.addNewNotification(userNotification);
    };

    getContext = memoizeOne((): INotificationsContext => {
        return {
            notifications: this.notifications,
            onlyShowUnread: this.state.onlyShowUnread,
            setOnlyShowUnread: this.setOnlyShowUnread,
            markAllAsRead: this.markAllAsRead,
            unreadNotificationsCount: this.unreadNotificationsCount,
            setIsBannerEnabled: this.setIsBannerEnabled,
            updateNotification: this.updateNotification,
            refreshNotifications: this.initNotifications
        };
    }, () => [this.state.notifications, this.state.onlyShowUnread, i18next.language]);

    setOnlyShowUnread = (onlyShowUnread: boolean) => {
        this.setState({
            onlyShowUnread
        });
    };

    markAllAsRead = async (): Promise<void> => {
        const notifications: IUserNotificationEntity[] = [];


        for (const notification of this.state.notifications) {
            notifications.push({
                ...notification,
                IsRead: true
            });
        }

        this.setState({
            notifications
        });

        await this.props.oData.getEntitySetWrapper(EntitySetName.Notifications)
            .action(OdataActionName.UserNotificationsMarkAllIsRead);
    };

    updateNotification = async (notification: Partial<IUserNotificationEntity>): Promise<void> => {
        this.setState((state) => {
            return {
                notifications: state.notifications.map(n => {
                    if (notification.Id === n.Id) {
                        return {
                            ...n,
                            ...notification
                        };
                    } else {
                        return n;
                    }
                })
            };
        });

        await this.props.oData.getEntitySetWrapper(EntitySetName.Notifications)
            .action(OdataActionName.UserNotificationSwitchIsRead, notification.Id);

    };

    setIsBannerEnabled = (isBannerEnabled: boolean) => {
        this.setState({
            isBannerEnabled
        });
    };

    addNewNotification = (newNotification: IUserNotificationEntity) => {
        this.setState((state) => {
            return {
                newNotification: this.state.isBannerEnabled ? this.transformNotificationEntityToINotification(newNotification) : null,
                notifications: [
                    ...state.notifications,
                    newNotification
                ]
            };
        });
    };

    handleNewNotificationFade = () => {
        this.setState({
            newNotification: null
        });
    };

    render() {
        return (
            <NotificationsContext.Provider value={this.getContext()}>
                {this.state.isBannerEnabled && this.state.newNotification &&
                    <PopperNotificationAlert
                        // use key to reset animation if new notification is shown before previous animation ended
                        key={this.state.newNotification.id}
                        avatarSrc={this.state.newNotification.avatarSrc}
                        title={this.state.newNotification.title}
                        subtitle={this.state.newNotification.subtitle}
                        onFadeEnd={this.handleNewNotificationFade}/>}
                {this.props.children}
            </NotificationsContext.Provider>
        );
    }
}

const ExtendedNotificationsProvider = withTranslation(["Notifications"])(withTheme(withOData(NotificationsProvider)));

export { ExtendedNotificationsProvider as NotificationsProvider };