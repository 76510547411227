import styled, { DefaultTheme } from "styled-components/macro";
import {
    T_BOLD_tiny,
    T_HEADER_bold,
    T_PLAIN_big,
    T_PLAIN_big_hig,
    T_PLAIN_med_hig,
    T_PLAIN_small
} from "../../global.style";
import { darken, transparentize } from "polished";
import { TileSize } from "./Calendar.utils";
import { TextEllipsisStyled } from "../textEllipsis/TextEllipsis.styles";

const GRID_GAP = 2;
const ACTIONS_WRAPPER_PADDING = 5;

export const DayLabel = styled.div`
    ${T_HEADER_bold};
    text-align: center;
    text-transform: uppercase;
`;

export const CalendarWrapper = styled.div<{
    $isSmall: boolean;
}>`
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-auto-rows: min-content;
    row-gap: ${GRID_GAP}px;
    column-gap: ${GRID_GAP}px;
    width: 100%;
    max-width: ${7 * TileSize.MAX + 6 * GRID_GAP}px;

    ${DayLabel} {
        padding-bottom: ${props => props.$isSmall ? 5 : 6}px;
    }
`;

export const DayWrapper = styled.div<{
    $isSelected?: boolean;
    $isDummy?: boolean;
    $size?: number;
    $isHoliday?: boolean;
}>`
    display: flex;
    min-width: ${TileSize.MIN}px;
    max-width: ${TileSize.MAX}px;
    flex-direction: column;
    aspect-ratio: 1 / 1;
    border-radius: 3px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
    border: 1px solid ${props => props.theme.C_BG_menu};
    border-color: ${props => props.$isSelected ? props.theme.C_ACT_main : "transparent"};
    cursor: ${props => props.$isDummy ? "inherit" : "pointer"};
    user-select: none;

    ${props => props.$size < TileSize.L ? `
        padding-bottom: 8px;
    ` : ""}

    background-color: ${props => props.$isSelected ? transparentize(0.15, props.theme.C_BTN_4L_trans_bg_selected) : props.theme.C_BG_menu};

    &:nth-child(7n + 13) { // saturdays
        background: ${props => !props.$isDummy && !props.$isSelected ? transparentize(0.98, props.theme.C_ACT_main) : ""};
    }

    &:nth-child(7n + 14) { // sundays
        background: ${props => !props.$isDummy && !props.$isSelected ? transparentize(0.95, props.theme.C_ACT_main) : ""};
    }

    ${props => props.$isHoliday ? `
        background: ${!props.$isSelected ? transparentize(0.95, props.theme.C_ACT_main) : ""};
    ` : ""}
    &:hover, &:focus-visible {
        ${props => !props.$isDummy ? `
            border: 1px solid ${transparentize(0.5, props.theme.C_ACT_main)};
            outline: none;
        ` : ""};
    }
`;

export const DayIndex = styled.div<{
    $isToday: boolean;
    $size: number;
}>`
    ${props => props.$size >= TileSize.L ? T_HEADER_bold : T_PLAIN_med_hig};
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    ${props => props.$isToday ? props.$size >= TileSize.L ? `
        color: ${props.theme.C_BTN_4D_emph_text};
        &::before {
            content: "";
            position: absolute;
            z-index: -1;
            background: ${props.theme.C_ACT_main};
            border-radius: 100%;
            width: 33px;
            height: 33px;
            top: -7px;
        }
    ` : `
        text-decoration: underline;
        text-underline-offset: 3px;
    ` : ""};
`;

export const WorkTimeSaldo = styled.div<{
    $isBig: boolean;
}>`
    ${props => props.$isBig ? T_PLAIN_big_hig : T_BOLD_tiny};
    color: ${props => transparentize(0.5, props.theme.C_TEXT_primary)};
    text-align: center;
`;

export const ActionsWrapper = styled.div<{
    $isBig: boolean;
}>`
    display: flex;
    justify-content: center;
    gap: 5px;
    padding: 0 5px;
    min-width: 1px;
    height: ${props => props.$isBig ? 20 : 10}px;
`;

export const ActionTitle = styled.div`
    margin-left: ${ACTIONS_WRAPPER_PADDING}px;
    overflow: hidden;

    ${TextEllipsisStyled} {
        ${T_PLAIN_small};
    }
`;

interface IStyledActionProps {
    $color: keyof DefaultTheme;
    $isStart: boolean;
    $isEnd: boolean;
    $size?: number;
    $hasConflict?: boolean;
    $dayHasMoreActions?: boolean;
    $isBig: boolean;
}

const getWidthForAction = (props: IStyledActionProps): string => {
    if (props.$dayHasMoreActions) {
        return "auto";
    }
    let extraWidth = 0;
    if (!props.$isEnd) {
        extraWidth += GRID_GAP + ACTIONS_WRAPPER_PADDING + 1;
    }
    if (!props.$isStart) {
        extraWidth += GRID_GAP + ACTIONS_WRAPPER_PADDING + 1;
    }
    return extraWidth ? `calc(100% + ${extraWidth}px)` : "100%";
};

export const Action = styled.div<IStyledActionProps>`
    display: inline-flex;
    color: ${props => props.theme.C_BTN_4D_emph_text};
    height: ${props => props.$isBig ? "20px" : "10px"};
    width: ${props => props.$isBig ? getWidthForAction(props) : "10px"};
    align-items: center;
    z-index: 1;

    padding: 0 ${props => props.$isEnd ? "5" : "10"}px 0 ${props => props.$isStart ? "5" : "10"}px;
    ${props => !props.$isBig ? "padding: 0;" : ""}

    border-bottom-left-radius: ${props => props.$isStart ? "3px" : "0"};
    border-top-left-radius: ${props => props.$isStart ? "3px" : "0"};
    border-top-right-radius: ${props => props.$isEnd ? "3px" : "0"};
    border-bottom-right-radius: ${props => props.$isEnd ? "3px" : "0"};

    background: ${props => props.$hasConflict ? `
        repeating-linear-gradient(
                -45deg,
                ${props.theme[props.$color]} 0 8px,
                ${darken(0.15, props.theme[props.$color])} 8px 16px
        )` : props.theme[props.$color]} fixed;
    ${props => !props.$isBig ? "background: none;" : ""}


    ${props => props.$isStart || !props.$isBig ? "" : `margin-left: -${GRID_GAP + ACTIONS_WRAPPER_PADDING + 1}px;`}
    ${props => props.$isEnd || !props.$isBig ? "" : `margin-right: -${GRID_GAP + ACTIONS_WRAPPER_PADDING + 1}px;`}

    flex-grow: 1;

    & .SI_4L_fill_orange {
        fill: ${props => props.$isBig ? props.theme.C_BTN_4D_emph_text : ""};
    }

    ${props => !props.$isBig ? `
        flex-grow: 0;
        & > ${Circle} { 
            background-color: ${props.theme[props.$color]};
            border-color: ${props.theme[props.$color]};
        }
        & + ${MoreButton} { 
            height: 10px;
            display: flex;
            align-items: center;
        }
    ` : ""}
`;

export const Circle = styled.div<{
    $isHalf: boolean;
    $size: number;
}>`
    height: 10px;
    min-width: ${props => props.$isHalf ? "5px" : "10px"};
    margin-right: ${props => props.$isHalf ? "5px" : "0"};
    background-color: ${props => props.theme.C_BTN_4D_emph_text};
    border: 1px solid ${props => props.theme.C_BTN_4D_emph_text};
    border-radius: ${props => props.$isHalf ? "10px 0 0 10px" : "100%"};
`;

export const MoreButton = styled.div<{
    $isSmall: boolean
}>`
    ${props => props.$isSmall ? T_PLAIN_small : T_PLAIN_big};
    color: ${props => props.theme.C_ACT_main};

    &:hover {
        color: ${props => props.theme.C_BTN_4L_emph_text_hover};
    }
`;

export const SmartSpace = styled.div<{
    $basis: number;
    $max: number;
}>`
    flex: 1 1 ${props => props.$basis}px;
    max-height: ${props => props.$max}px;
`;