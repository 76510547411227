import { AlertAction, IAlertProps, IBaseAlertProps } from "@components/alert/Alert";
import { isSelectBasedComponent } from "@components/inputs/select/SelectAPI";
import {
    allowCreateValues,
    getInfoValue,
    IFieldDef,
    isRequired,
    isVisible,
    isVisibleByPath,
    TFieldDefaultValue
} from "@components/smart/FieldInfo";
import { getCollapsedGroupId } from "@components/smart/Smart.utils";
import { ActionType, ISmartFastEntriesActionEvent } from "@components/smart/smartFastEntryList";
import {
    ISmartFieldBlur,
    ISmartFieldChange,
    ISmartFieldValidationError
} from "@components/smart/smartField/SmartField";
import { IDependentFieldDef, IFormGroupDef } from "@components/smart/smartFormGroup/SmartFormGroup";
import { ISummaryItem } from "@components/smart/smartSummaryItem/SmartSummaryItem";
import SmartTableManager from "@components/smart/smartTable/SmartTableManager";
import { IFormVariant, IVariant, Variant, VariantType } from "@components/variantSelector/VariantOdata";
import { isODataError, IValidationMessage, ODataError } from "@odata/Data.types";
import {
    createBindingContextFromValidatorPath,
    getBoundValue,
    getNestedValue,
    getNewItemsMaxId,
    ICachedFieldState,
    ISaveEntity,
    isNavigationForUpdate,
    IStorageSmartFieldValues,
    mainBatchRequestId,
    saveEntity,
    setBoundValue,
    setDirtyFlag,
    setNestedValue
} from "@odata/Data.utils";
import { getFieldInfo, IFieldInfo } from "@odata/FieldInfo.utils";
import {
    EntitySetName,
    IEvalaMetadataRule,
    IInboxFileEntity,
    OdataActionName,
    ODataEntityMetadata
} from "@odata/GeneratedEntityTypes";
import { BatchRequest, EVALA_METADATA_HEADER, IBatchResult, isBatchResultOk, isIUrl } from "@odata/OData";
import { IPrepareQuerySettings, prepareQuery } from "@odata/OData.utils";
import { ODataQueryResult } from "@odata/ODataParser";
import { DRAFT_ITEM_ID_PATH, tWithFallback } from "@pages/documents/Document.utils";
import { isDefined, isNotDefined, isObjectEmpty, removeNullValuesFromObject, uuidv4 } from "@utils/general";
import { logger } from "@utils/log";
import memoizeOne from "@utils/memoizeOne";
import { getOneFetch, isAbortException } from "@utils/oneFetch";
import i18next from "i18next";
import i18n from "i18next";
import { cloneDeep, isEmpty, mergeWith } from "lodash";
import React from "react";
import { ObjectSchema } from "yup";

import {
    BackendErrorCode,
    FieldType,
    FormMode,
    NavigationSource,
    PageViewMode,
    QueryParam,
    Sort,
    Status,
    ValidationErrorType,
    ValueType
} from "../../enums";
import { TRecordAny, TRecordString, TValue } from "../../global.types";
import { IMergedFieldDef, IModel, ModelEvent, ModelType, TMergedDefinition } from "../../model/Model";
import { IStorageModelData, StorageModel } from "../../model/StorageModel";
import { Validator } from "../../model/Validator";
import { IValidationError } from "../../model/Validator.types";
import BindingContext, {
    areBindingContextsDifferent,
    createBindingContext,
    IEntity,
    splitPath,
    TEntityKey
} from "../../odata/BindingContext";
import { ROUTE_NOT_FOUND } from "../../routes";
import { getQueryParameters } from "../../routes/Routes.utils";
import LocalSettings from "../../utils/LocalSettings";
import memoize from "../../utils/memoize";
import { IFormDef } from "./Form";
import { getAlertFromError, getFormTableFilterQuery } from "./Form.utils";


// if there is array with more items then indicator value, save is async (with different processing)
const LARGE_DATA_INDICATOR = 500;
// how much items is process in one step (the bigger the number, the longer freeze when saving large datasets)
const LARGE_DATA_CHUNK_SIZE = 200;

export interface IContextInitArgs {
    definition?: IFormDef;
    bindingContext?: BindingContext;
    formMode?: FormMode;

    /** If provided, data are used instead of firing a request */
    data?: IEntity;
    /** Don't load variants for forms without customization */
    ignoreVariants?: boolean;
    /** Don't set default values of empty fields */
    ignoreDefaults?: boolean;

    preserveInfos?: boolean;
    /** Used when variant (configuration) changes.
     * We want to load data for the newly add fields, but we want to keep any changes user might have done to the form. */
    preserveData?: boolean;
    onAfterLoad?: () => void;
}

export enum FormAlertPosition {
    Top = "top",
    Bottom = "bottom"
}

export interface IFormAlert extends IBaseAlertProps, Pick<IAlertProps, "action" | "onClose" | "isFullWidth" | "isOneLiner" | "onFadeEnd"> {
    position?: FormAlertPosition;
}

export interface IFormStorageSaveResult {
    bindingContext: BindingContext;
    data: IEntity;
    // todo improve interface both here and in OData.parseQueryResult
    // full response object returned from backend
    response?: any;
}

export interface IGetCorrectErrorBc {
    matchedBindingContext: BindingContext;
    error: IValidationMessage;
    entity: IEntity;
}


interface IAddFieldArgs {
    path: string;
    useForValidationByDefault: boolean;
    fieldDef?: IFieldDef;
    useForLoad?: boolean;
    collectionPath?: string;
    /** Required fields that are not present in current variant are still add to mergedDefinition,
     * so that their default values are set or their values are fetched during loading.
     * This property is stored to easily find out if the fields is part of the variant without having to run complicated search through the whole variant definition. */
    isNotInVariant?: boolean;
}

interface IBindingContextMap {
    bindingContext: BindingContext;
    entity: IEntity;
}

interface IFormStorageReloadArgs {
    preserveInfos: boolean;
    preserveData?: boolean;
    withoutBusy?: boolean;
}

export type TGetCorrectErrorBc = (args: IGetCorrectErrorBc) => BindingContext;
export type TCustomResponseHandler = (response: IBatchResult[], args: IValidateAndSave, formStorage: FormStorage) => Promise<any>;
export type TShouldUseCustomResHandler = (response: IBatchResult[]) => boolean;

export interface ISaveArgs {
    queryParams?: TRecordAny;
    successSubtitle?: string;
    data?: IEntity;
    bindingContext?: BindingContext;
    skipLoad?: boolean;
    // callback called before executing the batch request
    // provides the batch object, to inject custom requests
    onBeforeExecute?: (batch: BatchRequest) => Promise<void>;
    // list of entities (of navigation properties) with independent entity set that will be handled by prepareBatch
    // to create PATCH requests automatically
    updateEnabledFor?: string[];

    /** Sometimes (e.g. when amount is changed on item with existing deferred plan),
     * we want to react differently to error returned from BE (show dialog with options instead of error alert) */
    customResponseHandler?: TCustomResponseHandler;
    shouldUseCustomResponseHandler?: TShouldUseCustomResHandler;

    // Do not add etag to the request
    skipETag?: boolean;

    /** When backend returns validation error, there can be a mismatch between the entity path
     * that BE returns the error for and between the path we use on FE for the field.
     *
     * e.g. BE matches the error to "..../AccountAssignmentSelection" but our field has path "...../AccountAssignmentSelection/AccountAssignment"
     *
     * We can solve this with getCorrectErrorBc that we provide in custom FormView, to return correct binding context. */
    getCorrectErrorBc?: TGetCorrectErrorBc;

    // used in dialogs, where we don't want to render alert for field validation, to save space
    fieldValidationWithoutErrorAlert?: boolean;

    // if you have some special operation after save is done
    skipAfterSave?: boolean;
}

export interface IGroupStatus {
    isCreating?: boolean;
    isExpanded?: boolean;
    isMenuOpened?: boolean;
    activeTab?: string;
    selectedOption?: string;
    /** tabs with tables are hidden by default,
     and will only be shown once we know that they contain data */
    visibleTabs?: Record<string, boolean>;
}

export interface IValidateAndSave extends Omit<ISaveArgs, "bindingContext">, Omit<ISaveEntity, "batch"> {
}

type TJobFn = (entity: IEntity, bc?: BindingContext) => {};

interface IHandleOdataError {
    error: ODataError;
    bindingContext: BindingContext;
    entity?: IEntity;
    getCorrectErrorBc?: TGetCorrectErrorBc;
    fieldValidationWithoutErrorAlert?: boolean;
}

export interface IFormStatus {
    groups?: Record<string, IGroupStatus>;
    isChanged?: boolean;
}

export interface IFormStorageDefaultCustomData {
    busy?: boolean;
    busyDelayed?: boolean;
    draft?: IEntity;
    draftEtag?: string;
    isCustomizationDialogOpen?: boolean;
    isFirstRefreshAfterInit?: boolean;
    // whether we want to show "copy" on the form (has been created by copy button on another form)
    isCopy?: boolean;
    /* data stored in formStorage.customData with this key,
       will be used as initial data (default entity) next time "init" methods id called
       used for copy functionality*/
    initialData?: IEntity;
    Parent?: BindingContext;
    // ParentType?: string;
    // if bc set, TemporalPropertyDialog is opened for that bc
    temporalPropertyDialogBc?: BindingContext;
    // When there is some entity without draft, we want to attach temporarily inbox files to it when new one is created
    // and call ConvertToDocuments API on save
    inboxFilesToConvert?: IInboxFileEntity[];
    isDeleteButtonBusy?: boolean;
}

export interface IFormStorageData<E extends IEntity = IEntity, C extends IFormStorageDefaultCustomData = IFormStorageDefaultCustomData> extends IStorageModelData<E, C> {
    status?: IFormStatus;
    definition?: IFormDef;
    origEntity?: Partial<E>;
    // metadata object returned from backend for the current entity
    // contains etag and can contain DisabledPropertyRules/EnabledPropertyRules
    metadata?: ODataEntityMetadata;
    name?: string;
    alert?: IFormAlert;
    // form locked => lock icon in breadcrumbs, disabled fields are driven by evala metadata
    locked?: boolean;
    // form disabled => every field disabled, so far used when lock or delete table action is active
    disabled?: boolean;
    mergedDefinition?: TMergedDefinition;
    // results from additional promises added from concrete form view via function getAdditionalLoadPromise
    // todo unknown instead of any
    additionalResults?: any;
}

interface IUpdateDependentFieldArgs {
    field: IDependentFieldDef;
    fromBindingContext: BindingContext;
    toBindingContext: BindingContext;
    updatedData: TRecordAny;
}

interface IValidateAllRetVal {
    hasError: boolean;
    errorSubtitles: React.ReactNode[];
    validationErrors: TRecordString;
}

interface IDisabledFieldMetadata {
    // translated, concatenated message of all the errors related to the field
    message: string;
    // entity cannot be deleted - applicable for collection item, e.g. Items(4)
    cannotBeDeleted?: boolean;
    // cannot add item to  collection - applicable for whole collection, e.g. Items
    cannotAdd?: boolean;
    // cannot remove item from collection - applicable for whole collection, e.g. Items, Attachments..
    cannotDelete?: boolean;
    // unmodified data retrieved from backend
    originalRules: IEvalaMetadataRule[];
}

export class MockOData {

}

interface IFormModelOptions {
    // --------------------- DataImport specific ---------------------
    // settings used in DataImport
    // which doesn't use FormView, but still needs to render and validate fields based on form definition

    // prevent calling updateTabsVisibility during init
    ignoreTabsVisibility?: boolean;
    // enforce that all columns will be sent to Validator.createValidationSchema
    useAllFieldsForValidation?: boolean;
}

export interface IFormModel extends IModel, IFormModelOptions {
    pageViewMode?: PageViewMode;
}

export class FormStorage<E extends IEntity = IEntity, C extends IFormStorageDefaultCustomData = IFormStorageDefaultCustomData> extends StorageModel<E, IFormStorageData<E, C>, C> {
    // used for saves new items - by default when loading data, we want to reset alerts (which is done in init)
    // but for saving new items we want to display alert even after new stuff is load
    _preserveAlert: boolean;

    // flag for FIRST-TIME load. Don't turn it OFF once it is set, as it will result in form flicking when changing rows
    loaded: boolean;
    formMode: FormMode = FormMode.Default;
    // read only mode for the whole page (hide table, different buttons)
    pageViewMode: PageViewMode = PageViewMode.Default;

    options: IFormModelOptions;

    groupRefs: TRecordAny;
    activeGroups: Record<string, boolean> = {};

    data: IFormStorageData<E, C>;

    ignoreVariants: boolean;
    LOCAL_STORAGE_VARIANT_NAME = "formVariant";
    type = ModelType.Form;
    oneFetchEntity = getOneFetch();
    oneFetchDraft = getOneFetch();
    tabsDataBatch: BatchRequest;

    shouldDelayInitialBusy = true;

    constructor(args: IFormModel) {
        super(args);

        this.data = {
            entity: {},
            bindingContext: args?.bindingContext,
            fieldsInfo: {},
            temporalData: {},
            additionalFieldData: {},
            definition: args.definition as IFormDef
        };

        if (args.pageViewMode) {
            this.pageViewMode = args.pageViewMode;
        }

        this.options = {
            ignoreTabsVisibility: !!args.ignoreTabsVisibility,
            useAllFieldsForValidation: !!args.useAllFieldsForValidation
        };

        this.groupRefs = {};
    }

    _getReadOnlyFromDef = (): boolean => {
        return getInfoValue(this.data.definition, "isReadOnly", {
            storage: this.getThis(),
            data: this.data.entity
        });
    };

    get isReadOnly(): boolean {
        return this.pageViewMode === PageViewMode.FormReadOnly || this._getReadOnlyFromDef();
    }

    get isDisabled(): boolean {
        return this.data?.disabled;
    }

    // computed from the BE disabled flag api
    // returns null if the field is not disabled or metadata object, if the field is disabled
    // cached using _.memoize, reset on form init
    getBackendDisabledFieldMetadata = memoize((bc: BindingContext): IDisabledFieldMetadata => {
        const rules = new Map<string, IEvalaMetadataRule>();
        const evalaMetadata = this.data.metadata?.metadata;
        const fieldInfo = this.getInfo(bc);
        const navPath = fieldInfo?.backendPath ? bc.getParent().navigate(fieldInfo.backendPath).getNavigationPath() : bc.getNavigationPath();

        /** Adds weight to the applicable rules, that tells how strong the rule is for the given binding context,
         * so that we can differentiate between prefix matches and when the rule targets our particular binding context*/
        const fnFindApplicableRules = (rulesMap: Record<string, IEvalaMetadataRule[]>): {
            rules: IEvalaMetadataRule[];
            weight: number;
        } => {
            // find applicable rule - either navPath fully matches or just prefix matches
            let rules = rulesMap?.[navPath];
            let weight = navPath.split("/").length;

            if (!rules) {
                let tmpBc = bc.getParent();
                const rootBcPath = bc.getRootParent().toString();

                while (tmpBc.toString() !== rootBcPath && !rules) {
                    const prefixNavPath = tmpBc.getNavigationPath();
                    rules = rulesMap?.[prefixNavPath];

                    // rules with CannotBeDeleted/CannotAdd/CannotDelete has to match full binding context, not just prefix
                    if (rules) {
                        rules = rules.filter(rule => !rule.CannotBeDeleted && !rule.CannotAdd && !rule.CannotDelete);

                        if (rules.length === 0) {
                            rules = null;
                        }
                    }

                    weight = prefixNavPath.split("/").length;
                    tmpBc = tmpBc.getParent();
                }
            }

            if (!rules) {
                return null;
            }

            return {
                rules,
                weight
            };
        };

        const fieldDisabledRules = fnFindApplicableRules(evalaMetadata?.DisabledPropertyRules);

        if (fieldDisabledRules?.rules) {
            for (const rule of fieldDisabledRules.rules) {
                rules.set(rule.ErrorCode, rule);
            }
        }

        if (evalaMetadata?.EnabledPropertyRules && Object.keys(evalaMetadata.EnabledPropertyRules).length > 0) {
            const fieldEnabledRules = fnFindApplicableRules(evalaMetadata?.EnabledPropertyRules);

            for (const enabledRules of Object.values(evalaMetadata.EnabledPropertyRules)) {
                // skip the current navigation path's enabled rules
                if (enabledRules === fieldEnabledRules?.rules) {
                    continue;
                }

                for (const rule of enabledRules) {
                    // check if the error code of the current rule is not in the enabled rules for the current navigation path
                    if (!fieldEnabledRules || !fieldEnabledRules?.rules?.find(r => r.ErrorCode === rule.ErrorCode)) {
                        rules.set(rule.ErrorCode, rule);
                    }
                }
            }

            if (fieldDisabledRules?.rules && fieldEnabledRules?.rules) {
                for (const rule of fieldEnabledRules.rules) {
                    if (fieldDisabledRules.weight >= fieldEnabledRules.weight) {
                        continue;
                    }

                    // if we have enabled rule with bigger weight than disabled rule, remove the disabled rule from errors
                    const disabledRule = fieldDisabledRules.rules.find(r => r.ErrorCode === rule.ErrorCode);

                    if (disabledRule) {
                        rules.delete(rule.ErrorCode);
                    }
                }
            }
        }

        if (rules.size === 0) {
            return null;
        }

        const message = Array.from(rules.keys()).map(errCode => this.t(`Error:${errCode}`)).join("\n");

        // return a string of the error codes mapped to their corresponding error messages
        return {
            cannotBeDeleted: Array.from(rules.values()).some(rule => rule.CannotBeDeleted),
            cannotAdd: Array.from(rules.values()).some(rule => rule.CannotAdd),
            cannotDelete: Array.from(rules.values()).some(rule => rule.CannotDelete),
            message,
            originalRules: Array.from(rules.values())
        };
    }, (bc: BindingContext) => {
        return bc.getNavigationPath();
    });

    getOrigEntity = <E, >(): E => {
        return this.data.origEntity as E;
    };

    // todo improve interfaces and generics,
    // remove this function  if we manage to use correct generics in functions like getFormTableFilterQuery
    // this is just hack to make TS work without them
    getThis = (): FormStorage => {
        return this as unknown as FormStorage;
    };

    init = async (args: IContextInitArgs): Promise<any> => {
        const prevBindingContext = this.data.bindingContext;
        const { bindingContext } = args;
        // sometimes we switch entity type of the form when the selected row is changed (e.g. labels/label hierarchy)
        const hasEntityChanged = prevBindingContext?.getPath(true) !== bindingContext.getPath(true);

        this.loading = true;
        this.afterLoaded = false;

        this.reset(hasEntityChanged);
        if (args.formMode) {
            this.formMode = args.formMode;
        }
        this.data.definition = args.definition ?? this.data?.definition;

        if (isDefined(args.ignoreVariants) ? !args.ignoreVariants : !this.ignoreVariants) {
            await this.loadVariants(VariantType.Form, bindingContext);

            // ignore fields in variants that are not in fieldDefinition
            // this could happen e.g. when we forget to create a migration for removed field,
            // and it will still be present in user variant
            const fnFieldPresentInDefinition = (group: IFormGroupDef, field: IFieldDef) => {
                const collection = group.collection ?? group.lineItems?.collection;
                const path = collection ? `${collection}/${field.id}` : field.id;
                const presentInDefinition = !!this.data.definition.fieldDefinition[path];

                if (!presentInDefinition) {
                    logger.warn(`${field.id} stored in current variant is not present in current form definition. It is possible a migration should be created to remove it from users variants`);
                }

                return presentInDefinition;
            };
            for (const variant of Object.values(this.data.variants.allVariants)) {
                if (variant.formGroups) {
                    for (const formGroup of variant.formGroups) {
                        if (!formGroup.title) {
                            // title can be a function, which is not serialized -> get it from original definition
                            const origDef = this.data.definition.groups.find(grp => grp.id === formGroup.id);
                            formGroup.title = origDef?.title ?? "";
                        }
                        if (formGroup.rows) {
                            formGroup.rows = formGroup.rows.map(row => row.filter(field => fnFieldPresentInDefinition(formGroup, field)));
                        }
                    }
                }
            }
        }

        // store ignoreVariants if defined to be reused,
        // init is called from inside FormStorage, and we need to know
        // if the first init call from outside used ignoreVariants
        // => ignoreVariants always if not changed otherwise
        if (isDefined(args.ignoreVariants)) {
            this.ignoreVariants = args.ignoreVariants;
        }

        // store before calling collectColumnData, because it can be used in callbacks like isRequired that are called from there
        this.storeSingleValue("bindingContext", bindingContext);

        const canLoadDataFromBindingContext = bindingContext && !(bindingContext.isNew() || bindingContext.isLocal());
        // based on current definition merge defaultField def with groups and store it to mergedDefinition
        // merged definition is preserved among request of the same entity type (by same formstorage instance)
        if (!this.data.mergedDefinition) {
            try {
                this.collectColumnData(bindingContext, this.getVariant());
            } catch (e) {
                // Error loading variant, fallback to base definition
                logger.error(e.toString());
                // LocalStorage variant is broken -> clear it, select default variant and try to load it again
                this.setLocalStorageVariant(null);
                // remove partly created mergedDefinition
                delete this.data.mergedDefinition;
                this.collectColumnData(bindingContext, this.getVariant());
            }
        }

        let loadData: Promise<IEntity> = null;
        if (!args.data) {
            loadData = this.load(this.data.mergedDefinition, args.bindingContext, canLoadDataFromBindingContext);
        } else {
            // e.g. when document is copied
            // in case we are not loading data from BE, we need to clear related data -> metadata, etc...
            this.clearDisabledFieldMetadataCache();
            // todo: should we clear also origEntity??
        }
        let data = null;

        // create infos based on binding context. Part of infos is memoized, for now only fieldSettings is deepcloned and is recreated
        const fieldsInfo = this.loadInfos(this.data.mergedDefinition, bindingContext, args.preserveInfos);


        const promises: (Promise<unknown> | void)[] = [loadData, fieldsInfo];
        promises.push(...(this.getAdditionalLoadPromise(args) || []));

        await Promise.all(promises)
            .then(async (results: TRecordAny[]) => {
                // Check if store binding context doesn't change in the meantime -> in that case, do not store the results
                if (areBindingContextsDifferent(this.data.bindingContext, bindingContext)) {
                    return;
                }

                // store fieldsInfo immediately, otherwise they will not be available in Model.getInfo
                const loadedData = results[0];
                const loadedFieldsInfo = results[1];
                const additionalResults = results.slice(2);
                this.store({ fieldsInfo: loadedFieldsInfo });

                const preservedEntity = args.preserveData ? this.data.entity : null;
                const preservedOrigEntity = args.preserveData ? this.data.origEntity : null;
                // if we are preserving data, we need to preserve additionalFieldData as well
                // to not lose errors and dirty states
                const additionalFieldData = args.preserveData ? this.data.additionalFieldData : {};
                let _data: IFormStorageData<E> = loadedData;
                // !_data => only init default values for new form
                if (!args.data && isObjectEmpty(_data?.entity)) {
                    _data = this.clearForm(this.data.mergedDefinition, bindingContext);
                } else if (!args.ignoreDefaults && isObjectEmpty(_data?.origEntity)) {
                    // if there are some data, set default values for empty fields,
                    // e.g. when we open partial draft generated from isDoc

                    // store the loaded data into entity first,
                    // many of the isVisible callbacks that are used to check whether the default value should be set
                    // use storage.entity as source of data, not args.entity
                    this.store({
                        entity: (args.data as Partial<E>) ?? _data.entity,
                        origEntity: cloneDeep(args.data as Partial<E>) ?? _data.origEntity
                    });
                    this.setDefaultValueOfEmptyFields(this.data.mergedDefinition, bindingContext, args.data ?? _data.entity);
                }

                // Switching between newForm and already saved form resets group statuses
                const hasBcChanged = areBindingContextsDifferent(bindingContext, prevBindingContext);

                let entity: Partial<E>;
                let origEntity: Partial<E>;

                if (args.data) {
                    entity = args.data as Partial<E>;
                    origEntity = cloneDeep(args.data as Partial<E>);
                } else if (args.preserveData) {
                    entity = preservedEntity;
                    origEntity = preservedOrigEntity;

                    // only update the entity with the loaded data if the entity doesn't have the property yet
                    // use _.mergeWith to achieve this effect,
                    // _.merge only ignores "undefined" values in the _data.entity, but it still overrides the preserved changed values in entity with values from _data.entity
                    const fnCustomizer = (objValue: TValue, srcValue: TValue) => {
                        if (isNotDefined(objValue)) {
                            return srcValue;
                        }

                        return objValue;
                    };

                    mergeWith(entity, _data.entity, fnCustomizer);
                    mergeWith(origEntity, _data.origEntity, fnCustomizer);
                } else {
                    entity = _data.entity;
                    origEntity = _data.origEntity;
                }

                data = {
                    origEntity,
                    entity,
                    bindingContext,
                    status: this.clearFormStatus(hasBcChanged || bindingContext.isNew() ? {} : this.data.status?.groups),
                    additionalFieldData: additionalFieldData,
                    additionalResults: additionalResults,
                    temporalData: {}
                } as IFormStorageData<E, C>;

                data.alert = this._preserveAlert ? this.data.alert : null;

                this._preserveAlert = false;

                this.setCustomData({ inboxFilesToConvert: null });

                this.store(data);
                // update uuid so that we can recognize that the form has been reloaded (even when bc hasn't changed)
                this.updateUuid();
                // !! the order matters !!
                // ONE AND ONLY FORM force update IS CALLED FROM onAfterLoad ==>
                // A. we need set loaded true before so smartselects can be loaded when force update in onAfterLoad is called
                // B. If you modify something requiring current values setting from entity in on after load ---->
                //    CALL THIS METHOD YOURSELF AT THE END
                // C. EVERY overloaded onAfterLoad HAS TO CALL refresh !!!!!
                this.loaded = true;
                this.loading = false;

                await args.onAfterLoad?.();
                await this.onAfterLoad?.();
                this.afterLoaded = true;

                this.createFormValidationSchema();
            })
            .catch((e: Error) => {
                if (isAbortException(e)) {
                    return null;
                }
                if (isODataError(e)) {
                    if (e._code === BackendErrorCode.PermissionError) {
                        // todo: should we handle it in some special way??
                    }
                    const alert = getAlertFromError(e);
                    this.store({ alert });
                }
                this.loading = false;
                logger.error(e.toString());
            });

        if (!this.options.ignoreTabsVisibility) {
            this.updateTabsVisibility(null, true);
        }

        return data;
    };

    initWithoutLoad = async (definition: IFormDef, bindingContext: BindingContext): Promise<void> => {
        this.data.definition = definition;
        this.data.bindingContext = bindingContext;
        this.collectColumnData(bindingContext, this.getVariant());
        const fieldsInfo = await this.loadInfos(this.data.mergedDefinition, bindingContext);
        this.store({
            entity: {
                Id: bindingContext.getKey()
            } as unknown as E,
            origEntity: {},
            fieldsInfo,
            bindingContext,
            definition,
            additionalFieldData: {},
            additionalResults: {}
        });
        this.updateTabsVisibility(null, true);
    };

    /**
     * resets user changes to original saved data
     */
    async resetData(): Promise<void> {
        const { bindingContext, mergedDefinition, origEntity } = this.data;
        let clearedData;
        if (bindingContext.isNew()) {
            clearedData = this.clearForm(mergedDefinition, bindingContext);
        } else {
            clearedData = {
                entity: cloneDeep(origEntity),
                formStatus: this.clearFormStatus()
            };
        }

        this.store({
            ...clearedData,
            alert: null,
            additionalFieldData: {},
            temporalData: {}
        });
        await this.onAfterLoad?.();
    }

    handleFirstChange = (): void => {
        if (!this.data.status.isChanged && this.afterLoaded) {
            this.data.status.isChanged = true;

            this.refresh();
        }
    };

    /** !!Call from every place that could affect visibility of any tab, if "reload" isn't called instead.!!

     * @param affectedTabs list of tab ids that should be used to built visibility check request. If null, every tab will be checked.
     * @param rerenderOnlyIfSomeVisible performance optimization - if false, tab form group won't be re-rendered if no tab is visible
     *
     * Fetch first item (optimization, probably faster than count) for each table in tab,
     * to find out, whether we want to show the tab at all.
     * Don't await updateTabsVisibility so that the form isn't blocked for even longer period of time. */
    updateTabsVisibility = async (affectedTabs: string[], rerenderOnlyIfSomeVisible?: boolean): Promise<void> => {
        const definition = this.data.definition;

        if (this.formMode === FormMode.AuditTrail) {
            // completely hide tabs in AuditTrail (at least for now, before any spec/designs are made)
            return;
        }

        for (const group of (definition.groups ?? [])) {
            const origStatus = this.getGroupStatus(group.id);
            const visibleTabs: Record<string, boolean> = origStatus?.visibleTabs ?? {};
            const waitingTabs: string[] = [];
            this.tabsDataBatch?.abort();
            this.tabsDataBatch = this.oData.batch();

            this.tabsDataBatch.beginAtomicityGroup("group1");

            const isVisible = getInfoValue(group, "isVisible", {
                storage: this.getThis(),
                bindingContext: this.data.bindingContext,
                context: this.context
            });

            if (!group.tabs || !isVisible) {
                continue;
            }

            for (const tab of group.tabs) {
                if (affectedTabs && !affectedTabs.includes(tab.id)) {
                    continue;
                }

                if (!tab.table) {
                    visibleTabs[tab.id] = true;
                    continue;
                }

                waitingTabs.push(tab.id);

                const wrapper = this.tabsDataBatch.getEntitySetWrapper(tab.table.entitySet);
                const filter = getFormTableFilterQuery(this.getThis(), tab.table, this.data.bindingContext);

                wrapper.query().top(1).filter(filter.query);
            }

            try {
                if (waitingTabs.length > 0) {
                    const res = await this.tabsDataBatch.execute();

                    for (let i = 0; i < res.length; i++) {
                        visibleTabs[waitingTabs[i]] = (res[i]?.body as ODataQueryResult)?.value?.length > 0;
                    }
                }
            } catch (e) {
                if (isAbortException(e)) {
                    return;
                }
            }


            if (!rerenderOnlyIfSomeVisible || Object.keys(visibleTabs).length > 0) {
                let activeTab: string;

                if (origStatus?.activeTab && visibleTabs[origStatus.activeTab]) {
                    activeTab = origStatus.activeTab;

                    // if the original active tab is affected, reload the SmartTable to get new data
                    if (affectedTabs?.includes(activeTab)) {
                        const tableId = group.tabs.find(tab => tab.id === activeTab).table.id;

                        SmartTableManager.getTable(tableId).reloadTable();
                    }
                } else {
                    // of original active tab isn't visible, take first visible tab
                    activeTab = group.tabs.find(tab => visibleTabs[tab.id])?.id;
                }

                this.setGroupStatus({
                    ...origStatus,
                    visibleTabs,
                    activeTab
                }, group.id);

                this.refreshGroupByKey(group.id);
            }
        }
    };

    getVariant = (): IFormGroupDef[] => {
        const { groups } = this.data.definition;
        const savedVariant = this.getLocalStorageVariant() ?? this.data.variants?.currentVariant?.formGroups;

        if (savedVariant) {
            /**
             * If there is saved variant, we need to combine it with original group definition, because
             * serialization breaks callbacks and react components in the def.
             */
            return savedVariant.map(savedGroup => {
                const originalDef: Partial<IFormGroupDef> = groups.find(g => g.id === savedGroup.id) ?? {};
                const { id, title, rows, lineItems } = savedGroup;
                const mergedGroupDef = {
                    ...originalDef,
                    id, title, rows
                };
                if (originalDef.lineItems) {
                    mergedGroupDef.lineItems = {
                        ...originalDef.lineItems,
                        ...lineItems
                    };
                }
                return mergedGroupDef;
            });
        }
        // return FE definition if no other variant is set
        return groups ?? [];
    };

    setEntity = (entity: E): void => {
        this.data.origEntity = cloneDeep(entity);
        this.data.entity = entity;
    };

    /** Sets value to both data.entity and data.origEntity when we need to avoid data inconsistency */
    setValueToEntityAndOrigEntity = (bindingContext: BindingContext, value: any) => {
        this.setValue(bindingContext, value);
        this.data.origEntity = setBoundValue({
            bindingContext: bindingContext,
            dataBindingContext: this.data.bindingContext,
            data: this.data.origEntity,
            newValue: value
        });
    };

    storeSingleValue = (name: keyof IFormStorageData<E>, value: any): void => {
        this.storeSingleValueWithoutCheck(name, value);
    };

    getDefaultValue<T = unknown>(bc: BindingContext): T {
        const info = this.getInfo(bc);
        return getInfoValue(info, "defaultValue", {
            data: this.data.entity,
            storage: this.getThis(),
            info: info,
            context: this.context
        });
    }

    /**
     * Sometimes, we use localContext path as custom collection. In this case, we check first path of the bc
     * and check if there is a group with lineItems with collection prop of the same path.
     * @param bc
     */
    isLocalContextCollection(bc: BindingContext): boolean {
        const [basePath] = splitPath(bc.getNavigationPath(true));
        if (BindingContext.isLocalContextPath(basePath)) {
            const collectionGroup = this.data.definition.groups.find(g =>
                g.lineItems?.collection === basePath);
            return !!collectionGroup;
        }
        return false;
    }

    setDefaultValue = (bc: BindingContext, rootBindingContext = this.data.bindingContext, data?: IEntity): TFieldDefaultValue => {
        let fieldBc = bc;
        const info = this.getInfo(fieldBc);
        // todo: should we set default value only for visible fields??
        if (info && info.defaultValue !== undefined) {

            // skip setting up the collection field (like Items/Name)
            if (!fieldBc.isValid() || (info?.isCollectionField ?? this.isLocalContextCollection(fieldBc))) {
                return null;
            }

            const updatedData = data ?? this.data.entity;

            const defVal = cloneDeep(getInfoValue(info, "defaultValue", {
                data: updatedData,
                storage: this.getThis(),
                info: info,
                bindingContext: info.bindingContext,
                context: this.context
            }));

            if (defVal === undefined) {
                // if undefined is returned from the callback, do not set anything to the entity
                return defVal;
            }

            this.correctEnumValue(fieldBc, defVal, updatedData);

            if (isNavigationForUpdate(fieldBc, defVal)) {
                fieldBc = fieldBc.navigate(fieldBc.getKeyPropertyName());
            }

            setBoundValue({
                bindingContext: fieldBc,
                data: updatedData,
                newValue: defVal,
                dataBindingContext: rootBindingContext,
                preventCloning: true
            });

            if (this.hasError(bc)) {
                // we are setting default value, so we should probably remove errors from that field
                this.clearError(bc);
            }

            // on first load, we work with data object and no active fields needs to be set. We set active fields only
            // when working with entity
            if (!data) {
                this.addActiveField(bc);
            }

            return defVal;
        }
        return null;
    };

    setDefaultValueByPath = (path: string, rootBindingContext = this.data.bindingContext, data?: IEntity): TFieldDefaultValue => {
        return this.setDefaultValue(rootBindingContext.navigate(path), rootBindingContext, data);
    };

    clearFormStatus = (groups: Record<string, IGroupStatus> = {}): IFormStatus => {
        // preserve expanded group and active tab when switching items
        if (!isObjectEmpty(groups)) {
            for (const key of Object.keys(groups)) {
                groups[key] = {
                    isExpanded: groups[key].isExpanded,
                    activeTab: groups[key].activeTab
                };
            }
        }
        return {
            groups: groups
        };
    };

    loadInfos = async (columnData: TMergedDefinition, bindingContext: BindingContext, preserve = false): Promise<Record<string, IFieldInfo>> => {
        if (preserve) {
            for (const info of Object.values(this.data.fieldsInfo)) {
                const def = columnData[info.bindingContext.getNavigationPath(true)];
                info.fieldSettings.additionalItems = [...(def?.fieldDef?.fieldSettings?.additionalItems) || []];
            }
            return this.data.fieldsInfo;
        } else {
            const columns = Object.values(columnData);
            const infos: Record<string, IFieldInfo> = {};
            const infoPromises: Promise<IFieldInfo>[] = [];
            for (const column of columns) {
                infoPromises.push(this.loadInfo(column, bindingContext.navigate(column.path)));
            }
            const infosResult = await Promise.all(infoPromises);
            for (const info of infosResult) {
                infos[info.bindingContext.getFullPath(true)] = info;
            }
            return infos;
        }
    };

    addGroupRef = (ref: any, groupId: string) => {
        this.groupRefs[groupId] = ref;
    };


    reset = (hasEntityChanged: boolean): void => {
        if (hasEntityChanged) {
            // only reset if needed, to prevent unnecessary calls of createFormValidationSchema
            // which is slow because yup uses clone a lot internally
            this.data.mergedDefinition = null;
        }
        // NOTE: customData must not be reset. They may contain data, which are not entity specific.
        // For entity specific data, localContext on entity might be used or some other mechanism.
        // this.data.customData = {};
    };

    getFieldValues(bc: BindingContext, info?: IFieldInfo): IStorageSmartFieldValues {
        info = info || this.getInfo(bc);

        const data = super.getFieldValues(bc, info);

        const idBindingContext = bc.isNavigation() && !bc.isCollection() ?
            bc.navigate(bc.getKeyPropertyName()) : bc;

        const origEntity = this.data.origEntity;
        let origValue = getBoundValue({
            bindingContext: idBindingContext,
            data: origEntity,
            dataBindingContext: this.data.bindingContext
        }) as TValue;

        const _isVisible = (this.formMode === FormMode.AuditTrail) || data.isVisible;

        if (Array.isArray(data.value)) {
            const path = info.fieldSettings?.keyPath;

            if (path) {
                const values = [];
                for (const value of data.value) {
                    values.push(getNestedValue(path, value as unknown as TRecordAny));
                }
                data.value = values;

                // same transformation for origValue,
                // otherwise, SmartFieldUtils.isSameValue will always fail for labels on user locked documents
                if (Array.isArray(origValue)) {
                    const origValues = [];
                    for (const value of origValue as IEntity[]) {
                        origValues.push(getNestedValue(path, value as unknown as TRecordAny));
                    }
                    origValue = origValues;
                }
            }
        }

        return {
            ...data,
            origValue,
            isVisible: _isVisible
        };
    }

    clearForm = (parsedDefinition: TMergedDefinition, bindingContext: BindingContext) => {
        const defaultData: Partial<E> = {};
        const columns = Object.values(parsedDefinition);
        for (const column of columns) {
            this.setDefaultValueByPath(column.path, bindingContext, defaultData);
        }

        return {
            origEntity: {},
            entity: defaultData,
            formStatus: this.clearFormStatus()
        };
    };

    setDefaultValueOfEmptyFields = (parsedDefinition: TMergedDefinition, bindingContext: BindingContext, data: IEntity): void => {
        const columns = Object.values(parsedDefinition);
        for (const column of columns) {
            const _isVisible = isVisibleByPath(this.getThis(), column.path, data);
            const fieldBc = bindingContext.navigate(column.path);
            if (_isVisible && !isDefined(this.getValue(fieldBc, { dataStore: data }))) {
                this.setDefaultValue(fieldBc, bindingContext, data);
            }
        }
    };

    createFormValidationSchema = memoizeOne(() => {
        const colData: IMergedFieldDef[] = Object.values(this.data.mergedDefinition);
        const columnNames = colData.filter(column => this.options.useAllFieldsForValidation || column.useForValidation).map(column => column.path);

        this.createValidationSchema(columnNames);
    }, () => [this.data.mergedDefinition]);

    collectColumnData = (bindingContext: BindingContext, groups: IFormGroupDef[]): TMergedDefinition => {
        let currentGroupId = "";

        const _add = ({
                          path,
                          useForValidationByDefault,
                          fieldDef,
                          useForLoad = true,
                          collectionPath = "",
                          isNotInVariant
                      }: IAddFieldArgs) => {
            const isLocalContext = BindingContext.isLocalContextPath(path);
            if (!isLocalContext && !this.isValidPath(path)) {
                throw new Error(`Cannot navigate to ${path} from ${bindingContext.getFullPath()}`);
            }
            const defaultFieldDef = this.data.definition.fieldDefinition?.[path];
            fieldDef = {
                ...defaultFieldDef,
                ...fieldDef
            };

            const useForValidation = fieldDef.useForValidation ?? useForValidationByDefault;

            for (const def of fieldDef?.additionalProperties || []) {
                if (!def.id.startsWith("/")) {
                    let fieldPath = isLocalContext ? collectionPath : path;
                    fieldPath = fieldPath ? `${fieldPath}/${def.id}` : def.id;

                    if (!this.data.mergedDefinition[fieldPath]) {
                        _add({
                            path: fieldPath,
                            useForValidationByDefault: false,
                            fieldDef: def,
                            useForLoad: true
                        });
                    }
                }
            }
            // for (const column of fieldDef?.columns || []) {
            //     _add(`${fieldDef.id}/${column.id}`, false, column, true);
            // }

            this.data.mergedDefinition[path] = {
                groupId: currentGroupId,
                path,
                useForLoad,
                fieldDef,
                useForValidation,
                isNotInVariant
            };

            return fieldDef;
        };

        if (!this.data.mergedDefinition) {
            this.data.mergedDefinition = {};
            const additionalProperties = this.data.definition.additionalProperties;

            if (additionalProperties) {
                for (const property of additionalProperties) {
                    _add({
                        path: property.id,
                        useForValidationByDefault: false,
                        fieldDef: property
                    });
                    if (property.columns) {
                        for (const column of property.columns) {
                            _add({
                                path: `${property.id}/${column.id}`,
                                useForValidationByDefault: false,
                                fieldDef: column,
                                useForLoad: true
                            });
                        }
                    }
                }
            }

            const _processRows = (rows: IFieldDef[][], prefix: string, firstLevel = true) => {
                if (rows) {
                    rows.forEach(row => {
                        row.forEach(column => {
                            if (typeof column === "function") {
                                // ignore custom fields
                                return;
                            }

                            const path = column.isCollectionField !== false ? `${prefix}${column.id}` : column.id;

                            _add({
                                path: path,
                                useForValidationByDefault: true,
                                fieldDef: column
                            });

                            if (firstLevel && this.data.definition.fieldDefinition[path]?.collapsedRows) {
                                _processRows(this.data.definition.fieldDefinition[path].collapsedRows, prefix, false);
                            }
                        });
                    });
                }
            };

            const _processGroup = (group: IFormGroupDef) => {
                currentGroupId = group.id;
                if (group.tabs) {
                    for (const tabGroup of group.tabs) {
                        _processGroup(tabGroup);
                    }
                }
                // specified collection means all the other fields has to be prefixed with its path
                const prefix = group.collection ? `${group.collection}/` : "";

                const lineItemsDef = group.lineItems;
                if (lineItemsDef && lineItemsDef.collection) {
                    [...lineItemsDef.columns, ...(lineItemsDef.additionalFields ?? [])].forEach((column) => {
                        const key = `${lineItemsDef.collection}/${column.id}`;
                        const isLocal = BindingContext.isLocalContextPath(key);

                        _add({
                            path: key,
                            useForValidationByDefault: true,
                            fieldDef: column,
                            useForLoad: !isLocal,
                            collectionPath: lineItemsDef.collection
                        });
                    });
                    if (lineItemsDef.order) {
                        _add({
                            path: `${prefix}${lineItemsDef.collection}/${lineItemsDef.order}`,
                            useForValidationByDefault: false,
                            fieldDef: null
                        });
                    }
                }

                _processRows(group.rows, prefix);
            };

            for (const group of groups) {
                _processGroup(group);
            }

            if (this.data.definition.summary) {
                (this.data.definition.summary as ISummaryItem[]).forEach(item => {
                    const name = item.id;
                    if (!this.data.mergedDefinition[name]) {
                        _add({
                            path: name,
                            useForValidationByDefault: false,
                            fieldDef: item
                        });
                    }
                });
            }

            // add required fields from fieldDefinition that are not part of the variant,
            // - we need them in mergedDefinition to be able to set their default values,
            // add the rest of the fields from fieldDefinition,
            // - sometimes, we need the fields to have their info loaded (e.g. when we use local context field that is not used in any group),
            // - now we have to add it in additionalProperties. This will add it automatically, but with useForLoad set to false,
            // - so hopefully without any performance impact
            for (const key of Object.keys(this.data.definition.fieldDefinition ?? {})) {
                if (!this.data.mergedDefinition[key]) {
                    const required = isRequired({ // todo is it ok to call isRequired? the callbacks could expect data that are not yet loaded
                        info: this.data.definition.fieldDefinition[key],
                        storage: this.getThis(),
                        bindingContext: bindingContext.navigate(key),
                        context: this.context,
                        data: this.data.entity
                    });


                    _add({
                        path: key,
                        useForValidationByDefault: false,
                        isNotInVariant: true,
                        useForLoad: required
                    });
                }
            }
        }

        return this.data.mergedDefinition;
    };

    setFormAlert = (alert: IFormAlert): void => {
        this.store({
            alert: {
                ...alert,
                onClose: () => {
                    this.clearFormAlert();
                    this.refresh();
                }
            }
        });
    };

    clearFormAlert = (): void => {
        this.store({
            alert: null
        });
    };

    getMultiValue = (e: ISmartFieldChange) => {
        const info = this.getInfo(e.bindingContext);
        const path = info?.fieldSettings?.keyPath;
        let eventValues: string[] = e.value as string[];
        let existingValues = this.getValue(e.bindingContext);

        if (!existingValues) {
            // list of current values might not be initialized
            existingValues = [];
        }

        const values = [];

        if (!eventValues) {
            // beware of babel bug https://github.com/babel/babel/issues/9530
            eventValues = [];
        }

        for (const value of eventValues) {
            // if the relation already exist, we need to keep its values (like Id)
            const originalData = existingValues.find((val: any) => getNestedValue(path, val) === value) ?? {};
            const data = setNestedValue(value, path, originalData);

            if (info?.fieldSettings?.localDependentFields?.length > 0) {
                const selectedItem = e.selectedItems.find(item => item.id === value);

                for (const field of info.fieldSettings.localDependentFields) {
                    const dependentValue = getNestedValue(field.from.id, selectedItem.additionalData);
                    if (isDefined(dependentValue)) {
                        setNestedValue(dependentValue, field.to?.id ?? field.from.id, data);
                    }
                }
            }
            values.push(data);
        }

        // add proper new id for new items (items without existing id)
        // todo should this be handled here? This way it works with SmartLabelSelect,
        // but this case (multi select that adds/removes items, not just represents list of references)
        // should probably be handled more generally on SmartMultiSelect
        const keyPropertyName = e.bindingContext.getKeyPropertyName();

        for (let i = 0; i < values.length; i++) {
            if (!values[i].hasOwnProperty(keyPropertyName)) {
                values[i] = {
                    ...BindingContext.createNewEntity(getNewItemsMaxId(values)),
                    ...values[i]
                };
            }
        }
        return values;
    };

    storeMultiValue = (e: ISmartFieldChange, skipTempData?: boolean): void => {
        if (e.triggerAdditionalTasks) {
            const values = this.getMultiValue(e);
            this.setValue(e.bindingContext, values, null, skipTempData);

            setDirtyFlag(this.getThis(), e.bindingContext);
        } else if (e.type === FieldType.FileInput) {
            this.setValue(e.bindingContext, e.value, null, skipTempData);
        }
    };

    clearEmptyLineItems = (itemsFieldId: string, keepOne = false): void => {
        const fieldBc = this.data.bindingContext.navigate(itemsFieldId);
        const itemsGroup = this.data.definition.groups.find(group => group.id === itemsFieldId);
        const newItems = [];

        if (this.data.entity?.[itemsFieldId]) {
            for (const element of this.data.bindingContext.iterateNavigation(itemsFieldId, this.data.entity[itemsFieldId])) {
                // TODO: !element.entity.LinkedDocument?.Id => this is another case where new item is created on
                //  internal doc form and isn't dirty, yet we don't want to clear it -> find some better way how to handle these exceptions
                if (!element.bindingContext.isNew() || this.isDirty(element.bindingContext) || element.entity[DRAFT_ITEM_ID_PATH] || element.entity.LinkedDocument?.Id) {
                    newItems.push(element.entity);
                }
            }
        }

        if (keepOne && !newItems.length && this.data.entity?.[itemsFieldId]?.[0]) {
            newItems.push(this.data.entity[itemsFieldId][0]);
        }

        if (itemsGroup.lineItems.order) {
            newItems
                .sort((a, b) => a[itemsGroup.lineItems.order] - b[itemsGroup.lineItems.order])
                .forEach((item, idx) => {
                    item[itemsGroup.lineItems.order] = idx + 1;
                });
        }

        this.store({
            entity: setBoundValue({
                bindingContext: fieldBc,
                data: this.data.entity,
                newValue: newItems,
                dataBindingContext: this.data.bindingContext,
                preventCloning: true
            })
        });
    };

    handleTemporalChange(e: ISmartFieldChange): void {
        this.addActiveField(e.bindingContext);
        this.addActiveFieldsFromAffectedFields(e.bindingContext);

        const path = e.bindingContext.getFullPath();
        const info = this.getInfo(e.bindingContext);

        if (isSelectBasedComponent(e.type) && !e.triggerAdditionalTasks && !allowCreateValues(info)) {
            // update only currentValue for select based components
            const data = this.data.temporalData[path] ?? {};
            this.data.temporalData[path] = data;
            return;
        }

        const value = Array.isArray(e.value) ? this.getMultiValue(e) : e.value;

        this.data.temporalData[e.bindingContext.getFullPath()] = {
            value,
            additionalData: e.additionalData,
            originalEvent: e
        };
    }

    handleFieldStateChange(bc: BindingContext, state: ICachedFieldState, prevState: ICachedFieldState): void {
        let _shouldRefresh = false;
        if (!state.isRequired && prevState.isRequired) {
            // Field stopped being required -> clear possible errors
            this.clearError(bc);
            _shouldRefresh = true;
        }
        if (state.isVisible && !prevState.isVisible) {
            // Field became visible -> set default value for the field if value not present
            if (isNotDefined(this.getValue(bc))) {
                this.setDefaultValue(bc);
                _shouldRefresh = true;
            }
        }
        if (_shouldRefresh) {
            this.refreshFields();
        }
    }

    handleChange(e: ISmartFieldChange): void {
        const isMultiSelectField = Array.isArray(e.value);
        if (!isMultiSelectField) {
            super.handleChange(e);
        } else {
            this.storeMultiValue(e, true);
        }

        let updatedData = this.data.entity;
        const info = this.getInfo(e.bindingContext);
        if (allowCreateValues(info) && info.fieldSettings?.displayName) {
            // for creating new items we still want to be in the entity itself
            const bc = e.bindingContext.getNavigationBindingContext(info.fieldSettings?.displayName);
            updatedData = this.setBoundValue({
                bindingContext: bc,
                data: updatedData,
                newValue: e.currentValue,
                dataBindingContext: this.data.bindingContext,
                preventCloning: true
            });
        }

        this.store({
            entity: updatedData
        });

        if (e.triggerAdditionalTasks && !isMultiSelectField) {
            const info = this.getInfo(e.bindingContext);

            // if value is null, navigation to entity is already set to null, setting display name to null would
            // cause wrong data state (instead of null object containing only empty display name, eg. Vat: { Rate: null })
            if (info?.fieldSettings?.displayName && !e.bindingContext.isLocal() && e.value !== null) {
                // for selects copy also displayName from additionalData to entity, so it's in sync
                // todo: will it work in all cases? Maybe when localDependentFields are not defined?
                const def = { id: info.fieldSettings.displayName };
                this.processDependentField([{
                    from: def,
                    to: def,
                    navigateFrom: NavigationSource.Itself
                }], e.additionalData, e.bindingContext);
            }
            if (info?.fieldSettings?.localDependentFields?.length > 0) {
                this.processDependentField(info.fieldSettings.localDependentFields, e.additionalData, e.bindingContext);
            }

            if (info?.fieldSettings?.dependentFields?.length > 0) {
                // todo: might not work for collection forms
                this.loadDependentFields(info, e);
            }

            this.correctEnumValue(e.bindingContext, e.value);
        }

        this.handleFirstChange();
    }

    processDependentField = (fields: IDependentFieldDef[], data: IEntity, rootBc?: BindingContext) => {
        for (const field of fields) {
            let value = field.value ?? getNestedValue(field.from.id, data);
            const bc = field.navigateFrom === NavigationSource.Itself ? rootBc : field.navigateFrom === NavigationSource.Parent ? rootBc.getParent() : this.data.bindingContext;
            const targetBc = bc.navigate(field.to?.id ?? field.from.id);

            if (value === undefined) {
                // use null instead of undefined,
                // otherwise we don't send the value in the request, and it won't get deleted from the entity on save
                value = null;
            } else if (typeof value === "object") {
                value = cloneDeep(value);
            }
            this.clearAndSetValue(targetBc, value, true);
            this.correctEnumValue(targetBc, value);
        }
    };

    // Inverse process to "processDependentFields"
    // -> it extracts fields from storage according to "to" property and builds data according to "from" property
    extractFields<T>(fields: IDependentFieldDef[]): Partial<T> {
        const data: Partial<T> = {};
        for (const field of fields) {
            const value = this.getValue(this.data.bindingContext.navigate(field.to?.id ?? field.from.id));
            setNestedValue(value, field.from.id, data);
        }
        return data;
    }

    handleLineItemsAction = (args: ISmartFastEntriesActionEvent): void => {
        if (args.actionType === ActionType.Reorder || args.actionType === ActionType.Remove) {
            setDirtyFlag(this.getThis(), args.bindingContext);
        }

        if (args.actionType === ActionType.Remove) {
            // remove all errors and current values from removed field
            // it caused problems when user removed and added again row ---> same ID and Path so errors were transferred
            args.affectedItems?.forEach(item => {
                const bc = args.bindingContext.addKey(item);

                this.clearCollectionAdditionalFieldDataByPath(bc.getFullPath());
                // same thing for the field info,
                // everything related to the old item should be cleared
                this.clearInfo(bc);
            });
        }

        this.store({
            entity: this.setBoundValue({
                bindingContext: args.bindingContext,
                data: this.data.entity,
                newValue: args.items,
                dataBindingContext: this.data.bindingContext,
                preventCloning: true
            })
        });

        // just pressing add button don't change state of the form
        // DEV-13762
        if (args.actionType !== ActionType.Add) {
            this.handleFirstChange();
        }
    };

    handleLineItemsChange = (args: ISmartFieldChange): void => {
        this.handleChange(args);
    };

    loadDependentFields = async (parentItem: IFieldInfo, data: ISmartFieldChange): Promise<void> => {
        const bindingContext = data.bindingContext;
        const settings: IPrepareQuerySettings = {};

        const _updateDependentField = (args: IUpdateDependentFieldArgs) => {
            let updatedValue;

            if (args.field.parseData) {
                updatedValue = args.field.parseData(loadedData);
            } else {
                updatedValue = getBoundValue({
                    bindingContext: args.fromBindingContext,
                    data: loadedData,
                    dataBindingContext: data.bindingContext
                });
            }

            return this.setBoundValue({
                bindingContext: args.toBindingContext,
                data: args.updatedData,
                newValue: updatedValue,
                dataBindingContext: this.data.bindingContext,
                preventCloning: true
            });
        };

        const filter = getInfoValue(parentItem.filter, "dependentFields", {
            info: parentItem,
            data: this.data.entity,
            context: this.context
        });

        if (filter) {
            for (const path of Object.keys(filter)) {
                settings[path] = { filter: filter[path] };
            }
        }

        const columns = parentItem.fieldSettings?.dependentFields.map(column => ({ ...column.from }));

        let bc;
        if (parentItem.fieldSettings?.entitySet) {
            const entitySet = getInfoValue(parentItem.fieldSettings, "entitySet", {
                data: this.data.entity
            });
            bc = createBindingContext(entitySet, this.oData.metadata);
        } else {
            bc = bindingContext.isNavigation() ? bindingContext : bindingContext.getParent();
        }

        const query = await prepareQuery({
            oData: this.oData,
            bindingContext: bc.addKey(data.value as string),
            fieldDefs: columns,
            settings
        }).fetchData();

        if (!query) {
            // no dependent fields are loaded for businessPartner fetched from ARES
            return;
        }

        const loadedData = query?.value ?? {};
        const fields: BindingContext[] = [];

        let updatedData = this.data.entity;

        for (const field of parentItem.fieldSettings?.dependentFields) {
            if (isDefined(field.to)) {
                const fromPath = field.from.id;
                const toPath = field.to?.id;

                const fromBindingContext = bindingContext.navigateWithSiblingFallback(fromPath);
                const toBindingContext = this.data.bindingContext.navigate(toPath);
                fields.push(toBindingContext);

                updatedData = _updateDependentField({
                    field: field,
                    fromBindingContext,
                    toBindingContext,
                    updatedData
                });
            }
        }

        this.store({
            entity: updatedData
        });

        await this.validateFields(fields);
        this.refreshFields();
    };

    queryDraft = async (columnData: TMergedDefinition, draftId: TEntityKey, redirectToNotFoundOnFail?: boolean): Promise<{
        draft: IEntity;
        draftEtag: string;
    }> => {
        const draftDef = this.data.definition.draftDef;
        const draftBc = createBindingContext(draftDef.draftEntitySet, this.oData.metadata).addKey(draftId);
        const draftDefinition = cloneDeep(columnData);

        for (const key of Object.keys(draftDefinition)) {
            try {
                const bc = draftBc.navigate(key);
                const isFieldLockedByBe = !!this.getBackendDisabledFieldMetadata?.(bc);
                // in case the field is locked by BE, we want to ignore draft
                if (isFieldLockedByBe) {
                    // do not load locked fields
                    delete draftDefinition[key];
                }
            } catch {
                delete draftDefinition[key];
            }
        }

        const draftColumns = [...this.convertMergedDefToColumns(draftDefinition), ...draftDef.draftAdditionalProps];

        const draftQuery = await prepareQuery({
            oData: this.oData,
            bindingContext: draftBc,
            fieldDefs: draftColumns
        }).fetchData<IEntity>(this.oneFetchDraft.fetch);

        if (redirectToNotFoundOnFail && draftQuery === null) {
            this.history.replace(ROUTE_NOT_FOUND);
        }

        const draft = draftQuery?.value ?? {};
        const draftEtag = draftQuery?._metadata.etag;

        return { draft, draftEtag };
    };

    loadDraft = async (entity: IEntity, columnData: TMergedDefinition, isNew: boolean, canLoadDataFromBindingContext: boolean): Promise<E> => {
        const draftDef = this.data.definition.draftDef;
        const queryParams = getQueryParameters();

        if ((isNew && draftDef && queryParams[QueryParam.DraftId]) || entity[draftDef.draftProperty]?.Id) {
            const draftId = entity[draftDef.draftProperty]?.Id ?? queryParams[QueryParam.DraftId];

            try {
                const { draft, draftEtag } = await this.queryDraft(columnData, draftId, !canLoadDataFromBindingContext);

                this.setCustomData({
                    draft: cloneDeep(draft),
                    draftEtag
                });

                delete draft.Id;

                // we don't care about lock on draft
                if (this.data.definition.lockProperty) {
                    delete draft[this.data.definition.lockProperty];
                }

                removeNullValuesFromObject(draft);

                for (let item of (draft.Items || [])) {
                    item[DRAFT_ITEM_ID_PATH] = item.Id;
                    const linkedId = item[draftDef.navigationToItem]?.Id;
                    delete item.Id;
                    if (linkedId) {
                        const origItem = entity.Items?.find((origItem: IEntity) => origItem.Id === linkedId);
                        removeNullValuesFromObject(item);
                        item = Object.assign(item, { ...origItem, ...item });
                    } else {
                        const newId = getNewItemsMaxId(draft.Items) + 1;
                        item[BindingContext.NEW_ENTITY_ID_PROP] = newId;
                    }
                    delete item[draftDef.navigationToItem];
                }
                delete entity.Items;

                entity = {
                    ...entity,
                    ...draft,
                    [draftDef.draftProperty]: { ...draft, Id: draftId }
                };
            } catch (e) {
                if (isAbortException(e)) {
                    return null;
                }
                logger.error("FormStorage.loadDraft - error loading draft", e);
            }
        }

        // this method uses non-generic props like DocumentItem anyway
        // => it's ok to change the type
        // but it would be to move it somewhere else... (DocumentFormView?)
        return entity as unknown as E;
    };

    convertMergedDefToColumns = (columnData: TMergedDefinition): IFieldDef[] => {
        return Object.values(columnData).filter(column => column.useForLoad).map(column => {
            // use column.path even when fieldDef exist
            // it holds correct id (sometimes with navigation part)
            return { ...column.fieldDef, id: column.path };
        });
    };

    load = async (columnData: TMergedDefinition, bindingContext: BindingContext, canLoadDataFromBindingContext: boolean): Promise<IFormStorageData<E>> => {
        if (this.loaded) {
            // don't call the first time (this.loaded), to ensure correct busy state in the second pane Bubble in split page :/
            this.setBusy(true);
        }

        let entity: Partial<E> = {};
        let metadata: ODataEntityMetadata;

        if (canLoadDataFromBindingContext) {
            const columns = this.convertMergedDefToColumns(columnData);

            // support local context only case - no request to backend
            if (!bindingContext.isLocal() && !(this.oData instanceof MockOData)) {
                const settings: IPrepareQuerySettings = this.data.definition.querySettings ?? {};

                if (this.data.definition.groups) {
                    // add order for collections into the query,
                    // to ensure the data are always in the same, correct order
                    for (const lineItemsGroup of this.data.definition.groups.filter(g => g.lineItems)) {
                        if (!settings[lineItemsGroup.lineItems.collection]) {
                            settings[lineItemsGroup.lineItems.collection] = {
                                sort: [{
                                    id: lineItemsGroup.lineItems.order,
                                    sort: lineItemsGroup.lineItems.orderDirection ?? Sort.Asc
                                }]
                            };
                        }
                    }

                    for (const lineItemsGroup of this.data.definition.groups.filter(g => g.collection)) {
                        if (!settings[lineItemsGroup.collection]) {
                            settings[lineItemsGroup.collection] = {
                                sort: [{ id: lineItemsGroup.collectionOrder, sort: Sort.Asc }]
                            };
                        }
                    }
                }

                let options: RequestInit = null;

                if (!this.isReadOnly) {
                    options = {
                        headers: {
                            [EVALA_METADATA_HEADER]: "true"
                        }
                    };
                }

                const query = await prepareQuery({
                    oData: this.oData,
                    bindingContext: bindingContext,
                    fieldDefs: columns,
                    settings
                }).fetchData(this.oneFetchEntity.fetch, options);

                if (query === null) {
                    this.history.replace(ROUTE_NOT_FOUND);
                }
                entity = query?.value ?? {};
                metadata = query?._metadata;
            }
        }

        const origEntity = cloneDeep(entity); // deep copy so that updates done to 'data' won't be reflected in 'origEntity'

        // draft load depends on metadata, store it in advance
        // do this here, after the promises are awaited, so that the cache is not filled with wrong data in the meantime
        this.clearDisabledFieldMetadataCache(metadata);

        const draftDef = this.data.definition.draftDef;
        if (draftDef?.draftProperty && draftDef?.draftEntitySet && !this.isReadOnly) {
            entity = await this.loadDraft(entity, columnData, bindingContext.isNew(), canLoadDataFromBindingContext);
        }

        if (this.loaded) {
            // set delayedRefresh true so that the busy indicator disappears AFTER
            // the form content is already re-rendered with new data
            this.setBusy(false, true, false, true);
        }

        return {
            origEntity,
            entity,
            metadata
        };
    };

    // refresh stored metadata from BE
    clearDisabledFieldMetadataCache(metadata?: ODataEntityMetadata) {
        this.store({ metadata });
        // reset getBackendDisabledFieldMetadata cache
        this.getBackendDisabledFieldMetadata.cache.clear();
    }

    /** Fetch just disabled field metadata, we don't care about other parts of the entity.
     * Used when user switches from read only form (which doesn't load the metadata) to editable form. */
    loadDisabledFieldMetadata = async (): Promise<void> => {
        const res = await prepareQuery({
            oData: this.oData,
            bindingContext: this.data.bindingContext,
            fieldDefs: []
        }).fetchData(this.oneFetchEntity.fetch, {
            headers: {
                [EVALA_METADATA_HEADER]: "true"
            }
        });
        const metadata = res?._metadata;
        this.clearDisabledFieldMetadataCache(metadata);
    };

    // delete inbox files, which were temporarily attached to the new document
    removeAttachedInboxFiles(batch: BatchRequest): void {
        const { inboxFilesToConvert } = this.getCustomData();
        if (inboxFilesToConvert?.length) {
            const wrapper = batch.getEntitySetWrapper(EntitySetName.InboxFiles);
            inboxFilesToConvert.map(item => wrapper.action(OdataActionName.InboxFileRemoveInboxFileLeaveFileMetadata, item.Id));
        }
    }

    save = async (args: ISaveArgs = {}): Promise<IFormStorageSaveResult> => {
        let mainResultIndex = 0;

        const onBeforeExecute = async (batch: BatchRequest) => {
            // inject another onBeforeExecute to find out index of the main result
            // by comparing urls and current binding context
            await args.onBeforeExecute?.(batch);

            this.removeAttachedInboxFiles(batch);

            const requests = batch.getRequests();

            for (let i = 0; i < requests.length; i++) {
                const request = requests[i];

                if (!isIUrl(request) && request.id === mainBatchRequestId) {
                    mainResultIndex = i;
                }
            }
        };

        const response = await this.validateAndSave({
            ...args,
            bindingContext: args.bindingContext ?? this.data.bindingContext,
            entity: args.data ?? this.data.entity,
            originalEntity: this.data.origEntity,
            onBeforeExecute,
            getCorrectErrorBc: args.getCorrectErrorBc
        });

        if (!response) {
            return null;
        }

        const mainResponse = response[mainResultIndex];

        // todo the check for < 300 should probably be for all the results, not just one
        // somehow consolidate with the check in validateAndSave
        if (mainResponse && mainResponse.status < 300) {
            // display ok
            const data = mainResponse.body?.value;
            const bindingContext = this.data.bindingContext.isNew()
                ? createBindingContext(this.data.bindingContext.getFullPath(), this.oData.getMetadata()).removeKey().addKey(data)
                : this.data.bindingContext;

            this.setCustomData({ inboxFilesToConvert: null });

            if (this.data.bindingContext.isNew()) {
                this._preserveAlert = true;
                // Reset group status after saving new form
                const status = this.clearFormStatus({});
                /**
                 * Storing new bindingContext "clears" all infos and therefore items for select. Changing route will
                 * trigger refresh and selects triggers items load when storage is not loading :-/. This will most likely
                 * need more complex refactoring, e.g. selects might not store items in fieldInfos, trigger it in render,
                 * etc... so we have more control about it. For now, setting storage loading state in advance would help.
                 * */
                this.loading = true;
                this.store({
                    bindingContext,
                    status
                });
            } else {
                // new items will change URL which reloads the page it self
                if (!args.skipLoad) {
                    await this.loadDataAfterSave();
                }
            }

            if (!args.skipLoad && args.successSubtitle) {
                this.displaySaveOkMessage(args.successSubtitle);
            }

            return {
                bindingContext,
                data,
                response
            };
        }

        return null;
    };

    displaySaveOkMessage = (subTitle: string): void => {
        const alert = {
            status: Status.Success,
            title: tWithFallback(this.data.definition.translationFiles?.[0], "Validation.SuccessTitle", "Common"),
            subTitle
        };

        this.store({
            alert
        });
        this.refresh();
    };

    loadDataAfterSave = (): Promise<void> => {
        return this.init({
            preserveInfos: true,
            definition: this.data.definition,
            bindingContext: this.data.bindingContext
        });
    };

    reload = async ({
                        preserveInfos = true,
                        preserveData,
                        withoutBusy
                    }: IFormStorageReloadArgs = { preserveInfos: true }): Promise<void> => {
        if (!this.data.bindingContext || !this.data.definition) {
            // can't reload form with no item selected
            return;
        }

        this.data.mergedDefinition = null;

        if (!withoutBusy) {
            this.setBusy(true);
        }

        await this.init({
            preserveInfos,
            preserveData,
            definition: this.data.definition,
            bindingContext: this.data.bindingContext
        });

        if (!withoutBusy) {
            this.setBusy(false);
        }
    };

    setBusy = (busy = true, refreshPage = false, isBusyDelayed = false, delayedRefresh = false): void => {
        this.setCustomData({ busy, busyDelayed: isBusyDelayed });

        if (!delayedRefresh) {
            // usually, we want the busy state to change immediately
            this.refreshSync(refreshPage);
        } else {
            // but sometimes we may want to offset it so that the content under the busy indicator is already
            // re-rendered before the busy indicator disappears
            this.refresh(true);
        }
    };

    isBusy = (): boolean => {
        return this.getCustomData().busy;
    };

    expandErrorGroups = (): void => {
        for (const group of this.getVariant()) {
            if (group.rows) {
                for (const row of group.rows) {
                    for (const parentFieldName of row) {
                        const parentFieldBc = this.data.bindingContext.navigate(`${group.collection ? `${group.collection}/` : ""}${parentFieldName?.id}`);
                        const info = this.getInfo(parentFieldBc);

                        if (info.collapsedRows) {
                            for (const collapsedRow of info.collapsedRows) {
                                for (const fieldName of collapsedRow) {
                                    let fieldBc = this.data.bindingContext.navigate(fieldName?.id);

                                    if (fieldBc.getParent()?.isCollection()) {
                                        const parentBc = fieldBc.getParent();
                                        const name = fieldBc.getPath();
                                        const path = parentBc.getNavigationPath();
                                        const collData = this.getValueByPath(path);
                                        const keyName = fieldBc.getKeyPropertyName();
                                        if (Array.isArray(collData)) {
                                            for (const row of collData) {
                                                const parentWithKey = isDefined(row[keyName]) ? parentBc.addKey(row[keyName]) : parentBc.addKey(row[BindingContext.NEW_ENTITY_ID_PROP], true);
                                                fieldBc = parentWithKey.navigate(name);
                                                if (this.hasError(fieldBc)) {
                                                    this.expandGroup(true, getCollapsedGroupId(parentFieldName));
                                                    break;
                                                }
                                            }
                                        }
                                    }

                                    if (this.hasError(fieldBc)) {
                                        this.expandGroup(true, getCollapsedGroupId(parentFieldName));
                                        break;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    };

    validate = async (withoutAlert?: boolean, entity?: IEntity): Promise<boolean> => {
        const { hasError, errorSubtitles, validationErrors } = await this.validateAll(entity);

        if (hasError) {
            const alert = {
                status: Status.Error,
                title: this.t("Common:General.FormValidationErrorTitle"),
                subTitle: errorSubtitles?.length ? errorSubtitles.join("\n") : this.t("Common:General.FormValidationErrorSubTitle"),
                detailData: validationErrors
            };

            this.store({
                alert: withoutAlert ? null : alert
            });

            if (withoutAlert) {
                // log at least to console, so it's easier for developers to find out what's wrong
                console.error(alert.subTitle, alert.detailData);
            }

            this.expandErrorGroups();
        }

        return hasError;
    };

    /**
     * Some fields might be hidden according to some different field. We need to send null values to the BE in such
     * cases, so the model is not invalid. We may keep the model up to date manually in every handleChange, but this
     * would loose some simplicity of isVisible flag in def. This method goes through all fields and if they are
     * invisible, it sets null value to the storage. Except for isVisible flags, which are set to "false" directly
     * in def, because such a field is never visible in form, so it has different purpose (e.g. default company
     * value, etc...) -> we don't want to clear such value.
     */
    clearHiddenFields = (entity: IEntity = this.data.entity, rootBindingContext = this.data.bindingContext): Partial<E> => {
        const clearedData = cloneDeep(entity);
        BindingContext.each(entity, rootBindingContext, (value, bindingContext) => {
            const fieldInfo = this.getInfo(bindingContext);
            if (fieldInfo) {
                const prop = bindingContext?.getProperty();
                const isNullable = prop?.isNullable();
                const isBoolean = prop?.getType().getName() === ValueType.Boolean;
                const shouldClear = fieldInfo.clearIfInvisible !== false && !isVisible({
                    data: entity,
                    info: fieldInfo,
                    bindingContext,
                    storage: this.getThis(),
                    context: this.context
                });
                if (shouldClear && (isNullable || isBoolean)) {
                    setBoundValue({
                        bindingContext,
                        data: clearedData,
                        newValue: isNullable ? null : false,
                        dataBindingContext: rootBindingContext,
                        preventCloning: true
                    });
                }
            }
            return true;
        }, true);

        return clearedData as Partial<E>;
    };

    /** Recursively goes through whole entity and returns binding context for entity with matching TemporaryGuid */
    findValueByGuid = (guid: string, entity: IEntity = this.data.entity): BindingContext => {
        return BindingContext.find(entity, this.data.bindingContext, (value, bindingContext) => {
            return (value as IEntity)?.TemporaryGuid === guid;
        });
    };

    /** Recursively goes through whole entity and injects unique TemporaryGuid property
     * to every non empty, non enum, dependent navigation property */
    injectTemporaryGuids = (entity: IEntity, bidingContext: BindingContext = this.data.bindingContext): IEntity => {
        BindingContext.each(entity, bidingContext, (value, bc) => {
            const property = bc.getProperty();

            if (property?.isReadOnly()) {
                return false;
            }

            let entitySet;

            try {
                entitySet = bc?.getEntitySet();
            } catch {
            }

            const isObjectWithoutGuid = (typeof value === "object") && !isEmpty(value) && !(value as IEntity)?.TemporaryGuid;

            if (isObjectWithoutGuid && !bc?.isEnum() && !entitySet && !bc.isRoot() && !bc.isCollection()) {
                (value as IEntity).TemporaryGuid = uuidv4();
            }

            return true;
        });

        return entity;
    };

    isEtagMismatchError = (error: IBatchResult): boolean => {
        return error.status === 412;
    };

    handleEtagMismatchError = async (): Promise<void> => {
        this._preserveAlert = true;
        this.setFormAlert({
            status: Status.Warning,
            title: this.t("Common:Warnings.Warning"),
            subTitle: this.t("Common:Warnings.DataChanged")
        });

        await this.reload();
    };

    discontinuousFunction = async (entity: IEntity, largeData: IEntity[], bc: BindingContext, fn: TJobFn) => {
        // first clear entity trimmed for large data by default way
        const resultEntity = fn(entity as Partial<E>);
        const length = largeData.length;

        let lowCounter = 0;
        let topCounter = LARGE_DATA_CHUNK_SIZE;
        const resultArray: IEntity[] = [];

        do {
            const _items = largeData.slice(lowCounter, topCounter);

            await (new Promise(resolve => {
                setTimeout(() => {
                    const data: IEntity[] = fn(_items as any, bc) as IEntity[];
                    resultArray.push(...data);
                    resolve(true);
                });
            }));

            lowCounter += LARGE_DATA_CHUNK_SIZE;
            topCounter = Math.min(length, topCounter + LARGE_DATA_CHUNK_SIZE);
        } while (lowCounter < length);

        return {
            array: resultArray,
            entity: resultEntity
        };
    };

    checkForLargeData = (entity: IEntity = this.data.entity) => {
        // for now only 1st level of large arrays are supported as it is (and probably will:) be sufficient
        for (const key of Object.keys(entity)) {
            if (!BindingContext.isMetaProperty(key)) {
                const value = entity[key];
                if (Array.isArray(value) && value?.length > LARGE_DATA_INDICATOR) {
                    return {
                        path: key,
                        data: value,
                        bc: this.data.bindingContext.navigate(key)
                    };
                }
            }
        }

        return undefined;
    };

    trimArrayFromNonDirty = (largeData: IEntity[], rootBc: BindingContext) => {
        const bcPath = rootBc.getFullPath();
        const trimmedArray: IEntity[] = [];
        for (const row of largeData) {
            const id = isDefined(row[BindingContext.NEW_ENTITY_ID_PROP]) ? `${BindingContext.NEW_ENTITY_ID_PROP}=${row[BindingContext.NEW_ENTITY_ID_PROP]}` : row.Id;
            const bcRowPath = `${bcPath}(${id})`;

            if (this.data.additionalFieldData[bcRowPath]?.dirty) {
                trimmedArray.push(row);
            }
        }

        return trimmedArray;
    };

    validateAndSave = async (args: IValidateAndSave) => {

        // TODO:
        //  potentially we want to call clearHidden fields before validation
        //  be aware, args.entity is not always the whole entity so we always need to validate this.data.entity
        //  (double call of clearHiddenFields ?)

        this.setBusy(true);
        let entityForSave;
        const validationEntity = cloneDeep(this.data.entity);
        const largeData = this.checkForLargeData(validationEntity);

        // BOTH SCENARIOS STEPS:
        // 1. Clear hidden fields in WHOLE ENTITY (that is not what we are actually saving and is not part of beforeSave changes)
        // 2. Validate such cleared entity (that is not what we are saving, same as No. 1)
        // 3. Clear hidden fields in saving data (data with 'onbeforesave' (and similar) post processing)
        // 4. Inject GUID attributes to saving data

        if (largeData) {
            // first separate large array and the rest of the entity for easier manipulation
            setNestedValue([], largeData.path, validationEntity);
            // clear hidden fields and keep separation of entity and array
            const clearedData = await this.discontinuousFunction(validationEntity, largeData.data, largeData.bc, this.clearHiddenFields);
            // for speed purposes we validate only dirty flagged rows in large data array, in 99.9% cases should be sufficient,
            // the theoretical 0.1% will have to be handled by BE validation
            const trimmedLargeData = this.trimArrayFromNonDirty(clearedData.array, largeData.bc);
            // replace (for now empty) array with trimmed array (based on dirty flags)
            setNestedValue(trimmedLargeData, largeData.path, clearedData.entity);

            // classic validation but not with full entity but entity with cleared array (based on dirty flag)
            if (await this.validate(args.fieldValidationWithoutErrorAlert, clearedData.entity)) {
                this.setBusy(false);
                return null;
            }

            // now we throw away validationEntity and start working on what we are really saving ---> args.entity
            const largeSavingData = this.checkForLargeData(args.entity);
            if (largeSavingData) {
                setNestedValue([], largeSavingData.path, args.entity);

                const clearedSavingData = await this.discontinuousFunction(args.entity, largeSavingData.data, largeSavingData.bc, this.clearHiddenFields);
                const guidData = await this.discontinuousFunction(clearedSavingData.entity, clearedSavingData.array, largeSavingData.bc, this.injectTemporaryGuids);
                setNestedValue(guidData.array, largeSavingData.path, guidData.entity);
                entityForSave = guidData.entity;
            } else {
                // this is the case with large data but data are not part of saving data
                entityForSave = this.injectTemporaryGuids(this.clearHiddenFields(args.entity as unknown as E), args.bindingContext);
            }
        } else {
            this.data.entity = this.clearHiddenFields();
            // todo: ... we may want to call clearHiddenField on args entity before validation and then
            //  validate only args.entity instead stored data?
            if (await this.validate(args.fieldValidationWithoutErrorAlert)) {
                this.setBusy(false);
                return null;
            }

            // inject TemporaryGuid to each entity so that we can pair them correctly with backend errors
            // otherwise, BE is not capable to provide useful error paths
            // todo add E generic to IValidateAndSave instead of unknown casting here
            entityForSave = this.injectTemporaryGuids(this.clearHiddenFields(args.entity as unknown as E), args.bindingContext);
        }

        const batch: BatchRequest = this.oData.batch();
        batch.beginAtomicityGroup("group1");

        try {
            saveEntity({
                entity: entityForSave,
                originalEntity: args.originalEntity,
                bindingContext: args.bindingContext,
                queryParams: args.queryParams || {},
                updateEnabledFor: args.updateEnabledFor,
                changesOnly: true,
                etag: !args.skipETag && this.data.metadata?.etag,
                batch
            });

            await args.onBeforeExecute?.(batch);

            // batch be empty if there is no change in the form,
            // but response object is still expected
            if (batch.isEmpty()) {
                return [new Response("", { status: 200, statusText: "ok" })];
            }

            const response = await batch.execute();

            // todo the check for < 300 should probably be for all the results, not just the first one
            const error = response[0];
            if (!isBatchResultOk(error)) { // TODO handle 500 differently, doesn't have return body and message
                if (args.shouldUseCustomResponseHandler?.(response)) {
                    return await args.customResponseHandler(response, args, this.getThis());
                }

                // etag not matching => entity was changed from somewhere else,
                // user doesn't have up-to-date data
                if (this.isEtagMismatchError(error)) {
                    await this.handleEtagMismatchError();
                    return;
                }

                return await this.handleOdataError({
                    error: error.body as ODataError,
                    bindingContext: args.bindingContext,
                    entity: entityForSave,
                    getCorrectErrorBc: args.getCorrectErrorBc,
                    fieldValidationWithoutErrorAlert: args.fieldValidationWithoutErrorAlert
                });

            }
            return response;
        } catch (error) {
            this.setFormAlert(getAlertFromError(error));
        }
    };

    /** For given IValidationMessage of ODataError,
     * tries to return binding context of the field that the error is related to. */
    getODataErrorValidationMessagePropBindingContext = (args: IHandleOdataError, valMessage: IValidationMessage): BindingContext => {
        let propBindingContext: BindingContext;

        // use guid to find the correct binding context, if provided
        if (valMessage.entity?.temporaryGuid) {
            propBindingContext = this.findValueByGuid(valMessage.entity?.temporaryGuid, args.entity);

            if (valMessage.property && propBindingContext.getEntityType().getProperty(valMessage.property)) {
                propBindingContext = propBindingContext.navigate(valMessage.property);
            }
        } else if (valMessage.property) {
            try {
                propBindingContext = args.bindingContext.navigate(valMessage.property);
            } catch (error) {
                // wrong field name in error list
                const errMsg = `FormStorage.validateAndSave: wrong field name in error list ${valMessage.property}`;

                logger.error(errMsg);
                throw new Error(errMsg);
            }
        }
        if (args.getCorrectErrorBc) {
            propBindingContext = args.getCorrectErrorBc({
                matchedBindingContext: propBindingContext ?? args.bindingContext,
                error: valMessage,
                entity: args.entity
            });
        }

        return propBindingContext;
    };

    handleOdataError = async (args: IHandleOdataError): Promise<void> => {
        logger.error(args.error?._message);

        // todo can we tell if the error is tied to some field and show Některá pole jsou špatně vyplněná
        // or whether its more deep error that should show something better because it cannot be fixed by changing value of one of the fields?
        const isFieldValidationError = args.error?._code === BackendErrorCode.ValidationError;
        const isPermissionError = args.error?._code === BackendErrorCode.PermissionError;
        const title = isPermissionError ? this.t("Common:Errors.PermissionError") :
            (args.fieldValidationWithoutErrorAlert ? this.t("Common:General.FormValidationDialogErrorTitle") :
                (isFieldValidationError ? this.t("Common:General.FormValidationErrorTitle")
                    : this.t("Common:Errors.ErrorHappened")));

        // use Set instead of Array, so that none of the messages is repeated multiple times
        const messages: Set<string> = new Set<string>();

        // handle BE validation messages, which should be displayed in context of fields
        const valMessages = args.error?._validationMessages ?? [];

        for (const err of valMessages) {
            const message = err.message;

            let propBindingContext: BindingContext;

            try {
                propBindingContext = this.getODataErrorValidationMessagePropBindingContext(args, err);

                if (!propBindingContext) {
                    // message without binding to property -> display it as main message
                    messages.add(message);
                    continue;
                }
            } catch (e) {
                // we are not able to parse every error, f.e. update in items, at least display common message in such case,
                // is may be enough in a lot of cases
                messages.add(message);
                continue;
            }

            const path = propBindingContext.getNavigationPath(true);
            const mergedDef = this.data.mergedDefinition[path];

            if (mergedDef && !mergedDef.isNotInVariant) {
                if (!args.fieldValidationWithoutErrorAlert) {
                    messages.add(message);
                }

                // Display validation error on field
                this.handleValidationError({
                    bindingContext: propBindingContext,
                    data: {
                        errorType: ValidationErrorType.Backend,
                        message
                    }
                });
            } else {
                // info doesn't have to be loaded for the field
                // => load info to get label
                const fieldInfo = await getFieldInfo({
                    bindingContext: propBindingContext,
                    context: this.context,
                    fieldDef: {
                        id: path,
                        ...mergedDef
                    }
                });

                const hasDefinition = !!this.data.definition.fieldDefinition[path];

                if (hasDefinition) {
                    // error for field that is not present in the form
                    messages.add(this.t("Common:Errors.ErrorForFieldMissingInConfiguration", {
                        fieldName: fieldInfo?.label,
                        error: message
                    }));
                } else {
                    // without definition, the field cannot even be add to the form via config
                    // => just show error, but hopefully this won't happen very often
                    messages.add(message);
                }
            }
        }

        if (args.fieldValidationWithoutErrorAlert && messages.size === 0
            // if there is some weird error from backend (not tied to field), we still want to show alert, otherwise user has no idea what happened
            && valMessages?.length !== 0) {
            return;
        }
        // If there are validation message from BE, it should be displayed at the top, so user is clearly
        // informed about the errors (it might be useful also in cases the validation is not visible on the field
        // so user is always able to read the error.
        const subTitle = (args.error?._validationMessages?.length && Array.from(messages)) ||
            (isPermissionError ? this.t("Common:Errors.PermissionErrorSubTitle") : this.t("Common:General.FormValidationErrorSubTitle"));

        this.setFormAlert({
            detailData: args.error,
            status: Status.Error,
            // this flag indicates we show only small oneliner alert and title is more like generic stuff, subtitle holds more important information
            title,
            // last error message is displayed also at the top of the form (usually the only one)
            // todo: once we are able to distinguish which fields are visible and able to show validation error,
            //  show common `this.t("Common:General.FormValidationErrorSubTitle")` as subtitle
            subTitle,
            action: Array.isArray(subTitle) && subTitle.length > 1 ? AlertAction.Expand : AlertAction.None
        });
    };

    handleValidationError = (error: ISmartFieldValidationError): void => {
        this.setError(error.bindingContext, error.data);
    };

    _getGroupKeyFromBc = (bc: BindingContext): string => {
        const mergedFields = this.data.mergedDefinition;
        const path = bc.getNavigationPath(true);
        const def = mergedFields[path];
        return def?.groupId;
    };

    addActiveGroup = (bc: BindingContext): void => {
        this.addActiveGroupByKey(this._getGroupKeyFromBc(bc));
    };

    addActiveGroupByKey = (key: string): void => {
        this.activeGroups[key] = true;
    };

    refreshGroup = (bc: BindingContext): void => {
        this.refreshGroupByKey(this._getGroupKeyFromBc(bc));
    };

    refreshGroupByKey = (key: string): void => {
        this.groupRefs[key]?.forceUpdate();
    };

    _refreshGroups = (): void => {
        for (const key of Object.keys(this.activeGroups || {})) {
            this.groupRefs[key]?.forceUpdate();
        }

        this.activeGroups = {};
    };

    _shouldRefreshField = (path: string): boolean => {
        if (!isObjectEmpty(this.activeGroups)) {
            const def = this.data.mergedDefinition[path];

            // if this field is going to be refreshed via group refresh skip it
            if (def?.groupId && this.activeGroups[def.groupId]) {
                return false;
            }
        }
        return true;
    };

    refreshFields(triggerAdditionalTasks?: boolean, debounce?: boolean): void {
        super.refreshFields(triggerAdditionalTasks, debounce);
        this._refreshGroups();
    }

    /**
     * Yup doesn't validate nested fields of null navigations, so we need to add empty objects to the entity
     * if the navigation is not nullable
     * @param schema
     * @param entity
     * @param bc
     */
    _addEmptyNavigationObjects = (schema: ObjectSchema, entity: IEntity, bc: BindingContext): IEntity => {
        if (schema.fields) {
            for (const prop of Object.keys(schema.fields)) {
                if (isNotDefined(entity[prop]) && bc.isValidNavigation(prop)) {
                    const fieldBc = bc.navigate(prop);
                    if (fieldBc.isNavigation() && !fieldBc.isCollection() && !fieldBc.getProperty()?.isNullable()) {
                        entity[prop] = {};
                    }
                }
            }
        }
        return entity;
    };

    validateAll = async (entity?: IEntity): Promise<IValidateAllRetVal> => {
        let hasError = false;
        const errorSubtitles: React.ReactNode[] = [];
        const validationErrors: TRecordString = {};
        const allCleared = this.clearAllErrors();
        try {
            const data = this._addEmptyNavigationObjects(this._validationSchema, entity ?? this.data.entity, this.data.bindingContext);
            await this._validationSchema.validate(data, {
                abortEarly: false
            });
        } catch (error) {
            hasError = true;

            // if there are multiple errors for one field, show the first one
            const usedPaths: string[] = [];

            if (!error?.inner) {
                // this is not a validation error, may be some type error occured during validation -> rethrow
                throw error;
            }

            for (const err of error.inner) {
                if (err.type === ValidationErrorType.Form) {
                    errorSubtitles.push(err.message);
                    continue;
                }
                let bindingContext = createBindingContextFromValidatorPath(err.path, this.data.bindingContext, this.data.entity);

                const bindingContextPath = bindingContext.toString();

                if (usedPaths.includes(bindingContextPath)) {
                    continue;
                } else {
                    usedPaths.push(bindingContextPath);
                }

                let field = this.getInfo(bindingContext);

                if (!field) {
                    // this indicating select component  we validate it's key value but store the result to BC of the select itself
                    field = this.getInfo(bindingContext.getParent());
                    if (field) {
                        bindingContext = bindingContext.getParent();
                    }
                }

                let mergedDef = this.data.mergedDefinition[bindingContext.getNavigationPath(true, this.data.bindingContext)];

                if (mergedDef.useForValidation === false && bindingContext.isEnum()) {
                    // weird cases with enum Selects,
                    // for some reason validator pairs the required error with somePath/Code,
                    // even though it has useForValidation =>
                    // => it wouldn't get matched here with the field itself,
                    // because the fields bc is just somePath without Code
                    // ==> correct it
                    const parentBc = bindingContext.getParent();

                    if (this.getInfo(parentBc)) {
                        mergedDef = this.data.mergedDefinition[parentBc.getNavigationPath(true, this.data.bindingContext)];
                        bindingContext = parentBc;
                    }
                }

                if (!mergedDef || mergedDef.isNotInVariant) {
                    // error for field that is not present in the form
                    errorSubtitles.push(this.t("Common:Errors.ErrorForFieldMissingInConfiguration", {
                        fieldName: mergedDef?.fieldDef?.label,
                        error: Validator.getErrorText(err, i18next)
                    }));
                } else {
                    const errorObject = {
                        params: err.params,
                        errorType: err.type,
                        message: err.message
                    };

                    validationErrors[err.path] = Validator.getErrorText(err, i18n);

                    if (this.getTemporalData(bindingContext)) {
                        this.setTemporalError(bindingContext, errorObject);
                    } else {
                        this.setError(bindingContext, errorObject);
                    }
                }
            }
        }

        return {
            hasError: !allCleared || hasError,
            errorSubtitles,
            validationErrors
        };
    };

    async handleBlur(args: ISmartFieldBlur): Promise<IValidationError> {
        if (!args.wasChanged) {
            return undefined;
        }

        super.blur(args);

        // test for field specific error
        const oldError = this.getError(args.bindingContext);

        if (oldError && oldError.errorType === ValidationErrorType.Field) {
            return oldError;
        }

        return this.validateField(args.bindingContext, {}, this.data);
    }

    getLocalStorageVariant = (): IFormVariant => {
        if (this.ignoreVariants) {
            return null;
        }
        if (!this._cachedVariant) {
            this._cachedVariant = LocalSettings.get(this.id)?.formVariant;
        }

        (this._cachedVariant as IFormVariant)?.forEach(formGroup => {
            if (formGroup && !formGroup.title) {
                // title can be a function, which is not serialized -> get it from original definition
                const origDef = this.data.definition.groups.find(grp => grp.id === formGroup.id);
                formGroup.title = origDef.title;
            }
        });

        return this._cachedVariant as IFormVariant;
    };

    setLocalStorageVariant = (variant: IVariant): void => {
        this._cachedVariant = variant;
        LocalSettings.set(this.id, {
            formVariant: variant as IFormVariant
        });
    };

    changeVariant = async (variant: Variant): Promise<void> => {
        await super.changeVariant(variant);
        // note: without busy is important here. In case we are adding fields during the variant change, their infos
        //  are not loaded yet so if we re-render the form (form is rendered behind the busy indicator) it will/might
        //  fail on several places.
        await this.reload({
            preserveInfos: false,
            preserveData: false,
            withoutBusy: true
        });
    };

    handleRemove(bc: BindingContext): void {
        let valBc = bc;
        let value = null;

        if (bc.getKey()) { // removing item from collection
            const keyProp = bc.getKeyPropertyName();

            valBc = bc.removeKey();
            value = [...this.getValue(valBc)];

            const index = value.findIndex((item: IEntity) => item[keyProp] === bc.getKey());

            value.splice(index, 1);
        }

        this.setValue(valBc, value);
        this.clearAdditionalFieldData(bc);
        this.clearInfo(bc);
    }

    expandGroup = (expand: boolean, id: string): void => {
        this.store({
            status: {
                ...this.data.status,
                groups: {
                    ...this.data.status?.groups,
                    [id]: {
                        ...this.data.status?.groups[id],
                        isExpanded: expand
                    }
                }
            }
        });
        this.emitter.emit(ModelEvent.GroupStatusChanged, {
            groupStatus: { isExpanded: expand },
            id
        });
    };

    setGroupStatus = (groupStatus: IGroupStatus, id: string): void => {
        this.store({
            status: {
                ...this.data.status,
                groups: {
                    ...this.data.status?.groups,
                    [id]: {
                        ...this.data.status?.groups?.[id],
                        ...groupStatus
                    }
                }
            }
        });
        this.emitter.emit(ModelEvent.GroupStatusChanged, { groupStatus, id });
    };

    getGroupStatus = (groupId: string): IGroupStatus => {
        return this.data.status?.groups[groupId] ?? {};
    };
}