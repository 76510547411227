import {
    IBaseSelectionChangeArgs,
    ISelectionChangeArgs,
    ISelectItem,
    ISelectPropsBase,
    ISharedData,
    SelectAdditionalItems,
    SelectGroups,
    TSelectItemId
} from "@components/inputs/select/Select.types";
import { _showTabularHeader, NO_RECORD_FOUND } from "@components/inputs/select/Select.utils";
import { isDefined, isNotDefined } from "@utils/general";
import { debounce } from "lodash";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { DASH_CHARACTER } from "../../../constants";
import { BasicInputSizes, IconSize, TextAlign } from "../../../enums";
import { KeyName } from "../../../keyName";
import memoizeOne from "../../../utils/memoizeOne";
import { CaretIcon } from "../../icon";
import Text from "../../text";
import { ITokenProps } from "../../token";
import Tokenizer, { TokenizerClean } from "../../token/Tokenizer";
import { ICheckboxChange } from "../checkbox";
import { IInputOnBlurEvent } from "../input";
import { BasicSelectClean, IState as IBasicSelectState } from "./BasicSelect";
import { BasicSelectStyled } from "./MultiSelect.styles";
import { GlobalAllPrefix, StyledCheckbox } from "./Select.styles";
import { filterItems, getFirstMatchingItemIndex } from "./SelectAPI";
import { ISelectMenuItemContentArgs } from "./SelectMenuItem";


export interface IMultiSelectionChangeArgs extends IBaseSelectionChangeArgs {
    value: TSelectItemId[];
    selectedItems?: ISelectItem[];
}

export interface IMultiSelectProps extends Omit<ISelectPropsBase, "value" | "onChange"> {
    hideDisplayAll?: boolean;
    showClearSelection?: boolean;
    shouldCheckParents?: boolean;
    shouldCheckChildren?: boolean;
    value?: TSelectItemId[];
    onChange?: (args: IMultiSelectionChangeArgs) => void;
    hiddenItems?: ISelectItem[];
    // for weird cases where we have MultiSelect but don't want to be able to select all items at once
    withoutMainToggle?: boolean;
    trailingTextWithoutFocus?: string; // to show text on blurred multiselect with tokens
}

interface IState extends IBasicSelectState {
    selectedInputItemIndex?: number;
    skipKeyHandling?: boolean;
    hasFocus?: boolean;
}

class MultiSelect extends React.Component<IMultiSelectProps & WithTranslation, IState> {
    static defaultProps = {
        shouldCheckParents: true,
        shouldCheckChildren: true,
        openOnClick: true,
        inputIsReadOnly: false
    };

    private _refInput = React.createRef<HTMLInputElement>();
    private _refSelect = React.createRef<BasicSelectClean>();
    private _tokenizerRef = React.createRef<TokenizerClean>();

    ignoreNextFocus = false;

    state: IState = {
        selectedInputItemIndex: -1,
        hasFocus: false
    };

    getWidth = () => {
        return parseInt(this.props.width || BasicInputSizes.L);
    };

    componentDidMount() {
        this.renderTokenLinksForDisabledMultiSelect();
    }

    componentDidUpdate(prevProps: IMultiSelectProps): void {
        // after deleting last item remove read only flag
        if (this.props.value?.length === 0) {
            this._refInput.current?.removeAttribute("readOnly");
        }
        if (this.props.value?.length !== prevProps.value?.length) {
            // after selecting / unselecting value, current search term is kept in the input, so meno won't change.
            // However we want to select whole value, so user can start over easily with searching something different
            // @see DEV-6050
            this._refInput.current?.setSelectionRange(0, (this._refSelect?.current as any)?.state.currentValue?.length);
        }

        if (this.props.items?.length !== prevProps.items?.length
            || this.props.value?.length !== prevProps.value?.length
            || this.props.isDisabled !== prevProps.isDisabled) {
            this.renderTokenLinksForDisabledMultiSelect();
        }
    }

    get isOpen() {
        return !!(this._refSelect?.current as any)?.state?.isOpen;
    }

    get isGlobalAll() {
        return this.props.value?.find?.(item => item === SelectAdditionalItems.GlobalAll);
    }

    /** In case the MultiSelect is disabled, the Input it uses is disabled as well.
     * This creates a problem for us, because we want to be able to use links on the tokens, if present,
     * even if the MultiSelect itself is disabled.
     * This function is simple hack that does just that by copying the tokens text and rendering it in the DOM
     * in place that is outside the disabled styles (opacity:0.5 and pointer-events: none)
     * https://solitea-cz.atlassian.net/browse/DEV-24022
     * feel free to remove this function if we decide to completely change the behavior for disabled MultiSelects,
     * e.g. if we found out that users actually want to be able to see all values in disabled MultiSelect..
     * Then, we would've to make bigger overhaul of the component.
     * Wrapped in debounce to ensure that the tokens are already rendered and positioned
     *  (i think tokenizer uses some timeouts of its own)*/
    renderTokenLinksForDisabledMultiSelect = debounce((): void => {
        const tokenizerElem = this._tokenizerRef.current?._tokenizerRef?.current;
        const inputWrapperElem = tokenizerElem?.parentElement?.parentElement?.parentElement?.parentElement;

        if (!this.props.isDisabled) {
            // remove all cloned links, only direct descendants (:scope + >)
            inputWrapperElem?.querySelectorAll(":scope > a").forEach(element => element.remove());

            return;
        }

        if (!inputWrapperElem || !this.props.isDisabled || this.props.value?.length === 0 || !this.props.items?.find(item => item.link)) {
            return;
        }

        const inputRect = inputWrapperElem.getBoundingClientRect();

        for (const tokenElem of tokenizerElem.children) {
            const link = tokenElem.children[0];

            if (link.tagName !== "A") {
                continue;
            }

            const linkComputedStyles = getComputedStyle(link);

            if (linkComputedStyles.visibility === "hidden") {
                continue;
            }

            const newLink = link.cloneNode() as HTMLAnchorElement;
            const linkRect = link.getBoundingClientRect();
            const linkParentRect = link.parentElement.getBoundingClientRect();
            const linkParentStyles = getComputedStyle(link.parentElement);
            const linkParentLeftPadding = parseInt(linkParentStyles.paddingLeft);
            const linkParentTopPadding = parseInt(linkParentStyles.paddingTop);

            newLink.style.fontSize = linkComputedStyles.fontSize;
            newLink.style.fontWeight = linkComputedStyles.fontWeight;
            newLink.style.letterSpacing = linkComputedStyles.letterSpacing;
            newLink.style.position = "absolute";
            newLink.style.left = `${linkRect.left - inputRect.left - linkParentLeftPadding - 1}px`;
            newLink.style.top = `${linkRect.top - inputRect.top - linkParentTopPadding - 2}px`;
            newLink.style.width = `${linkParentRect.width}px`;
            newLink.style.height = `${linkParentRect.height}px`;
            newLink.style.padding = getComputedStyle(link.parentElement).padding;
            newLink.style.border = linkParentStyles.border;
            newLink.style.borderColor = "transparent";
            newLink.textContent = link.textContent;
            newLink.style.overflow = "hidden";
            newLink.style.textOverflow = "ellipsis";
            newLink.style.whiteSpace = "nowrap";
            newLink.setAttribute("title", newLink.textContent);

            inputWrapperElem.prepend(newLink);
        }
    });

    getMoreItemsText = (count: number) => {
        return count ? this.props.t("Common:Select.MoreItems", { count }) : "";
    };

    moveLeft = () => {
        if (this._refInput.current.selectionStart > 0) {
            // caret in text
            return;
        }

        let { selectedInputItemIndex } = this.state;

        this._refInput.current.setAttribute("readOnly", "true");

        if (selectedInputItemIndex === -1) {
            selectedInputItemIndex = this.props.value?.length ?? 0;
        }
        selectedInputItemIndex = Math.max(0, selectedInputItemIndex - 1);

        this.setState({
            selectedInputItemIndex
        });

        this.scrollToToken(selectedInputItemIndex);
    };

    moveRight = () => {
        let { selectedInputItemIndex } = this.state;

        if (this._refInput.current.selectionStart > 0 || selectedInputItemIndex === -1) {
            // caret in text
            return;
        }

        if (selectedInputItemIndex !== -1) {
            selectedInputItemIndex += 1;
            if (selectedInputItemIndex > this.props.value.length - 1) {
                selectedInputItemIndex = -1;
            }

            this.setState({
                selectedInputItemIndex
            });

            if (selectedInputItemIndex === -1) {
                this._refInput.current.removeAttribute("readOnly");
            } else {
                this.scrollToToken(selectedInputItemIndex, TextAlign.Right);
            }
        }
    };

    // TODO move keyboard functionality to Tokenizer
    protected handleKeyDown = (e: React.KeyboardEvent) => {
        const _deleteItem = (index: number, moveCursor = -1) => {
            const values = [...this.props.value];
            values.splice(index, 1);
            const selectedItems = this.getAllItems()?.filter(item => values.indexOf(item.id) !== -1);
            this.props.onChange({
                value: values,
                triggerAdditionalTasks: true,
                selectedItems
            });
            const nextIndex = values.length === 0 ? -1
                : Math.max(Math.min(index + moveCursor, values.length - 1), 0);

            this.setState({
                selectedInputItemIndex: nextIndex
            });
            if (nextIndex !== -1) {
                this.scrollToToken(nextIndex, "nearest");
            }
        };

        let selectedInputItemIndex = this.state.selectedInputItemIndex;

        if ((this.props.value || "").length > 0) {
            switch (e.key) {
                case KeyName.Home:
                    if (selectedInputItemIndex !== -1) {
                        selectedInputItemIndex = 0;
                        this.setState({
                            selectedInputItemIndex
                        });
                        this.scrollToToken(selectedInputItemIndex, TextAlign.Left);
                    }
                    return;

                case KeyName.End:
                    if (selectedInputItemIndex !== -1) {
                        selectedInputItemIndex = this.props.value.length - 1;
                        this.setState({
                            selectedInputItemIndex
                        });
                        this.scrollToToken(selectedInputItemIndex, TextAlign.Right);
                    }
                    return;

                case KeyName.ArrowLeft:
                    this.moveLeft();

                    return;

                case KeyName.ArrowRight:
                    this.moveRight();

                    return;

                case KeyName.Delete:
                    if (selectedInputItemIndex !== -1) {
                        _deleteItem(selectedInputItemIndex, 0);
                    }
                    return;

                case KeyName.Backspace:
                    const toDel = selectedInputItemIndex;
                    if (toDel === -1 && this._refInput.current.selectionStart === 0 && this._refInput.current.selectionEnd === 0) {
                        this._refInput.current.setAttribute("readOnly", "true");
                        this.setState({
                            selectedInputItemIndex: this.props.value.length - 1
                        });
                    }

                    if (toDel !== -1) {
                        _deleteItem(toDel);
                    }
                    return;
            }
        }
    };

    handleWheel = (event: React.WheelEvent) => {
        if (event.deltaY < 0) {
            this.moveLeft();
        } else {
            this.moveRight();
        }
    };

    getAllItems = () => {
        return [...(this.props.additionalItems || []), ...this.props.items, ...(this.props.hiddenItems || [])]
            .filter(item => !this.isAdditionalUnselectableItem(item));
    };

    getAllSelectableItems = () => {
        return this.getAllItems().filter(item => !item.isDisabled);
    };

    getTokenDefs = memoizeOne((): ITokenProps[] => {
        const allItems = this.getAllItems();

        return (this.props.value || []).map((value, index: number) => {
            const item = allItems.find(item => item.id === value);

            if (!item) {
                return null;
            }

            return {
                id: item.id.toString(),
                title: item.label,
                description: item.description,
                color: item.color,
                link: item.link,
                isSelected: this.state.selectedInputItemIndex === index
            };
        }).filter(val => val);
    }, () => [
        this.props.additionalItems, this.props.items,
        this.props.hiddenItems, this.props.value,
        this.state.selectedInputItemIndex
    ]);

    setChildren = (item: ISelectItem, check: boolean, values: TSelectItemId[], recursiveCall = false) => {
        for (const child of item.children || []) {
            this.setChildren(child, check, values, true);
        }
        if (!item.isDisabled && recursiveCall) {
            this.setValue(item.id, values, check);
        }
    };

    isAllChildrenSet = (item: ISelectItem, values: TSelectItemId[]) => {
        if (!values.find(id => id === item.id)) {
            return false;
        }

        for (const child of item.children || []) {
            if (!this.isAllChildrenSet(child, values)) {
                return false;
            }
        }

        return true;
    };

    setParents = (item: ISelectItem, check: boolean, values: TSelectItemId[]) => {
        let parent = item.parent;
        while (parent) {
            if (!check) {
                this.setValue(parent.id, values, false);
            } else {
                // current parent is disabled -> stop processing selection
                if (parent.isDisabled) {
                    return;
                }
                // test whether there is any unchecked children
                for (const child of parent.children) {
                    if (!this.isAllChildrenSet(child, values)) {
                        return;
                    }
                }
                this.setValue(parent.id, values, true);
            }

            parent = parent.parent;
        }
    };

    setValue = (id: TSelectItemId, values: TSelectItemId[], forceCheck?: boolean) => {
        const index = values.findIndex(item => item === id);
        if ((isNotDefined(forceCheck) || forceCheck) && index === -1) {
            values.push(id);
            return true;
        }

        if ((isNotDefined(forceCheck) || !forceCheck) && index !== -1) {
            values.splice(index, 1);
        }

        return false;
    };

    handleInputItemClick = (index: number) => {
        this._refInput.current.setAttribute("readOnly", "true");

        this.setState({
            selectedInputItemIndex: index
        });
    };

    handleInnerInputClick = () => {
        this.setState({
            selectedInputItemIndex: -1
        });
        this._refInput.current.removeAttribute("readOnly");
    };

    isSelected = (item: ISelectItem) => {
        if (item.id === SelectAdditionalItems.SelectAll) {
            return this.areAllItemsChecked();
        } else if (item.id === SelectAdditionalItems.SelectFiltered) {
            return this.areAllAvailableItemsChecked();
        }
        const valueArr = this.props.value || [];
        // for boolean select, find will return the matched value, which might be "false", so check if defined instead
        return isDefined((valueArr).find(item1 => item1 === item.id)) || item.isSelected ||
                (!!item.additionalData?.isNoRecord && valueArr.length === 0 && !this.hasSelectedAdditionalItem());
    };

    _isTabular = () => {
        return (this.props.columns || []).length > 1;
    };

    removeSelection = () => {
        if (this.state.selectedInputItemIndex !== -1) {
            this.setState({
                selectedInputItemIndex: -1
            });
        }
    };

    correctTokenizerItems = () => {
        this._tokenizerRef.current?.correctItems();
    };

    /**
     * Scrolls to token with index idx
     * @param idx
     * @param alignment - new alignment or 'nearest' to calculate nearest edge
     * @param forceAlignment
     */
    scrollToToken = (idx: number, alignment: TextAlign | "nearest" = TextAlign.Left, forceAlignment = false) => {
        this._tokenizerRef.current.scrollToIndex(idx, alignment, forceAlignment);
    };

    handleBlur = (e: IInputOnBlurEvent) => {
        if (this.getShareData().searchTerm) {
            // we erase current value on blur
            this.props.onChange({
                value: this.props.value
            });
            this.getShareData().searchTerm = "";
        }

        this.removeSelection();
        this.setState({ hasFocus: false });

        this.props.onBlur?.(e);
    };

    handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        if (!this.ignoreNextFocus) {
            this.props.onFocus?.(e);
        }
        this._tokenizerRef.current?.forceUpdate();
        this.ignoreNextFocus = false;
        this.setState({ hasFocus: true });
    };

    getShareData = (): ISharedData => {
        return (this._refSelect.current as any)?.sharedData;
    };

    handleChange = (args: ISelectionChangeArgs) => {
        if (!args.triggerAdditionalTasks) {
            this.props.onChange({
                value: this.props.value,
                triggerAdditionalTasks: false
            });
            this.forceUpdate(); // just for appendAdditionalItems
            return;
        }

        let triggerAdditionalTasks = args.triggerAdditionalTasks ?? false;
        let selectedItems: ISelectItem[] = [];
        let values: TSelectItemId[] = [];

        if (args.value === SelectAdditionalItems.GlobalAll) {
            values = [SelectAdditionalItems.GlobalAll];
            selectedItems = [(this.props.additionalItems).find(item => item.id === SelectAdditionalItems.GlobalAll)];
            triggerAdditionalTasks = true;
            this._refSelect.current.setIsOpen(false);
        } else {
            // remove global all
            let filteredValues = [...this.props.value || []];
            if (this.isGlobalAll) {
                filteredValues = [];
            }

            if (args.value === SelectAdditionalItems.SelectAll) {
                // toggles selection of ALL items
                const areAllItemsChecked = this.areAllItemsChecked();

                if (!areAllItemsChecked) {
                    selectedItems = [...this.getAllSelectableItems()];
                    values = selectedItems.map(item => item.id);
                }

                triggerAdditionalTasks = true;
            } else if (args.value === SelectAdditionalItems.SelectFiltered) {
                // toggles selection of currently rendered items
                const areAllFilteredItemsChecked = this.areAllAvailableItemsChecked();
                const newValues = new Set(filteredValues || []);
                const renderedItems = this.getRenderedItems();
                renderedItems.forEach(item => {
                    newValues[areAllFilteredItemsChecked ? "delete" : "add"](item.id);
                });

                values = Array.from(newValues);
                selectedItems = (this.getAllItems() || []).filter(item => values.indexOf(item.id) !== -1);
                triggerAdditionalTasks = true;
            } else {
                values = filteredValues;
                const allItems = this.getAllItems() || [];

                // Note: false could be valid value for Boolean select/multiselect
                if (isDefined(args.value)) {
                    const item = allItems.find(item => item.id === args.value);

                    const isChecked = this.setValue(args.value, values);
                    if (this.props.shouldCheckChildren) {
                        this.setChildren(item, isChecked, values);
                    }
                    if (this.props.shouldCheckParents) {
                        this.setParents(item, isChecked, values);
                    }
                    this.setState({
                        selectedInputItemIndex: -1
                    });

                    triggerAdditionalTasks = true;
                }

                selectedItems = allItems.filter(item => values.indexOf(item.id) !== -1);
            }

            // When checkbox is clicked and selects a new value, we need to return back the focus to the main input
            if (isDefined(args.value)) {
                // since we are firing the focus our selves => ignore it in the handler
                this.ignoreNextFocus = true;
                this._refInput.current.focus();
                this._refInput.current.removeAttribute("readOnly");
            }
        }

        this.props.onChange({
            value: values,
            selectedItems,
            triggerAdditionalTasks,
            additionalData: args.additionalData,
            sharedData: args.sharedData
        });
    };

    areAllItemsChecked = () => {
        const selectedIds = this.props.value || [];
        return this.getAllSelectableItems().every(item => selectedIds.includes(item.id));
    };

    areAllAvailableItemsChecked = () => {
        const selectedIds = this.props.value || [];
        return (this.getRenderedItems().every(item => selectedIds.includes(item.id)));
    };

    getHandleCheckboxChange = memoizeOne((itemArgs: ISelectMenuItemContentArgs) => {
        return (args: ICheckboxChange) => {
            itemArgs.onClick(args.origEvent as unknown as React.MouseEvent);
        };
    });

    renderCheckbox = (item: ISelectItem, itemArgs: ISelectMenuItemContentArgs) => {
        if (item.id === SelectAdditionalItems.GlobalAll) {
            return <GlobalAllPrefix></GlobalAllPrefix>;
        }

        if ((item.groupId === SelectGroups.Default && this.props.renderDefaultGroupWithoutCheckboxes)
            || (item.id === SelectAdditionalItems.SelectAll && this.props.withoutMainToggle)) {
            return null;
        }

        return (
            <StyledCheckbox
                _isTabular={this._isTabular()}
                isDisabled={item.isDisabled}
                onChange={this.getHandleCheckboxChange(itemArgs)}
                checked={this.isSelected(item)}
            />
        );
    };

    /**
     * Returns true if item is "system" one, which can't be selected (it's ID can't be used in value),
     * e.g. SelectAll, etc...
     * @param item
     */
    isAdditionalUnselectableItem = (item: ISelectItem): boolean => {
        const AdditionalItemIds: TSelectItemId[] = [
            SelectAdditionalItems.SelectAll,
            SelectAdditionalItems.SelectFiltered,
            SelectAdditionalItems.GlobalAll,
            NO_RECORD_FOUND
        ];
        return AdditionalItemIds.includes(item.id?.toString());
    };

    getRenderedItems = () => {
        // todo: dirty will need some love in the future -- better share data among them
        const items = this.getShareData()?.renderedItems as ISelectItem[];
        return (items || []).filter(item => !this.isAdditionalUnselectableItem(item) && !item.isDisabled);
    };

    appendAdditionalItems = () => {
        const extraItems = [];
        if (!this.props.hideDisplayAll && !(this._isTabular() && _showTabularHeader(this.props))) {
            /**
             * SelectAll should be displayed only when there are some visible items. When select is not
             * filtered (currentValue is empty) we need to check for all items - for the first render, there are no
             * sharedData with renderedItems :/. When there is currentValue, we need to check renderedItems from
             * SharedData, but without additionalItems
             */
            let shouldDisplaySelectAll;
            const { searchTerm } = this.getShareData() ?? {};
            if (searchTerm) {
                let filteredItems: ISelectItem[];
                const firstIndex = getFirstMatchingItemIndex(this.props.items, searchTerm, this.props.searchType);
                if (!firstIndex?.anyWordFit) {
                    filteredItems = filterItems({
                        items: this.getAllItems(),
                        skipFilter: false,
                        searchType: this.props.searchType,
                        value: searchTerm
                    });
                }
                const firstItem = filteredItems?.length ? filteredItems[0] : this.props.items?.[firstIndex?.anyWordFit];
                shouldDisplaySelectAll = firstItem && !this.isAdditionalUnselectableItem(firstItem);
            } else {
                shouldDisplaySelectAll = this.props.items.length > 1;
            }
            if (shouldDisplaySelectAll) {
                const hasAll = this.props.additionalItems?.find(item => item.id === SelectAdditionalItems.GlobalAll
                    || item.id === SelectAdditionalItems.SelectAll);

                if (!hasAll) {
                    const selectAllItem = {
                        id: SelectAdditionalItems.SelectAll,
                        label: this.props.t("Common:Select.All"),
                        isSearchable: false,
                        groupId: SelectGroups.Default
                    };
                    extraItems.push(selectAllItem);
                }

                if (searchTerm) {
                    const hasAllFiltered = this.props.additionalItems?.find(item => item.id === SelectAdditionalItems.SelectFiltered);

                    if (!hasAllFiltered) {
                        const selectAllFilteredItem = {
                            id: SelectAdditionalItems.SelectFiltered,
                            label: this.props.t("Common:Select.SearchResults"),
                            isSearchable: false,
                            groupId: SelectGroups.Default
                        };
                        extraItems.push(selectAllFilteredItem);
                    }
                }
            }
        }

        const specialTopItems = this.props.additionalItems?.filter(item => item.groupId === SelectGroups.BeforeDefault) || [];
        const rest = this.props.additionalItems?.filter(item => item.groupId !== SelectGroups.BeforeDefault) || [];

        return [...specialTopItems, ...extraItems, ...rest];
    };

    createContentBefore = () => {
        const tokenDefs = this.getTokenDefs();
        return (
            <Tokenizer tokens={tokenDefs}
                       showAll={this.state.hasFocus}
                       customMoreTokensText={this.getMoreItemsText}
                       onClick={(tokenId) => {
                           this.handleInputItemClick(tokenDefs.findIndex(tokenDef => tokenDef.id === tokenId));
                       }}
                       trailingText={this.state.hasFocus ? "" : this.props.trailingTextWithoutFocus}
                       extraSpace={this.state.hasFocus ? 40 : (this.props.trailingTextWithoutFocus ? 0 : 40)}
                       ref={this._tokenizerRef}/>
        );
    };

    renderReadOnlyColorItems = () => {
        const tokenDefs = this.getTokenDefs();
        const isEmpty = !tokenDefs || tokenDefs.length === 0;

        if (isEmpty) {
            return (
                <Text>
                    {DASH_CHARACTER}
                </Text>
            );
        }

        return (
            <Tokenizer tokens={tokenDefs}
                       maxWidth={"600px"}
                       maxVisibleTokens={4}
                       showMoreTokensTooltip/>
        );
    };

    hasSelectedAdditionalItem = (): boolean => {
        return !!this.props.additionalItems?.some(i => i.isSelected);
    }

    render() {
        const { value, additionalItems, ...props } = this.props;

        if (this.props.isReadOnly && this.props.items?.[0]?.color) {
            return this.renderReadOnlyColorItems();
        }

        const isGlobalAll = this.isGlobalAll;

        return (
            <BasicSelectStyled
                    ref={this._refSelect}
                    {...props}
                    inputIcon={this.props.inputIcon || <CaretIcon width={IconSize.M}/>}
                    isSelected={this.isSelected}
                    closeOnSelection={false}
                    itemContent={this.renderCheckbox}
                    onKeyDown={this.handleKeyDown}
                    onWheel={this.handleWheel}
                    value={isGlobalAll ? SelectAdditionalItems.GlobalAll : ""}
                    contentBefore={isGlobalAll ? null : this.createContentBefore()}
                    isMultiSelect
                    hasValue={!!this.props.value?.length || this.hasSelectedAdditionalItem()}
                    onChange={this.handleChange}
                    useAutoSelection={false}
                    useTypingForward={true}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    additionalItems={this.appendAdditionalItems()}
                    inputRef={this._refInput}
                    inputProps={{
                        onClick: this.handleInnerInputClick
                    }}
            />
        );
    }
}

const MultiSelectWithTranslation = withTranslation(["Common"], { withRef: true })(MultiSelect);
export { MultiSelectWithTranslation as MultiSelect };
