import { isVisibleByPath } from "@components/smart/FieldInfo";
import { ISmartFieldBlur, ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { createPath } from "@odata/BindingContext";
import {
    IPrDeductionEntity,
    PrDeductionEntity,
    PrDeductionTemporalCurrentEntity,
    PrDeductionTemporalEntity,
    PrIndividualDeductionEntity
} from "@odata/GeneratedEntityTypes";
import { PrDeductionTypeCode } from "@odata/GeneratedEnums";
import {
    handleCurrentTemporalPropChange,
    setTemporalDefaultValueByPath,
    setTemporalRequiredSelectDefaultPropertyBagValue
} from "@odata/TemporalUtils";
import {
    getBankBlurArgs,
    handleBankFieldChangeWithoutSavedAccount,
    hasEmptyBankAccount
} from "@pages/banks/bankAccounts/BankAccounts.utils";
import { setMatchingNumberRange } from "@pages/numberRange/NumberRange.utils";
import { DEBT_BALANCE_PATH } from "@pages/payroll/deduction/Deduction.utils";
import { handleBankAccountOrIBANBlur } from "@utils/BankUtils";
import { cloneDeep } from "lodash";

import { AppContext } from "../../../contexts/appContext/AppContext.types";
import { getUtcDayjs } from "../../../types/Date";
import { FormViewForExtend, IFormViewProps } from "../../../views/formView/FormView";


class DeductionFormView<E extends IPrDeductionEntity, P extends IFormViewProps<E>> extends FormViewForExtend<E, P> {
    static contextType = AppContext;

    async onAfterLoad(): Promise<void> {
        const storage = this.props.storage.getThis();
        const bindingContext = this.props.storage.data.bindingContext;

        if (bindingContext.isNew()) {
            if (this.entity.TypeCode === PrDeductionTypeCode.MealVoucher) {
                setTemporalDefaultValueByPath(storage, createPath(PrIndividualDeductionEntity.CurrentTemporalPropertyBag, PrDeductionTemporalCurrentEntity.PercentageEmployee));
                setTemporalDefaultValueByPath(storage, createPath(PrIndividualDeductionEntity.CurrentTemporalPropertyBag, PrDeductionTemporalCurrentEntity.PercentageEmployer));
            }
            if (!this.entity.NumberOurs) {
                await setMatchingNumberRange(storage);
            }

            if (isVisibleByPath(storage, createPath(PrIndividualDeductionEntity.CurrentTemporalPropertyBag, PrDeductionTemporalCurrentEntity.CalculationType))) {
                setTemporalRequiredSelectDefaultPropertyBagValue(
                        bindingContext.navigate(createPath(PrIndividualDeductionEntity.CurrentTemporalPropertyBag, PrDeductionTemporalCurrentEntity.CalculationType)),
                        storage
                );
            }
        }
        return super.onAfterLoad();
    }

    handleAmountDebtTotalChange = (e: ISmartFieldChange): void => {
        if (e.bindingContext.getPath(true) === PrIndividualDeductionEntity.AmountDebtTotal) {
            this.props.storage.refreshField(DEBT_BALANCE_PATH);
        }
    };

    handleDateEndChange = (e: ISmartFieldChange): void => {
        const storage = this.props.storage.getThis();
        if (e.bindingContext.getPath(true) === PrDeductionEntity.DateEnd && e.triggerAdditionalTasks) {
            const endOfMonth = getUtcDayjs(e.value as Date).clone().endOf("month").toDate();
            storage.setValueByPath(PrDeductionEntity.DateEnd, endOfMonth);
        }
    }

    handleChange = (e: ISmartFieldChange): void => {
        const storage = this.props.storage.getThis();
        const path = e.bindingContext.getPath(true);
        if (path === PrIndividualDeductionEntity.ChildSupportType ||
                path === PrDeductionTemporalEntity.IsIncludingAllEmployees) {
            storage.refresh();
        }

        storage.handleChange(e);

        this.handleDateEndChange(e);
        this.handleAmountDebtTotalChange(e);

        handleBankFieldChangeWithoutSavedAccount({ storage }, e);

        handleCurrentTemporalPropChange(e, storage);
        storage.refreshFields(e.triggerAdditionalTasks);
    };

    handleBlur = async (args: ISmartFieldBlur): Promise<void> => {
        if (args.wasChanged) {
            handleBankAccountOrIBANBlur(getBankBlurArgs(this.props.storage.getThis(), args.bindingContext));
            this.props.storage.refreshFields();
        }
    }

    onBeforeSave(): E {
        const storage = this.props.storage.getThis();
        const data = cloneDeep(this.entity);
        delete data.Recipient.BusinessPartner; // we don't want to have reference to BP, just use it as a template

        if (hasEmptyBankAccount(storage)) {
            delete data.BankAccount;
        }

        if (data.TypeCode === PrDeductionTypeCode.MealVoucher) {
            delete data.BankAccount;
            delete data.Recipient;
        }
        return data;
    }
}

export default DeductionFormView;