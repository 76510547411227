import { AlertPosition } from "@components/alert/Alert";
import { WithAlert, withAlert } from "@components/alert/withAlert";
import { WithBusyIndicator, withBusyIndicator } from "@components/busyIndicator/withBusyIndicator";
import { IReadOnlyListItem } from "@components/readOnlyList/ReadOnlyListItem";
import { IGetValueArgs } from "@components/smart/FieldInfo";
import { getRow } from "@components/smart/smartTable/SmartTable.utils";
import { IColumn } from "@components/table";
import { IRowProps } from "@components/table/Rows";
import { IToolbarItem, TToolbarItem } from "@components/toolbar";
import { isODataError } from "@odata/Data.types";
import { getDetailRouteByEntityType, getEntityTypeFromBindingContext } from "@odata/EntityTypes";
import { EntitySetName, EntityTypeName, IAssetEntity, IUnorganizedAssetEntity } from "@odata/GeneratedEntityTypes";
import { AssetItemTypeCode, AssetTypeCode } from "@odata/GeneratedEnums";
import { getCompanyCurrency, isCashBasisAccountingCompany } from "@utils/CompanyUtils";
import { joinReactNodes } from "@utils/domUtils";
import { isDefined } from "@utils/general";
import { Dayjs } from "dayjs";
import i18next from "i18next";
import React, { ReactElement } from "react";
import { Trans } from "react-i18next";
import { ValidationError } from "yup";

import { BreadCrumbProvider } from "../../../components/breadCrumb";
import Clickable from "../../../components/clickable";
import CollapsibleSection from "../../../components/collapsibleSection/CollapsibleSection";
import ReadOnlyList from "../../../components/readOnlyList/ReadOnlyList";
import { ScrollBar } from "../../../components/scrollBar";
import SimpleTable from "../../../components/simpleTable";
import { NEW_ITEM_DETAIL } from "../../../constants";
import { WithAuthContext, withAuthContext } from "../../../contexts/authContext/withAuthContext";
import { QueryParam, Status, ToolbarItemType } from "../../../enums";
import { ColoredText, ToolbarStaticText } from "../../../global.style";
import { IModifierKeys, TValue } from "../../../global.types";
import BindingContext, { createBindingContext, IEntity } from "../../../odata/BindingContext";
import { getQueryParameters } from "../../../routes/Routes.utils";
import { formatCurrency } from "../../../types/Currency";
import DateType, { getUtcDayjs } from "../../../types/Date";
import memoizeOne from "../../../utils/memoizeOne";
import { getAlertFromError } from "../../../views/formView/Form.utils";
import { FormStorage } from "../../../views/formView/FormStorage";
import { FormViewForExtend, IFormViewProps } from "../../../views/formView/FormView";
import PlugAndPlayForm from "../../../views/formView/PlugAndPlayForm";
import TableView from "../../../views/table";
import { ICustomButtonDefArgs, TableButtonsAction, TableButtonsActionType } from "../../../views/table/TableToolbar";
import { ITableViewBaseProps, ITableViewBaseState } from "../../../views/table/TableView";
import { getConfirmationActionText } from "../../../views/table/TableView.render.utils";
import { SecondaryTableViewTitle, TopWrapper } from "../../../views/table/TableView.styles";
import View from "../../../views/View";
import { getFiscalYearByDate, isInFYorPeriod } from "../../fiscalYear/FiscalYear.utils";
import { getDefByEntityType } from "../../getDefByEntityType";
import { setMatchingNumberRange } from "../../numberRange/NumberRange.utils";
import {
    createAssetFromEntries,
    getInOpenFYValidation,
    getSameOrLaterItemOfTypeValidation,
    getValidationResult,
    pairDocumentItemsWithAsset
} from "../Asset.utils";
import { changeAssetPrice, IFixedAssetCustomData } from "../fixedAsset/FixedAsset.utils";
import { getChangePriceDefFactory } from "../fixedAsset/FixedAssetChangePriceDef";
import FixedAssetChangePriceFormView from "../fixedAsset/FixedAssetChangePriceFormView";
import {
    assetPriceFormatter,
    FixedAssetEditableWindowActionFinish,
    FixedAssetFormViewAction
} from "../fixedAsset/FixedAssetDef";
import { getAddToCostsDefFactory } from "./dialogs/AddToCostsDef";
import AddToCostsDialogFormView from "./dialogs/AddToCostsDialogFormView";
import CompleteFixedAsset from "./dialogs/CompleteFixedAsset";
import CreateNewAssetDialog from "./dialogs/CreateNewAssetDialog";


enum UnorganizedAssetAction {
    Create = "Create",
    Complete = "Complete",
    AddToCosts = "AddToCosts"
}

interface IProps extends ITableViewBaseProps, WithAuthContext, WithBusyIndicator, WithAlert {
}

class UnorganizedAssetTableView extends TableView<IProps> {
    _actionDialogRef = React.createRef<any>();
    _formRef = React.createRef<FormViewForExtend<IFormViewProps<IEntity>>>();
    _selectedRows: IUnorganizedAssetEntity[];
    _amount = 0;
    _maxEntryDate: Dayjs;

    static defaultProps: Partial<IProps> = {
        hideDrilldown: true
    };

    constructor(props: IProps) {
        super(props);

        this.getToolbarButtons = this.getToolbarButtons.bind(this);
        this.getCustomButtonDef = this.getCustomButtonDef.bind(this);
        this.handleCustomAction = this.handleCustomAction.bind(this);
        this.getActiveButtons = this.getActiveButtons.bind(this);
        this.getDisabledButtons = this.getDisabledButtons.bind(this);
        // this.presetDefaultFilters = this.presetDefaultFilters.bind(this);

        let defaultTab: AssetTypeCode;

        if (this.isInEditableWindow) {
            // if (this.getEditableWindowActionType() === FixedAssetFormViewAction.ChangePrice) {
            //     props.storage.emitter.on(ModelEvent.FiltersCleared, this.presetDefaultFilters);
            //     this.presetDefaultFilters();
            // }

            if (this.rootStorageAssetType) {
                this.setTableAction(UnorganizedAssetAction.Complete);
            }
        }

        if (!this.isCbaCompany) {
            defaultTab = getQueryParameters()[QueryParam.DefaultTab] as AssetTypeCode ?? this.rootStorageAssetType ?? AssetTypeCode.Tangible;

            this.state = {
                ...this.state,
                selectedSecondaryFilter: defaultTab
            };
            this.applySecondaryFilter(defaultTab);
        }
    }

    shouldComponentUpdate(nextProps: IProps, nextState: ITableViewBaseState): boolean {
        if (isDefined(nextProps.alert) !== isDefined(this.props.alert)) {
            return true;
        }
        return super.shouldComponentUpdate(nextProps, nextState);
    }

    // componentWillUnmount() {
    //     super.componentWillUnmount();
    //
    //     this.props.storage.emitter.off(ModelEvent.FiltersCleared, this.presetDefaultFilters);
    // }

    // presetDefaultFilters() {
    //     if (this.isInEditableWindow && this.getEditableWindowActionType() === FixedAssetFormViewAction.ChangePrice) {
    //         const type = this.getChangePriceType();
    //         // set filter according to assetItemPrice
    //         const isReduction = type === AssetItemTypeCode.ReductionOfPrice;
    //         this.props.storage.setFilterValueByPath(UnorganizedAssetEntity.Amount, [createFilterRow({
    //             value: 0,
    //             condition: isReduction ? Condition.LesserThan : Condition.GreaterOrEqual
    //         })]);
    //         this.props.storage.applyFilters();
    //     }
    // }

    get shouldRenderBreadcrumbs(): boolean {
        return !this.isInEditableWindow;
    }

    handleRowSelect = (bindingContext: BindingContext, props: IRowProps, modifiers?: IModifierKeys): void => {
        // no row selection on UnorganizedAsset
    };

    getToolbarButtons(): TableButtonsActionType[] {
        const buttons = super.getToolbarButtons();
        const filteredButtons = buttons.filter(id => ![TableButtonsAction.Add, TableButtonsAction.Remove].includes(id as TableButtonsAction));

        let assetActions = [
            UnorganizedAssetAction.Create,
            UnorganizedAssetAction.Complete
        ];

        if (!this.isCbaCompany) {
            assetActions.push(UnorganizedAssetAction.AddToCosts);
        }

        if (this.isInEditableWindow) {
            // show only AddTo action in editableWindow
            assetActions = assetActions.filter(id => id === UnorganizedAssetAction.Complete);
        }

        return [...assetActions, ...filteredButtons];
    }

    getDisabledButtons(disableAll?: boolean): TableButtonsActionType[] {
        const disabledButtons = super.getDisabledButtons(disableAll);
        const action = this.getTableAction();
        const unorganizedButtons = [
            UnorganizedAssetAction.Create,
            UnorganizedAssetAction.Complete,
            UnorganizedAssetAction.AddToCosts
        ].filter(button => button !== action);
        const isEmpty = this.props.storage.tableAPI?.getState().rowCount === 0;

        if (disableAll || action || isEmpty) {
            return [...disabledButtons, ...unorganizedButtons];
        }

        return disabledButtons;
    }

    createToolbarItem(id: UnorganizedAssetAction, iconName: string, args: ICustomButtonDefArgs, forceDisabled = false): IToolbarItem {
        const action = this.getTableAction();
        const isDisabled = (!!action && action !== id) || args.isDisabled || forceDisabled;
        const isActive = action === id;
        return {
            itemType: ToolbarItemType.Icon,
            id,
            label: this.props.storage.t(`FixedAsset:Unorganized.${id}`),
            iconName,
            isDisabled,
            isActive
        };
    }

    getCustomButtonDef(action: TableButtonsActionType, args: ICustomButtonDefArgs): IToolbarItem {
        switch (action) {
            case UnorganizedAssetAction.AddToCosts:
                return this.createToolbarItem(UnorganizedAssetAction.AddToCosts, "Costs", args);
            case UnorganizedAssetAction.Create:
                return this.createToolbarItem(UnorganizedAssetAction.Create, "Add", args);
            case UnorganizedAssetAction.Complete:
                return this.createToolbarItem(UnorganizedAssetAction.Complete, "AddTo", args);
            default:
                return super.getCustomButtonDef(action, args);
        }
    }

    setTableAction = async (action: UnorganizedAssetAction = null, active = true): Promise<void> => {
        await super.setTableAction(action, active);
        this.forceUpdate();
    };

    get isCbaCompany(): boolean {
        return isCashBasisAccountingCompany(this.props.storage.context);
    }

    get isInEditableWindow(): boolean {
        return !!this.props.rootStorage;
    }

    getRootStorage = (): FormStorage<IAssetEntity, IFixedAssetCustomData> => {
        return this.props.rootStorage as FormStorage<IAssetEntity, IFixedAssetCustomData>;
    };

    getEditableWindowActionType = memoizeOne((): FixedAssetFormViewAction => {
        return this.getRootStorage()?.getCustomData().FixedAssetFormViewAction;
    });

    getChangePriceType = (): AssetItemTypeCode => {
        return this.getRootStorage()?.getCustomData().AssetItemTypeCode;
    };

    get rootStorageAssetType(): AssetTypeCode {
        return (this.getRootStorage()?.data.entity as IAssetEntity)?.Type?.Code as AssetTypeCode;
    }

    get assetType(): AssetTypeCode {
        return this.state.selectedSecondaryFilter as AssetTypeCode;
    }

    getSelectedAssets = async (): Promise<IUnorganizedAssetEntity[]> => {
        const rows = this.props.storage.tableAPI.getState().rows;
        const selectedRows = await this.props.storage.tableAPI.getAffectedRows();
        return selectedRows.map(item => getRow(rows, item.bc).customData.entity as IUnorganizedAssetEntity);
    };

    getEntryIds = (rows: IUnorganizedAssetEntity[]): number[] => rows.map(item => item.DocumentItem.Id);

    handleToolbarConfirm = async (): Promise<void> => {
        const selectedAssets = await this.getSelectedAssets();

        if (this.isInEditableWindow) {
            // We do pairing immediately because we already have AssetId
            // Note: only pairing action is currently used this way
            const rootStorage = this.getRootStorage();
            const entryIds = this.getEntryIds(selectedAssets);
            const assetId = rootStorage.data.bindingContext.getKey();
            let alert;
            let entity = null;

            switch (this.getEditableWindowActionType()) {
                case FixedAssetFormViewAction.AddFromUnsorted:
                    this.props.setBusy(true);
                    let result;
                    if (rootStorage.data.bindingContext.isNew()) {
                        // creates new asset with paired documentItems
                        result = await createAssetFromEntries(entryIds, rootStorage.data.entity as IAssetEntity);
                        entity = result.data;
                    } else {
                        // complete existing
                        result = await pairDocumentItemsWithAsset(entryIds, assetId);
                    }
                    alert = result.alert;
                    break;
                case FixedAssetFormViewAction.ChangePrice:
                    const { storage } = this._formRef.current.props;
                    if (await storage.validate()) {
                        this._formRef.current.forceUpdate();
                        return;
                    }
                    // remove possible error alert if present
                    storage.clearFormAlert();
                    this._formRef.current.forceUpdate();

                    this.props.setBusy(true);
                    try {
                        const originalAsset = rootStorage.data.entity as IAssetEntity;
                        const type = this.getChangePriceType();
                        const data = await changeAssetPrice(type, storage, entryIds, assetId);
                        const price = formatCurrency(data.CalculatedPrice, data.CurrencyCode);
                        const originalPrice = formatCurrency(originalAsset.CalculatedPrice, data.CurrencyCode);
                        const title = i18next.t(`FixedAsset:ChangePrice.SuccessAlert_${type}`);

                        alert = {
                            status: Status.Success,
                            title: i18next.t("Common:Validation.SuccessTitle"),
                            hoverTitle: title,
                            subTitle: (<>
                                {title}
                                <br/>
                                <Trans i18nKey={"FixedAsset:ChangePrice.SuccessAlertSubtitle"}
                                       values={{ price, originalPrice }}
                                       components={{
                                           1: <strong/>
                                       }}/>
                            </>)
                        };
                    } catch (e) {
                        if (isODataError(e)) {
                            const assetPriceValidation = e._validationMessages?.find(message => message.code === "AssetValueCannotBeUpdatedLowPrice");
                            if (assetPriceValidation) {
                                // we want to show this message in this particular case
                                // todo: how to solve this generally??
                                e._message = assetPriceValidation.message;
                            }
                        }
                        alert = getAlertFromError(e);
                    }
                    break;
            }

            this.props.setBusy(false);
            this.props.setAlert(alert);

            if (entity || alert.status === Status.Success) {
                rootStorage.emitter.emit(FixedAssetEditableWindowActionFinish, entity);

                // turn off table action, reloads table, etc...
                await this.handleCustomDialogClose();
            }
        } else {
            this._selectedRows = selectedAssets;
            this.forceUpdate();
        }
    };

    handleToolbarCancel = (): void => {
        if (this.isTableAction()) {
            this.props.storage.tableAPI.cancelAction();
            this._selectedRows = [];
            this.calculateMinChangePriceDate(this._selectedRows);
            this._formRef.current?.props.storage.clearFormAlert();
            this._amount = 0;
            this.setTableAction(null);
        }
    };

    getConfirmText(tableAction: TableButtonsAction | string): string | React.ReactElement {
        const { storage } = this.props;
        const activeRowActionCount = storage.tableAPI?.getState().activeRows.size;

        if ([UnorganizedAssetAction.Create, UnorganizedAssetAction.Complete, UnorganizedAssetAction.AddToCosts]) {
            let actionTitleKey = tableAction;
            if (this.isInEditableWindow && this.getEditableWindowActionType() === FixedAssetFormViewAction.ChangePrice) {
                // in editable window, only "Complete" action is visible, but with different confirmation according to
                // actual action
                actionTitleKey = `ChangePrice_${this.getChangePriceType()}`;
            }
            return getConfirmationActionText(storage.t(`FixedAsset:Unorganized.${actionTitleKey}`), activeRowActionCount);
        }

        return super.getConfirmText(tableAction);
    }

    getActionText(action: UnorganizedAssetAction, type: "Confirm" | "Title"): string {
        const { storage } = this.props;
        return storage.t(`FixedAsset:Unorganized.${action}${type}`);
    }

    createAndInitFormStorage = (entitySet: EntitySetName, prepareNumberRange = false): FormStorage => {
        const { storage } = this.props;
        const bindingContext = createBindingContext(entitySet, storage.oData.metadata);
        const definition = getDefByEntityType(getEntityTypeFromBindingContext(bindingContext))(storage.context);
        // create form definition from massEditable fields' def merged with form group definitions
        const formDef = definition.form;

        const formStorage = new FormStorage({
            oData: storage.oData,
            history: storage.history,
            t: storage.t,
            context: storage.context,
            id: formDef.id,
            definition: formDef,
            refresh: () => {
                this._actionDialogRef.current?.forceUpdate();
            }
        });

        formStorage.init({
            bindingContext: bindingContext.addKey(NEW_ITEM_DETAIL, true),
            data: {}, // just empty form without default values
            ignoreDefaults: true
        })
                .then(() => prepareNumberRange && setMatchingNumberRange(formStorage))
                .then(() => formStorage.refresh());

        return formStorage;
    };

    getSelectedRowsTitle = (): ReactElement => {
        return getConfirmationActionText(this.props.storage.t("FixedAsset:Unorganized.SelectedRecords"), this._selectedRows.length);
    };

    renderSelectedRowsTable = (withoutMargin = false): ReactElement => {
        const tableState = this.props.storage.tableAPI.getState();
        // Table columns
        const columns: IColumn[] = tableState.columns.map(column => ({
            id: column.id,
            textAlign: column.textAlign,
            label: column.label
        }));

        const rows = this.props.storage.tableAPI.getRowsArray().filter(row =>
                this._selectedRows.find(selectedRow => selectedRow.DocumentItem.Id === row.customData?.entity.DocumentItem.Id));

        return (
                <ScrollBar style={{
                    overflowX: "visible",
                    width: "100%",
                    height: "auto",
                    marginBottom: withoutMargin ? 0 : "35px"
                }}>
                    <SimpleTable columns={columns} rows={rows}/>
                </ScrollBar>
        );
    };

    renderSelectedRowsCollapsibleSection = (): ReactElement => {
        return (
                <CollapsibleSection title={this.getSelectedRowsTitle()}
                                    defaultIsOpen={false}
                                    removeBodyPadding
                                    style={{
                                        marginBottom: "35px",
                                        width: "100%"
                                    }}>
                    {this.renderSelectedRowsTable(true)}
                </CollapsibleSection>
        );
    };

    renderCustomDialog = (): ReactElement => {
        const action = this.getTableAction();
        if (!this._selectedRows?.length || !action) {
            return null;
        }
        const ids = this.getEntryIds(this._selectedRows);

        let storage: FormStorage;

        switch (action) {
            case UnorganizedAssetAction.Complete:
                // editable window with fixed asset selection
                return (
                        <CompleteFixedAsset storage={this.props.storage}
                                            renderedTable={this.renderSelectedRowsTable()}
                                            type={this.assetType}
                                            entryIds={ids}
                                            onClose={this.handleCustomDialogClose}/>
                );
            case UnorganizedAssetAction.Create:
                storage = this.createAndInitFormStorage(EntitySetName.Assets, true);
                return (
                        <CreateNewAssetDialog ref={this._actionDialogRef}
                                              title={this.getActionText(action, "Title")}
                                              confirmText={this.getActionText(action, "Confirm")}
                                              onClose={this.handleCustomDialogClose}
                                              storage={storage}
                                              entryIds={ids}>
                            {this.renderSelectedRowsCollapsibleSection()}
                        </CreateNewAssetDialog>
                );
            case UnorganizedAssetAction.AddToCosts:
                const bindingContext = createBindingContext(BindingContext.localContext("AddToCosts"), this.props.storage.oData.metadata);
                const initialData = {
                    customData: {
                        header: this.renderSelectedRowsCollapsibleSection(),
                        documentItemIds: ids
                    }
                };
                return (
                        <PlugAndPlayForm
                                getDef={getAddToCostsDefFactory(this.addToCostsDateCustomValidator)}
                                t={this.props.storage.t}
                                bindingContext={bindingContext}
                                initialData={initialData}
                                formView={AddToCostsDialogFormView}
                                formViewProps={{
                                    dialogProps: {
                                        title: this.props.storage.t("FixedAsset:AddToCosts.Title")
                                    },
                                    formProps: {
                                        renderScrollbar: false
                                    },
                                    onAfterConfirm: this.handleAddToCostsConfirm,
                                    onClose: () => this.handleCustomDialogClose(false)
                                }}
                        />
                );
            default:
                return null;
        }
    };

    handleAddToCostsConfirm = async (): Promise<void> => {
        // success message
        const links = this._selectedRows.map((entity) => {
            const url = getDetailRouteByEntityType(entity.Document.DocumentType.Code as EntityTypeName, entity.Document.Id, true);
            return (
                    <Clickable link={url} isLink>{entity.Document.NumberOurs}</Clickable>
            );
        });
        const linkNodes = joinReactNodes(links, ", ");
        const alert = {
            status: Status.Success,
            title: i18next.t("Common:Validation.SuccessTitle"),
            subTitle: (<>{this.props.storage.t("FixedAsset:AddToCosts.SuccessMessage")}: {linkNodes}</>)
        };
        this.props.setAlert(alert);

        await this.handleCustomDialogClose(true);
    };

    handleCustomDialogClose = async (reloadTable = true): Promise<void> => {
        const { storage } = this.props;
        const { tableAPI } = storage;
        // remove breadcrumbs, which might be added in editable window
        storage.context.setViewBreadcrumbs({ items: [], lockable: false });
        await tableAPI.confirmAction();
        this._selectedRows = [];
        this._amount = 0;
        this.setTableAction(null);
        if (reloadTable) {
            await tableAPI.reloadTable();
        }
    };

    headerData = (entity: IAssetEntity): IReadOnlyListItem[] => {
        const storage = this.props.rootStorage;
        const info = storage.getInfo(storage.data.bindingContext.navigate("Name"));
        return [{
            label: info.label,
            value: entity.Name
        }, {
            label: storage.t("MinorAsset:Form.Status"),
            value: i18next.t(`MinorAsset:Status.${entity.Status?.Code}`).toString()
        }, {
            label: storage.t("FixedAsset:Form.CalculatedPrice"),
            value: assetPriceFormatter(entity.CalculatedPrice, { entity })
        }];
    };

    getMaxJournalEntryDateValidation = (value: TValue, args: IGetValueArgs): boolean | ValidationError => {
        if (this._maxEntryDate && this._maxEntryDate.isAfter(value as Date, "day")) {
            const opts = {
                date: DateType.format(this._maxEntryDate)
            };
            return new ValidationError(i18next.t("FixedAsset:Validation.JournalEntryTransaction", opts), false, args.bindingContext.getPath(true));
        }
        return true;
    };

    getTechnicalImprovementDateValidation = (value: TValue, args: IGetValueArgs): boolean | ValidationError => {
        if (this.getChangePriceType() === AssetItemTypeCode.TechnicalImprovement) {
            const storage = this.props.rootStorage;
            const asset = storage.data.entity as IAssetEntity;
            const FY = getFiscalYearByDate(storage.context, value as Date);
            if (isInFYorPeriod(FY, asset.DateFirstPutInUse)) {
                return new ValidationError(i18next.t("FixedAsset:Validation.TechnicalImprovementDate"), false, args.bindingContext.getPath(true));
            }
        }
        return true;
    };

    changePriceDateCustomValidator = (value: TValue, args: IGetValueArgs): boolean | ValidationError => {
        const argsWithAssetStorage = { ...args, storage: this.props.rootStorage };
        return getValidationResult(
                getInOpenFYValidation(value, args),
                getSameOrLaterItemOfTypeValidation(value, argsWithAssetStorage, [
                    AssetItemTypeCode.TechnicalImprovement,
                    AssetItemTypeCode.IncreaseOfPrice,
                    AssetItemTypeCode.ReductionOfPrice,
                    AssetItemTypeCode.PutInUse
                ]),
                this.getMaxJournalEntryDateValidation(value, args),
                this.getTechnicalImprovementDateValidation(value, args)
        );
    };

    addToCostsDateCustomValidator = (value: TValue, args: IGetValueArgs): boolean | ValidationError => {
        return getValidationResult(
                getInOpenFYValidation(value, args),
                this.getMaxJournalEntryDateValidation(value, args)
        );
    };

    calculateMinChangePriceDate = (selectedRows: IUnorganizedAssetEntity[]): void => {
        // calculate maximal TransactionDate
        //  - ChangePriceDate needs to be later
        //  - DateOfTransaction in AddToCosts dialog needs to be later
        let maxDate: Dayjs;
        selectedRows.forEach((row) => {
            if (!maxDate || maxDate.isBefore(row.EntryDate)) {
                maxDate = getUtcDayjs(row.EntryDate);
            }
        });
        this._maxEntryDate = maxDate;

        if (this.getEditableWindowActionType() === FixedAssetFormViewAction.ChangePrice) {
            // re-validate change price date and refresh form
            const { storage } = this._formRef.current.props;
            storage.validateFieldSync(storage.data.bindingContext.navigate("Date"));
            // always update -> error may also disappear after user removes problematic entry
            this._formRef.current.forceUpdate();
        }
    };

    renderAssetHeader = (): ReactElement => {
        const storage = this.props.rootStorage;
        const entity = storage.data.entity as IAssetEntity;
        let actionContent;

        switch (this.getEditableWindowActionType()) {
            case FixedAssetFormViewAction.AddFromUnsorted:
                actionContent = (
                        <SecondaryTableViewTitle>{storage.t("FixedAsset:Unorganized.TitleCompletion")}</SecondaryTableViewTitle>);
                break;
            case FixedAssetFormViewAction.ChangePrice:
                const bindingContext = createBindingContext(BindingContext.localContext("ChangePrice"), storage.oData.metadata);

                actionContent = (<>
                    <SecondaryTableViewTitle>{storage.t(`FixedAsset:Unorganized.TitleChangePrice_${this.getChangePriceType()}`)}</SecondaryTableViewTitle>
                    <PlugAndPlayForm
                            formRef={this._formRef}
                            getDef={getChangePriceDefFactory(this.changePriceDateCustomValidator)}
                            t={this.props.storage.t}
                            bindingContext={bindingContext}
                            formView={FixedAssetChangePriceFormView}
                            formViewProps={{
                                formProps: { style: { marginBottom: "30px" } }
                            }}
                    />
                </>);
                break;
        }

        return (<>
            <ReadOnlyList title={`${this.props.rootStorage.t("FixedAsset:FormTitle")} ${entity.NumberOurs}`}
                          data={this.headerData(entity)}
            />
            {actionContent}
        </>);
    };

    handleActiveRowActionCountChange = async (): Promise<void> => {
        const rows = this.props.storage.tableAPI.getState().rows;
        const selectedRows = await this.props.storage.tableAPI.getAffectedRows();
        const selectedAssets: IUnorganizedAssetEntity[] = [];
        this._amount = selectedRows.reduce((sum: number, item) => {
            const row = getRow(rows, item.bc);
            const values = row.customData.entity as IUnorganizedAssetEntity;
            selectedAssets.push(values);
            return sum + values.Amount;
        }, 0);
        this.calculateMinChangePriceDate(selectedAssets);
        // refresh would reload secondary query filters, we just need to rerender confirmation buttons
        this.forceUpdate();
    };

    getStaticToolbarItems = (): TToolbarItem[] => {
        if (!this.getTableAction()) {
            return [];
        }
        const formattedAmount = formatCurrency(this._amount, getCompanyCurrency(this.props.storage.context));
        return [(
                <ToolbarStaticText key={"summary"}>
                    {this.props.storage.t("FixedAsset:Unorganized.SumOfSelected")}: <ColoredText
                        color="C_SEM_text_warning"><strong>{formattedAmount}</strong></ColoredText>
                </ToolbarStaticText>
        )];
    };

    renderTabs = (): ReactElement => {
        if (!!this.rootStorageAssetType || this.isCbaCompany) {
            // Type is preselected by the asset (rendered in editable window on top of asset form)
            // we also don't render tabs in CBA as it is not solved yet
            // -> do not render the tabs at all (but filter query is still applied)
            return null;
        }

        return super.renderTabs();
    };

    render = () => {
        if (!this.isLoaded()) {
            return null;
        }

        return (
                <>
                    {this.shouldRenderBreadcrumbs &&
                            <BreadCrumbProvider back={this.props.back ?? this.props.storage?.initialHistoryState?.back}
                                                customBreadCrumbs={this.props.storage?.initialHistoryState?.breadCrumbs}
                                                removeLast={!!this.props.storage.data.rowBindingContext}/>}
                    <View scrollProps={{ ref: this._scrollBarInstanceRef }}
                          {...this.getViewProps()}
                          hotspotContextId={this.props.storage.id}>
                        <TopWrapper _loaded={this.isLoaded()}>
                            {this.isInEditableWindow ? this.renderAssetHeader() : this.renderSmartHeader()}
                            {this.renderFilterBar()}
                            {this.renderAlert()}
                            {this.renderTabs()}
                            {this.renderToolbar()}
                        </TopWrapper>
                        {this.renderTable()}
                        {this.renderCustomDialog()}
                        {this.renderDefaultDialogs()}
                        {/* We support both cases, tableAlert and Alert HOC */}
                        {this.props.alert}
                    </View>
                </>
        );
    };
}

export default withAuthContext(withBusyIndicator({ isDelayed: true })(withAlert({
    autoHide: true,
    position: AlertPosition.CenteredBottom
})(UnorganizedAssetTableView)));
