import { formatDateToDateString, getHourDifference, isSameDay, isSameMonth } from "@components/inputs/date/utils";
import { addEmptyLineItem } from "@components/smart/smartFastEntryList";
import { getNewItemsMaxId } from "@odata/Data.utils";
import {
    IPrWorkingPatternDayEntity,
    IPrWorkingPatternDayIntervalEntity,
    IPrWorkingPatternEntity,
    PrWorkingPatternDayEntity,
    PrWorkingPatternDayIntervalEntity,
    PrWorkingPatternEntity
} from "@odata/GeneratedEntityTypes";
import { PrWorkingPatternTypeCode, PrWorkIntervalTypeCode } from "@odata/GeneratedEnums";
import { isNotDefined } from "@utils/general";
import { Dayjs } from "dayjs";
import React, { Component } from "react";

import CalendarWithInputs from "../../../components/calendarWithInputs/CalendarWithInputs";
import { BREAK_LENGTH, WORK_LENGTH } from "../../../constants";
import BindingContext from "../../../odata/BindingContext";
import { getUtcDayjs } from "../../../types/Date";
import NumberType from "../../../types/Number";
import { FormStorage } from "../../../views/formView/FormStorage";
import { AdditionalCalendarInfo, CalendarFieldWrapper, WeekContentAfter } from "./WorkingPattern.styles";
import { dateStartPath, rotationLengthPath, timeDiffPath } from "./WorkingPatternsDef";
import { IWorkingPatternCustomData } from "./WorkingPatternsFormView";

interface IProps {
    storage: FormStorage<IPrWorkingPatternEntity, IWorkingPatternCustomData>
}

export class SmartCalendarWrapper extends Component<IProps> {
    getNewEmptyIntervalLineItem = (newItemsMax?: number): Partial<IPrWorkingPatternDayIntervalEntity> => {
        const storage = this.props.storage;
        return addEmptyLineItem<IPrWorkingPatternDayIntervalEntity>({
            storage,
            bindingContext: this.props.storage.data.bindingContext.navigate(PrWorkingPatternEntity.Days).navigate(PrWorkingPatternDayEntity.Intervals),
            newItemsCount: (newItemsMax ?? 0) + 1,
            columns: [{
                id: PrWorkingPatternDayIntervalEntity.TimeStart
            }, {
                id: PrWorkingPatternDayIntervalEntity.TimeEnd
            }, {
                id: timeDiffPath
            }],
            context: storage.context
        });
    };

    createDefaultIntervals = (workingHours: number): IPrWorkingPatternDayIntervalEntity[] => {
        const storage = this.props.storage;
        const intervals: IPrWorkingPatternDayIntervalEntity[] = [];

        let hoursLeft = workingHours;
        let lastWasBreak = true;
        let startDate = getUtcDayjs(storage.data.entity.DefaultDayStart) ?? getUtcDayjs().startOf("day").add(8, "hours");

        while (hoursLeft > 0) {
            const endDate = startDate.clone().add(Math.min(hoursLeft, (lastWasBreak ? WORK_LENGTH : BREAK_LENGTH)), "hours");
            let newInterval = this.getNewEmptyIntervalLineItem(intervals.length);
            newInterval = {
                ...newInterval,
                TimeStart: startDate.clone().toDate(),
                TimeEnd: endDate.toDate(),
                Type: {
                    Code: lastWasBreak ? PrWorkIntervalTypeCode.Work : PrWorkIntervalTypeCode.MealBreak
                }
            };
            startDate = endDate.clone();
            intervals.push(newInterval);
            if (lastWasBreak) {
                hoursLeft -= WORK_LENGTH;
            }
            lastWasBreak = !lastWasBreak;
        }
        return intervals;
    };

    handleChange = (val: number, day: Dayjs): void => {
        const storage = this.props.storage;
        const entity = storage.getEntity();
        const intervals = this.createDefaultIntervals(val);

        const editedDay = entity.Days.find(d => isSameDay(day, getUtcDayjs(d.Date)));
        if (editedDay) {
            editedDay.WorkingHours = val;
            editedDay.Intervals = intervals;
        } else {
            const newItemsMax = getNewItemsMaxId(entity.Days);
            const newDay = BindingContext.createNewEntity<IPrWorkingPatternDayEntity>(newItemsMax + 1, {
                Date: day.toDate(),
                WorkingHours: val,
                Intervals: intervals
            });
            entity.Days.push(newDay);
            this.handleSelect(day);
        }
        this.props.storage.refresh();
    };

    handleSelect = (day: Dayjs): void => {
        const storage = this.props.storage;
        if (isNotDefined(day)) {
            storage.setCustomData({ selectedDay: null });
            storage.refresh();
            return;
        }

        const entity = this.props.storage.getEntity();
        const editedDay = entity.Days?.find(d => isSameDay(day, getUtcDayjs(d.Date)));
        const daysBc = this.props.storage.data.bindingContext.navigate(PrWorkingPatternEntity.Days);

        const newEmptyInterval = {
            ...this.getNewEmptyIntervalLineItem(),
            Type: {
                Code: PrWorkIntervalTypeCode.Work
            }
        };

        if (editedDay) {
            const id = editedDay[BindingContext.NEW_ENTITY_ID_PROP] ?? editedDay.Id;
            const bc = daysBc.addKey(id, !!editedDay[BindingContext.NEW_ENTITY_ID_PROP]);
            if (!editedDay.Intervals?.length) {
                editedDay.Intervals = [newEmptyInterval];
            }
            this.props.storage.setCustomData({ selectedDay: bc });
        } else {
            const newItemsMax = getNewItemsMaxId(entity.Days);
            const newDay = BindingContext.createNewEntity<IPrWorkingPatternDayEntity>(newItemsMax + 1, {
                Date: day.toDate(),
                Intervals: [newEmptyInterval]
            });
            entity.Days.push(newDay);
            const bc = this.props.storage.data.bindingContext.navigate(PrWorkingPatternEntity.Days).addKey(newDay[BindingContext.NEW_ENTITY_ID_PROP], true);
            this.props.storage.setCustomData({ selectedDay: bc });
        }
        this.props.storage.refresh();
    };

    handleMonthChange = (day: Dayjs): void => {
        this.props.storage.setCustomData({
            selectedMonth: day
        });
        this.props.storage.refresh();
    };

    daysValueMap = (): Record<string, number> => {
        const entity = this.props.storage.data.entity as IPrWorkingPatternEntity;

        return (entity.Days ?? []).reduce((map, day) => {
            map[formatDateToDateString(day.Date)] = day.WorkingHours;
            return map;
        }, {} as Record<string, number>);
    };

    formatHoursString = (hours: number): string => {
        return `${NumberType.format(hours, { maximumFractionDigits: 2 })} ${this.props.storage.t("Components:Calendar.HourPlaceholder")}`;
    };

    get totalWorkingHoursForCurrentMonth(): number {
        const entity = this.props.storage.data.entity;
        const selectedMonth = this.props.storage.getCustomData().selectedMonth ?? getUtcDayjs(entity.Days?.[0]?.Date);
        return entity.Days?.filter(d => isSameMonth(d.Date, selectedMonth.toDate()))?.reduce((count, day) => {
            count += day.WorkingHours ?? 0;
            return count;
        }, 0);
    }

    get remainingWorkingHoursForCurrentMonth(): number {
        const entity = this.props.storage.data.entity;
        const selectedMonth = this.props.storage.getCustomData().selectedMonth ?? getUtcDayjs(entity.Days?.[0]?.Date);
        const workHours = entity.Days?.filter(d => isSameMonth(d.Date, selectedMonth.toDate()))?.reduce((count, day) => {
            const plannedHours = day.Intervals?.filter(interval => interval.Type?.Code === PrWorkIntervalTypeCode.Work).reduce((count, interval) => {
                if (isNotDefined(interval.TimeStart) || isNotDefined(interval.TimeEnd)) {
                    return count;
                }
                count += getHourDifference(getUtcDayjs(interval.TimeStart), getUtcDayjs(interval.TimeEnd));
                return count;
            }, 0);
            count += plannedHours ?? 0;
            return count;
        }, 0);
        return this.totalWorkingHoursForCurrentMonth - workHours;
    }

    render() {
        const type = this.props.storage.getValueByPath(PrWorkingPatternEntity.TypeCode);
        const rotationLength = Math.min(this.props.storage.getValueByPath(rotationLengthPath), 365);
        const isDifferentOddAndEvenWeek = this.props.storage.getValueByPath(PrWorkingPatternEntity.IsDifferentOddAndEvenWeek);
        const startDate = getUtcDayjs(this.props.storage.getValueByPath(dateStartPath));

        return <CalendarFieldWrapper isDifferentOddAndEvenWeek={isDifferentOddAndEvenWeek}>
            <CalendarWithInputs
                    type={type}
                    onChange={this.handleChange}
                    onSelect={this.handleSelect}
                    onMonthChange={this.handleMonthChange}
                    rotationLength={rotationLength}
                    startDate={startDate}
                    isDifferentOddAndEvenWeek={isDifferentOddAndEvenWeek}
                    daysValuesMap={this.daysValueMap()}
            />
            {type === PrWorkingPatternTypeCode.Monthly && <AdditionalCalendarInfo>
                <div>
                    {this.props.storage.t("WorkingPatterns:WorkingTimePool")}&nbsp;
                    <b>{this.formatHoursString(this.totalWorkingHoursForCurrentMonth)}</b>
                </div>
                {/*<div>
                    {this.props.storage.t("WorkingPatterns:RemainsToPlan")}&nbsp;<ColoredText
                        color={"C_SEM_text_warning"}><b>{this.formatHoursString(this.remainingWorkingHoursForCurrentMonth)}</b></ColoredText>
                </div>*/}
            </AdditionalCalendarInfo>}
            {type === PrWorkingPatternTypeCode.Weekly && isDifferentOddAndEvenWeek &&
                    <WeekContentAfter>
                        <div>{this.props.storage.t("WorkingPatterns:Even")}</div>
                        <div>{this.props.storage.t("WorkingPatterns:Odd")}</div>
                    </WeekContentAfter>
            }
        </CalendarFieldWrapper>;
    }
}