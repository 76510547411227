import { CountryCode, PrWorkingPatternTypeCode, PrWorkIntervalTypeCode } from "@odata/GeneratedEnums";
import { isDefined, isNotDefined, isObjectEmpty } from "@utils/general";
import {
    formatHoursToTimeFormat,
    getHourDifference,
    getMonthDays,
    isSameDay,
    isSameMonth
} from "@components/inputs/date/utils";
import {
    IPrAttendanceDayEntity,
    IPrAttendanceDayIntervalEntity,
    IPrAttendanceEntity,
    IPrEmploymentTemporalEntity,
    IPrWorkingPatternDayIntervalEntity,
    IPrWorkingPatternEntity,
    PrAttendanceDayEntity,
    PrAttendanceDayIntervalEntity,
    PrAttendanceEntity
} from "@odata/GeneratedEntityTypes";
import { TValue } from "../../../global.types";
import { ColoredText } from "../../../global.style";
import React from "react";
import { ICellValueObject } from "@components/table";
import { FormStorage } from "../../../views/formView/FormStorage";
import { IAttendanceCustomData } from "./AttendanceFormView";
import { IDayAction } from "@components/calendar/Calendar.utils";
import { DefaultTheme } from "styled-components/macro";
import { IGetValueArgs } from "@components/smart/FieldInfo";
import { timeDiffPath } from "../workingPatterns/WorkingPatternsDef";
import BindingContext from "../../../odata/BindingContext";
import { getNewItemsMaxId } from "@odata/Data.utils";
import { BREAK_LENGTH, REST_API_URL, WORK_LENGTH } from "../../../constants";
import i18next from "i18next";
import customFetch from "../../../utils/customFetch";
import memoize from "../../../utils/memoize";
import { addEmptyLineItem } from "@components/smart/smartFastEntryList";
import { getUtcDayjs } from "../../../types/Date";
import { Dayjs } from "dayjs";

// this serves as fake ID as we want to select NO items when there is no item selected which is opposite of default filter behaviour
// so where there is no filter item selected we put this fake non existing id so we ensure no items are loaded in table
export const ATTENDANCE_FAKE_ID = -9999;

export enum AttendanceFastEntry {
    Absence = "Absence",
    Work = "Work"
}

export const getDayWorkingHours = (day: IPrAttendanceDayEntity): number => {
    return day?.Intervals?.filter(i => i.WorkType?.Code === PrWorkIntervalTypeCode.Work).reduce((hours, interval) => {
        if (isNotDefined(interval.TimeStart) || isNotDefined(interval.TimeEnd)) {
            return hours;
        }
        const diff = getHourDifference(getUtcDayjs(interval.TimeStart), getUtcDayjs(interval.TimeEnd));
        return hours + diff;
    }, 0) ?? 0;
};

export const getDayAbsenceHours = (day: IPrAttendanceDayEntity, dayHoursFund: number): number => {
    return day?.Intervals?.filter(i => !!i.AbsenceCategory?.Id).reduce((hours, interval) => {
        if (interval.IsWholeDay) {
            return dayHoursFund;
        }
        if (isNotDefined(interval.TimeStart) || isNotDefined(interval.TimeEnd)) {
            return hours;
        }
        const diff = getHourDifference(getUtcDayjs(interval.TimeStart), getUtcDayjs(interval.TimeEnd));
        return hours + diff;
    }, 0) ?? 0;
};

export const getWorkingPattern = (attendance: IPrAttendanceEntity): IPrWorkingPatternEntity => {
    const monthDate = getUtcDayjs().set("month", attendance.Month - 1).set("year", attendance.Year);
    const findFn = (bag: IPrEmploymentTemporalEntity) => {
        return getUtcDayjs(monthDate).isBetween(getUtcDayjs(bag.DateValidFrom), getUtcDayjs(bag.DateValidTo));
    };

    const employmentWorkPattern = attendance.Employment?.TemporalPropertyBag?.find(findFn)?.WorkingPattern;

    if (isObjectEmpty(employmentWorkPattern)) {
        return attendance.Employment.Template?.TemporalPropertyBag?.find(findFn)?.WorkingPattern;
    }
    return employmentWorkPattern;
};

export const getWorkingPatternDaysMap = (workingPattern: IPrWorkingPatternEntity, month: Dayjs, holidays: Date[] = []): Record<number, number> => {
    const monthDays = getMonthDays(month);
    if (workingPattern.Type?.Code === PrWorkingPatternTypeCode.Weekly) {
        const result = monthDays.reduce((map: Record<number, number>, day) => {
            const dayIndex = day.get("date");
            const wpDay = workingPattern.Days?.find(d => {
                let res = day.day() === getUtcDayjs(d.Date).day();
                if (workingPattern.IsDifferentOddAndEvenWeek) {
                    res = res && (day.week() % 2 === getUtcDayjs(d.Date).week() % 2);
                }
                return res;
            });
            map[dayIndex] = wpDay?.WorkingHours ?? 0;
            return map;
        }, {});

        for (const holiday of holidays) {
            result[getUtcDayjs(holiday).get("date")] = 0;
        }
        return result;
    }

    return monthDays.reduce((map: Record<number, number>, day) => {
        const dayIndex = day.get("date");
        const wpDay = workingPattern.Days?.find(d => isSameDay(day, getUtcDayjs(d.Date)));
        map[dayIndex] = wpDay?.WorkingHours ?? 0;
        return map;
    }, {});
};

export const balanceFormatter = (val: TValue): ICellValueObject => {
    const sign = val > 0 ? "+" : val < 0 ? "-" : "";
    const color = val > 0 ? "C_SEM_text_good" : val < 0 ? "C_SEM_text_bad" : "C_TEXT_primary";

    const formattedVal = `${sign}${formatHoursToTimeFormat(Math.abs(val as number) / 60)}`;
    return {
        value: <ColoredText
                color={color}>
            {formattedVal}
        </ColoredText>,
        tooltip: formattedVal
    };
};

export const handleDaySelect = (selectedDays: Set<number>, storage: FormStorage<IPrAttendanceEntity, IAttendanceCustomData>): void => {
    storage.setCustomData({ selectedDays });
    storage.refresh();
};

export const parseDayActionsFromData = (days: IPrAttendanceDayEntity[]): Record<number, IDayAction[]> => {
    let lastAbsence: number = null;
    return days.sort((d1, d2) => getUtcDayjs(d1.Date).diff(d2.Date)).reduce((actionsMap: Record<number, IDayAction[]>, day) => {
        const date = getUtcDayjs(day.Date);
        const dayIndex = date.get("date");
        const absences = day.Intervals?.filter(i => !!i.AbsenceCategory?.Id);
        const isMonday = date.day() === 1;

        const daysActions: IDayAction[] = [];
        if (absences?.length) {
            if (absences.length > 1 && absences.some(a => a.IsWholeDay ||
                    absences.some(a2 => {
                        return a2 !== a && (
                                getUtcDayjs(a2.TimeStart).isBetween(getUtcDayjs(a.TimeEnd), getUtcDayjs(a.TimeStart), "minutes") ||
                                getUtcDayjs(a2.TimeEnd).isBetween(getUtcDayjs(a.TimeEnd), getUtcDayjs(a.TimeStart), "minutes")
                        );
                    }))) {
                daysActions.push({
                    color: absences[0].AbsenceCategory.Color as keyof DefaultTheme,
                    title: i18next.t("Attendance:EditAbsence"),
                    isFullDay: true,
                    isStart: true,
                    isEnd: true,
                    hasConflict: true
                });
                lastAbsence = null;
            } else {
                for (const absence of absences) {
                    const isContinuingAbsence = isDefined(lastAbsence) && lastAbsence === absence.AbsenceCategory.Id && absences.length === 1;
                    lastAbsence = absence.AbsenceCategory.Id;
                    daysActions.push({
                        color: absence.AbsenceCategory.Color as keyof DefaultTheme,
                        title: absence.AbsenceCategory.CurrentTemporalPropertyBag?.Name,
                        isFullDay: absence.IsWholeDay,
                        isStart: isMonday || !isContinuingAbsence,
                        hideTitle: isMonday && isContinuingAbsence,
                        isEnd: true,
                        hasConflict: false
                    });

                    if (isContinuingAbsence && actionsMap[dayIndex - 1]) {
                        actionsMap[dayIndex - 1][0].isEnd = isMonday;
                    }
                }
            }
        } else {
            lastAbsence = null;
        }
        actionsMap[dayIndex] = daysActions;
        return actionsMap;
    }, {});
};

export const isAbsenceInWorkFEList = (args: IGetValueArgs): boolean => {
    const entity = args.item ?? args.storage.getValue(args.bindingContext.getParent());
    return args.fastEntryListId === AttendanceFastEntry.Work && !!entity?.AbsenceCategory?.Id;
};

export const sortIntervalsByDate = (i1: IPrAttendanceDayIntervalEntity, i2: IPrAttendanceDayIntervalEntity): number => {
    if (i1.IsStartNextDay !== i2.IsStartNextDay) {
        return i1.IsStartNextDay ? 1 : -1;
    }
    return getUtcDayjs(i1.TimeStart).diff(getUtcDayjs(i2.TimeStart));
};

export const hasFullDayAbsence = (entity: IPrAttendanceDayEntity): boolean => {
    return entity.Intervals?.length === 1 && entity.Intervals[0].IsWholeDay && !!entity.Intervals[0].AbsenceCategory?.Id;
};

export const getAbsenceIntervals = (entity: IPrAttendanceDayEntity): IPrAttendanceDayIntervalEntity[] => {
    return entity?.Intervals?.filter(i => !!i.AbsenceCategory?.Id).sort(sortIntervalsByDate) ?? [];
};

export const generateIntervalsForDay = (dayBc: BindingContext, minutes: number, storage: FormStorage): void => {
    const absenceIntervals = getAbsenceIntervals(storage.getValue(dayBc));

    let startDate = getUtcDayjs().startOf("day").add(8, "hours");
    let minutesLeft = minutes;
    let lastWasBreak = true;
    const newIntervals = [];

    while (minutesLeft > 0) {
        if (absenceIntervals.length && getUtcDayjs(absenceIntervals[0].TimeStart).diff(startDate) >= 0) {
            lastWasBreak = true;
            startDate = getUtcDayjs(absenceIntervals[0].TimeEnd);
            newIntervals.push(absenceIntervals.shift());
        } else {
            let endDate = startDate.add(Math.min(minutesLeft, (lastWasBreak ? WORK_LENGTH * 60 : BREAK_LENGTH * 60)), "minutes");
            if (absenceIntervals.length && getUtcDayjs(absenceIntervals[0].TimeStart).diff(endDate) >= 0) {
                endDate = getUtcDayjs(absenceIntervals[0].TimeStart);
            }
            const maxId = getNewItemsMaxId([...newIntervals, ...absenceIntervals]);
            let newInterval: IPrAttendanceDayIntervalEntity = addEmptyLineItem<IPrAttendanceDayIntervalEntity>({
                storage,
                bindingContext: storage.data.bindingContext.navigate(PrAttendanceEntity.Days).navigate(PrAttendanceDayEntity.Intervals),
                newItemsCount: maxId + 1,
                columns: [{
                    id: PrAttendanceDayIntervalEntity.TimeStart
                }, {
                    id: PrAttendanceDayIntervalEntity.TimeEnd
                }, {
                    id: timeDiffPath
                }],
                context: storage.context
            });

            newInterval = {
                ...newInterval,
                TimeStart: startDate.clone().toDate(),
                TimeEnd: endDate.toDate(),
                WorkType: {
                    Code: lastWasBreak ? PrWorkIntervalTypeCode.Work : PrWorkIntervalTypeCode.MealBreak
                }
            };

            if (lastWasBreak) {
                minutesLeft -= getHourDifference(startDate, endDate, true, true);
            }

            startDate = endDate.clone();
            newIntervals.push(newInterval);
            lastWasBreak = !lastWasBreak;
        }
    }

    storage.setValue(dayBc.navigate(PrAttendanceDayEntity.Intervals), [...newIntervals, ...absenceIntervals]);
};

export const getDaySaldo = (day: IPrAttendanceDayEntity, fund: number): number => {
    let datedHours = 0;

    if (day.Intervals?.some(i => i.IsWholeDay)) {
        datedHours = fund;
    } else {
        datedHours = getDayWorkingHours(day);
        const absenceHours = getDayAbsenceHours(day, fund);
        datedHours = datedHours > fund ? datedHours : Math.min(datedHours + absenceHours, fund);
    }
    return datedHours - fund;
};

export const updateDaySaldo = (day: IPrAttendanceDayEntity, storage: FormStorage<IPrAttendanceEntity, IAttendanceCustomData>): void => {
    const dayIndex = getUtcDayjs(day.Date).get("date");
    const fund = storage?.getCustomData().hoursFundMap?.[dayIndex] ?? 0;

    storage.setCustomData({
        saldoMap: {
            ...storage.getCustomData().saldoMap,
            [dayIndex]: getDaySaldo(day, fund)
        }
    });
};

export const createNewAttendanceDayEntity = (dayIndex: number, storage: FormStorage<IPrAttendanceEntity, IAttendanceCustomData>): IPrAttendanceDayEntity => {
    const days = storage.data.entity.Days;
    const newItemsMax = getNewItemsMaxId(days);
    let newDay: IPrAttendanceDayEntity = addEmptyLineItem<IPrAttendanceDayEntity>({
        storage,
        bindingContext: storage.data.bindingContext.navigate(PrAttendanceEntity.Days),
        newItemsCount: newItemsMax + 1,
        columns: [],
        context: storage.context
    });

    newDay = {
        ...newDay,
        Date: getUtcDayjs(days[0]?.Date).set("date", dayIndex).toDate(),
        Intervals: []
    };

    days.push(newDay);
    return newDay;
};


export const refreshActions = (storage: FormStorage<IPrAttendanceEntity, IAttendanceCustomData>): void => {
    const actions = parseDayActionsFromData(storage.data.entity.Days);
    storage.setCustomData({ actions });
};

export const refreshBalance = (storage: FormStorage<IPrAttendanceEntity, IAttendanceCustomData>): void => {
    const saldoMap = storage.getCustomData().saldoMap;
    const balance = Object.values(saldoMap).reduce((balance, saldo) => {
        return balance + saldo;
    }, 0);

    storage.setValueByPath(PrAttendanceEntity.Balance, Math.round(balance * 60));
};

export const refreshSaldoMap = (storage: FormStorage<IPrAttendanceEntity, IAttendanceCustomData>, days?: Set<number>): void => {
    const selectedDays = days ?? storage.getCustomData().selectedDays;
    selectedDays.forEach(day => {
        const dayEntity = storage.data.entity.Days.find(d => getUtcDayjs(d.Date).get("date") === day);
        updateDaySaldo(dayEntity, storage);
    });
};

type TWorkInterval = (IPrAttendanceDayIntervalEntity | IPrWorkingPatternDayIntervalEntity);

export function transformWorkIntervalsBeforeSave(intervals: TWorkInterval[]): TWorkInterval[] {
    let highestHour: Date = null;
    let isNextDay = false;

    return intervals?.filter(interval => {
        return "AbsenceCategory" in interval ? interval.AbsenceCategory?.Id !== ATTENDANCE_FAKE_ID : true;
    })?.sort(sortIntervalsByDate).map((interval, index) => {
        interval.Order = index + 1; // BE starts ordering with 1

        if (!isNextDay && (!highestHour || getHourDifference(getUtcDayjs(highestHour), getUtcDayjs(interval.TimeStart), false) >= 0)) {
            highestHour = interval.TimeStart;
            interval.IsStartNextDay = false;
        } else {
            isNextDay = true;
            interval.IsStartNextDay = true;
        }

        if (!isNextDay && getHourDifference(getUtcDayjs(highestHour), getUtcDayjs(interval.TimeEnd), false) >= 0) {
            highestHour = interval.TimeEnd;
            interval.IsEndNextDay = false;
        } else {
            isNextDay = true;
            interval.IsEndNextDay = true;
        }

        return interval;
    });
}

export const getMonthHolidays = memoize(async (monthDate: Dayjs, year: number, countryCode: CountryCode): Promise<Date[]> => {
    const cc = countryCode ?? CountryCode.CzechRepublic;
    const url = `${REST_API_URL}/PrCalendar/PublicHolidays/${cc}/${year}`;
    const res = await customFetch(url);
    const allHolidays = await res.json() as Date[];
    return allHolidays.filter(h => isSameMonth(monthDate.toDate(), getUtcDayjs(h).toDate()));
});