import { ISelectItem, TSelectItemId } from "@components/inputs/select/Select.types";
import {
    IAffectedField,
    ifAll,
    ifAny,
    IFieldDef,
    IGetValueArgs,
    isFieldDisabled,
    isVisible,
    isVisibleByPath,
    not
} from "@components/smart/FieldInfo";
import { withDisplayName } from "@components/smart/GeneralFieldDefinition";
import { ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { TFilterDef } from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { IDependentFieldDef } from "@components/smart/smartFormGroup/SmartFormGroup";
import {
    fetchItemsByInfo,
    getRenderedItemsByInfo,
    invalidateItems
} from "@components/smart/smartSelect/SmartSelectAPI";
import { isInVatPayerVariantContext } from "@components/variantSelector/VariantOdata";
import { getBoundValue, getNestedValue, setNestedValue } from "@odata/Data.utils";
import {
    BusinessPartnerEntity,
    DocumentCbaCategoryEntity,
    DocumentDraftEntity,
    DocumentEntity,
    DocumentItemEntity,
    DocumentVatClassificationEntity,
    DocumentVatClassificationSelectionEntity,
    EntitySetName,
    EntityTypeName,
    FlattenAccountEntity,
    ICbaCategoryEntity,
    ICountryEntity,
    IDocumentBusinessPartnerEntity,
    IDocumentEntity,
    IDocumentVatClassificationEntity,
    IDocumentVatClassificationSelectionEntity,
    IInvoiceReceivedEntity,
    IRegularDocumentItemEntity,
    IVatClassificationEntity,
    IVatEntity,
    VatClassificationEntity,
    VatStatementSectionEntity
} from "@odata/GeneratedEntityTypes";
import {
    AccountCategoryCode,
    AccountTypeCode,
    CbaCategoryTaxImpactCode,
    CountryClassificationCode,
    CountryCode,
    DocumentTypeCode,
    PayableReceivableTypeCode,
    SelectionCode,
    TaxApplicabilityCode,
    VatDeductionTypeCode,
    VatReverseChargeCode,
    VatStatementSectionCategoryCode,
    VatStatusCode
} from "@odata/GeneratedEnums";
import { getEnumDisplayValue } from "@odata/GeneratedEnums.utils";
import { formatBooleanValue, IFormatOptions, isEmptyValue } from "@odata/OData.utils";
import {
    isAccountAssignmentCompanyValue,
    isCashBasisAccountingCompany,
    isVatRegisteredCompany
} from "@utils/CompanyUtils";
import { formatPercent, isDefined, isObjectEmpty, sortCompareFn } from "@utils/general";
import { compareString } from "@utils/string";
import i18next from "i18next";
import { TestContext, ValidationError } from "yup";

import { AppMode } from "../../../contexts/appContext/AppContext.types";
import { BasicInputSizes, FastEntryInputSizes, FieldType, NavigationSource, ValidatorType } from "../../../enums";
import { TRecordAny, TValue } from "../../../global.types";
import BindingContext, { createPath, IEntity } from "../../../odata/BindingContext";
import NumberType from "../../../types/Number";
import memoizeOne from "../../../utils/memoizeOne";
import { FormStorage } from "../../../views/formView/FormStorage";
import { getSingleAccountFieldDef } from "../../accountAssignment/AccountAssignment.utils";
import {
    CASH_BASIS_ACCOUNTING_GROUP_ID,
    getCBAItemCategory,
    getDefaultPercetageFromImpactCode
} from "../../cashBasisAccounting/CashBasisAccounting.utils";
import { getCompanyUseVatReducedDeduction } from "../../companies/Company.utils";
import { isIssued, isNotSavedDocument, isReceived, VAT_ASSIGNMENT_GROUP_ID } from "../../documents/Document.utils";
import { TFieldDefinition, TFieldsDefinition } from "../../PageUtils";

// Limit in CZK for simplified tax documents. NumberTheirs is mandatory for documents between VatPayers.
export const SimplifiedTaxDocumentLimit = 10000;

interface IGetVatRowFieldsArgs {
    prefix?: string;
    isDocumentItem?: boolean;
    addNonTaxAccount?: boolean;
    isVatRule?: boolean;
}

// todo how to check correct format with TLocalContext
export const SAVED_VATS_PATH = "##SavedVats##";
export const ITEMS_VAT_DEDUCTION_PATH = "##ItemsVatDeduction##";
export const VAT_REVERSE_CHARGE_SELECT_PATH = "##VatReverseChargeSelect##";
export const VAT_CLASSIFICATION_PATH = `${DocumentEntity.VatClassificationSelection}/${DocumentVatClassificationSelectionEntity.VatClassification}`;
export const AUTOMATED_VAT_INCLUSION_MODE_PATH = `${VAT_CLASSIFICATION_PATH}/${VatClassificationEntity.IsInAutomatedVatInclusionMode}`;
export const DOCUMENT_REVERSE_CHARGE_PATH = `${VAT_CLASSIFICATION_PATH}/${VatClassificationEntity.VatReverseCharge}`;

export const BaseConditionsPlaceholderPath = BindingContext.localContext("BaseConditionsPlaceholder");

// only available VatControlStatementCode for automated VAT inclusion
export const AutomatedVatInclusionModeEUControlStatementCode = "CZ_2016_A2";
export const AutomatedVatInclusionModeNonEUControlStatementCode = "CZ_2016_N";

export const VatReducedDeductionCodes = [
    VatDeductionTypeCode.Reduced,
    VatDeductionTypeCode.ReducedAndProportional
];

export interface IVatClassificationExtendedEntity extends IVatClassificationEntity {
    [SAVED_VATS_PATH]?: string;
    [VAT_REVERSE_CHARGE_SELECT_PATH]?: string;
    [ITEMS_VAT_DEDUCTION_PATH]?: string;
}

export const VatSelectionFields = [
    VatClassificationEntity.VatReverseCharge, VatClassificationEntity.VatStatementSection, VatClassificationEntity.VatControlStatementSection,
    /* DEV-15519 VatClassificationEntity.VatOss,*/ VatClassificationEntity.VatVies, VatClassificationEntity.IsInAutomatedVatInclusionMode, VAT_REVERSE_CHARGE_SELECT_PATH,
    VatClassificationEntity.VatDeductionType, VatClassificationEntity.VatProportionalDeduction, DocumentVatClassificationEntity.NonTaxAccount
];

export function isReducedDeduction(code: TValue): boolean {
    return VatReducedDeductionCodes.includes(code as VatDeductionTypeCode);
}

export const invalidateSavedVats = (storage: FormStorage, collectionName = "Items"): void => {
    const vatBc = storage.data.bindingContext.navigate(SAVED_VATS_PATH);
    const bcs = [vatBc];

    for (const item of storage.data.entity[collectionName] || []) {
        const itemBc = storage.data.bindingContext.navigate(`${collectionName}(${item.Id})/${SAVED_VATS_PATH}`);
        bcs.push(itemBc);
    }

    invalidateItems(bcs, storage);
};

export const getNonTaxAccountFilter = (): string => `${FlattenAccountEntity.TaxApplicabilityCode} eq '${TaxApplicabilityCode.NotTaxApplicable}' AND ${FlattenAccountEntity.CategoryCode} eq '${AccountCategoryCode.IncomeStatement}' AND ${FlattenAccountEntity.TypeCode} eq '${AccountTypeCode.Expense}'`;

type TDocumentAmountProp =
        DocumentItemEntity.Amount
        | DocumentItemEntity.AmountVat
        | DocumentItemEntity.AmountNet
        | DocumentItemEntity.TransactionAmount
        | DocumentItemEntity.TransactionAmountVat
        | DocumentItemEntity.TransactionAmountNet;
type TBaseDocumentEntityWithItems = IDocumentEntity & { Items?: IRegularDocumentItemEntity[] };

function getDocumentItemsSummaryAmount<T extends TBaseDocumentEntityWithItems>(entity: T, prop: TDocumentAmountProp = DocumentItemEntity.TransactionAmountNet, itemsProp: keyof T = "Items"): number {
    let sum = 0;
    const Items = entity[itemsProp] as unknown as IRegularDocumentItemEntity[];
    const useTransactionCurrency = prop.includes("Transaction");
    const sumProp = (!useTransactionCurrency ? `Transaction${prop}` : prop) as keyof IRegularDocumentItemEntity;
    const rate = entity.ExchangeRatePerUnit ?? 1;

    Items?.forEach((item) => {
        const itemAmount = item[sumProp] as number;
        if (NumberType.isValid(itemAmount)) {
            sum += itemAmount;
        }
    });
    return useTransactionCurrency ? sum : sum * rate;
}

const VatControlStatementRulesWithoutMandatoryNumberTheirs = ["CZ_2016_N", "CZ_2016_B3"];
const VatControlStatementRulesWithMandatoryNumberTheirs = ["CZ_2016_B2"];
const DefaultVatRuleIds: TSelectItemId[] = [-1, -2, -4];

export function isDefaultVatRule(item: ISelectItem<TSelectItemId>): boolean {
    return DefaultVatRuleIds.includes(item?.id);
}

/**
 * Finds default vatRule from items. If businessPartnerDefault is set, it is used as a default value (if available in items).
 * @param items
 * @param businessPartnerDefault
 */
export function getDefaultVatRuleFromItems(items: ISelectItem[], businessPartnerDefault: IVatClassificationEntity): ISelectItem {
    let res: ISelectItem = null;
    if (businessPartnerDefault) {
        res = items?.find(item => item.id === businessPartnerDefault.Id);
    }
    return res ?? items?.find(item => isDefaultVatRule(item));
}

export function hasNumberTheirsAndVatIdConditions(args: IGetValueArgs): boolean {
    const { storage } = args;
    const entity = storage.getEntity<IInvoiceReceivedEntity>();

    const isVatPayer = entity.BusinessPartner?.VatStatusCode === VatStatusCode.VATRegistered || entity.BusinessPartner?.VatStatusCode === VatStatusCode.IdentifiedPerson;
    const isVatConfigured = (entity.VatClassificationSelection?.Selection?.Code && entity.VatClassificationSelection?.Selection?.Code !== SelectionCode.None);
    const { VatControlStatementSectionCode } = entity.VatClassificationSelection?.VatClassification ?? {};
    const isVatControlStatementMandatoryException = VatControlStatementSectionCode && VatControlStatementRulesWithoutMandatoryNumberTheirs.includes(VatControlStatementSectionCode);
    const isVatControlStatementMandatory = VatControlStatementSectionCode && VatControlStatementRulesWithMandatoryNumberTheirs.includes(VatControlStatementSectionCode);
    const amount = getDocumentItemsSummaryAmount<IInvoiceReceivedEntity>(entity, DocumentItemEntity.Amount);

    return (isVatControlStatementMandatory || Math.abs(amount) >= SimplifiedTaxDocumentLimit) && isVatPayer && isVatConfigured && !isVatControlStatementMandatoryException;
}

export const isNumberTheirsAndVatIdMandatory = ifAll(hasNumberTheirsAndVatIdConditions, isEeaRule);

export const getVatRowFields = (args?: IGetVatRowFieldsArgs): IFieldDef[] => {
    return [].concat(...getVatGroupRowsDef(args));
};

export function getVatGroupRowsDef(args?: IGetVatRowFieldsArgs): (IFieldDef[])[] {
    const rows: (IFieldDef[])[] = [];
    const prefix = args?.prefix ?? "";

    const _addRow = (...args: IFieldDef[]) => {
        const defs = args.filter(item => !!item);
        if (defs.length) {
            rows.push(defs);
        }
    };

    if (!args?.isDocumentItem) {
        _addRow(
                { id: `${prefix}IsInAutomatedVatInclusionMode` },
                { id: `${prefix}VatReverseCharge` }
        );
        _addRow({ id: args?.isDocumentItem ? `${prefix}${VAT_REVERSE_CHARGE_SELECT_PATH}` : VAT_REVERSE_CHARGE_SELECT_PATH });
        _addRow(
                { id: `${prefix}VatStatementSection` },
                args?.isVatRule && { id: BaseConditionsPlaceholderPath },
                !args?.isDocumentItem && { id: `${prefix}VatControlStatementSection` },
                // DEV-15519 { id: `${prefix}VatOss` },
                { id: `${prefix}VatVies` }
        );
    }

    _addRow(!args?.isDocumentItem && { id: `${prefix}VatDeductionType` },
            { id: `${prefix}VatProportionalDeduction` },
            args?.addNonTaxAccount && { id: `${prefix}NonTaxAccount` }
    );

    return rows;
}

/**
 * Corrects selection code -> values from savedPath select can be rule ids. In this case it should be
 * converted to "SelectionCode.Copy"
 * @param val
 */
export function correctSelectionCode(val: unknown): SelectionCode {
    if (val && !SelectionCode[val as SelectionCode]) {
        return SelectionCode.Copy;
    }
    return val as SelectionCode;
}

function getSavedVatsCode(args: IGetValueArgs): SelectionCode {
    // Items bindingContext for lineItems, otherwise data.bindingContext for saved vats on document level or
    // when used on VatClassifications form
    const { collectionBindingContext } = args.bindingContext?.splitByCollectionPath() ?? {};
    const rootBc = collectionBindingContext ?? args.storage.data.bindingContext;

    const savedVats = rootBc.navigate(SAVED_VATS_PATH);
    const val = args.storage.getValue(savedVats);
    return correctSelectionCode(val);
}

function hasVatClassification(args: IGetValueArgs): boolean {
    const code = getSavedVatsCode(args);
    return code && code !== SelectionCode.None && code !== SelectionCode.Default;
}

/**
 * Only for documentFormView -> for internal documents, we show all possibilities
 * @param storage
 */
function isInternalDocument({ storage }: IGetValueArgs): boolean {
    const entity = storage.data.entity;
    return entity?.DocumentTypeCode === DocumentTypeCode.InternalDocument;
}

/**
 * Receivable flag for document or Vat entity
 * @param args
 */
function isReceivedType({ storage, item, data }: IGetValueArgs): boolean {
    const entity = item?.additionalData ?? data ?? storage.data.entity;
    if (entity.DocumentTypeCode) {
        return isReceived(entity.DocumentTypeCode);
    }
    return entity.PayableReceivableTypeCode === PayableReceivableTypeCode.Payable;
}

/**
 * Issued flag for document or Vat entity
 * @param args
 */
function isIssuedType({ storage, item }: IGetValueArgs): boolean {
    const entity = item?.additionalData ?? storage.data.entity;
    if (entity.DocumentTypeCode) {
        return isIssued(entity.DocumentTypeCode);
    }
    return entity.PayableReceivableTypeCode === PayableReceivableTypeCode.Receivable;
}

function getCountry({ storage, data }: IGetValueArgs): ICountryEntity {
    const entity = data ?? storage.data.entity;
    return entity.BusinessPartner?.Country ?? entity.Country ?? {
        Code: CountryCode.CzechRepublic,
        IsEuMember: true,
        IsEEA: true
    };
}

export function isAgendaDomesticRule(args: IGetValueArgs): boolean {
    const entity = args.data ?? args.storage.data.entity;
    if (args.storage?.data?.bindingContext.getEntityType().getName() === EntityTypeName.VatClassification) {
        return (entity as IVatClassificationEntity).CountryClassificationCode === CountryClassificationCode.Domestic;
    }
    return getCountry(args)?.Code === CountryCode.CzechRepublic;
}

export function isEeaRule(args: IGetValueArgs): boolean {
    const { entity, bindingContext } = args.storage.data;
    if (bindingContext.getEntityType().getName() === EntityTypeName.VatClassification) {
        return (entity as IVatClassificationEntity).CountryClassificationCode === CountryClassificationCode.EuropeanUnion;
    }
    return getCountry(args)?.IsEEA !== false;
}

export const isEuRuleExceptAgendaDomestic = ifAll(isEeaRule, not(isAgendaDomesticRule));

export function isVatRegisteredEntity({ storage }: IGetValueArgs): boolean {
    const { entity, bindingContext } = storage.data;
    if (bindingContext.getEntityType().getName() === EntityTypeName.VatClassification) {
        return !!(entity as IVatClassificationEntity).IsVatRegistered;
    }
    return [VatStatusCode.VATRegistered, VatStatusCode.IdentifiedPerson].includes(entity.BusinessPartner?.VatStatusCode);
}

function isUnknownOrVatRegisteredEntity({ storage }: IGetValueArgs): boolean {
    const { entity, bindingContext } = storage.data;
    if (bindingContext.getEntityType().getName() === EntityTypeName.VatClassification) {
        return !!(entity as IVatClassificationEntity).IsVatRegistered;
    }
    return VatStatusCode.NotVATRegistered !== entity.BusinessPartner?.VatStatusCode;
}

export function isCba({ context, storage }: IGetValueArgs): boolean {
    return isCashBasisAccountingCompany(context ?? storage?.context);
}

export function isAssetAcquisition({ context, storage }: IGetValueArgs): boolean {
    const entity: IDocumentEntity = storage.data.entity;
    return entity.CbaCategory?.IsAssetAcquisition === true;
}

export function isProportionalDeduction(code: TValue): boolean {
    return [VatDeductionTypeCode.Proportional, VatDeductionTypeCode.ReducedAndProportional].includes(code as VatDeductionTypeCode);
}

export function hasVatProportionalDeduction({ storage, bindingContext }: IGetValueArgs): boolean {
    const { bindingContext: dataBindingContext } = storage.data;
    const isVatRuleForm = dataBindingContext.getEntityType().getName() === EntityTypeName.VatClassification;
    const rootBc = isVatRuleForm ? dataBindingContext : bindingContext.getParent();
    const bc = rootBc.navigate(VatClassificationEntity.VatDeductionType);
    const code = storage.getValue(bc, { useDirectValue: false });
    return isProportionalDeduction(code);
}

export function hasReverseCharge(args: IGetValueArgs): boolean {
    const { storage } = args;
    const { bindingContext } = storage.data;
    const isVatRuleForm = bindingContext.getEntityType().getName() === EntityTypeName.VatClassification;
    const rootBc = isVatRuleForm ? bindingContext : bindingContext.navigate(VAT_CLASSIFICATION_PATH);
    const bc = rootBc.navigate(VatClassificationEntity.VatReverseCharge);
    const _isVisible = isReverseChargeVisible(args);
    const value = storage.getValue(bc, { useDirectValue: false });
    return _isVisible && value && value !== VatReverseChargeCode.Ne;
}

export function isInAutomatedVatInclusionMode(args: IGetValueArgs): boolean {
    const { storage } = args;
    const { bindingContext } = storage.data;
    const isVatClassification = bindingContext.getEntityType().getName() === EntityTypeName.VatClassification;
    const path = isVatClassification ? VatClassificationEntity.IsInAutomatedVatInclusionMode : AUTOMATED_VAT_INCLUSION_MODE_PATH;
    const fieldBc = storage.data.bindingContext.navigate(path);
    const info = storage.getInfo(fieldBc);
    const _isVisible = isVisible({ storage, bindingContext: fieldBc, info });
    return _isVisible && storage.getValue(fieldBc);
}

export function isDocumentReverseCharged(args: IGetValueArgs): boolean {
    const { storage } = args;
    const bindingContext = storage.data.bindingContext.navigate(DOCUMENT_REVERSE_CHARGE_PATH);
    const info = storage.getInfo(bindingContext);
    const _isVisible = isVisible({ storage, bindingContext, info });
    const reverseCharge = storage.getValue(bindingContext, { useDirectValue: false });
    return _isVisible && reverseCharge && reverseCharge !== VatReverseChargeCode.Ne;
}

function hasVatRule(args: IGetValueArgs): boolean {
    const code = getSavedVatsCode(args);
    return code !== SelectionCode.None;
}

export const getAdditionalSavedVatsItems = (addDefault: boolean, addNone = true, addOwn = true): ISelectItem[] => {
    const additionalItems: ISelectItem[] = [];
    if (addNone) {
        additionalItems.push({
            id: SelectionCode.None,
            label: i18next.t("Document:Vat.None"),
            groupId: "Default",
            additionalData: { SelectionCode: SelectionCode.None }
        });
    }
    if (addOwn) {
        additionalItems.push({
            id: SelectionCode.Own,
            label: i18next.t("Document:AccountAssignment.Own"),
            groupId: "Default",
            additionalData: { SelectionCode: SelectionCode.Own }
        });
    }

    if (addDefault) {
        additionalItems.unshift({
            id: SelectionCode.Default,
            label: i18next.t("Document:AccountAssignment.Default"),
            groupId: "Default",
            additionalData: { SelectionCode: SelectionCode.Default }
        });
    }

    return additionalItems;
};

const isReverseChargeSelectVisible = ifAll(isVatRegisteredEntity, isAgendaDomesticRule, ifAny(isIssuedType, isReceivedType));
const isReverseChargeVisible = ifAny(isReverseChargeSelectVisible, ifAll(isIssuedType, isVatRegisteredEntity, isEeaRule));

const statementSectionRowProps = [
    VatStatementSectionEntity.StandardVatRateRow,
    VatStatementSectionEntity.ReducedVatRateRow,
    VatStatementSectionEntity.AdditionalStandardVatRateRow,
    VatStatementSectionEntity.AdditionalReducedVatRateRow
];

function getUniqValuesJoined<T = unknown>(propNames: (keyof T)[], entity: T): string {
    return propNames.reduce((result, propName) => {
        const current = entity?.[propName];
        if (isDefined(current) && !result.includes(current)) {
            result.push(current);
        }
        return result;
    }, []).join(", ");
}

function vatReverseChangeCodeFormatter(value: TValue): string {
    return value ? i18next.t("Vats:Form.VatReverseChangeCodeDescription", { code: value }) : "";
}

function _getDefPropAsInt<T = unknown>(entity: T, props: (keyof T)[]) {
    const definedProp = props.find(prop => isDefined(entity[prop]));
    return definedProp ? parseInt(entity[definedProp].toString()) : 0;
}

function getAffectedFieldsByType(type: PayableReceivableTypeCode, isLineItem: boolean): IAffectedField[] {
    const affectedFields: IAffectedField[] = [];
    if (!isLineItem) {
        if (type !== PayableReceivableTypeCode.InternalDocument) {
            affectedFields.push({ id: "BusinessPartner/TaxNumber" });
        }
        if (type === PayableReceivableTypeCode.Payable) {
            affectedFields.push({ id: "NumberTheirs" });
        }
    }
    return affectedFields;
}

const isVatRegisteredCompanyForDateFn = (type: PayableReceivableTypeCode) => {
    return (args: IGetValueArgs) => {
        if (args.context.getAppMode() === AppMode.OrganizationSettings) {
            // VatRules configuration is not related to any company, so we behave as it is always VAT payer
            return true;
        }
        if (type === PayableReceivableTypeCode.InternalDocument) {
            // what should be the behavior on internal document?
            return false;
        }

        const datePath = type === PayableReceivableTypeCode.Payable ? DocumentDraftEntity.DateVatDeduction : DocumentDraftEntity.DateTaxableSupply;

        return isVatRegisteredCompany(args.context, args.storage.data.entity[datePath]) === true;
    };
};

/**
 * Validates item in context of valid combination of VatDeductionType and CBA Category settings.
 * Expects LineItem's BindingContext in args
 * @param args
 * @constructor
 */
function proportionalDeductionForCBAValidator(args: IGetValueArgs, testContext: TestContext): boolean | ValidationError {
    const { bindingContext, storage } = args;
    if (isCba(args)) {
        // cash basis account
        const proportionalDeductionBc = bindingContext
                .navigate(DocumentItemEntity.VatClassificationSelection)
                .navigate(DocumentVatClassificationSelectionEntity.VatClassification)
                .navigate(DocumentVatClassificationEntity.VatProportionalDeduction);
        if (hasVatProportionalDeduction({ ...args, bindingContext: proportionalDeductionBc })) {
            const settings = getCBAItemCategory(storage as FormStorage, bindingContext);
            const proportionalDeduction = storage.getValue(proportionalDeductionBc);
            // AssetAcquisition has zero impact on tax, but can apply up to 100 percent of VAT
            const maxPercentage = settings.IsAssetAcquisition ? 100 : settings.TaxPercentage;
            if (proportionalDeduction > maxPercentage) {
                return new ValidationError(i18next.t("Vats:Form.CantUseHigherDeductionThanCbaTaxPercentageError", { max: settings.TaxPercentage }), false, testContext.path);
            }
        }
    }
    return true;
}

export const hasBaseConditionsFilled = (args: IGetValueArgs): boolean => {
    const entity = args.storage.data.entity as IVatClassificationEntity;
    return isDefined(entity.IsVatRegistered) && isDefined(entity.CountryClassificationCode) && isDefined(entity.PayableReceivableTypeCode);
};

export function getVatFieldsDef(isDocument: boolean, type: PayableReceivableTypeCode = null, prefix = ""): TFieldsDefinition {

    const _isInternalDocument = type === PayableReceivableTypeCode.InternalDocument;
    const isItem = prefix.startsWith("Items/");
    // default flags according to definition type (document form vs. Vat rules form)
    const isRequired = isDocument ? (isItem ? false : hasVatClassification) : true;
    const isVisible = isDocument ? hasVatRule : hasBaseConditionsFilled;

    if (_isInternalDocument) {
        // isRequired = false;
        // there is no Vat Section on Internal documents
        return {};
    }
    const removeFromCustomization = {
        useForCustomization: false
    };

    const affectedFields = isDocument ? getAffectedFieldsByType(type, isItem) : [];

    const def: TFieldsDefinition = {
        [`${prefix}VatReverseCharge`]: {
            customizationData: {
                ...(isItem || _isInternalDocument ? removeFromCustomization : {}),
                isRequired: !_isInternalDocument
            },
            label: i18next.t("Vats:Form.VatReverseCharge"),
            type: FieldType.Switch,
            affectedFields: [{ id: VAT_REVERSE_CHARGE_SELECT_PATH }, { id: `${prefix}VatStatementSection` }],
            defaultValue: (args) => {
                const withReverseCharge = isVatRegisteredEntity(args) && isEuRuleExceptAgendaDomestic(args);
                return withReverseCharge ? VatReverseChargeCode.Ano : VatReverseChargeCode.Ne;
            },
            formatter: (val: TValue) => {
                return val && val !== VatReverseChargeCode.Ne;
            },
            isVisible: _isInternalDocument ? false : ifAll(isVisible, isReverseChargeVisible)
        },
        [isItem ? `${prefix}${VAT_REVERSE_CHARGE_SELECT_PATH}` : VAT_REVERSE_CHARGE_SELECT_PATH]: {
            type: FieldType.ComboBox,
            label: i18next.t("Vats:Form.VatReverseChargeCode"),
            fieldSettings: {
                displayName: "Name",
                entitySet: "VatReverseCharges",
                transformFetchedItems: (items: ISelectItem[]) =>
                        // sorts items by Code, but numerically
                        items.sort((a, b) => sortCompareFn(parseInt(a.id as string), parseInt(b.id as string))),
                shouldDisplayAdditionalColumns: true,
                preloadItems: true
            },
            formatter: (val: TValue, args?: IFormatOptions): string => {
                const isSet = val && ![VatReverseChargeCode.Ano, VatReverseChargeCode.Ne].includes(val as VatReverseChargeCode);
                return isSet ? args.item?.Name ?? args.entity.VatReverseCharge?.Name : "";
            },
            width: BasicInputSizes.XL,
            filter: {
                select: `not(Code in ('${VatReverseChargeCode.Ano}','${VatReverseChargeCode.Ne}'))`
            },
            isRequired,
            isVisible: _isInternalDocument ? false : ifAll(isVisible, isReverseChargeSelectVisible, hasReverseCharge),
            columns: [
                { id: "Name" },
                {
                    id: "Code",
                    formatter: vatReverseChangeCodeFormatter
                }
            ],
            customizationData: (isItem || _isInternalDocument ? removeFromCustomization : undefined)
        },
        [`${prefix}VatStatementSection`]: {
            type: FieldType.ComboBox,
            fieldSettings: {
                displayName: "Description",
                transformFetchedItems: (items) =>
                        items.sort((a, b) => {
                            return sortCompareFn(_getDefPropAsInt(a.additionalData, statementSectionRowProps), _getDefPropAsInt(b.additionalData, statementSectionRowProps));
                        }),
                itemsForRender: (items: ISelectItem[], args: IGetValueArgs) => {
                    if (isInternalDocument(args)) {
                        return items;
                    }
                    const _isReceived = isReceivedType(args);
                    const _isReverseCharged = hasReverseCharge(args);
                    const _isInAutomatedVatInclusionMode = isInAutomatedVatInclusionMode(args);
                    return (items || []).filter(item => {
                        const code = item.additionalData?.VatStatementSectionCategoryCode;
                        if (!_isReceived) {
                            return code === VatStatementSectionCategoryCode.DocumentIssued;
                        }
                        if (_isReverseCharged) {
                            return code === VatStatementSectionCategoryCode.DocumentReceivedInReverseChargeMode;
                        }
                        if (_isInAutomatedVatInclusionMode) {
                            return code === VatStatementSectionCategoryCode.DocumentReceivedInAutomatedVATInclusionMode;
                        }

                        return code === VatStatementSectionCategoryCode.DocumentReceived;
                    });
                },
                shouldDisplayAdditionalColumns: true,
                preloadItems: true
            },
            columns: [
                {
                    id: "Description",
                    additionalProperties: [{ id: "VatStatementSectionCategoryCode" }]
                }, {
                    id: BindingContext.localContext("Row"),
                    additionalProperties: statementSectionRowProps.map((id) => ({ id })),
                    formatter: (val: TValue, args?: IFormatOptions): string => {
                        const rowNums = getUniqValuesJoined(statementSectionRowProps, args?.item);
                        return i18next.t("Vats:Form.VatStatementSectionDescription", { rowNums }).toString();
                    }
                }
            ],
            width: BasicInputSizes.XL,
            isRequired,
            isVisible: isVisible,
            customizationData: (isItem ? removeFromCustomization : isDocument ? {} : { dependents: [BaseConditionsPlaceholderPath] })
        },
        [`${prefix}VatControlStatementSection`]: {
            type: FieldType.ComboBox,
            fieldSettings: {
                displayName: "Name",
                transformFetchedItems: (items) =>
                        items
                                .sort((a, b) => compareString(a.additionalData.Name, b.additionalData.Name)),
                itemsForRender: (items: ISelectItem[], args: IGetValueArgs) => {
                    if (isInternalDocument(args)) {
                        return items;
                    }
                    const _automatedVatInclusionMode = isInAutomatedVatInclusionMode(args);
                    const _allowedIds: TSelectItemId[] = [];
                    if (_automatedVatInclusionMode) {
                        _allowedIds.push(isEeaRule(args) ? AutomatedVatInclusionModeEUControlStatementCode : AutomatedVatInclusionModeNonEUControlStatementCode);
                    }
                    return (items || []).filter(item => {
                        // in automatedVatinclusionMode, only AR items are visible regardless document type
                        if (_allowedIds.length) {
                            // Automated Vat Inclusion Mode has only one possible item
                            return _allowedIds.includes(item.id);
                        }
                        // types has to match
                        return isReceivedType({ item }) === isReceivedType(args) || isIssuedType({ item }) === isIssuedType(args);
                    });
                },
                shouldDisplayAdditionalColumns: true,
                preloadItems: true
            },
            defaultValue: (args: IGetValueArgs) => {
                if (isInAutomatedVatInclusionMode(args)) {
                    return isEeaRule(args) ? AutomatedVatInclusionModeEUControlStatementCode
                            : AutomatedVatInclusionModeNonEUControlStatementCode;
                }
                return null;
            },
            columns: [
                {
                    id: "Name",
                    formatter: (val: TValue, args?: IFormatOptions): string => getControlSectionName(args.item?.Name)
                },
                {
                    id: "Description",
                    additionalProperties: [{ id: "PayableReceivableTypeCode" }]
                }
            ],
            affectedFields,
            isDisabled: isInAutomatedVatInclusionMode,
            isRequired: isItem ? false : isRequired,
            isVisible: isVisible,
            customizationData: (isItem ? removeFromCustomization : {})
        },
        [`${prefix}VatDeductionType`]: {
            type: FieldType.ComboBox,
            width: BasicInputSizes.M,
            label: i18next.t("Vats:Form.DeductionAdjustment"),
            fieldSettings: {
                displayName: "Name",
                noRecordText: i18next.t("Vats:Form.WithoutDeductionAdjustment"),
                transformFetchedItems: (items: ISelectItem[], args: IGetValueArgs) => {
                    if (!items) {
                        return [];
                    }
                    const hasReducedDeduction = getCompanyUseVatReducedDeduction(args.storage.context);
                    if (!hasReducedDeduction) {
                        items = items.filter(item => !isReducedDeduction(item.id));
                    }
                    return items;
                }
            },
            isVisible: isItem || _isInternalDocument ? false : ifAll(isVisible, isReceivedType, isUnknownOrVatRegisteredEntity),
            clearIfInvisible: false,
            customizationData: (isItem || _isInternalDocument ? removeFromCustomization : {}),
            affectedFields: [{ id: `${prefix}VatProportionalDeduction` }]
        },
        [`${prefix}VatProportionalDeduction`]: {
            type: FieldType.NumberInput,
            width: BasicInputSizes.M,
            label: i18next.t("Vats:Form.VatProportionalDeduction"),
            isRequired: hasVatProportionalDeduction,
            defaultValue: (args: IGetValueArgs) => {
                if (isCba(args)) {
                    const { CbaCategory } = args.storage.data.entity as IDocumentEntity;
                    if (CbaCategory?.TaxImpact?.Code === CbaCategoryTaxImpactCode.Partial) {
                        return CbaCategory?.TaxPercentage ?? 100;
                    }
                }
                return null;
            },
            fieldSettings: {
                unit: "%",
                showSteppers: true,
                min: 0,
                max: 100
            },
            validator: {
                type: ValidatorType.Number,
                settings: {
                    min: 0,
                    max: 100
                }
            },
            isVisible: (_isInternalDocument) ? false : hasVatProportionalDeduction,
            customizationData: (isItem || _isInternalDocument ? removeFromCustomization : {})
        },
        // DEV-15519 [`${prefix}VatOss`]: {
        //     isRequired,
        //     type: FieldType.ComboBox,
        //     fieldSettings: {
        //         displayName: "Name"
        //     },
        //     isVisible: _isInternalDocument ? false : ifAll(isVisible, isIssuedType, not(isVatRegistered), isEuRuleExceptAgendaDomestic),
        //     customizationData: (isItem || _isInternalDocument ? removeFromCustomization : {})
        // },
        [`${prefix}VatVies`]: {
            isRequired,
            type: FieldType.ComboBox,
            fieldSettings: {
                displayName: "Name",
                shouldDisplayAdditionalColumns: true,
                preloadItems: true
            },
            columns: [
                { id: "Name" },
                { id: "Description" }
            ],
            isVisible: _isInternalDocument ? isVisible : ifAll(isVisible, isIssuedType, isEuRuleExceptAgendaDomestic, isVatRegisteredEntity),
            customizationData: (isItem ? removeFromCustomization : {})
        },
        [`${prefix}IsInAutomatedVatInclusionMode`]: {
            isRequired,
            customizationData: {
                ...(isItem || _isInternalDocument ? removeFromCustomization : {}),
                isRequired: !_isInternalDocument
            },
            type: FieldType.Switch,
            defaultValue: true,
            isVisible: _isInternalDocument ? false : ifAll(isVisible, isReceivedType, not(isAgendaDomesticRule), isVatRegisteredEntity),
            affectedFields: [{ id: `${prefix}VatStatementSection` }],
        }
    };

    if (isDocument && !isItem) {
        def[`${prefix}VatDeductionType`].affectedFields.push({ id: createPath("Items", ITEMS_VAT_DEDUCTION_PATH) });
        if (!def[`${prefix}VatProportionalDeduction`].affectedFields) {
            def[`${prefix}VatProportionalDeduction`].affectedFields = [];
        }
        def[`${prefix}VatProportionalDeduction`].affectedFields.push({ id: createPath("Items", ITEMS_VAT_DEDUCTION_PATH) });
    }

    const addNonTaxAccount = isDocument && type === PayableReceivableTypeCode.Payable;
    if (addNonTaxAccount) {
        def[`${prefix}VatDeductionType`].affectedFields.push({ id: `${prefix}NonTaxAccount` });
        def[`${prefix}NonTaxAccount`] = {
            ...getSingleAccountFieldDef(),
            filter: {
                select: getNonTaxAccountFilter()
            },
            label: i18next.t("Vats:Form.NonTaxAccountNumber"),
            isRequired: hasVatProportionalDeduction,
            isVisible: ifAll(hasVatProportionalDeduction, isAccountAssignmentCompanyValue, isReceivedType),
            customizationData: (isItem || _isInternalDocument ? removeFromCustomization : {})
        };
    }

    return def;
}

function vatClassificationComparisonFunction(origEntity: TRecordAny, entity: TRecordAny, bc: BindingContext): boolean {
    const parentBc = bc.getParent();
    const vc = getBoundValue({
        bindingContext: parentBc.navigate("VatClassificationSelection"),
        data: entity,
        dataBindingContext: bc.getRootParent()
    }) as IDocumentVatClassificationSelectionEntity;
    const ovc = getBoundValue({
        bindingContext: parentBc.navigate("VatClassificationSelection"),
        data: origEntity,
        dataBindingContext: bc.getRootParent()
    }) as IDocumentVatClassificationSelectionEntity;

    if (vc?.SelectionCode === SelectionCode.None && ovc?.SelectionCode === SelectionCode.None) {
        return true;
    }

    return vc?.SelectionCode === ovc?.SelectionCode &&
            vc?.VatClassification?.VatControlStatementSectionCode === ovc?.VatClassification?.VatControlStatementSectionCode &&
            vc?.VatClassification?.VatDeductionTypeCode === ovc?.VatClassification?.VatDeductionTypeCode &&
            vc?.VatClassification?.VatProportionalDeduction === ovc?.VatClassification?.VatProportionalDeduction &&
            vc?.VatClassification?.NonTaxAccount?.Id === ovc?.VatClassification?.NonTaxAccount?.Id &&
            vc?.VatClassification?.VatReverseChargeCode === ovc?.VatClassification?.VatReverseChargeCode &&
            // DEV-15519 vc?.VatClassification?.VatOssCode === ovc?.VatClassification?.VatOssCode &&
            vc?.VatClassification?.VatStatementSectionCode === ovc?.VatClassification?.VatStatementSectionCode &&
            vc?.VatClassification?.VatViesCode === ovc?.VatClassification?.VatViesCode;
}

// definition for ITEMS_VAT_DEDUCTION_PATH field
export function getItemsDeductionDef(type: PayableReceivableTypeCode): TFieldDefinition {
    const affectedFields = getAffectedFieldsByType(type, true);

    return {
        type: FieldType.ComboBox,
        width: FastEntryInputSizes.M,
        label: i18next.t("Vats:Form.VatDeductionAdjustment"),
        isVisible: isReceivedType,
        // set default value, so that the field can be removed in variant configuration
        // real default value is handled dynamically in onAfterLoad
        defaultValue: null,
        comparisonFunction: vatClassificationComparisonFunction,
        backendPath: "VatClassificationSelection",
        affectedFields,
        fieldSettings: {
            isEnum: true,
            additionalItems: getAdditionalSavedVatsItems(true, false, false),
            entitySet: EntitySetName.VatDeductionTypes,
            displayName: "Name",
            noRecordText: i18next.t("Vats:Form.WithoutDeductionAdjustment"),
            transformFetchedItems: (items: ISelectItem[], args: IGetValueArgs) => {
                if (!items) {
                    return [];
                }
                const hasReducedDeduction = getCompanyUseVatReducedDeduction(args.storage.context);
                if (!hasReducedDeduction) {
                    items = items.filter(item => !isReducedDeduction(item.id));
                }
                return items
                        .map(item => ({
                            ...item,
                            additionalData: { ...item.additionalData, SelectionCode: SelectionCode.Own }
                        }));
            },
            getCustomTabularData(val: TValue, args: IFormatOptions): string[] {
                if (isProportionalDeduction(val)) {
                    const { storage, bindingContext } = args;
                    const bc = bindingContext.getParent().navigate("VatClassificationSelection/VatClassification/VatProportionalDeduction");
                    const percentage = storage.getValue(bc);

                    return [`${percentage}%`];
                }
                return null;
            }
        },
        additionalProperties: [
            { id: "VatClassificationSelection/VatClassification/IsInAutomatedVatInclusionMode" },
            { id: "VatClassificationSelection/VatClassification/VatControlStatementSection/Code" },
            // DEV-15519 { id: "VatClassificationSelection/VatClassification/VatOss/Code" },
            { id: "VatClassificationSelection/VatClassification/VatDeductionType/Code" },
            { id: "VatClassificationSelection/VatClassification/VatProportionalDeduction" },
            { id: "VatClassificationSelection/VatClassification/NonTaxAccount/Number" },
            { id: "VatClassificationSelection/VatClassification/NonTaxAccount/Name" },
            { id: "VatClassificationSelection/VatClassification/VatReverseCharge/Code" },
            { id: "VatClassificationSelection/VatClassification/VatStatementSection/Code" },
            { id: "VatClassificationSelection/VatClassification/VatVies/Code" },
            { id: "VatClassificationSelection/VatClassification/Name" },
            { id: "VatClassificationSelection/VatClassification/ChartOfAccounts" }
        ],
        formatter: (val: TValue, args: IFormatOptions): string => {
            const { item, storage } = args;
            const noRecord = i18next.t("Vats:Form.WithoutDeductionAdjustment");
            const defaultSelection = i18next.t("Document:AccountAssignment.Default");
            if (val === SelectionCode.Default) {
                const vatClassPath = createPath(DocumentEntity.VatClassificationSelection, DocumentVatClassificationSelectionEntity.VatClassification);
                const defaultDeduction = storage.getValueByPath(createPath(vatClassPath, DocumentVatClassificationEntity.VatDeductionType));
                if (!defaultDeduction?.Name) {
                    return defaultSelection;
                }
                let defaultLabel = defaultDeduction.Name;
                if (isProportionalDeduction(defaultDeduction?.Code)) {
                    const percentage = storage.getValueByPath(createPath(vatClassPath, DocumentVatClassificationEntity.VatProportionalDeduction));
                    if (NumberType.isValid(percentage)) {
                        defaultLabel += ` | ${percentage}%`;
                    }
                }
                return `(${defaultSelection}) ${defaultLabel}`;
            }
            return item?.label ?? val ? getEnumDisplayValue(EntityTypeName.VatDeductionType, val as VatDeductionTypeCode) : noRecord;
        },
        validator: {
            type: ValidatorType.Custom,
            settings: {
                customValidator: (value: TValue, args: IGetValueArgs, testContext): boolean | ValidationError => {
                    const { storage, bindingContext } = args;
                    const entity = storage.data.entity;

                    if (isReducedDeduction(value) && !getCompanyUseVatReducedDeduction(storage.context)) {
                        return new ValidationError(i18next.t("Vats:Form.CantUseReducedDeductionError"), false, testContext.path);
                    }

                    const accountBc = bindingContext.getParent().navigate("VatClassificationSelection/VatClassification/NonTaxAccount");
                    if (isProportionalDeduction(value) && !storage.getValue(accountBc, { useDirectValue: false })) {
                        return new ValidationError(i18next.t("Vats:Form.InvalidAccountValue"), false, testContext.path);
                    }

                    // in this case field is not even available on form
                    if (entity.DocumentTypeCode === DocumentTypeCode.ProformaInvoiceReceived && !entity.IsTaxDocument) {
                        return true;
                    }

                    // we don't allow combination of custom setting on item level and "None" setting on default rule,
                    // because there would be missing setting for VatStatementSection and VatControlStatementSection
                    if (isVatRegisteredCompanyForDateFn(type) && storage.getValueByPath(SAVED_VATS_PATH) === SelectionCode.None && value !== SelectionCode.Default) {
                        return new ValidationError(i18next.t("Vats:Form.CantUseCustomRuleWithNoRuleOnDocumentLevelError"), false, testContext.path);
                    }

                    // otherwise it's valid
                    return true;
                }
            }
        },
        columns: [{
            id: "Name",
            additionalProperties: []
        }]
    };
}

// definition for SAVED_VATS_PATH field
export function getGeneralSavedVats(type: PayableReceivableTypeCode, isLineItem?: boolean, addNonTaxAccount = false): TFieldDefinition {
    const affectedFields = getAffectedFieldsByType(type, isLineItem);

    const fieldDef: TFieldDefinition = {
        type: FieldType.ComboBox,
        width: isLineItem ? FastEntryInputSizes.M : BasicInputSizes.XL,
        label: i18next.t("Document:Form.VatDefault"),
        isRequired: !isLineItem && type !== PayableReceivableTypeCode.InternalDocument ? isVatRegisteredCompanyForDateFn(type) : false,
        customizationData: {
            isRequired: isInVatPayerVariantContext
        },
        // set default value, so that the field can be removed in variant configuration
        // real default value is handled dynamically in onAfterLoad
        defaultValue: null,
        comparisonFunction: vatClassificationComparisonFunction,
        backendPath: "VatClassificationSelection",
        affectedFields: [
            ...affectedFields,
            { id: `Items/${ITEMS_VAT_DEDUCTION_PATH}`, revalidate: true }
        ],
        fieldSettings: {
            additionalItems: getAdditionalSavedVatsItems(isLineItem),
            entitySet: "VatClassifications",
            displayName: "Name",
            transformFetchedItems: (items) =>
                    (items || []).map(item => ({
                        ...item,
                        additionalData: { ...item.additionalData, SelectionCode: SelectionCode.Copy }
                    })),
            itemsForRender: (items: ISelectItem[], args: IGetValueArgs): ISelectItem[] => {
                if (isInternalDocument(args)) {
                    return items;
                }
                return (items || []).filter(item => isVatItemMatchingEntity(args.storage.data.entity, item));
            }
        },
        additionalProperties: [
            { id: "VatClassificationSelection/VatClassification/IsInAutomatedVatInclusionMode" },
            { id: "VatClassificationSelection/VatClassification/VatControlStatementSection/Code" },
            // DEV-15519 { id: "VatClassificationSelection/VatClassification/VatOss/Code" },
            { id: "VatClassificationSelection/VatClassification/VatDeductionType/Code" },
            { id: "VatClassificationSelection/VatClassification/VatProportionalDeduction" },
            { id: "VatClassificationSelection/VatClassification/NonTaxAccount/Number" },
            { id: "VatClassificationSelection/VatClassification/NonTaxAccount/Name" },
            { id: "VatClassificationSelection/VatClassification/VatReverseCharge/Code" },
            { id: "VatClassificationSelection/VatClassification/VatStatementSection/Code" },
            { id: "VatClassificationSelection/VatClassification/VatVies/Code" },
            { id: "VatClassificationSelection/VatClassification/Name" },
            { id: "VatClassificationSelection/VatClassification/ChartOfAccounts" }
        ],
        collapsedRows: getVatGroupRowsDef({
            prefix: "VatClassificationSelection/VatClassification/",
            addNonTaxAccount
        }),
        columns: [{
            id: "Name",
            additionalProperties: [
                { id: "IsInAutomatedVatInclusionMode" }, { id: "CountryClassificationCode" }, { id: "IsVatRegistered" },
                { id: "VatReverseCharge" }, { id: "VatStatementSection" }, { id: "VatControlStatementSection" },
                /* DEV-15519 { id: "VatOss" },*/ { id: "VatVies" }, { id: "VatStatementSection" },
                { id: "VatDeductionType" }, { id: "VatProportionalDeduction" }, { id: "NonTaxAccountNumber" }, { id: "NonTaxAccountName" }
            ]
        }]
    };

    if (type !== PayableReceivableTypeCode.InternalDocument) {
        fieldDef.filter = {
            select: `PayableReceivableTypeCode eq '${type}'`
        };
    }

    if (!isLineItem) {
        fieldDef.fieldSettings.noRecordText = i18next.t("Common:Select.NoRecord");
    } else {
        // DEV-11162 we don't want to see vat rules on line items, just "own" or "default"
        fieldDef.fieldSettings.items = [];
        fieldDef.fieldSettings.dontDisplayNoDataFound = true;
    }

    return fieldDef;
}

interface IGetTableDefOptions {
    isIssued?: boolean;
    isReceived?: boolean;
    isVatRule?: boolean;
}

export function getVatTableColumnsDef(opts: IGetTableDefOptions): TFieldsDefinition {
    const { isIssued, isReceived, isVatRule } = opts;
    if (!isIssued && !isReceived && !isVatRule) {
        // Internal document -> no vat related column...
        return {};
    }
    const BaseEntity = isVatRule ? VatClassificationEntity : DocumentVatClassificationEntity;
    const _getPath = (path: string): string =>
            isVatRule ? path : createPath(DocumentEntity.VatClassificationSelection, DocumentVatClassificationSelectionEntity.VatClassification, path);
    return {
        [_getPath(BaseEntity.VatReverseCharge)]: {
            formatter: (val: TValue, { entity }: IFormatOptions<IVatClassificationEntity>) => {
                const { VatReverseCharge } = entity;
                if (!VatReverseCharge?.Code || [VatReverseChargeCode.Ne, VatReverseChargeCode.Ano].includes(VatReverseCharge?.Code as VatReverseChargeCode)) {
                    return "";
                }
                return VatReverseCharge.Name;
            }
        },
        ...withDisplayName(_getPath(BaseEntity.VatStatementSection), "Description"),
        ...withDisplayName(_getPath(BaseEntity.VatControlStatementSection), "Description"),
        // DEV-15519 ...withDisplayName(_getPath(BaseEntity.VatOss), "Name"),
        ...(!isReceived ? withDisplayName(_getPath(BaseEntity.VatVies), "Description") : {}),
        // further fields are visible on Received or Internal documents, VatRules
        ...(!isIssued ? {
            [_getPath(BaseEntity.VatProportionalDeduction)]: {
                formatter: (val: TValue) => formatPercent(val)
            },
            [_getPath(BaseEntity.IsInAutomatedVatInclusionMode)]: {
                formatter: (val: TValue, { entity, storage }: IFormatOptions<IVatClassificationEntity>) => {
                    const isInternalDocument = !isIssued && !isReceived && !isVatRule;
                    const args: IGetValueArgs = {
                        data: entity,
                        storage
                    };
                    // todo: change the field to NULLABLE on BE and then just format the value null/true/false and don't check visibility from additional data
                    if (isInternalDocument || !(isReceived || isReceivedType(args)) || isAgendaDomesticRule(args)) {
                        return "";
                    }
                    return formatBooleanValue(!!val);
                },
                additionalProperties: [isVatRule ? { id: `/${_getPath(VatClassificationEntity.PayableReceivableType)}` } : { id: "/BusinessPartner/Country/IsEEA" }]
            }
        } : {})
    };
}

function getControlSectionName(name: string): string {
    if (name === "-") {
        return name;
    }
    return i18next.t("Vats:Form.VatControlStatementSectionDescription", { sections: name }).toString();
}

export function getVatTableFilterDefs(opts: IGetTableDefOptions): Record<string, TFilterDef> {
    const { isReceived, isIssued, isVatRule } = opts;
    if (!isReceived && !isIssued && !isVatRule) {
        // Internal document -> no vat related column...
        return {};
    }
    const BaseEntity = isVatRule ? VatClassificationEntity : DocumentVatClassificationEntity;
    const _getPath = (path: string): string =>
            isVatRule ? path : createPath(DocumentEntity.VatClassificationSelection, DocumentVatClassificationSelectionEntity.VatClassification, path);
    return {
        ...withDisplayName(_getPath(BaseEntity.VatReverseCharge), "Name"),
        ...withDisplayName(_getPath(BaseEntity.VatStatementSection), {
            fieldSettings: {
                displayName: "Name"
            },
            additionalProperties: [{ id: "Description" }],
            formatter: (val: TValue, opts: IFormatOptions<IEntity>): string => {
                const { entity } = opts;
                const section = entity?.Description ? entity : entity.VatClassificationSelection?.VatClassification?.VatStatementSection;
                if (section?.Description || section?.Name) {
                    return [section.Name, section.Description].filter(Boolean).join(" - ");
                }
                return opts.placeholder;
            }
        }),
        ...withDisplayName(_getPath(BaseEntity.VatControlStatementSection), {
            fieldSettings: {
                displayName: "Name"
            },
            additionalProperties: [{ id: "Description" }],
            formatter: (val: TValue, opts: IFormatOptions<IEntity>): string => {
                const { entity } = opts;
                const section = entity?.Name ? entity : entity?.VatClassificationSelection?.VatClassification?.VatControlStatementSection;
                if (section?.Description || section?.Name) {
                    return [section.Name, section.Description].filter(Boolean).join(" - ");
                }
                return opts.placeholder;
            }
        }),
        // DEV-15519 ...withDisplayName(_getPath(BaseEntity.VatOss), "Name"),
        ...(!isReceived ? withDisplayName(_getPath(BaseEntity.VatVies), {
            fieldSettings: {
                displayName: "Name"
            },
            additionalProperties: [{ id: "Description" }],
            formatter: (val: TValue, opts: IFormatOptions<IEntity>): string => {
                const { entity } = opts;
                const section = entity?.Name ? entity : entity?.VatClassificationSelection?.VatClassification?.VatVies;
                if (section?.Description || section?.Name) {
                    return [section.Name, section.Description].filter(Boolean).join(" - ");
                }
                return opts.placeholder;
            }
        }) : {}),
        // further fields are visible on Received or Internal documents, VatRules
        ...(!isIssued ? {
            [_getPath(BaseEntity.VatProportionalDeduction)]: {
                formatter: (val: TValue) => isEmptyValue(val) ? i18next.t("Common:General.Empty").toString() : formatPercent(val)
            },
            [_getPath(BaseEntity.IsInAutomatedVatInclusionMode)]: {}
        } : {})
    };
}

export function getClassificationCodeFromCountry(country: ICountryEntity): CountryClassificationCode {
    switch (true) {
            // keep at the beginning because of priority
            // todo: match with agenda country instead
        case !country || country?.Code === CountryCode.CzechRepublic:
            return CountryClassificationCode.Domestic;
        case country?.IsEEA:
            return CountryClassificationCode.EuropeanUnion;
        default:
            return CountryClassificationCode.OutsideTheEuropeanUnion;
    }
}

/**
 * Filters vats with same flags as selected businessPartner
 * @param data
 * @param item
 */
export function isVatItemMatchingEntity(data: IEntity, item: ISelectItem): boolean {
    if (!item?.additionalData) {
        return false;
    }

    const vatStatus = data.BusinessPartner?.VatStatus?.Code;

    // if VatStatus is not set, we assume BP is VatPayer (e.g. receipt without BusinessPartner)
    if (vatStatus && [VatStatusCode.VATRegistered, VatStatusCode.IdentifiedPerson].includes(vatStatus) !== item.additionalData.IsVatRegistered) {
        return false;
    }

    const docCode = getClassificationCodeFromCountry(data.BusinessPartner?.Country);
    const code = (item.additionalData as IVatClassificationEntity).CountryClassificationCode;

    return code === docCode;
}

function findMatchingItem(storage: FormStorage, vats: ISelectItem[], vat: IEntity): ISelectItem {
    const fields = [
        "IsInAutomatedVatInclusionMode", "VatControlStatementSection/Code", /* DEV-15519 "VatOss/Code",*/
        "VatDeductionType/Code", "VatProportionalDeduction", "NonTaxAccount",
        "VatReverseCharge/Code", "VatStatementSection/Code", "VatVies/Code", "Name"
    ];
    const baseBc = storage.data.bindingContext.navigate("VatClassificationSelection/VatClassification");
    // get only visible values, so we ignore hidden fields with default value, etc...
    const visibleFields = fields.filter(id => {
        const bindingContext = baseBc.navigate(id);
        const info = storage.getInfo(bindingContext);
        return isVisible({ storage, bindingContext, info });
    });
    return vats.find((vatItem: ISelectItem) => {
        const vat1 = vatItem.additionalData as IVatClassificationEntity;
        for (const path of visibleFields) {
            if (path === "NonTaxAccount") {
                // special check for nonTaxAccount
                if (vat.NonTaxAccount?.Number !== vat1.NonTaxAccountNumber || vat.NonTaxAccount?.Name !== vat1.NonTaxAccountName) {
                    return false;
                }
            } else {
                let defaultValue;
                if (path === "VatReverseCharge/Code") {
                    defaultValue = VatReverseChargeCode.Ne;
                }
                const val = getNestedValue(path, vat) ?? defaultValue;
                const val1 = getNestedValue(path, vat1) ?? defaultValue;
                if (val !== val1) {
                    return false;
                }
            }
        }

        return true;
    });
}

export const loadSavedVats = (storage: FormStorage): Promise<ISelectItem[]> => {
    const info = storage.getInfo(storage.data.bindingContext.navigate(SAVED_VATS_PATH));
    if (info) {
        return fetchItemsByInfo(storage, info);
    }

    return null;
};

export const correctVatIds = async (storage: FormStorage): Promise<void> => {
    let vats = await loadSavedVats(storage);

    if (vats) {
        vats = vats.filter(item => isVatItemMatchingEntity(storage.data.entity, item));
        correctVatId(storage, vats, storage.data.entity);

        for (const item of (storage.data.entity.Items || [])) {
            // correctVatId(storage, vats, item);
            correctVatDeduction(item);
        }
    }
};

export function correctVatId(storage: FormStorage, vats: ISelectItem[], data: IEntity, isInit = true): void {
    const code = data.VatClassificationSelection?.Selection?.Code ?? data.VatClassificationSelection?.SelectionCode;

    const vatCodeValue = data.VatClassificationSelection?.VatClassification?.VatReverseCharge?.Code;
    if (vatCodeValue && isInit) {
        setNestedValue(vatCodeValue, VAT_REVERSE_CHARGE_SELECT_PATH, data);
    }

    if ([SelectionCode.Own, SelectionCode.Default, SelectionCode.None].includes(code)) {
        data[SAVED_VATS_PATH] = code;
    }

    if ([SelectionCode.Copy, SelectionCode.Own].includes(code)) {
        const entity = data.VatClassificationSelection?.VatClassification;
        const matchingSelectItem = findMatchingItem(storage, vats, entity);
        if (matchingSelectItem) {
            data[SAVED_VATS_PATH] = matchingSelectItem.id;
            data.VatClassificationSelection.SelectionCode = SelectionCode.Copy;
        } else {
            data[SAVED_VATS_PATH] = SelectionCode.Own;
            data.VatClassificationSelection.SelectionCode = SelectionCode.Own;
        }
    }
}

export function correctVatDeduction(data: IEntity): void {
    const code = data.VatClassificationSelection?.Selection?.Code ?? data.VatClassificationSelection?.SelectionCode;
    const VatClassification = (data.VatClassificationSelection?.VatClassification ?? {}) as IDocumentVatClassificationEntity;

    data[ITEMS_VAT_DEDUCTION_PATH] = code === SelectionCode.Own ? VatClassification.VatDeductionType?.Code
            : SelectionCode.Default;
}

export function isVatRuleSame(r1: IDocumentVatClassificationSelectionEntity, r2: IDocumentVatClassificationSelectionEntity): boolean {
    const { VatClassification: v1 } = r1 ?? {},
            { VatClassification: v2 } = r2 ?? {};
    // todo: Name + Note ??
    if (v1 && v2) {
        return v1.IsInAutomatedVatInclusionMode === v2.IsInAutomatedVatInclusionMode &&
                v1.VatControlStatementSectionCode === v2.VatControlStatementSectionCode &&
                v1.VatDeductionTypeCode === v2.VatDeductionTypeCode &&
                v1.VatOssCode === v2.VatOssCode &&
                v1.VatProportionalDeduction === v2.VatProportionalDeduction &&
                v1.NonTaxAccount?.Number === v2.NonTaxAccount?.Number;
    }
    return (r1?.Selection?.Code ?? r1?.SelectionCode) === (r2?.Selection?.Code ?? r2?.SelectionCode);
}

export const getSelectionCodeDependentField = (navigateFrom = NavigationSource.Parent): IDependentFieldDef => {
    return {
        from: { id: "SelectionCode" },
        to: { id: "VatClassificationSelection/Selection/Code" },
        navigateFrom
    };
};

export function getVatDependentFields(navigateFrom = NavigationSource.Parent): IDependentFieldDef[] {
    const prefix = "VatClassificationSelection/VatClassification/";

    return [
        getSelectionCodeDependentField(navigateFrom),
        {
            from: { id: "VatReverseCharge" },
            to: { id: `${prefix}VatReverseCharge` },
            navigateFrom
        }, {
            from: { id: "VatReverseCharge/Code" },
            to: { id: VAT_REVERSE_CHARGE_SELECT_PATH },
            navigateFrom
        }, {
            from: { id: "VatStatementSection/Code" },
            to: { id: `${prefix}VatStatementSection` },
            navigateFrom
        }, {
            from: { id: "VatControlStatementSection/Code" },
            to: { id: `${prefix}VatControlStatementSection` },
            navigateFrom
        }, {
            from: { id: "VatDeductionTypeCode" },
            to: { id: `${prefix}VatDeductionType` },
            navigateFrom
        }, {
            from: { id: "VatProportionalDeduction" },
            to: { id: `${prefix}VatProportionalDeduction` },
            navigateFrom
        }, {
            // DEV-15519     from: { id: "VatOss" },
            //     to: { id: `${prefix}VatOss` },
            //     navigateFrom
            // }, {
            from: { id: "VatVies" },
            to: { id: `${prefix}VatVies` },
            navigateFrom
        }, {
            from: { id: "VatStatementSection" },
            to: { id: `${prefix}VatStatementSection` },
            navigateFrom
        }, {
            from: { id: "IsInAutomatedVatInclusionMode" },
            to: { id: `${prefix}IsInAutomatedVatInclusionMode` },
            navigateFrom
        }, {
            from: { id: "Name" },
            to: { id: `${prefix}Name` },
            navigateFrom
        }];
}

export interface IVatConfigOptions {
    isInAutomatedVatInclusionMode: boolean;
    isInAutomatedVatInclusionModeVisible: boolean;
    isReverseChargeVisible: boolean;
    defaultVatRate: number;
    isReverseCharged: boolean;
}

export function getVatConfigOptions(storage: FormStorage, documentTypeCode: DocumentTypeCode): IVatConfigOptions {
    const defaultVatRate = documentTypeCode !== DocumentTypeCode.InternalDocument &&
            storage.getDefaultValue<IVatEntity>(storage.data.bindingContext.navigate("Items/Vat"))?.Rate;

    const isInAutomatedVatInclusionModeVisible = isVisibleByPath(storage, AUTOMATED_VAT_INCLUSION_MODE_PATH);
    const isReverseChargeVisible = isVisibleByPath(storage, DOCUMENT_REVERSE_CHARGE_PATH);
    const isReverseCharged = isDocumentReverseCharged({ storage });

    return {
        isInAutomatedVatInclusionMode: isInAutomatedVatInclusionModeVisible && isInAutomatedVatInclusionMode({ storage }),
        isInAutomatedVatInclusionModeVisible,
        defaultVatRate,
        isReverseChargeVisible,
        isReverseCharged
    };
}


export function clearVatClassificationFields(storage: FormStorage, rootBc: BindingContext = storage.data.bindingContext, initiateValues?: boolean): void {
    const fields = getVatRowFields({
        prefix: `${VAT_CLASSIFICATION_PATH}/`,
        addNonTaxAccount: true
    });

    for (const field of fields) {
        const bc = rootBc.navigate(field.id);
        storage.clearValue(bc);
        storage.addActiveField(bc);
        if (initiateValues) {
            storage.setDefaultValueByPath(bc.getNavigationPath());
        }
    }
}

interface IHandleCbaCategoryChange {
    storage: FormStorage;
    e: ISmartFieldChange;
    documentTypeCode: DocumentTypeCode;
}

// when Vat master field has changed, corrects Vat Rule field value
//  - preselects system rule if empty and if the entity is new
//  - clear value if selected rule is not in the list anymore
export const handleCbaCategoryChange = ({ storage, e, documentTypeCode }: IHandleCbaCategoryChange): void => {
    const dataBc = storage.data.bindingContext;

    if (e.bindingContext.getParent().getPath() === DocumentEntity.CbaCategory) {
        const path = e.bindingContext.getPath();

        const taxPercentagePath = createPath(DocumentEntity.CbaCategory, DocumentCbaCategoryEntity.TaxPercentage);
        const isAssetAcquisitionPath = createPath(DocumentEntity.CbaCategory, DocumentCbaCategoryEntity.IsAssetAcquisition);
        const taxImpactPath = createPath(DocumentEntity.CbaCategory, DocumentCbaCategoryEntity.TaxImpact);

        const isCategoryChange = path === DocumentCbaCategoryEntity.Category;
        const cbaCategory: Partial<ICbaCategoryEntity> = isCategoryChange ? e.additionalData : null;
        if (isCategoryChange) {
            if (!isObjectEmpty(cbaCategory)) {
                storage.setValueByPath(taxPercentagePath, cbaCategory.TaxPercentage);
                storage.setValueByPath(taxImpactPath, cbaCategory.TaxImpact);
                storage.setValueByPath(isAssetAcquisitionPath, cbaCategory.IsAssetAcquisition);
            }
        }

        const isTaxImpactChange = e.bindingContext.getNavigationPath() === createPath(DocumentEntity.CbaCategory, DocumentCbaCategoryEntity.TaxImpact);

        if (isTaxImpactChange) {
            storage.setValueByPath(taxPercentagePath, getDefaultPercetageFromImpactCode(e.value as CbaCategoryTaxImpactCode));
            storage.setValueByPath(isAssetAcquisitionPath, false);
        }

        storage.addActiveGroupByKey("Items"); // to refresh category name and percentage in current value on item cba category selection
        storage.addActiveGroupByKey(CASH_BASIS_ACCOUNTING_GROUP_ID);
        const isVatModified = storage.isDirty(dataBc.navigate(DocumentEntity.VatClassificationSelection))
                || storage.isDirty(dataBc.navigate(SAVED_VATS_PATH));

        if (dataBc.isNew() && !isVatModified && documentTypeCode !== DocumentTypeCode.InternalDocument) {
            // default VatRule for new entities
            const taxImpactCode = storage.getValueByPath(taxImpactPath)?.Code;
            const vatAssignmentGroupId = getVatAssignmentGroupId(storage);
            const isReceivedDocumentFromNonVatPayer = isReceived(documentTypeCode) && storage.data.entity?.BusinessPartner?.VatStatusCode === VatStatusCode.NotVATRegistered;

            if (!isReceivedDocumentFromNonVatPayer && (taxImpactCode === CbaCategoryTaxImpactCode.Partial || path === DocumentCbaCategoryEntity.TaxPercentage)) {
                correctVatSelectionFields({
                    storage,
                    documentTypeCode
                })
                        .then(() => {
                            // partial -> percentage is changed -> synchronize with the VatRule
                            const deductionTypePath = createPath(DocumentEntity.VatClassificationSelection, DocumentVatClassificationSelectionEntity.VatClassification, DocumentVatClassificationEntity.VatDeductionType);

                            // change default Vat Rule according to Evidence category
                            storage.setValueByPath(deductionTypePath, VatDeductionTypeCode.Proportional, true);
                            storage.setDefaultValueByPath("VatClassificationSelection/VatClassification/VatProportionalDeduction");
                            correctDataAfterVatChildrenChangeOnDocument(storage);
                            storage.refreshGroupByKey(vatAssignmentGroupId);
                        });
            } else if (taxImpactCode === CbaCategoryTaxImpactCode.Nontax && !storage.getValueByPath(isAssetAcquisitionPath)) {
                const savedVatsBc = storage.data.bindingContext.navigate(SAVED_VATS_PATH);

                // update selectionCode to own if children changes
                storage.setValue(savedVatsBc, SelectionCode.None);
                storage.clearValueByPath(createPath(DocumentEntity.VatClassificationSelection, DocumentVatClassificationSelectionEntity.VatClassification));
            } else {
                // back to "Tuzemske plneni"
                correctVatSelectionFields({
                    storage,
                    documentTypeCode
                });
            }

            storage.addActiveGroupByKey(vatAssignmentGroupId);
        }
    }
};

export const getVatAssignmentGroupId = memoizeOne((storage: FormStorage) => {
    const { data } = storage;
    const groups = storage.getLocalStorageVariant() ?? data.variants?.currentVariant?.formGroups ?? data.definition.groups;
    const vatsGroup = groups?.find(group =>
            !!group.rows?.find(row => !!row.find(itemDef => itemDef.id === SAVED_VATS_PATH)));
    return vatsGroup?.id ?? VAT_ASSIGNMENT_GROUP_ID;
}, (storage: FormStorage) => [storage.data.variants?.currentVariant?.formGroups, storage.getLocalStorageVariant()]);

export const correctDataAfterVatChildrenChangeOnDocument = (storage: FormStorage): void => {
    const { bindingContext } = storage.data;
    const savedVatsBc = bindingContext.navigate(SAVED_VATS_PATH);

    // update selectionCode to own if children changes
    storage.setValue(savedVatsBc, SelectionCode.Own);

    // We copy name and compare it to match with exact rule (two rules might have same values),
    // however if user changes any of the children manually, we break the relation and there will be always "Own"
    storage.clearValue(bindingContext.navigate("VatClassificationSelection/VatClassification/Name"));
    storage.clearAdditionalFieldData(savedVatsBc);
};

interface ICorrectVatSelectionFields {
    storage: FormStorage;
    documentTypeCode: DocumentTypeCode;
    forceDefaultValue?: boolean;
}

export function getDefaultPropName(documentTypeCode: DocumentTypeCode): BusinessPartnerEntity.ReceivedDocumentDefault | BusinessPartnerEntity.IssuedDocumentDefault {
    return isReceived(documentTypeCode) ? BusinessPartnerEntity.ReceivedDocumentDefault : BusinessPartnerEntity.IssuedDocumentDefault;
}

export function getDefaultVatRuleFromBP(documentBusinessPartner: IDocumentBusinessPartnerEntity, documentTypeCode: DocumentTypeCode): IVatClassificationEntity {
    return documentBusinessPartner.BusinessPartner?.[getDefaultPropName(documentTypeCode)]?.VatClassification;
}

//  - clear ControlStatement and Statement select if their values are not present anymore
export const correctVatSelectionFields = async ({
                                                    storage,
                                                    documentTypeCode,
                                                    forceDefaultValue
                                                }: ICorrectVatSelectionFields): Promise<void> => {
    const { bindingContext, entity } = storage.data;
    const bc = bindingContext.navigate(SAVED_VATS_PATH);
    const isDisabled = isFieldDisabled(storage.getInfo(bc), storage, bc);
    const isDirty = storage.isDirty(bc);
    const isNotSaved = isNotSavedDocument(storage);
    const valueIsNotSet = !storage.getValue(bc, { useDirectValue: false });
    const shouldDefaultValue = (isNotSaved || valueIsNotSet || forceDefaultValue) && !isDisabled && !isDirty;

    const isReceivedDocumentFromNonVatPayer = isReceived(documentTypeCode) && storage.data.entity?.BusinessPartner?.VatStatusCode === VatStatusCode.NotVATRegistered;
    const BPDefaultVatRule = getDefaultVatRuleFromBP(entity.BusinessPartner, documentTypeCode);
    const shouldClearVatSelection = shouldDefaultValue && isObjectEmpty(BPDefaultVatRule) && isReceivedDocumentFromNonVatPayer;


    if (!isVisibleByPath(storage, SAVED_VATS_PATH)) {
        return;
    }

    if (!isVatRegisteredCompany(storage.context)) {
        // set empty as default value when vat is not supposed to be used
        storage.setValue(bindingContext.navigate(SAVED_VATS_PATH), SelectionCode.None);
        return;
    }

    const _getValueAndItemsFromBc = async (bc: BindingContext): Promise<{
        value?: TValue;
        items?: ISelectItem[]
    }> => {
        const info = storage.getInfo(bc);
        if (!info) {
            return {};
        }
        const value = storage.getValue(bc, { useDirectValue: false });
        const items = await getRenderedItemsByInfo(storage, info, bc);
        return { value, items };
    };

    const _hasInvalidValue = (value: TValue, items: ISelectItem[]) => value && !items.find(item => item.id === value);

    const _clearValueIfInvalid = async (bc: BindingContext): Promise<void> => {
        const { value, items } = await _getValueAndItemsFromBc(bc);
        if (_hasInvalidValue(value, items)) {
            storage.clearValue(bc);
        }
    };

    const _check = async (rootBc: BindingContext) => {
        const savedVatsBc = rootBc.navigate(SAVED_VATS_PATH);
        const { value, items } = await _getValueAndItemsFromBc(savedVatsBc);
        const hasInvalidValue = _hasInvalidValue(value, items);
        const defaultRule = getDefaultVatRuleFromItems(items, BPDefaultVatRule);
        if (shouldClearVatSelection) {
            storage.setValue(savedVatsBc, SelectionCode.None);
            clearVatClassificationFields(storage, rootBc, false);
        } else if (defaultRule && (shouldDefaultValue || hasInvalidValue)) {
            // preselect system rule
            storage.setValue(savedVatsBc, defaultRule.id);
            storage.processDependentField(getVatDependentFields(), defaultRule.additionalData, savedVatsBc);
            storage.refreshGroupByKey(getVatAssignmentGroupId(storage));
        } else if (hasInvalidValue) {
            storage.clearValue(savedVatsBc);
            clearVatClassificationFields(storage, rootBc, true);
        } else if (value === SelectionCode.Own) {
            const promises = [VatClassificationEntity.VatControlStatementSection, VatClassificationEntity.VatStatementSection]
                    .map(path => _clearValueIfInvalid(rootBc.navigate(`${VAT_CLASSIFICATION_PATH}/${path}`)));
            await Promise.all(promises);
        }
        // force to filter items in itemFactory, so they are correctly displayed in select
        storage.addActiveField(savedVatsBc);
    };

    const allChecks = [_check(bindingContext)];

    for (const item of bindingContext.iterateNavigation("Items", entity?.Items ?? [])) {
        allChecks.push(_check(item.bindingContext));
    }

    await Promise.all(allChecks);
};
