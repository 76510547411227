import { getDaysUnit, ifAll, IFieldDefFn, IGetValueArgs, not } from "@components/smart/FieldInfo";
import { ActionButtonPosition } from "@components/smart/smartFastEntryList";
import { FilterBarGroup, getDefaultFilterGroupDef } from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { IFormGroupDef } from "@components/smart/smartFormGroup/SmartFormGroup";
import { ISummaryItem } from "@components/smart/smartSummaryItem/SmartSummaryItem";
import {
    EntitySetName,
    EntityTypeName,
    PrEmploymentEntity,
    PrEmploymentExtraSalaryEntity,
    PrEmploymentExtraSalaryTemporalCurrentEntity,
    PrEmploymentExtraSalaryTemporalEntity,
    PrEmploymentSalaryComponentEntity,
    PrEmploymentSalaryComponentTemporalCurrentEntity,
    PrEmploymentSalaryComponentTemporalEntity,
    PrEmploymentTemplateEntity,
    PrEmploymentTemplateExtraSalaryEntity,
    PrEmploymentTemplateExtraSalaryTemporalCurrentEntity,
    PrEmploymentTemplateExtraSalaryTemporalEntity,
    PrEmploymentTemplateSalaryComponentEntity,
    PrEmploymentTemplateSalaryComponentTemporalCurrentEntity,
    PrEmploymentTemplateSalaryComponentTemporalEntity,
    PrEmploymentTemplateTemporalEntity,
    PrEmploymentTemporalEntity,
    PrExtraSalaryComputationTypeEntity,
    PrExtraSalaryEntity,
    PrGuaranteedSalaryDegreeEntity,
    PrSalaryComponentEntity,
    PrSalaryComponentTypeEntity
} from "@odata/GeneratedEntityTypes";
import { PrExtraSalaryComputationTypeCode, PrSalaryComponentTypeCode } from "@odata/GeneratedEnums";
import {
    getTemporalAdditionalProperties,
    getTemporalPropertyFieldDefinition,
    getTemporalPropertyLineItemFieldDefinition,
    TemporalValidityFieldsDef
} from "@odata/TemporalUtils";
import { getCompanyCurrency } from "@utils/CompanyUtils";
import i18next from "i18next";
import React from "react";

import { DASH_CHARACTER } from "../../../constants";
import { BasicInputSizes, FastEntryInputSizes, FieldType, LabelStatus, Sort } from "../../../enums";
import { Model } from "../../../model/Model";
import BindingContext, { createPath } from "../../../odata/BindingContext";
import CurrencyType from "../../../types/Currency";
import { IFormDef } from "../../../views/formView/Form";
import { ISplitPageTableDef } from "../../../views/table/TableView.utils";
import { setDefByEntityType } from "../../getDefByEntityType";
import {
    getNumberOursSummaryDef,
    getNumberRangeFieldDefs,
    NumberRangeAdditionalProperties
} from "../../numberRange/NumberRange.utils";
import { getItemBreadCrumbsText, IDefinition, IGetDefinition, TFieldsDefinition } from "../../PageUtils";
import {
    DialogType,
    EmploymentTranslationFiles,
    ExtraSalaryGrpId,
    ExtraSalaryTogglePath,
    getExtractHistoryFromTemplateFn,
    getExtraSalaryTemporalDialogSettings,
    getSalaryComponentTemporalDialogSettings,
    isLegislativeExtraSalary,
    LegislativeExtraSalaryLocalPath,
    SalaryComponentGrpId
} from "../employment/Employment.utils";
import CollapsibleSectionToggleField from "../extraSalary/CollapsibleSectionToggleField";
import { getExtraSalaryValueUnit } from "../extraSalary/ExtraSalary.utils";
import LegislativeExtraSalariesSmartFastEntryList from "../extraSalary/LegislativeExtraSalariesSmartFastEntryList";
import EmploymentTemplateFormView from "./EmploymentTemplateFormView";


function getLineItemBindingContext(bc: BindingContext, collectionName: string): BindingContext {
    do {
        bc = bc.getParent();
    } while (bc.getPath(true) !== collectionName);
    return bc;
}

// function isLineItemWithoutDefault(collectionName: string, { storage, bindingContext }: IGetValueArgs): boolean {
//     const lineItemBc = getLineItemBindingContext(bindingContext, collectionName);
//     const lineItem = storage.getValue(lineItemBc);
//     return lineItem && (isNotDefined(lineItem.Default) || isEmpty(lineItem.Default));
// }

// const isExtraSalaryWithoutDefault = isLineItemWithoutDefault.bind(null, PrEmploymentTemplateEntity.ExtraSalaries);
// const isSalaryComponentWithoutDefault = isLineItemWithoutDefault.bind(null, PrEmploymentTemplateEntity.SalaryComponents);

export function isPartOfLegislativeExtraSalaryItem({ storage, bindingContext }: IGetValueArgs): boolean {
    const bc = getLineItemBindingContext(bindingContext, PrEmploymentTemplateEntity.ExtraSalaries);
    return isLegislativeExtraSalary({ storage, bindingContext: bc });
}

function areExtraSalariesToggled({ storage }: IGetValueArgs): boolean {
    return storage.getValueByPath(ExtraSalaryTogglePath);
}

export const getCommonEmploymentDefs = (isTemplate: boolean): TFieldsDefinition => {

    const tmpFieldsDef = { ...TemporalValidityFieldsDef };

    const TemporalEntity = isTemplate ? PrEmploymentTemplateTemporalEntity : PrEmploymentTemporalEntity;
    const MainEntity = isTemplate ? PrEmploymentTemplateEntity : PrEmploymentEntity;
    const SalaryComponentTemporalEntity = isTemplate ? PrEmploymentTemplateSalaryComponentTemporalEntity : PrEmploymentSalaryComponentTemporalEntity;
    const ExtraSalaryTemporalEntity = isTemplate ? PrEmploymentTemplateExtraSalaryTemporalEntity : PrEmploymentExtraSalaryTemporalEntity;

    return {
        ...getTemporalPropertyFieldDefinition({
            propName: TemporalEntity.LeaveDays,
            definition: {
                width: BasicInputSizes.M,
                fieldSettings: {
                    unit: ({ storage, bindingContext }): string => {
                        const value = storage.getValue(bindingContext) ?? 0;
                        const hours = isNaN(value) ? DASH_CHARACTER : value * 8;
                        return storage.t("Employment:TemplateForm.LeaveDaysUnit", { hours }).toString();
                    },
                    temporalDialog: {
                        dialogTitle: i18next.t("Employment:TemplateForm.PlanLeaveDaysChange"),
                        extractHistory: getExtractHistoryFromTemplateFn(TemporalEntity.LeaveDays)
                    }
                }
            },
            isSynchronized: true
        }),
        ...getTemporalPropertyFieldDefinition({
            propName: TemporalEntity.SickDays,
            definition: {
                width: BasicInputSizes.S,
                fieldSettings: {
                    unit: getDaysUnit,
                    temporalDialog: {
                        dialogTitle: i18next.t("Employment:TemplateForm.PlanSickDaysChange"),
                        extractHistory: getExtractHistoryFromTemplateFn(TemporalEntity.SickDays)
                    }
                }
            },
            isSynchronized: true
        }),
        ...getTemporalPropertyFieldDefinition({
            propName: TemporalEntity.GuaranteedSalaryDegree,
            definition: {
                type: FieldType.ComboBox,
                fieldSettings: {
                    displayName: PrGuaranteedSalaryDegreeEntity.Name,
                    temporalDialog: {
                        dialogTitle: i18next.t("Employment:TemplateForm.PlanGuaranteedSalaryDegreeChange"),
                        extractHistory: getExtractHistoryFromTemplateFn(TemporalEntity.GuaranteedSalaryDegree)
                    }
                }
            },
            isSynchronized: !isTemplate
        }),
        ...getTemporalPropertyFieldDefinition({
            propName: TemporalEntity.WorkingPattern,
            definition: {
                type: FieldType.ComboBox,
                width: BasicInputSizes.XL,
                fieldSettings: {
                    displayName: "Name",
                    shouldDisplayAdditionalColumns: true,
                    preloadItems: true,
                    temporalDialog: {
                        dialogTitle: i18next.t("Employment:TemplateForm.PlanWorkingPatternChange"),
                        extractHistory: getExtractHistoryFromTemplateFn(TemporalEntity.WorkingPattern)
                    }
                },
                columns: [
                    { id: "Name" },
                    { id: "Type/Name" }
                ]
            },
            isSynchronized: !isTemplate
        }),
        ...getTemporalPropertyLineItemFieldDefinition({
            collectionName: MainEntity.SalaryComponents,
            propName: SalaryComponentTemporalEntity.Name,
            definition: {
                width: FastEntryInputSizes.M,
                fieldSettings: {
                    temporalDialog: getSalaryComponentTemporalDialogSettings()
                }
                // isRequired: isSalaryComponentWithoutDefault
            },
            isSynchronized: true
        }),
        ...getTemporalPropertyLineItemFieldDefinition({
            collectionName: MainEntity.SalaryComponents,
            propName: SalaryComponentTemporalEntity.Type,
            definition: {
                type: FieldType.ComboBox,
                width: FastEntryInputSizes.S,
                defaultValue: PrSalaryComponentTypeCode.Monthly,
                fieldSettings: {
                    displayName: PrSalaryComponentTypeEntity.Name
                }
                // isRequired: isSalaryComponentWithoutDefault
            },
            withOpeningButton: false,
            isSynchronized: true
        }),
        ...getTemporalPropertyLineItemFieldDefinition({
            collectionName: MainEntity.SalaryComponents,
            propName: SalaryComponentTemporalEntity.Amount,
            definition: {
                width: FastEntryInputSizes.S,
                fieldSettings: {
                    unit: args => CurrencyType.getCurrencyUnit(getCompanyCurrency(args.context)),
                    temporalDialog: getSalaryComponentTemporalDialogSettings()
                },
                additionalProperties: [
                    { id: `/${MainEntity.SalaryComponents}/${PrSalaryComponentEntity.TemporalPropertyBag}/${SalaryComponentTemporalEntity.Name}` },
                    { id: `/${MainEntity.SalaryComponents}/${PrSalaryComponentEntity.TemporalPropertyBag}/${SalaryComponentTemporalEntity.Type}` },
                    { id: `/${MainEntity.SalaryComponents}/${PrSalaryComponentEntity.TemporalPropertyBag}/${SalaryComponentTemporalEntity.Amount}` }
                ]
                // isRequired: isSalaryComponentWithoutDefault
            },
            withOpeningButton: true,
            isSynchronized: true
        }),
        ...getTemporalPropertyLineItemFieldDefinition({
            collectionName: MainEntity.ExtraSalaries,
            propName: ExtraSalaryTemporalEntity.Name,
            definition: {
                width: BasicInputSizes.L,
                fieldSettings: {
                    temporalDialog: getExtraSalaryTemporalDialogSettings()
                },
                isReadOnly: isPartOfLegislativeExtraSalaryItem
                // isRequired: isExtraSalaryWithoutDefault
            },
            isSynchronized: true
        }),
        ...getTemporalPropertyLineItemFieldDefinition({
            collectionName: MainEntity.ExtraSalaries,
            propName: ExtraSalaryTemporalEntity.ComputationType,
            definition: {
                type: FieldType.ComboBox,
                width: BasicInputSizes.XL,
                defaultValue: PrExtraSalaryComputationTypeCode.AverageHourlySalaryPercentage,
                fieldSettings: {
                    displayName: PrExtraSalaryComputationTypeEntity.Name,
                    temporalDialog: getExtraSalaryTemporalDialogSettings()
                },
                isReadOnly: isPartOfLegislativeExtraSalaryItem
            },
            isSynchronized: true
        }),
        ...getTemporalPropertyLineItemFieldDefinition({
            collectionName: MainEntity.ExtraSalaries,
            propName: ExtraSalaryTemporalEntity.Value,
            definition: {
                width: BasicInputSizes.S,
                fieldSettings: {
                    unit: getExtraSalaryValueUnit,
                    temporalDialog: getExtraSalaryTemporalDialogSettings()
                },
                additionalProperties: [
                    { id: `/${MainEntity.ExtraSalaries}/${PrExtraSalaryEntity.TemporalPropertyBag}/${ExtraSalaryTemporalEntity.Name}` },
                    { id: `/${MainEntity.ExtraSalaries}/${PrExtraSalaryEntity.TemporalPropertyBag}/${ExtraSalaryTemporalEntity.ComputationType}` },
                    { id: `/${MainEntity.ExtraSalaries}/${PrExtraSalaryEntity.TemporalPropertyBag}/${ExtraSalaryTemporalEntity.Value}` }
                ]
                // isRequired: isExtraSalaryWithoutDefault
            },
            withOpeningButton: true,
            isSynchronized: true
        }),
        [LegislativeExtraSalaryLocalPath]: {
            type: FieldType.Custom,
            label: i18next.t("Employment:TemplateForm.LegislativeExtraSalaries"),
            labelStatus: LabelStatus.Removed,
            render: ({ props }: IFieldDefFn) => {
                return (
                    <LegislativeExtraSalariesSmartFastEntryList {...props} />
                );
            },
            customizationData: {
                useForCustomization: false
            }
        },
        [ExtraSalaryTogglePath]: {
            type: FieldType.Custom,
            label: i18next.t("Employment:TemplateForm.OwnExtraSalaries"),
            render: ({ props }: IFieldDefFn) => (
                <CollapsibleSectionToggleField {...props} />
            ),
            customizationData: {
                useForCustomization: false
            }
        },
        ...tmpFieldsDef
    };
};

export const getCommonEmploymentGroupDef = (isTemplate: boolean): IFormGroupDef[] => {

    const TemporalEntity = isTemplate ? PrEmploymentTemplateTemporalEntity : PrEmploymentTemporalEntity;
    const MainEntity = isTemplate ? PrEmploymentTemplateEntity : PrEmploymentEntity;
    const SalaryComponentEntity = isTemplate ? PrEmploymentTemplateSalaryComponentEntity : PrEmploymentSalaryComponentEntity;
    const SalaryComponentTemporalCurrentEntity = isTemplate ? PrEmploymentTemplateSalaryComponentTemporalCurrentEntity : PrEmploymentSalaryComponentTemporalCurrentEntity;
    const ExtraSalaryEntity = isTemplate ? PrEmploymentTemplateExtraSalaryEntity : PrEmploymentExtraSalaryEntity;
    const ExtraSalaryTemporalCurrentEntity = isTemplate ? PrEmploymentTemplateExtraSalaryTemporalCurrentEntity : PrEmploymentExtraSalaryTemporalCurrentEntity;

    return [
        {
            id: "salary",
            title: i18next.t("Employment:TemplateForm.Salary"),
            rows: [[
                { id: createPath(MainEntity.CurrentTemporalPropertyBag, TemporalEntity.GuaranteedSalaryDegree) }
            ]]
        }, {
            id: SalaryComponentGrpId,
            title: i18next.t("Employment:TemplateForm.SalaryComponents"),
            showGroupDivider: false,
            lineItems: {
                collection: MainEntity.SalaryComponents,
                order: "Id",
                temporalDialog: getSalaryComponentTemporalDialogSettings(),
                columns: [
                    { id: createPath(SalaryComponentEntity.CurrentTemporalPropertyBag, SalaryComponentTemporalCurrentEntity.Name) },
                    { id: createPath(SalaryComponentEntity.CurrentTemporalPropertyBag, SalaryComponentTemporalCurrentEntity.Type) },
                    { id: createPath(SalaryComponentEntity.CurrentTemporalPropertyBag, SalaryComponentTemporalCurrentEntity.Amount) }
                ],
                canReorder: false,
                isItemCloneable: false,
                isAddButtonTransparent: true,
                isTheOnlyItemRemovable: true,
                customActionButtons: [{
                    id: DialogType.SalaryComponent,
                    title: i18next.t("Employment:TemplateForm.Pick"),
                    position: ActionButtonPosition.Before,
                    isTransparent: false
                }]
            }
        }, {
            id: ExtraSalaryGrpId,
            title: i18next.t("Employment:TemplateForm.ExtraSalaries"),
            showGroupDivider: false,
            rows: [
                [{ id: LegislativeExtraSalaryLocalPath }],
                [{ id: ExtraSalaryTogglePath }]
            ],
            lineItems: {
                collection: MainEntity.ExtraSalaries,
                order: "Id",
                temporalDialog: getExtraSalaryTemporalDialogSettings(),
                columns: [
                    { id: createPath(ExtraSalaryEntity.CurrentTemporalPropertyBag, ExtraSalaryTemporalCurrentEntity.Name) },
                    { id: createPath(ExtraSalaryEntity.CurrentTemporalPropertyBag, ExtraSalaryTemporalCurrentEntity.ComputationType) },
                    { id: createPath(ExtraSalaryEntity.CurrentTemporalPropertyBag, ExtraSalaryTemporalCurrentEntity.Value) }
                ],
                additionalFields: [
                    { id: createPath(ExtraSalaryEntity.Default, PrExtraSalaryEntity.BaseTypeCode) }
                ],
                canAdd: areExtraSalariesToggled,
                canReorder: false,
                isItemCloneable: false,
                // LegislativeExtraSalaries are rendered separately, so they can be collapsed
                isItemVisible: ifAll(not(isLegislativeExtraSalary), areExtraSalariesToggled),
                isAddButtonTransparent: true,
                isTheOnlyItemRemovable: true,
                customActionButtons: [{
                    id: DialogType.ExtraSalary,
                    title: i18next.t("Employment:TemplateForm.Pick"),
                    position: ActionButtonPosition.Before,
                    isTransparent: false,
                    isVisible: areExtraSalariesToggled
                }]
            }
        }, {
            id: "absence",
            title: i18next.t("Employment:TemplateForm.AbsenceValues"),
            rows: [[
                { id: createPath(MainEntity.CurrentTemporalPropertyBag, TemporalEntity.LeaveDays) },
                { id: createPath(MainEntity.CurrentTemporalPropertyBag, TemporalEntity.SickDays) }
            ]]
        }, {
            id: "workingTime",
            title: i18next.t("Employment:TemplateForm.WorkingTime"),
            rows: [[
                { id: createPath(MainEntity.CurrentTemporalPropertyBag, TemporalEntity.WorkingPattern) }
            ]]
        }
    ];
};

export const getDefinitions: IGetDefinition = (): IDefinition => {
    const table: ISplitPageTableDef = {
        id: `${EntityTypeName.PrEmploymentTemplate}Table`,
        filterBarDef: [{
            ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
            defaultFilters: [
                PrEmploymentTemplateEntity.NumberOurs,
                PrEmploymentTemplateEntity.Name
            ],
            filterDefinition: {
                [PrEmploymentTemplateEntity.NumberOurs]: {},
                [PrEmploymentTemplateEntity.Name]: {}
            },
            isValueHelp: true
        }],
        initialSortBy: [{ id: PrEmploymentTemplateEntity.NumberOurs, sort: Sort.Asc }],
        columns: [
            PrEmploymentTemplateEntity.NumberOurs,
            PrEmploymentTemplateEntity.Name
        ],
        columnDefinition: {
            [PrEmploymentTemplateEntity.NumberOurs]: {},
            [PrEmploymentTemplateEntity.Name]: {}
        },
        title: i18next.t("Employment:Template.Title")
    };

    const summary: ISummaryItem[] = [{
        ...getNumberOursSummaryDef("Employment")
    }];
    const form: IFormDef = {
        id: `${EntityTypeName.PrEmploymentTemplate}Form`,
        translationFiles: getDefinitions.translationFiles,
        getItemBreadCrumbText: (storage: Model) =>
                getItemBreadCrumbsText(storage, i18next.t("Employment:Template.New"), i18next.t("Employment:Template.FormTitle")),
        isDeletable: true,
        formControl: EmploymentTemplateFormView,
        summary,
        additionalProperties: [
            ...getTemporalAdditionalProperties(PrEmploymentTemplateTemporalEntity, PrEmploymentTemplateEntity),
            ...NumberRangeAdditionalProperties,
            {
                id: PrEmploymentTemplateEntity.ExtraSalaries,
                additionalProperties: [
                    {
                        id: PrEmploymentTemplateExtraSalaryEntity.Default,
                        additionalProperties: [
                            { id: PrExtraSalaryEntity.TypeCode },
                            { id: PrExtraSalaryEntity.BaseTypeCode }
                        ]
                    }
                ]
            },
            {
                id: PrEmploymentTemplateEntity.SalaryComponents,
                additionalProperties: [
                    { id: PrEmploymentTemplateSalaryComponentEntity.Default }
                ]
            }
        ],
        fieldDefinition: {
            ...getNumberRangeFieldDefs("Employment"),
            [PrEmploymentTemplateEntity.Name]: {
                width: BasicInputSizes.XL
            },
            ...getCommonEmploymentDefs(true)
        },
        groups: [
            {
                id: "main",
                rows: [[
                    { id: PrEmploymentTemplateEntity.Name }
                ]]
            },
            ...getCommonEmploymentGroupDef(true)
        ]
    };
    return {
        entitySet: EntitySetName.PrEmploymentTemplates,
        table,
        form
    };
};
getDefinitions.translationFiles = [...EmploymentTranslationFiles];
setDefByEntityType(EntityTypeName.PrEmploymentTemplate, getDefinitions);