import { ifAll, ifAny, IGetValueArgs } from "@components/smart/FieldInfo";
import { CompanyBankAccountEntity, EntitySetName, EntityTypeName } from "@odata/GeneratedEntityTypes";
import { CompanyBankAccountTypeCode } from "@odata/GeneratedEnums";
import { getEnumNameSpaceName } from "@odata/GeneratedEnums.utils";
import { isCashBasisAccountingCompany } from "@utils/CompanyUtils";
import { arrayInsert, forEachKey } from "@utils/general";
import i18next from "i18next";

import { BANK_ACCOUNT_BALANCE_SHEET_ACCOUNT_PREFIX } from "../../../constants";
import { IAppContext } from "../../../contexts/appContext/AppContext.types";
import { BasicInputSizes, FieldType, TextAlign } from "../../../enums";
import { IFormDef } from "../../../views/formView/Form";
import { setDefByEntityType } from "../../getDefByEntityType";
import { getDefaultRangeDefinition } from "../../numberRange/NumberRangeFields.utils";
import { IDefinition, IGetDefinition, TFieldsDefinition } from "../../PageUtils";
import {
    getAccountRelatedAccountSettingsFieldsDef,
    getAccountSettingsFormGroup,
    getBankAccountsFieldBaseDef,
    getInitialBalanceFieldsDef,
    getInitialBalanceFormGroup,
    getIsDefaultSwitchDef,
    hasRelatedEntity
} from "./BankAccounts.utils";

function isPaymentService(args: IGetValueArgs) {
    return args.storage.data.entity.CompanyBankAccountType?.Code === CompanyBankAccountTypeCode.PaymentService;
}

function isBankFieldVisible(args: IGetValueArgs): boolean {
    return args.storage.data.entity.CompanyBankAccountType?.Code === CompanyBankAccountTypeCode.Bank;
}

function addAnalyticAccountDefs(context: IAppContext): TFieldsDefinition {
    if (!isCashBasisAccountingCompany(context)) {
        return getAccountRelatedAccountSettingsFieldsDef(BANK_ACCOUNT_BALANCE_SHEET_ACCOUNT_PREFIX);
    }
    return null;
}

export const getDefinitions: IGetDefinition = (context: IAppContext): IDefinition => {

    const { Bank: BankBaseDef, ...restBankFieldDefs } = getBankAccountsFieldBaseDef();

    const form: IFormDef = {
        id: `${EntityTypeName.CompanyBankAccount}Form`,
        translationFiles: getDefinitions.translationFiles,
        additionalProperties: [{
            id: CompanyBankAccountEntity.Logo
        }, {
            id: CompanyBankAccountEntity.IsActive
        }, {
            id: CompanyBankAccountEntity.Currency
        }],
        fieldDefinition: {
            Name: {
                width: BasicInputSizes.L,
                textAlign: TextAlign.Left,
                label: i18next.t("Banks:Accounts.Name")
            },
            Bank: {
                ...BankBaseDef,
                isVisible: ifAll(isBankFieldVisible, BankBaseDef.isVisible),
                isReadOnly: hasRelatedEntity,
                fieldSettings: {
                    ...BankBaseDef.fieldSettings,
                    localDependentFields: [
                        ...BankBaseDef.fieldSettings?.localDependentFields,
                        { from: { id: "LogoId" }, to: { id: "Logo/Id" } }
                    ],
                    entitySet: EntitySetName.Banks
                }
            },
            HasAutoPosting: {
                type: FieldType.Switch,
                isReadOnly: hasRelatedEntity
            },
            BankStatementNumberRangeDefinition: {
                ...getDefaultRangeDefinition(EntityTypeName.BankStatement)
            },
            CompanyBankAccountType: {
                type: FieldType.SegmentedButton,
                defaultValue: CompanyBankAccountTypeCode.Bank,
                fieldSettings: {
                    displayName: "Name",
                    items: [{
                        id: CompanyBankAccountTypeCode.Bank,
                        label: i18next.t("Banks:Accounts.Bank")
                    }, {
                        id: CompanyBankAccountTypeCode.PaymentService,
                        label: i18next.t("Banks:Accounts.OtherService")
                    }]
                },
                affectedFields: [{ id: "AccountNumber" }, { id: "AbaNumber" }, { id: "Bank" }, { id: "IBAN" }, { id: "SWIFT" }, { id: "Country" }, { id: "PaymentServiceID" }, { id: "PaymentServiceName" }],
                isReadOnly: hasRelatedEntity
            },
            PaymentServiceID: {
                isRequired: true,
                isVisible: isPaymentService,
                isReadOnly: hasRelatedEntity
            },
            PaymentServiceName: {
                isRequired: true,
                isVisible: isPaymentService,
                isReadOnly: hasRelatedEntity
            },
            IsDefault: getIsDefaultSwitchDef(),
            ...(addAnalyticAccountDefs(context) ?? {}),
            ...getInitialBalanceFieldsDef()
        },
        groups: [
            {
                id: "main",
                rows: [
                    [
                        { id: CompanyBankAccountEntity.Name },
                        { id: CompanyBankAccountEntity.IsDefault }
                    ],
                    [
                        { id: CompanyBankAccountEntity.BankStatementNumberRangeDefinition }
                    ],
                    [
                        { id: CompanyBankAccountEntity.CompanyBankAccountType }
                    ],
                    [
                        { id: CompanyBankAccountEntity.Country }
                    ],
                    [
                        { id: CompanyBankAccountEntity.AbaNumber },
                        { id: CompanyBankAccountEntity.AccountNumber },
                        { id: CompanyBankAccountEntity.Bank },
                        { id: CompanyBankAccountEntity.IBAN },
                        { id: CompanyBankAccountEntity.SWIFT }
                    ],
                    [
                        { id: CompanyBankAccountEntity.PaymentServiceName },
                        { id: CompanyBankAccountEntity.PaymentServiceID }
                    ]
                ]
            },
            getInitialBalanceFormGroup()
        ]
    };

    if (!isCashBasisAccountingCompany(context)) {
        form.groups = arrayInsert(form.groups, getAccountSettingsFormGroup(), 1);
    }

    forEachKey(restBankFieldDefs, (key) => {
        form.fieldDefinition[key] = {
            ...restBankFieldDefs[key],
            isVisible: ifAll(isBankFieldVisible, restBankFieldDefs[key].isVisible),
            isReadOnly: ifAny(hasRelatedEntity, restBankFieldDefs[key].isReadOnly)
        };
    });

    return {
        entitySet: EntitySetName.CompanyBankAccounts,
        form
    };
};

getDefinitions.translationFiles = [
    "Banks",
    getEnumNameSpaceName(EntityTypeName.Currency),
    getEnumNameSpaceName(EntityTypeName.BankApiStatementImportFrequency),
    getEnumNameSpaceName(EntityTypeName.BankApiStatementImportRange)
];

setDefByEntityType(EntityTypeName.CompanyBankAccount, getDefinitions);