import { IFieldDef, IGetValueArgs } from "@components/smart/FieldInfo";
import { getLinks, withDisplayName } from "@components/smart/GeneralFieldDefinition";
import { IFormGroupDef } from "@components/smart/smartFormGroup/SmartFormGroup";
import { TCellValue } from "@components/table";
import {
    CbaCategoryEntity,
    CbaEntryEntity,
    ClearedStatusEntity,
    DocumentAccountAssignmentEntity,
    DocumentAccountAssignmentSelectionEntity,
    DocumentEntity,
    DocumentLinkEntity,
    EntitySetName,
    EntityTypeName,
    IInternalDocumentItemEntity,
    InternalDocumentEntity,
    InternalDocumentItemEntity
} from "@odata/GeneratedEntityTypes";
import {
    CbaCategoryTaxImpactCode,
    CbaEntryTypeCode,
    CompanyPermissionCode,
    DocumentTypeCode
} from "@odata/GeneratedEnums";
import { getEnumNameSpaceName, getEnumSelectItems } from "@odata/GeneratedEnums.utils";
import { IFormatOptions } from "@odata/OData.utils";
import {
    accruedExpenseFilter,
    accruedRevenueFilter,
    excludeInitialBalancesFilter,
    FUTURE_EXPENSES_ACCOUNT,
    FUTURE_REVENUES_ACCOUNT,
    getOpenAccrualFilter
} from "@utils/accounting";
import { isAccountAssignmentCompany, isCashBasisAccountingCompany, isVatRegisteredCompany } from "@utils/CompanyUtils";
import { arrayInsert, isDefined, isObjectEmpty } from "@utils/general";
import i18next from "i18next";
import { cloneDeep } from "lodash";

import { IAppContext } from "../../../contexts/appContext/AppContext.types";
import { EditableTextSizes, FastEntryInputSizes, FieldType } from "../../../enums";
import { TValue } from "../../../global.types";
import { Model } from "../../../model/Model";
import { createPath } from "../../../odata/BindingContext";
import { getUtcDate } from "../../../types/Date";
import { FormStorage } from "../../../views/formView/FormStorage";
import { IChangedFilter } from "../../../views/table/TableView.utils";
import {
    getAccAssignmentGroup,
    getDocumentFilterByUsedAccountNumber
} from "../../accountAssignment/AccountAssignment.utils";
import { addAssetDef, AssetTranslations } from "../../asset/fixedAsset/FixedAsset.utils";
import { SAVED_ACCOUNTS_PATH } from "../../banks/bankAccounts/BankAccounts.utils";
import { isPairAmountReadOnly } from "../../banks/Pair.utils";
import {
    CBA_CATEGORY_PATH,
    CbaSelectionCode,
    getCashBasisAccountingGroup
} from "../../cashBasisAccounting/CashBasisAccounting.utils";
import { setDefByEntityType } from "../../getDefByEntityType";
import { getItemBreadCrumbsText, IDefinition, IGetDefinition } from "../../PageUtils";
import {
    commonDocumentTranslations,
    getCurrencyUnit,
    readOnlyCurrencyItemFormatter,
    VAT_ASSIGNMENT_GROUP_ID
} from "../Document.utils";
import { addAccountAssignmentTableDefs, draftInfoPath } from "../DocumentCommonDefs";
import { clearEmptyDateGroupDateFields, getDefinitions as getDocumentDefinitions } from "../DocumentDef";
import { addAccrualsDef } from "../extensions/accruals/Accruals.utils";
import { getTimeResTab } from "../extensions/timeResolution/TimeResolution.utils";
import InternalDocumentFormView from "./InternalDocumentFormView";

export const ADD_CLEARING_ITEM = "addClearingItem";
export const ACCRUED_DOCUMENTS_FILTERNAME = "##AccruedDocuments##";
export const EXPENSES_AND_REVENUES_FILTERNAME = "##ExpensesAndRevenuesFilter##";

export enum AccrualFilterTypes {
    Open = "Open",
    Closed = "Closed",
    Passive = "Passive",
    Active = "Active"
}

export enum ExpensesAndRevenuesFilterTypes {
    Expenses = "Expenses",
    Revenues = "Revenues"
}

export const getDefinitions: IGetDefinition = (context: IAppContext): IDefinition => {
    const definition = cloneDeep(getDocumentDefinitions({
        entitySet: EntitySetName.InternalDocuments,
        documentType: DocumentTypeCode.InternalDocument,
        tableId: `${EntityTypeName.InternalDocument}Table`,
        formId: `${EntityTypeName.InternalDocument}Form`,
        formControl: InternalDocumentFormView,
        translationFiles: getDefinitions.translationFiles,
        getItemBreadCrumbText: (storage: Model) =>
            getItemBreadCrumbsText(storage, i18next.t("InternalDocument:Breadcrumbs.New"),
                storage.data.entity?.NumberOurs && i18next.t("InternalDocument:Breadcrumbs.WithNumber", { number: storage.data.entity.NumberOurs })),
        permissions: [CompanyPermissionCode.InternalAndCorrectiveDocuments],
        context
    }));

    const hasAccountAssignment = isAccountAssignmentCompany(context);
    const isCbaCompany = isCashBasisAccountingCompany(context);

    delete definition.form.fieldDefinition[SAVED_ACCOUNTS_PATH];

    definition.form.fieldDefinition[InternalDocumentEntity.TransactionCurrency].isReadOnly = true;

    delete definition.table.additionalProperties;
    definition.form.additionalProperties = [
        ...(definition.form.additionalProperties ?? []),
        { id: createPath(InternalDocumentEntity.DocumentLinks, DocumentLinkEntity.Type) },
        { id: createPath(InternalDocumentEntity.DocumentLinks, DocumentLinkEntity.Amount) },
        { id: createPath(InternalDocumentEntity.DocumentLinks, DocumentLinkEntity.TargetDocument) }
    ];

    definition.table.columns = [
        draftInfoPath,
        InternalDocumentEntity.NumberOurs,
        InternalDocumentEntity.DateCreated,
        InternalDocumentEntity.Amount,
        InternalDocumentEntity.Explanation,
        InternalDocumentEntity.Attachments,
        InternalDocumentEntity.Note
    ];
    definition.table.columnDefinition.Explanation = { id: InternalDocumentEntity.Explanation };
    delete definition.table.tabs;

    definition.table.filterBarDef[0].filterDefinition = {
        ...definition.table.filterBarDef[0].filterDefinition,
        Explanation: {}
    };

    if (hasAccountAssignment) {
        const numberOursIndex = definition.table.columns.findIndex(col => col === InternalDocumentEntity.NumberOurs);

        definition.table.columns = arrayInsert(definition.table.columns, InternalDocumentEntity.DateAccountingTransaction, numberOursIndex + 1);
        definition.table.filterBarDef[0].filterDefinition = {
            ...definition.table.filterBarDef[0].filterDefinition,
            [ACCRUED_DOCUMENTS_FILTERNAME]: {
                label: i18next.t("InternalDocument:Form.Accruals"),
                type: FieldType.MultiSelect,
                fieldSettings: {
                    items: [
                        {
                            label: i18next.t("InternalDocument:Form.OpenAccruals"),
                            id: AccrualFilterTypes.Open
                        },
                        {
                            label: i18next.t("InternalDocument:Form.ClosedAccruals"),
                            id: AccrualFilterTypes.Closed
                        },
                        {
                            label: i18next.t("InternalDocument:Form.ActiveAccruals"),
                            id: AccrualFilterTypes.Active
                        },
                        {
                            label: i18next.t("InternalDocument:Form.PassiveAccruals"),
                            id: AccrualFilterTypes.Passive
                        }
                    ]
                },
                isValueHelp: false,
                filter: {
                    buildFilter: (item: IChangedFilter): string => {
                        const filterValues = (Array.isArray(item.value) ? item.value : [item.value]) as AccrualFilterTypes[];

                        const orFilters = [];
                        const andFilters = [];

                        if (filterValues.includes(AccrualFilterTypes.Active)) {
                            orFilters.push(accruedRevenueFilter);
                        }
                        if (filterValues.includes(AccrualFilterTypes.Passive)) {
                            orFilters.push(accruedExpenseFilter);
                        }

                        const isOpen = filterValues.includes(AccrualFilterTypes.Open);
                        const isClosed = filterValues.includes(AccrualFilterTypes.Closed);
                        if (isOpen && isClosed) {
                            // this means "all" -> use not filter
                        } else if (isClosed) {
                            andFilters.push(getOpenAccrualFilter(false));
                        } else if (isOpen) {
                            andFilters.push(getOpenAccrualFilter());
                        }

                        // if user selects open, closed of both and nothing else, he wants to see accruals (not all ID),
                        // so we add implicitly both conditions for accruals if not present
                        if (!orFilters.length && andFilters.length) {
                            orFilters.push(accruedExpenseFilter, accruedRevenueFilter);
                        }

                        if (orFilters.length) {
                            andFilters.push(`(${orFilters.join(" OR ")})`);
                        }
                        return andFilters.length ? `(${andFilters.join(" AND ")} AND ${excludeInitialBalancesFilter})` : "";
                    }
                }
            },
            [EXPENSES_AND_REVENUES_FILTERNAME]: {
                label: i18next.t("InternalDocument:Form.ExpensesAndRevenues"),
                type: FieldType.MultiSelect,
                fieldSettings: {
                    items: [
                        {
                            label: i18next.t("InternalDocument:Form.Expenses"),
                            id: ExpensesAndRevenuesFilterTypes.Expenses
                        },
                        {
                            label: i18next.t("InternalDocument:Form.Revenues"),
                            id: ExpensesAndRevenuesFilterTypes.Revenues
                        }
                    ]
                },
                isValueHelp: false,
                filter: {
                    buildFilter: (item: IChangedFilter): string => {
                        const filterValues = (Array.isArray(item.value) ? item.value : [item.value]) as ExpensesAndRevenuesFilterTypes[];

                        const filters = [];

                        if (filterValues.includes(ExpensesAndRevenuesFilterTypes.Expenses)) {
                            filters.push(getDocumentFilterByUsedAccountNumber(DocumentAccountAssignmentEntity.CreditAccount, FUTURE_EXPENSES_ACCOUNT));
                        }
                        if (filterValues.includes(ExpensesAndRevenuesFilterTypes.Revenues)) {
                            filters.push(getDocumentFilterByUsedAccountNumber(DocumentAccountAssignmentEntity.DebitAccount, FUTURE_REVENUES_ACCOUNT));
                        }

                        return filters.length ? `((${filters.join(" OR ")}) AND ${excludeInitialBalancesFilter})` : "";
                    }
                }
            }
        };
    } else if (isCbaCompany) {
        definition.table.columnDefinition = {
            ...definition.table.columnDefinition,
            "DateCbaEntry": {},
            ...withDisplayName(InternalDocumentEntity.CbaEntryType)
        };
        definition.table.columns = arrayInsert(definition.table.columns, InternalDocumentEntity.DateCbaEntry, 1);
    }

    definition.table.filterBarDef[0].filterDefinition[createPath(InternalDocumentEntity.Items, InternalDocumentItemEntity.Amount)] = {
        label: i18next.t("Common:General.Amount")
    };

    definition.form.summary = definition.form.summary.filter((item) => item.id !== createPath(InternalDocumentEntity.ClearedStatus, ClearedStatusEntity.Code));

    const itemsGroup = {
        ...definition.form.groups.find(group => group.id === "Items")
    };

    definition.form.fieldDefinition[createPath(InternalDocumentEntity.Items, InternalDocumentItemEntity.Amount)] = {
        label: i18next.t("Common:General.Amount"),
        additionalProperties: [{ id: `/${createPath(InternalDocumentEntity.Items, InternalDocumentItemEntity.TransactionAmount)}` }, { id: `/${createPath(InternalDocumentEntity.Items, InternalDocumentItemEntity.TransactionCurrencyCode)}` }],
        width: FastEntryInputSizes.S,
        isReadOnly: (args: IGetValueArgs) => {
            return isPairAmountReadOnly(args.storage as FormStorage, args.bindingContext);
        },
        formatter: readOnlyCurrencyItemFormatter,
        fieldSettings: {
            unit: getCurrencyUnit
        }
    };

    definition.form.fieldDefinition[createPath(InternalDocumentEntity.Items, InternalDocumentItemEntity.Note)] = {};
    definition.form.fieldDefinition[createPath(InternalDocumentEntity.Items, InternalDocumentItemEntity.LinkedDocument, DocumentEntity.NumberOurs)] = {
        additionalProperties: [{ id: `/${createPath(InternalDocumentEntity.Items, InternalDocumentItemEntity.LinkedDocument, DocumentEntity.DocumentTypeCode)}` }],
        isVisible: args => {
            return args.storage.data.entity?.Items?.some((item: IInternalDocumentItemEntity) => !!item.LinkedDocument?.Id);
        },
        formatter: (val: TValue, args?: IFormatOptions) => {
            const row = args.storage.getValue(args.bindingContext.getParent());
            const code = getLinks(args, [{
                LinkedDocument: row
            }]);

            return code.fragment as TCellValue;
        },
        label: i18next.t("Document:FormTab.PairedDocument"),
        isRequired: false,
        isReadOnly: true,
        customizationData: {
            useForCustomization: hasAccountAssignment
        }
    };
    definition.form.fieldDefinition[createPath(InternalDocumentEntity.Items, InternalDocumentItemEntity.TransactionCurrencyCode)] = {
        clearIfInvisible: false,
        customizationData: {
            useForCustomization: false
        }
    };

    itemsGroup.lineItems.columns = [
        { id: InternalDocumentItemEntity.Description },
        { id: InternalDocumentItemEntity.Amount },
        { id: createPath(InternalDocumentItemEntity.LinkedDocument, DocumentEntity.NumberOurs) }
    ];

    itemsGroup.lineItems.isItemCloneable = args => {
        return isObjectEmpty(args.item.LinkedDocument);
    };

    if (hasAccountAssignment) {
        definition.table.filterBarDef[0].defaultFilters.splice(1, 0, InternalDocumentEntity.DateAccountingTransaction);

        itemsGroup.lineItems.columns = arrayInsert(itemsGroup.lineItems.columns,
            { id: createPath(InternalDocumentItemEntity.AccountAssignmentSelection, DocumentAccountAssignmentSelectionEntity.AccountAssignment) },
            2);

        itemsGroup.lineItems.customActionButtons = [{
            id: ADD_CLEARING_ITEM,
            title: i18next.t("Document:Form.AddClearingItem"),
            isDisabled: args => {
                return args.storage.isDisabled || !!(args.storage as FormStorage).getBackendDisabledFieldMetadata(args.storage.data.bindingContext.navigate("Items"));
            }
        }];
    } else if (isCbaCompany) {
        const additionalItems = definition.form.fieldDefinition[`Items/${CBA_CATEGORY_PATH}`].fieldSettings.additionalItems;
        const ownIndex = additionalItems?.findIndex(item => item.id === CbaSelectionCode.Own);
        const idx = isDefined(ownIndex) ? ownIndex : additionalItems.length - 1;
        if (isVatRegisteredCompany(context)) {
            additionalItems.splice(idx, 0, {
                id: CbaCategoryTaxImpactCode.VATCorrection,
                label: i18next.t("Categories:CbaCategoryTaxImpactCode.VatCorr")
            });
            const taxImpactPaths = [
                createPath(InternalDocumentEntity.CbaCategory, CbaCategoryEntity.TaxImpact),
                createPath(InternalDocumentEntity.Items, InternalDocumentItemEntity.CbaCategory, CbaCategoryEntity.TaxImpact)
            ];
            taxImpactPaths.forEach(path => {
                definition.form.fieldDefinition[path].fieldSettings.items.push({
                    id: CbaCategoryTaxImpactCode.VATCorrection,
                    label: i18next.t("Categories:CbaCategoryTaxImpactCode.VatCorr")
                });
            });
        }

        itemsGroup.lineItems.columns = arrayInsert(itemsGroup.lineItems.columns, { id: CBA_CATEGORY_PATH }, 2);
        definition.table.filterBarDef[0].defaultFilters.splice(1, 0, InternalDocumentEntity.DateCbaEntry);
    }

    definition.form.additionalProperties = [
        ...definition.form.additionalProperties,
        {
            id: createPath(InternalDocumentEntity.Items, InternalDocumentItemEntity.LinkedDocument, DocumentEntity.ExchangeRatePerUnit)
        }, {
            id: createPath(InternalDocumentEntity.Items, InternalDocumentItemEntity.LinkedDocument, DocumentEntity.TransactionAmount)
        }, {
            id: createPath(InternalDocumentEntity.Items, InternalDocumentItemEntity.LinkedDocument, DocumentEntity.ClearedStatusCode)
        }
    ];

    definition.form.fieldDefinition[InternalDocumentEntity.Explanation] = {
        id: "Explanation",
        type: FieldType.EditableText,
        isConfirmable: true,
        isRequired: true,
        width: EditableTextSizes.M,
        fieldSettings: {
            placeholder: i18next.t("InternalDocument:Form.ExplanationPlaceholder")
        }
    };

    const vatsGroup = definition.form.groups.find(group => group.id === VAT_ASSIGNMENT_GROUP_ID);
    // Vat fields should not be in the form by default, but let keep the empty group to make life easier
    // when user want to add some fields
    if (vatsGroup) {
        vatsGroup.rows = [[]];
    }

    const tabsGroup: IFormGroupDef = definition.form.groups.find(group => group.id === "Tabs");

    if (isCbaCompany) {
        const cbaEntryTabDef = tabsGroup.tabs.find(group => group.id.startsWith("cbaEntryLedger_"));
        cbaEntryTabDef.table.parentKey = `${CbaEntryEntity.InternalDocument}/Id`;
    }

    tabsGroup.tabs.push(getTimeResTab(EntitySetName.InternalDocuments, true));

    definition.form.groups = [
        definition.form.groups.find(group => group.id === "Note"),
        vatsGroup,
        itemsGroup,
        tabsGroup
    ].filter(val => val);


    if (hasAccountAssignment) {
        const informationGroupRows: IFieldDef[][] = [[
            { id: InternalDocumentEntity.DateAccountingTransaction },
            { id: InternalDocumentEntity.TransactionCurrency },
            { id: InternalDocumentEntity.Labels }
        ], [
            { id: InternalDocumentEntity.Explanation }
        ]];

        // has to have id "Vat" to be controlled by DocumentFormView.handleAccAssignmentChange
        const informationGroup: IFormGroupDef = {
            id: "Information",
            title: i18next.t("InternalDocument:Form.InformationGroup"),
            isSmallBottomMargin: true,
            rows: informationGroupRows
        };


        definition.form.groups = arrayInsert(definition.form.groups, informationGroup, 1);
        definition.form.groups = arrayInsert(definition.form.groups, {
            ...getAccAssignmentGroup()
        }, 2);

        addAccountAssignmentTableDefs(definition);
    } else if (isCbaCompany) {
        const selectItems = getEnumSelectItems(EntityTypeName.CbaEntryType);
        definition.form.fieldDefinition[InternalDocumentEntity.CbaEntryType] = {
            type: FieldType.SegmentedButton,
            defaultValue: CbaEntryTypeCode.Expense,
            fieldSettings: {
                displayName: "Name",
                items: [
                    selectItems.find(i => i.id === CbaEntryTypeCode.Income),
                    selectItems.find(i => i.id === CbaEntryTypeCode.Expense)
                ]
            }
        };

        definition.form.fieldDefinition[InternalDocumentEntity.DateCbaEntry] = {
            isRequired: true,
            defaultValue: getUtcDate()
        };

        const informationGroup: IFormGroupDef = {
            id: "Information",
            title: i18next.t("InternalDocument:Form.InformationGroup"),
            isSmallBottomMargin: true,
            rows: [[
                { id: InternalDocumentEntity.CbaEntryType },
                { id: InternalDocumentEntity.DateCbaEntry },
                { id: InternalDocumentEntity.TransactionCurrency },
                { id: InternalDocumentEntity.Labels }
            ], [
                { id: InternalDocumentEntity.Explanation }
            ]]
        };

        definition.form.groups = arrayInsert(definition.form.groups, informationGroup, 1);
        definition.form.groups = arrayInsert(definition.form.groups, {
            ...getCashBasisAccountingGroup()
        }, 2);
    }

    addAssetDef(definition);
    addAccrualsDef(definition, { isExpense: true, isRevenue: true });

    return clearEmptyDateGroupDateFields(definition);
};

getDefinitions.translationFiles = ["InternalDocument", ...AssetTranslations, ...commonDocumentTranslations, getEnumNameSpaceName(EntityTypeName.CbaEntryType)];
setDefByEntityType(EntityTypeName.InternalDocument, getDefinitions);