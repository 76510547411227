import { AlertAction } from "@components/alert/Alert";
import { getInfoValue } from "@components/smart/FieldInfo";
import { ISmartFieldBlur, ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { ISmartFormGroupActionEvent } from "@components/smart/smartFormGroup/SmartFormGroup";
import { saveEntity } from "@odata/Data.utils";
import { IRecurringTaskEntity, RecurringTaskEntity } from "@odata/GeneratedEntityTypes";
import { RecurringTaskPeriodCode, RecurringTaskStateCode, RecurringTaskTypeCode } from "@odata/GeneratedEnums";
import { BatchRequest } from "@odata/OData";
import { getOneFetch, isAbortException } from "@utils/oneFetch";
import React from "react";

import BusyIndicator from "../../components/busyIndicator";
import { Button, ButtonGroup } from "../../components/button";
import SmartFormDeleteButton from "../../components/smart/smartFormDeleteButton/SmartFormDeleteButton";
import { REST_API_URL } from "../../constants";
import { AppContext } from "../../contexts/appContext/AppContext.types";
import { withPermissionContext } from "../../contexts/permissionContext/withPermissionContext";
import { Status } from "../../enums";
import BindingContext, { IEntity } from "../../odata/BindingContext";
import DateType, { getUtcDate } from "../../types/Date";
import customFetch, { getDefaultPostParams } from "../../utils/customFetch";
import { FormViewForExtend, IFormViewProps } from "../../views/formView/FormView";
import { DailyRadioButtonIds } from "./RecurringTask.def";
import { RecurringTaskFormViewAction } from "./RecurringTask.utils";
import TaskEditDialog from "./TaskEditDialog";

interface IState {
    isDialogOpen: boolean;
}

class RecurringTaskFormView extends FormViewForExtend<IRecurringTaskEntity, IFormViewProps<IRecurringTaskEntity>, IState> {
    static contextType = AppContext;

    oneFetchNextDate = getOneFetch();

    state: IState = {
        isDialogOpen: false
    };

    shouldComponentUpdate(nextProps: Readonly<IFormViewProps<IRecurringTaskEntity>>, nextState: IState): boolean {
        return this.state.isDialogOpen !== nextState.isDialogOpen;
    }

    onAfterLoad = async () => {
        const storage = this.props.storage;

        if (!storage.data.bindingContext.isNew()) {
            storage.setValueByPath(BindingContext.localContext("NoEnd"), !storage.getValueByPath(RecurringTaskEntity.DateEnd));
            storage.setValueByPath(BindingContext.localContext("DailyRadioGroup"),
                storage.getValueByPath(RecurringTaskEntity.DoesExecuteEveryWeekDay) ? DailyRadioButtonIds.WorkDay : DailyRadioButtonIds.Nth);
            storage.refreshGroupByKey("scheduler");
        } else {
            this.entity.TypeCode = RecurringTaskTypeCode.InvoiceReceived;
            this.entity.DateStart = getUtcDate();
        }

        if (this.entity.StateCode === RecurringTaskStateCode.Paused) {
            this.setPausedAlert();
        }

        this.updateNextExecutionDate();
        return super.onAfterLoad();
    };

    setPausedAlert = (): void => {
        this.props.storage.setFormAlert({
            status: Status.Warning,
            title: this.props.storage.t("RecurringTasks:GoodToKnow"),
            subTitle: this.props.storage.t("RecurringTasks:TaskIsStopped", { date: DateType.localFormat(this.entity.DateLastModified) }),
            action: AlertAction.Close,
            onClose: () => {
                this.props.storage.clearFormAlert();
                this.props.storage.refresh();
            }
        });
    };

    handlePeriodCodeChange = (e: ISmartFieldChange): void => {
        if ([RecurringTaskEntity.PeriodCode, RecurringTaskEntity.Interval].includes(e.bindingContext.getPath() as RecurringTaskEntity)) {
            this.props.storage.refreshGroupByKey("scheduler");
        }
    };

    handleNoEndChange = (e: ISmartFieldChange): void => {
        if (e.bindingContext.getPath() === BindingContext.localContext("NoEnd")) {
            this.props.storage.refreshGroupByKey("scheduler");
            this.props.storage.setValueByPath(RecurringTaskEntity.DateEnd, null);
        }
    };

    updateNextExecutionDate = async (): Promise<void> => {
        try {
            const res = await this.oneFetchNextDate.fetch(`${REST_API_URL}/RecurringTasks/CalculateDateNextExecution`, {
                ...getDefaultPostParams(),
                body: JSON.stringify(this.entity)
            });

            let dateNextExecution: Date;

            if (res.ok && res.status !== 204) {
                dateNextExecution = getUtcDate(await res.json());
            } else {
                dateNextExecution = null;
            }

            this.props.storage.setValueByPath(RecurringTaskEntity.DateNextExecution, dateNextExecution);
            this.props.storage.refreshFields();
        } catch (e) {
            if (isAbortException(e)) {
                return;
            } else {
                throw e;
            }
        }
    };

    handleGroupAction = (args: ISmartFormGroupActionEvent) => {
        switch (args.id) {
            case RecurringTaskFormViewAction.ChooseTask:
            case RecurringTaskFormViewAction.EditTask:
                this.openTaskDialog();
        }
    };

    handleChange(e: ISmartFieldChange): void {
        const path = e.bindingContext.getPath();
        const storage = this.props.storage;

        this.handleNoEndChange(e);
        this.handlePeriodCodeChange(e);
        storage.handleChange(e);

        if ([RecurringTaskEntity.PeriodCode, RecurringTaskEntity.Interval, RecurringTaskEntity.ExecuteEverySpecificDayOfWeek,
            RecurringTaskEntity.ExecuteInMonth, BindingContext.localContext("NoEnd"), BindingContext.localContext("DailyRadioGroup")].includes(path)) {
            this.updateNextExecutionDate();
        }

        // triggerAdditionalTasks === true for select like components has same
        // meaning as triggerAdditionalTasks === undefined for the rest types
        const shouldUpdate = e.triggerAdditionalTasks !== false;
        this.props.storage.refreshFields(shouldUpdate);
    }

    handleBlur = async (e: ISmartFieldBlur) => {
        if (e.wasChanged && e.bindingContext.getPath() !== RecurringTaskEntity.Name) {
            this.updateNextExecutionDate();
        }

        await this.props.storage.handleBlur(e);
        this.props.storage.refreshFields();
    };

    isActive = (): boolean => {
        return this.entity.StateCode === RecurringTaskStateCode.Active || this.entity.State?.Code === RecurringTaskStateCode.Active;
    };

    toggleTaskState = async (): Promise<void> => {
        const storage = this.props.storage;
        const isActive = this.isActive();
        const newStateCode = isActive ? RecurringTaskStateCode.Paused : RecurringTaskStateCode.Active;

        const batch: BatchRequest = this.props.storage.oData.batch();
        batch.beginAtomicityGroup("stateChange");
        saveEntity({
            bindingContext: storage.data.bindingContext,
            entity: { StateCode: newStateCode },
            batch
        });
        const results = await batch.execute();

        if (results[0].status < 300) {
            storage.setValueByPath(RecurringTaskEntity.StateCode, newStateCode);
            storage.setValueByPath(RecurringTaskEntity.State, {
                Code: newStateCode,
                Name: storage.t(`RecurringTasks:${isActive ? "Stopped" : "Running"}`)
            });
            storage.setFormAlert({
                status: Status.Success,
                title: this.props.storage.t(`RecurringTasks:${isActive ? "Stopped" : "Restored"}Alert`),
                onFadeEnd: () => {
                    if (isActive) {
                        this.setPausedAlert();
                    } else {
                        this.props.storage.clearFormAlert();
                    }
                    this.props.storage.refresh();
                }
            });
            this.props.onTableRefreshNeeded?.();
            this.forceUpdate();
        }
    };

    generateDraft = async (): Promise<void> => {
        const storage = this.props.storage;
        await customFetch(`${REST_API_URL}/RecurringTasks/Execute?recurringTaskId=${this.entity.Id}`);
        // todo: handle error
        this.props.onTableRefreshNeeded?.({ refreshRowOnly: true });
        this.entity.Executions.push({});
        if (storage.data.alert) {
            storage.data.alert = null;
            storage.refresh();
        }
        storage.data.alert = {
            status: Status.Success,
            title: storage.t("RecurringTasks:Done"),
            subTitle: storage.t("RecurringTasks:GeneratedOnce"),
            isFullWidth: false,
            isOneLiner: false
        };
        storage.refresh();

    };

    renderButtons(): React.ReactElement {
        const storage = this.props.storage;
        const formDef = storage.data.definition;
        const isFormReadOnly = getInfoValue(formDef, "isReadOnly", {
            storage,
            data: this.entity
        });
        const isNew = storage.data.bindingContext.isNew();
        return (
            <ButtonGroup wrap={"wrap"}>
                <Button onClick={this.props.onCancel}
                        isTransparent>{storage.t("Common:General.Cancel")}</Button>
                {this.isDeletable &&
                    <SmartFormDeleteButton storage={this.props.storage}
                                           onClick={this.handleDelete}/>
                }
                <Button onClick={this.generateDraft}
                        isDisabled={isNew || !this.entity.Id}
                        isTransparent>{storage.t("RecurringTasks:Run")}</Button>
                <Button onClick={this.toggleTaskState}
                        isDisabled={isNew}
                        isTransparent>{storage.t(`RecurringTasks:${this.isActive() ? "Pause" : "Restore"}`)}</Button>
                {!isFormReadOnly &&
                    <Button
                        onClick={this.handleSaveClick}>{storage.t("RecurringTasks:SaveAndSet")}</Button>
                }
            </ButtonGroup>
        );
    }

    onBeforeSave = (): IEntity => {
        const storage = this.props.storage;
        const entity = this.entity;
        const draft = storage.data.entity.DocumentDraft;
        if (!draft?.Id && !draft?.[BindingContext.NEW_ENTITY_ID_PROP]) {
            throw new Error(storage.t("RecurringTasks:DraftIsMandatory"));
        }

        if (storage.data.bindingContext.isNew()) {
            entity.Company = { Id: this.context.getCompany().Id };
            entity.StateCode = RecurringTaskStateCode.Active;
        }

        entity.DoesExecuteEveryWeekDay = entity.PeriodCode === RecurringTaskPeriodCode.Daily &&
            storage.getValueByPath(BindingContext.localContext("DailyRadioGroup")) === DailyRadioButtonIds.WorkDay;

        return entity;
    };

    openTaskDialog = () => {
        this.setState({ isDialogOpen: true });
    };

    handleTaskDialogClose = () => {
        this.setState({ isDialogOpen: false });
        this.props.storage.refreshGroupByKey("Document");
    };

    render() {
        if (!this.isReady()) {
            return <BusyIndicator isDelayed/>;
        }

        return <>
            {this.renderForm()}
            {this.state.isDialogOpen &&
                <TaskEditDialog
                    onClose={this.handleTaskDialogClose}
                    storage={this.props.storage}
                />
            }

        </>;
    }
}

export default withPermissionContext(RecurringTaskFormView);