import { HOTSPOT_ID_ATTR } from "@components/hotspots/Hotspots.utils";
import { addNewLineItem } from "@components/smart/smartFastEntryList";
import { IColumn, TCellValue } from "@components/table";
import { IDocumentEntity, IRegularDocumentItemEntity } from "@odata/GeneratedEntityTypes";
import { CurrencyCode, DocumentTypeCode, SelectionCode } from "@odata/GeneratedEnums";
import {
    isDocumentReverseCharged,
    isInAutomatedVatInclusionMode,
    ITEMS_VAT_DEDUCTION_PATH,
    SAVED_VATS_PATH
} from "@pages/admin/vatRules/VatRules.utils";
import { getCompanyCurrencyCode } from "@pages/companies/Company.utils";
import { getCompanyCurrency, isVatRegisteredCompany } from "@utils/CompanyUtils";
import { isDefined, roundToDecimalPlaces, sortCompareBooleanFn } from "@utils/general";
import Big from "big.js";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import SimpleTable, { ISimpleTableRow } from "../../../../components/simpleTable/SimpleTable";
import { DASH_CHARACTER } from "../../../../constants";
import { AppContext, IAppContext } from "../../../../contexts/appContext/AppContext.types";
import { RowType, TextAlign } from "../../../../enums";
import { ColoredText } from "../../../../global.style";
import BindingContext from "../../../../odata/BindingContext";
import TestIds from "../../../../testIds";
import CurrencyType, { formatCurrency } from "../../../../types/Currency";
import NumberType from "../../../../types/Number";
import { FormStorage } from "../../../../views/formView/FormStorage";
import { calcLineItemValues, isIssued, setDefaultLabelsFromEntityToItems } from "../../Document.utils";
import { IDocumentExtendedEntity } from "../../DocumentInterfaces";
import { IProformaEntity } from "../../proformaInvoices/ProformaInvoice.utils";
import { RELATED_PROFORMA_ADDITIONAL_RESULT_IDX } from "../proforma/Proforma.utils";
import { StyledItemsSummary, StyledRoundText, UnderTableText } from "./ItemsSummary.styles";

export const ItemsSummaryPath = BindingContext.localContext("ItemsSummary");

interface IProps extends WithTranslation {
    storage: FormStorage;
}

function proformaFormatter(value: string): TCellValue {
    return {
        value: (
            <ColoredText color={"C_SEM_text_bad"}>{value}</ColoredText>
        ),
        tooltip: value
    };
}

class ItemsSummary extends React.Component<IProps> {
    static contextType = AppContext;

    constructor(props: IProps) {
        super(props);

        // register ItemsSummary on storage, so that it can be refreshed as any other field
        props.storage.addRef(this, props.storage.data.bindingContext.navigate(ItemsSummaryPath));
    }

    componentWillUnmount() {
        this.props.storage.removeRef(this, this.props.storage.data.bindingContext.navigate(ItemsSummaryPath));
    }

    createRoundingItem = () => {
        // works only on CZK documents
        const { storage } = this.props;
        const document = storage.data.entity as IDocumentExtendedEntity;
        const minorUnit = document.Currency?.MinorUnit ?? 2;

        const total = roundToDecimalPlaces(minorUnit, document.Items.reduce((totalAmount, item) => totalAmount + item.TransactionAmount, 0));
        const roundItemValue = roundToDecimalPlaces(minorUnit, Math.round(total) - total);

        const newValues = calcLineItemValues({
            TransactionAmount: roundItemValue,
            Quantity: 1,
            Vat: 0
        }, "TransactionAmount", "TransactionAmountNet", 2);

        const newItem = addNewLineItem<IRegularDocumentItemEntity>(this.props.storage, "Items", "Items", null, {
            ...newValues,
            Description: "Zaokrouhlení",
            [SAVED_VATS_PATH]: SelectionCode.Default,
            [ITEMS_VAT_DEDUCTION_PATH]: SelectionCode.Default
        });

        if (newItem.AccountAssignmentSelection) {
            newItem.AccountAssignmentSelection.Selection = { Code: SelectionCode.Default };
        }

        newItem.LabelSelection = {
            Selection: {
                Code: SelectionCode.Default
            },
            SelectionCode: SelectionCode.Default
        };
        storage.setDirty(storage.data.bindingContext.navigate("Items").addKey(newItem)); // so it is not removed on save
        setDefaultLabelsFromEntityToItems(storage);
        storage.refresh();
    };

    render() {
        const { storage } = this.props;
        const document = storage.data.entity as IDocumentEntity;
        const items = storage.data.entity.Items as IRegularDocumentItemEntity[];
        const itemsBc = storage.data.bindingContext.navigate("Items");
        const currency = document.Currency?.Code ?? document.CurrencyCode ?? getCompanyCurrencyCode(storage.context);
        const transactionCurrency = document.TransactionCurrency?.Code ?? document.TransactionCurrencyCode;
        const exchangeRate = transactionCurrency === getCompanyCurrency(storage.context) ? 1 : document.ExchangeRatePerUnit;
        const isExchangeRateValid = NumberType.isValid(exchangeRate);
        const rates: Record<string, Pick<IRegularDocumentItemEntity, "TransactionAmount" | "TransactionAmountVat" | "TransactionAmountNet">> = {};
        const isIssuedType = isIssued(this.props.storage.data.bindingContext.getEntityType().getName() as DocumentTypeCode);
        const vatReverseChargeSet = isIssuedType && isDocumentReverseCharged({
            storage: this.props.storage,
            bindingContext: this.props.storage.data.bindingContext
        });
        const automatedVatInclusionModeSet = isInAutomatedVatInclusionMode({
            storage: this.props.storage,
            bindingContext: this.props.storage.data.bindingContext
        });
        const isVatRegistered = isVatRegisteredCompany(this.props.storage.context);

        const minorUnit = document.Currency?.MinorUnit ?? 2;

        const columns: IColumn[] = [
            isVatRegistered ? {
                id: "Vat",
                label: this.props.t("Document:ItemsSummary.Vat"),
                textAlign: TextAlign.Right
            } : null,
            isVatRegistered ? {
                id: "AmountNet",
                label: this.props.t("Document:ItemsSummary.Base"),
                textAlign: TextAlign.Right
            } : null,
            isVatRegistered ? {
                id: "AmountVat",
                label: this.props.t("Document:ItemsSummary.VatSum"),
                textAlign: TextAlign.Right
            } : null,
            { id: "Amount", label: this.props.t("Document:ItemsSummary.Sum"), textAlign: TextAlign.Right }
        ].filter(v => v);

        let isEmpty = true;

        for (const item of (items ?? [])) {
            const itemBc = itemsBc.addKey(item);

            if (storage.getError(itemBc.navigate("TransactionAmountNet")) || storage.getError(itemBc.navigate("TransactionAmountVat")) || storage.getError(itemBc.navigate("TransactionAmount"))) {
                continue;
            }

            const rate = item.Vat?.Rate ?? "0";

            if (!rates[rate]) {
                rates[rate] = {
                    TransactionAmountNet: 0, TransactionAmountVat: 0, TransactionAmount: 0
                };
            }

            // use Transaction values and then multiply them with exchangeRate
            // we cannot use Amount values directly, ItemsSummary is used even for draft and those values doesn't have to be present
            rates[rate].TransactionAmountNet += isNaN(item.TransactionAmountNet) ? 0 : item.TransactionAmountNet;
            rates[rate].TransactionAmountVat += isNaN(item.TransactionAmountVat) ? 0 : item.TransactionAmountVat;
            rates[rate].TransactionAmount += isNaN(item.TransactionAmount) ? 0 : item.TransactionAmount;

            if (isDefined(item.TransactionAmountNet) || isDefined(item.TransactionAmountVat) || isDefined(item.TransactionAmount)) {
                isEmpty = false;
            }
        }

        if (!isExchangeRateValid) {
            isEmpty = true;
        }

        let rows: ISimpleTableRow[];

        if (isEmpty && Object.keys(rates).length) {
            rows = [{
                id: "empty",
                values: {
                    AmountNet: DASH_CHARACTER,
                    AmountVat: DASH_CHARACTER,
                    Amount: DASH_CHARACTER,
                    Vat: DASH_CHARACTER
                }
            }];
        } else {
            rows = Object.keys(rates).map(rate => parseInt(rate)).sort().reverse().map((rate) => {
                const amountNet = roundToDecimalPlaces(minorUnit, rates[rate].TransactionAmountNet * exchangeRate);
                const amountVat = roundToDecimalPlaces(minorUnit, rates[rate].TransactionAmountVat * exchangeRate);
                const amount = new Big(amountNet).add(amountVat).toNumber();
                return {
                    id: rate,
                    values: {
                        AmountNet: formatCurrency(amountNet, currency),
                        AmountVat: isVatRegistered ? formatCurrency(amountVat, currency) : null,
                        Amount: formatCurrency(amount, currency),
                        Vat: `${rate} %`
                    }
                };
            });
        }

        const ddoppSum = {
            TransactionAmountNet: 0,
            TransactionAmount: 0,
            TransactionAmountVat: 0
        };
        let proformaSumAmount = 0;
        const proformaDocuments = ((storage.data.additionalResults[RELATED_PROFORMA_ADDITIONAL_RESULT_IDX] ?? []) as IProformaEntity[])
            .sort((a, b) => sortCompareBooleanFn(a.IsTaxDocument, b.IsTaxDocument)) ?? [];

        if (proformaDocuments.length > 0 && rows.length > 0) {
            rows[rows.length - 1].isUnderlined = true;
        }

        for (const doc of proformaDocuments) {
            if (doc.IsTaxDocument) {
                ddoppSum.TransactionAmount += doc.TransactionAmount;
                ddoppSum.TransactionAmountVat += doc.TransactionAmountVat;
                ddoppSum.TransactionAmountNet += doc.TransactionAmountNet;
            } else {
                proformaSumAmount += roundToDecimalPlaces(minorUnit, doc.TransactionAmountNet * exchangeRate) + roundToDecimalPlaces(minorUnit, doc.TransactionAmountVat * exchangeRate);
            }
        }
        if (ddoppSum.TransactionAmount) {
            const amountNetDdopp = roundToDecimalPlaces(minorUnit, ddoppSum.TransactionAmountNet * exchangeRate);
            const amountVatDdopp = roundToDecimalPlaces(minorUnit, ddoppSum.TransactionAmountVat * exchangeRate);
            const amountDdopp = new Big(amountNetDdopp).add(amountVatDdopp).toNumber();
            rows.push({
                id: `proforma_ddopp`,
                values: {
                    AmountNet: proformaFormatter(formatCurrency(-1 * amountNetDdopp, currency)),
                    AmountVat: proformaFormatter(formatCurrency(-1 * amountVatDdopp, currency)),
                    Amount: proformaFormatter(formatCurrency(-1 * amountDdopp, currency)),
                    Vat: ""
                }
            });
        }
        if (proformaSumAmount > 0) {
            const negProformaSumAmount = new Big(proformaSumAmount).neg().toNumber();
            rows.push({
                id: `proforma`,
                values: {
                    AmountNet: "",
                    AmountVat: "",
                    Amount: proformaFormatter(formatCurrency(negProformaSumAmount, currency)),
                    Vat: ""
                }
            });
        } else if (!isVatRegistered) {
            // for non vatRegistered company without proformaSum,
            // only show the summary row => remove everything else
            rows = [];
        }

        // if (isEmpty && !rows.length) {
        //     return null;
        // }

        const Summary = {
            id: "sum",
            type: RowType.Aggregation,
            values: {
                AmountVat: DASH_CHARACTER,
                Amount: DASH_CHARACTER
            }
        };

        let sumValue = 0;
        let totalAmount = 0;

        if (!isEmpty) {
            const totalAmountVat = new Big(Object.values(rates).reduce((sum, current) => {
                return new Big(sum).add(roundToDecimalPlaces(minorUnit, current["TransactionAmountVat"] * exchangeRate)).toNumber();
            }, 0)).toNumber();
            const totalAmountVatWithDDOPP = new Big(totalAmountVat).minus(roundToDecimalPlaces(minorUnit, ddoppSum["TransactionAmountVat"] * exchangeRate)).toNumber();

            const totalAmountNet = new Big(Object.values(rates).reduce((sum, current) => {
                return new Big(sum).add(roundToDecimalPlaces(minorUnit, current["TransactionAmountNet"] * exchangeRate)).toNumber();
            }, 0)).toNumber();

            const totalAmountNetWithDDOPP = new Big(totalAmountNet).minus(roundToDecimalPlaces(minorUnit, ddoppSum["TransactionAmountNet"] * exchangeRate)).toNumber();

            totalAmount = new Big(totalAmountVat).add(totalAmountNet).toNumber();
            sumValue = new Big(totalAmountVatWithDDOPP).add(totalAmountNetWithDDOPP).minus(proformaSumAmount).toNumber();

            Summary.values["AmountVat"] = formatCurrency(totalAmountVatWithDDOPP, currency);
            Summary.values["Amount"] = formatCurrency(sumValue, currency);
        }

        rows.push(Summary);


        let underTableText = "";

        if (vatReverseChargeSet) {
            underTableText += this.props.t("Document:ItemsSummary.VatReverseChargeInfo");
        } else if (automatedVatInclusionModeSet) {
            underTableText += this.props.t("Document:ItemsSummary.AutomatedVatInclusionInfo");
        }

        if (isExchangeRateValid && transactionCurrency !== getCompanyCurrency(storage.context)) {
            if (underTableText) {
                underTableText += "\n";
            }

            const currencyUsedByCompany = (this.context as IAppContext).getData().currenciesUsedByCompany?.find(c => c.FromCurrencyCode === transactionCurrency);
            const currencyAmount = currencyUsedByCompany?.Amount ?? 1;
            const transactionCurrencyFormatted = formatCurrency(currencyAmount, transactionCurrency);
            const currencyFormatted = CurrencyType.format(exchangeRate * currencyAmount, {
                currency,
                // we don't want to round the currency, but that is not option of Intl
                // => use maximum value possible to avoid rounding
                maximumFractionDigits: 20
            });

            underTableText += this.props.t("Document:ItemsSummary.UsedExchangeRate", { exchangeRate: `${transactionCurrencyFormatted} / ${currencyFormatted}` });
        }

        const itemsDisabledMetadata = storage.getBackendDisabledFieldMetadata(itemsBc);
        // this functionality should be used only for CZK, we dont want to round other currencies
        // also use totalAmount and not sum value, as proforma invoices should not have impact on rounding
        const canRound = transactionCurrency === CurrencyCode.CzechKoruna && !this.props.storage.isReadOnly
                && roundToDecimalPlaces(minorUnit, totalAmount) !== Math.round(totalAmount);
        // rounding should be disabled when Items disabled
        const isRoundDisabled = !!itemsDisabledMetadata;


        return (
            <StyledItemsSummary data-testid={TestIds.ItemsSummaryTable}>
                <SimpleTable
                    columns={columns}
                    rows={rows}
                    disableFocus
                />
                {canRound &&
                    <StyledRoundText
                        {...{ [HOTSPOT_ID_ATTR]: "ItemsSummaryRoundingItem" }}
                        onClick={this.createRoundingItem}
                        isDisabled={isRoundDisabled}>
                        {this.props.t("Document:ItemsSummary.Round")}
                    </StyledRoundText>
                }
                {underTableText &&
                    <UnderTableText data-testid={TestIds.UnderTableText}>{underTableText}</UnderTableText>}
            </StyledItemsSummary>
        );
    }

}

export default withTranslation(["Document"])(ItemsSummary);