import { ConditionType, createFilterRow } from "@components/conditionalFilterDialog/ConditionalFilterDialog.utils";
import { getTableIntentLink } from "@components/drillDown/DrillDown.utils";
import { formatDateToDateString } from "@components/inputs/date/utils";
import { getSimpleBoolSelectItems, IFieldDef, IGetValueArgs } from "@components/smart/FieldInfo";
import { IFormGroupDef, TFormTable } from "@components/smart/smartFormGroup/SmartFormGroup";
import { ISummaryItem } from "@components/smart/smartSummaryItem/SmartSummaryItem";
import { TSmartODataTableStorage } from "@components/smart/smartTable/SmartODataTableBase";
import { TFormatterFn } from "@components/smart/smartTable/SmartTable.utils";
import { TCellValue } from "@components/table";
import { getDocumentTypeCodeFromEntityType, getRouteByDocumentType } from "@odata/EntityTypes";
import {
    ClearingDocumentLinkEntity,
    DocumentDraftEntity,
    DocumentEntity,
    DocumentItemEntity,
    DocumentLinkTypeEntity,
    DocumentTypeEntity,
    EntitySetName,
    EntityTypeName,
    IAccountEntity,
    IClearingDocumentLinkEntity,
    IDocumentEntity,
    IDocumentLockEntity,
    IExchangeRateEntity,
    IFiscalPeriodEntity,
    IFiscalYearEntity,
    ILabelSelectionEntity,
    InternalDocumentEntity,
    IOppositeDocumentLinkEntity,
    IProformaInvoiceIssuedEntity,
    IProformaInvoiceReceivedEntity,
    IUndirectedDocumentLinkEntity,
    IVatEntity,
    LabelSelectionEntity,
    ODataEntityMetadata,
    PaymentDocumentEntity,
    UndirectedDocumentLinkEntity
} from "@odata/GeneratedEntityTypes";
import {
    ClearedStatusCode,
    CompanyPermissionCode,
    CurrencyCode,
    DocumentLinkTypeCode,
    DocumentTypeCode,
    LanguageCode,
    LockTypeCode,
    PostedStatusCode,
    SelectionCode
} from "@odata/GeneratedEnums";
import { getEnumNameSpaceName } from "@odata/GeneratedEnums.utils";
import { ICreateFilterStringSettings, IFormatOptions, transformToODataString } from "@odata/OData.utils";
import { ODataQueryResult } from "@odata/ODataParser";
import {
    getCompanyCurrency,
    isAccountAssignmentCompany,
    isCashBasisAccountingCompany,
    isVatRegisteredCompany
} from "@utils/CompanyUtils";
import { IEntityWithDraft } from "@utils/DraftUtils";
import { isDefined, isObjectEmpty, roundToDecimalPlaces } from "@utils/general";
import memoize from "@utils/memoize";
import Big from "big.js";
import { Dayjs } from "dayjs";
import i18next from "i18next";
import React from "react";
import { ValidationError } from "yup";

import {
    AttachmentIndicatorIcon,
    CorrectiveInvoicesIssuedPlainIcon,
    CorrectiveInvoicesReceivedPlainIcon,
    InternalDocumentsPlainIcon,
    InvoicesIssuedPlainIcon,
    InvoicesReceivedPlainIcon,
    IProps as IIconProps,
    OtherLiabilitiesPlainIcon,
    OtherReceivablesPlainIcon,
    ProformaInvoicesIssuedPlainIcon,
    ProformaInvoicesIssuedTaxPlainIcon,
    ProformaInvoicesReceivedPlainIcon,
    ProformaInvoicesReceivedTaxPlainIcon
} from "../../components/icon";
import { GET_FILE_NAME_URL, NEW_ITEM_DETAIL, REST_API_URL } from "../../constants";
import { IAppContext, IBreadcrumb } from "../../contexts/appContext/AppContext.types";
import { TCompanyPermissions } from "../../contexts/permissionContext/PermissionContext";
import {
    FieldType,
    IconSize,
    PageViewMode,
    PaneStatus,
    QueryParam,
    Sort,
    Status,
    TextAlign,
    ValueType
} from "../../enums";
import { ColoredText, SmallText } from "../../global.style";
import { TRecordAny, TRecordString, TValue } from "../../global.types";
import { Model } from "../../model/Model";
import { StorageModel } from "../../model/StorageModel";
import { ValidationMessage } from "../../model/Validator.types";
import BindingContext, { createPath, IEntity } from "../../odata/BindingContext";
import { ROUTE_INTERNAL_DOCUMENT } from "../../routes";
import { getQueryParameters, removeQueryParam, setQueryParams } from "../../routes/Routes.utils";
import CurrencyType, { formatCurrency, formatCurrencyVariableDecimals } from "../../types/Currency";
import DateType, { getUtcDayjs } from "../../types/Date";
import NumberType, { getScale } from "../../types/Number";
import customFetch from "../../utils/customFetch";
import { IFormDef } from "../../views/formView/Form";
import { getAlertFromError } from "../../views/formView/Form.utils";
import { FormStorage } from "../../views/formView/FormStorage";
import { ITableRefreshNeeded } from "../../views/formView/FormView";
import { VatRuleTranslationFiles } from "../admin/vatRules/VatRules.consts";
import { getActiveFiscalYears, getFiscalYearByDate, isInFYorPeriod } from "../fiscalYear/FiscalYear.utils";
import { getPageViewMode } from "../PageUtils";
import { DocumentStatusLocalPath, getStatusFilterId, StatusCodeEnumTranslations } from "../reports/CommonDefs";
import { GET_EXCHANGE_RATE_URL } from "../reports/exchangeRates/ExchangeRate.utils";
import { DocumentNameStyled, InlineIconWrapper } from "./Document.style";
import { getAgendaCountryCode } from "@pages/companies/Company.shared.utils";
import { IChangedFilter } from "../../views/table/TableView.utils";
import { IFilterQuery } from "../../model/TableStorage";
import Clickable from "@components/clickable";

export interface ILineItemValues {
    Quantity: number;
    TransactionUnitPrice?: number;
    TransactionUnitPriceVat?: number;
    TransactionUnitPriceNet?: number;
    TransactionAmount?: number;
    TransactionAmountNet?: number;
    TransactionAmountVat?: number;
    Vat: number;
}

export const DRAFT_ITEM_ID_PATH = "##DraftItemId##";

export const DefaultDueDays = 14;

export const PAYMENT_ORDER_ACTION = "PaymentOrder";
export const DOC_DRAFT_MASS_ACCOUNTING = "DocumentMassAccounting";
export const ATTACHMENTS_COLUMN_LOCAL_CONTEXT = BindingContext.localContext("Attachments");
export const VAT_ASSIGNMENT_GROUP_ID = "Vats";
export const DOCUMENT_CLEARING_FORM_TAB_ID = "Clearing";

export const metadataRuleTypes: (keyof ODataEntityMetadata["metadata"])[] = ["EnabledPropertyRules", "DisabledPropertyRules"];

export const lineItemsTriggerProps = [
    "Quantity", "Vat", "UnitPrice", "UnitPriceVat", "UnitPriceNet",
    "TransactionAmount", "TransactionAmountNet", "TransactionAmountVat"
];

export enum MetadataLockType {
    UpdatingLockedRecord = "UpdatingLockedRecord",
    CannotEditClearedDocument = "CannotEditClearedDocument",
    DocumentCannotBeUpdatedHasCorrection = "DocumentCannotBeUpdatedHasCorrection",
    UpdatingRecordWithElectronicSubmission = "UpdatingRecordWithElectronicSubmission",
    DocumentCannotBeUpdatedInClosedFiscalYear = "DocumentCannotBeUpdatedInClosedFiscalYear",
    DocumentWithAssetCannotBeModified = "DocumentWithAssetCannotBeModified",
    InternalDocumentCanBeEditedOnlyByInternalFeatureProcessing = "InternalDocumentCanBeEditedOnlyByInternalFeatureProcessing",
    PostedBankTransactionInClosedYearCannotBeModified = "PostedBankTransactionInClosedYearCannotBeModified",
    CashReceiptInClosedYearCannotBeModified = "CashReceiptInClosedYearCannotBeModified",
    CannotEditLinkedDocument = "CannotEditLinkedDocument",
    CannotEditLinkedProforma = "CannotEditLinkedProforma"
}

export function hasMetadataRule(storage: FormStorage, lockType: MetadataLockType): boolean {
    for (const ruleType of metadataRuleTypes) {
        for (const rules of Object.values(storage?.data?.metadata?.metadata?.[ruleType] ?? {})) {
            for (const rule of rules) {
                if (rule.ErrorCode === lockType) {
                    return true;
                }
            }
        }
    }
    return false;
}

const getPriceNetFromPrice = (price: number, vatRate: number) => {
    return price / (1 + vatRate);
};

function getDocumentItemCurrency(storage: Model): CurrencyCode {
    return storage?.data.entity?.TransactionCurrency?.Code ?? getCompanyCurrency(storage.context);
}

export function readOnlyCurrencyItemFormatter(value: TValue, args: IFormatOptions): string {
    if (args.readonly) {
        return formatCurrency(value as number, getDocumentItemCurrency(args.storage));
    }
    const { minorUnit } = getScale(args.bindingContext, args.storage);
    return NumberType.format(value as number, { minimumFractionDigits: minorUnit });
}

export function getCurrencyUnit(args: IGetValueArgs): string {
    return CurrencyType.getCurrencyUnit(getDocumentItemCurrency(args.storage));
}

function roundVatToDecimalPlaces(places = 1, number: number) {
    // VAT has special rounding, we want something like Math.ceil, but with float, so we have to change rounding mode for negative numbers
    // -0.015 => -0.01 but -0.016 => -0.02
    const x = Big(number);
    if (x.gt(0)) {
        return roundToDecimalPlaces(places, number);
    }

    return new Big(roundToDecimalPlaces(places, x.plus(1).toNumber())).minus(1).toNumber();
}

export const calcLineItemValues = (values: ILineItemValues, changedValue: string, lastChangedValue: string, decimalPlaces: number): ILineItemValues => {
    const roundToCurrencyDecimalPlaces = roundToDecimalPlaces.bind(null, decimalPlaces);
    const zeroVatRate = values.Vat / 100;
    const newValues: ILineItemValues = { ...values };

    if (!lastChangedValue) {
        lastChangedValue = "TransactionAmountNet";
    }

    if (newValues.Quantity === 0 && changedValue !== "Quantity") {
        newValues.Quantity = 1;
    }

    const supportedProps = ["TransactionUnitPrice", "TransactionUnitPriceVat", "TransactionUnitPriceNet", "TransactionAmount", "TransactionAmountNet", "TransactionAmountVat"];
    const calcFromProp = (supportedProps.includes(changedValue) ? changedValue : lastChangedValue) as keyof ILineItemValues;

    if (newValues[calcFromProp] === undefined || newValues[calcFromProp] === null || typeof newValues[calcFromProp] !== "number") {
        return values;
    }

    switch (calcFromProp) {
        case "TransactionUnitPrice":
            newValues.TransactionAmountNet = getPriceNetFromPrice(newValues.Quantity * newValues.TransactionUnitPrice, zeroVatRate);
            break;
        case "TransactionUnitPriceVat":
            newValues.TransactionAmountVat = roundVatToDecimalPlaces(decimalPlaces, newValues.Quantity * newValues.TransactionUnitPriceVat);
            break;
        case "TransactionUnitPriceNet":
            newValues.TransactionAmountNet = newValues.TransactionUnitPriceNet * newValues.Quantity;
            break;
        case "TransactionAmount":
            newValues.TransactionAmountNet = getPriceNetFromPrice(newValues.TransactionAmount, zeroVatRate);
            break;
        case "TransactionAmountVat":
        case "TransactionAmountNet":
        default:
            break;
    }

    if (isNaN(newValues.TransactionAmountNet)) {
        newValues.TransactionAmountNet = 0;
    }

    if (["TransactionUnitPriceVat", "TransactionAmountVat"].includes(changedValue)) {
        // don't calculate the Vat, it was set by the user. Just round the value if needed
        newValues.TransactionAmountVat = roundVatToDecimalPlaces(decimalPlaces, newValues.TransactionAmountVat);
    } else {
        newValues.TransactionAmountVat = roundVatToDecimalPlaces(decimalPlaces, zeroVatRate * newValues.TransactionAmountNet);
    }
    newValues.TransactionAmountNet = roundToCurrencyDecimalPlaces(newValues.TransactionAmountNet);
    newValues.TransactionAmount = roundToCurrencyDecimalPlaces(newValues.TransactionAmountNet + newValues.TransactionAmountVat);

    // use 4 places for unit price to have it same as it is on BE.
    newValues.TransactionUnitPrice = roundToCurrencyDecimalPlaces(newValues.TransactionAmount / newValues.Quantity);
    newValues.TransactionUnitPriceNet = roundToCurrencyDecimalPlaces(newValues.TransactionAmountNet / newValues.Quantity);
    newValues.TransactionUnitPriceVat = roundVatToDecimalPlaces(decimalPlaces, newValues.TransactionAmountVat / newValues.Quantity);

    return newValues;
};

export const tWithFallback = (namespace: string, key: string, fallbackNS: string): string => {
    return i18next.t([`${namespace}:${key}`, `${fallbackNS}:${key}`]);
};

export const getFormDefGroup = (def: IFormDef, id: string): IFormGroupDef => {
    return def.groups.find(group => group.id === id);
};

export const getFiscalDataCorrespondingToDateAccountingTransaction = (storage: StorageModel, dateTransaction?: Date | Dayjs): {
    fiscalYear: IFiscalYearEntity,
    fiscalPeriod: IFiscalPeriodEntity
} => {
    const dateAccountingTransaction = dateTransaction ?? storage.data.entity.DateAccountingTransaction;
    let fiscalPeriod: IFiscalPeriodEntity;
    let fiscalYear: IFiscalYearEntity;

    if (dateAccountingTransaction) {
        fiscalYear = getFiscalYearByDate(storage.context, dateAccountingTransaction);
        const searchDay = dateAccountingTransaction;
        fiscalPeriod = fiscalYear?.Periods.find(period => isInFYorPeriod(period, searchDay));
    }

    return {
        fiscalYear,
        fiscalPeriod
    };
};

/** Matching accounts have same Name and Number */
export const isMatchingAccount = (acc1: IAccountEntity, acc2: IAccountEntity, compareByNumberOnly = false): boolean => {
    return acc1 && acc2 && acc1.Number?.toString() === acc2.Number?.toString() && (compareByNumberOnly || acc1.Name === acc2.Name);
};

interface IGetInvoicePdfUrl {
    documentType: EntityTypeName;
    entityId: number;
    companyId: string;
    isForPrint?: boolean;
    language: LanguageCode;
}

// we need companyId because pdfs are retrieved without customFetch
export const getInvoicePdfUrl = (args: IGetInvoicePdfUrl): string => {
    return `${REST_API_URL}/${args.documentType}PdfExport/${args.entityId}/${(!!args.isForPrint).toString()}${args.language ? `/${args.language}` : ""}?CompanyId=${args.companyId}`;
};

export const getSummaryAmount = (amountName?: string): ISummaryItem => {
    return {
        id: amountName ?? "TransactionAmount",
        label: i18next.t("Document:Form.Amount"),
        updateFromLiveValue: true,
        formatter: (val: TValue, args: IFormatOptions) => {
            if (isDefined(val)) {
                const currency = args.entity?.TransactionCurrency?.Code ?? args.entity?.TransactionCurrencyCode;
                return formatCurrencyVariableDecimals(val, currency);
            }

            return null;
        },
        colorFormatter: (val: TValue) => {
            return val > 0 ? "C_SEM_el_good" : "";
        }
    };
};

export const documentTableAmountFormatter = (entity: IEntity, context: IAppContext, amountSuffix = ""): TCellValue => {
    if (entity[`Amount${amountSuffix}`] === null) {
        return null;
    }
    const currencyCode = entity.CurrencyCode ?? getCompanyCurrency(context);
    const formattedAmount = formatCurrency(entity[`Amount${amountSuffix}`] || 0, currencyCode);

    if (entity.TransactionCurrencyCode && entity.TransactionCurrencyCode !== currencyCode) {
        const formattedTransactionAmount = formatCurrency(entity[`TransactionAmount${amountSuffix}`] || 0, entity.TransactionCurrencyCode);

        return {
            value: <><SmallText>{formattedAmount} | </SmallText>{formattedTransactionAmount}</>,
            tooltip: `${formattedAmount} | ${formattedTransactionAmount}`
        };
    }
    return formattedAmount;
};

interface IGetAccountJournalTable {
    hasDocItem: boolean;
    isPaymentDocument?: boolean;
}

export const getAccountJournalTable = ({ hasDocItem, isPaymentDocument }: IGetAccountJournalTable): TFormTable => {
    const columns: IFieldDef[] = [
        { id: "DateAccountingTransaction" },
        { id: "Description" },
        {
            id: "Amount",
            formatter: (val, args) => documentTableAmountFormatter(args.entity, args.context),
            additionalProperties: [
                { id: "TransactionAmount" }, { id: "TransactionCurrency" }
            ]
        },
        {
            id: "DebitAccount",
            fieldSettings: {
                displayName: "Number"
            }
        },
        {
            id: "CreditAccount",
            fieldSettings: {
                displayName: "Number"
            }
        }
    ];

    if (hasDocItem) {
        columns.unshift({
            id: "DocumentItem/Order",
            // we want the label to refer to line items
            label: i18next.t("Document:FormTab.Item")
        });
    }

    return {
        id: `accountingJournal`,
        entitySet: EntitySetName.JournalEntries,
        // journal entry is to either Document type or PaymentDocument type
        parentKey: isPaymentDocument ? "PaymentDocument/Id" : "Document/Id",
        initialSortBy: [
            hasDocItem ?
                { id: "DocumentItem/Order", sort: Sort.Asc } :
                { id: "DateAccountingTransaction", sort: Sort.Asc }
        ],
        columns: columns
    };
};

export function enhancedDocumentTypeWithDDOPP(entity: IEntity, fallback: string): string {
    const type = entity.DocumentTypeCode as DocumentTypeCode;
    const isDDOPP = entity.IsTaxDocument;
    if ([DocumentTypeCode.ProformaInvoiceReceived, DocumentTypeCode.ProformaInvoiceIssued].includes(type) && isDDOPP) {
        return i18next.t(`Common:DocumentTypeName.${type === DocumentTypeCode.ProformaInvoiceReceived ? "Received" : "Issued"}DDOPP`).toString();
    }
    return fallback;
}

interface IGetDocumentPairedTable {
    selectQuery: string;
    addAmountColumn?: boolean;
    addTransactionAmountColumn?: boolean;
    entitySet?: EntitySetName;
    isCbaCompany: boolean;
}

export const getDocumentPairedTable = (options: IGetDocumentPairedTable): TFormTable => {
    const dateProp = options.isCbaCompany ? UndirectedDocumentLinkEntity.DateCbaPayment : UndirectedDocumentLinkEntity.DateAccountingTransaction;
    const pairedDocumentDateProp = options.isCbaCompany ? DocumentEntity.DateCbaDocument : DocumentEntity.DateAccountingTransaction;
    const tableDef: TFormTable = {
        id: `pairedDocuments`,
        entitySet: options.entitySet ?? EntitySetName.UndirectedDocumentLinks,
        parentKey: "Document/Id",
        filter: (args: IGetValueArgs) => {
            return `Document/Id eq ${args.storage.data.bindingContext.getKey()} AND (${options.selectQuery})`;
        },
        initialSortBy: [
            { id: dateProp, sort: Sort.Asc }
        ],
        columns: [
            { id: dateProp },
            {
                id: "PairedDocument/DocumentType",
                fieldSettings: {
                    displayName: "Name"
                },
                label: i18next.t("Document:PairedDocuments.DocumentType"),
                formatter: (val: TValue, args: IFormatOptions<IUndirectedDocumentLinkEntity>) => {
                    const { PairedDocument } = args.entity;
                    return enhancedDocumentTypeWithDDOPP({
                        ...PairedDocument,
                        IsTaxDocument: args.entity?.PairedDocumentIsTaxDocument
                    }, val as string);
                },
                additionalProperties: [
                    { id: `/${UndirectedDocumentLinkEntity.PairedDocumentIsTaxDocument}` },
                    { id: `/${UndirectedDocumentLinkEntity.PairedDocument}/${pairedDocumentDateProp}` }
                ]
            },
            {
                id: "PairedDocument",
                fieldSettings: {
                    displayName: "NumberOurs"
                },
                label: i18next.t("Document:PairedDocuments.Document")
            }
        ]
    };

    if (options.addAmountColumn) {
        tableDef.columns.push(
            {
                id: "Amount",
                formatter: (val, args) => documentTableAmountFormatter(args.entity, args.context),
                additionalProperties: [
                    { id: "TransactionAmount" }, { id: "TransactionCurrency" }
                ]
            }
        );
    }

    if (options.addTransactionAmountColumn) {
        tableDef.columns.push(
            {
                id: "TransactionAmount"
            }
        );
    }

    tableDef.columns.push({
        id: "Type",
        fieldSettings: {
            displayName: "Name"
        },
        label: i18next.t("Document:PairedDocuments.Type")
    });

    return tableDef;
};

export const CLEARING_DOCUMENTS_TABLE_ID = "clearingDocuments";

export const getDocumentClearingTableDef = (selectQuery?: string): TFormTable => {
    return {
        id: CLEARING_DOCUMENTS_TABLE_ID,
        entitySet: EntitySetName.ClearingDocumentLinks,
        parentKey: `${ClearingDocumentLinkEntity.Document}/${DocumentEntity.Id}`,
        filter: (args: IGetValueArgs) => {
            const queries = [`${ClearingDocumentLinkEntity.Document}/${DocumentEntity.Id} eq ${args.storage.data.bindingContext.getKey()}`];
            if (selectQuery) {
                queries.push(selectQuery);
            }
            return queries.join(" AND ");
        },
        initialSortBy: [
            { id: ClearingDocumentLinkEntity.DateClearing, sort: Sort.Asc }
        ],
        columns: [
            { id: ClearingDocumentLinkEntity.DateClearing },
            {
                id: `${ClearingDocumentLinkEntity.PairedDocument}/${DocumentEntity.DocumentType}`,
                fieldSettings: {
                    displayName: DocumentTypeEntity.Name
                },
                additionalProperties: [
                    { id: `/${ClearingDocumentLinkEntity.PairedDocumentIsTaxDocument}` },
                    { id: `/${createPath(ClearingDocumentLinkEntity.PairedPaymentDocument, PaymentDocumentEntity.DocumentType, DocumentTypeEntity.Name)}` },
                    { id: `/${createPath(ClearingDocumentLinkEntity.PairedPaymentDocument, PaymentDocumentEntity.NumberOurs)}` },
                    { id: `/${createPath(ClearingDocumentLinkEntity.PairedDocument, DocumentEntity.DateAccountingTransaction)}` }
                ],
                formatter: (val: TValue, args: IFormatOptions<IClearingDocumentLinkEntity>) => {
                    const { PairedDocument, PairedPaymentDocument } = args.entity;
                    const entity = PairedDocument && !isObjectEmpty(PairedDocument) ? PairedDocument : PairedPaymentDocument;
                    const fallback = entity?.DocumentType?.Name;
                    return enhancedDocumentTypeWithDDOPP({
                        ...entity,
                        IsTaxDocument: args.entity?.PairedDocumentIsTaxDocument
                    }, fallback);
                },
                label: i18next.t("Document:PairedDocuments.DocumentType")
            },
            {
                id: ClearingDocumentLinkEntity.PairedDocument,
                fieldSettings: {
                    displayName: DocumentEntity.NumberOurs
                },
                additionalProperties: [
                    { id: `/${createPath(ClearingDocumentLinkEntity.PairedPaymentDocument, PaymentDocumentEntity.NumberOurs)}` }
                ],
                // todo convince BE to create view that will merge back Document and PaymentDocument
                // => if that happens, remove this formatter and additionalProperties
                formatter: (val, args: IFormatOptions) => {
                    // value can be either PairedDocument or PairedPaymentDocument
                    const entity = args.entity as unknown as IClearingDocumentLinkEntity;
                    const pairedDocument = isObjectEmpty(entity.PairedDocument) ? entity.PairedPaymentDocument : entity.PairedDocument;
                    const numberOurs = pairedDocument.NumberOurs;

                    return getTableIntentLink(numberOurs as string, {
                        route: `${getRouteByDocumentType(pairedDocument.DocumentType.Code as DocumentTypeCode)}/${pairedDocument.Id}`,
                        context: args.storage.context,
                        storage: args.storage
                    });

                },
                label: i18next.t("Document:PairedDocuments.Document")
            },
            { id: ClearingDocumentLinkEntity.TransactionAmount },
            {
                id: ClearingDocumentLinkEntity.Type,
                fieldSettings: {
                    displayName: DocumentLinkTypeEntity.Name
                },
                label: i18next.t("Document:PairedDocuments.Type")
            }
        ]
    };
};

interface IGetAutomatedVatInclusionTableOptions {
    isCbaCompany: boolean;
}

export const getAutomatedVatInclusionTable = (opts: IGetAutomatedVatInclusionTableOptions): TFormTable => {

    const DateColumn = opts.isCbaCompany ? InternalDocumentEntity.DateCbaDocument : InternalDocumentEntity.DateAccountingTransaction;

    const tableDef = {
        id: `automatedVatInclusionTable`,
        entitySet: EntitySetName.InternalDocuments,
        filter: (args: IGetValueArgs) => {
            return `DocumentLinks/any(x: x/TargetDocument/id eq ${args.storage.data.bindingContext.getKey()} AND x/TypeCode in (${transformToODataString([DocumentLinkTypeCode.AutomatedVATInclusionInputVAT, DocumentLinkTypeCode.AutomatedVATInclusionOutputVAT], ValueType.String)}))`;
        },
        initialSortBy: [
            { id: DateColumn, sort: Sort.Asc }
        ],
        columns: [
            { id: DateColumn },
            {
                id: InternalDocumentEntity.DocumentType,
                fieldSettings: {
                    displayName: DocumentTypeEntity.Name
                },
                label: i18next.t("Document:PairedDocuments.DocumentType")
            },
            {
                id: InternalDocumentEntity.NumberOurs,
                label: i18next.t("Document:PairedDocuments.Document"),
                formatter: (val: TValue, args: IFormatOptions): TCellValue => {
                    return getTableIntentLink(val as string, {
                        route: `${ROUTE_INTERNAL_DOCUMENT}/${args.entity.Id}`,
                        context: args.storage.context,
                        storage: args.storage
                    });
                }
            },
            { id: InternalDocumentEntity.Amount },
            { id: InternalDocumentEntity.Explanation }
        ]
    };

    return tableDef;
};

export const anyPathEquals = (bindingContext: BindingContext, paths: string[], basePath: string): boolean => {
    let anyEquals = false;
    const bcFullPath = bindingContext.getFullPath(true);

    for (const path of paths) {
        const fullPath = basePath ? `${basePath}/${path}` : path;

        if (fullPath === bcFullPath) {
            anyEquals = true;
            break;
        }
    }

    return anyEquals;
};

export const hasPermissionForDocument = (code: DocumentTypeCode, permissions: TCompanyPermissions): boolean => {
    if (code === DocumentTypeCode.BankTransaction) {
        return permissions.has(CompanyPermissionCode.Bank);
    } else if ([DocumentTypeCode.CashReceiptIssued, DocumentTypeCode.CashReceiptReceived].includes(code)) {
        return permissions.has(CompanyPermissionCode.CashBox);
    } else if (code === DocumentTypeCode.InternalDocument) {
        return permissions.has(CompanyPermissionCode.InternalAndCorrectiveDocuments);
    } else if (code === DocumentTypeCode.OtherLiability) {
        return permissions.has(CompanyPermissionCode.Reports);
    } else if (ReceivedDocumentTypes.includes(code)) {
        return permissions.has(CompanyPermissionCode.InvoicesReceived);
    } else if (IssuedDocumentTypes.includes(code)) {
        return permissions.has(CompanyPermissionCode.InvoicesIssued);
    }
    return true;
};

export function getIconForDocument(entityTypeName: EntityTypeName, isTaxDocument?: boolean): React.ComponentType<IIconProps> {
    if (isTaxDocument) {
        switch (entityTypeName) {
            case EntityTypeName.ProformaInvoiceReceived:
                return ProformaInvoicesReceivedTaxPlainIcon;
            case EntityTypeName.ProformaInvoiceIssued:
                return ProformaInvoicesIssuedTaxPlainIcon;
        }
    }
    switch (entityTypeName) {
        case EntityTypeName.InvoiceIssued:
            return InvoicesIssuedPlainIcon;
        case EntityTypeName.InvoiceReceived:
            return InvoicesReceivedPlainIcon;
        case EntityTypeName.OtherLiability:
            return OtherLiabilitiesPlainIcon;
        case EntityTypeName.OtherReceivable:
            return OtherReceivablesPlainIcon;
        case EntityTypeName.CorrectiveInvoiceIssued:
            return CorrectiveInvoicesIssuedPlainIcon;
        case EntityTypeName.CorrectiveInvoiceReceived:
            return CorrectiveInvoicesReceivedPlainIcon;
        case EntityTypeName.ProformaInvoiceReceived:
            return ProformaInvoicesReceivedPlainIcon;
        case EntityTypeName.ProformaInvoiceIssued:
            return ProformaInvoicesIssuedPlainIcon;
        case EntityTypeName.InternalDocument:
            return InternalDocumentsPlainIcon;
        case EntityTypeName.BankTransaction:
            return InternalDocumentsPlainIcon;
    }
    return null;
}

export const hasPermissionForDocumentEntityType = (entity: EntityTypeName, companyPermissions: TCompanyPermissions): boolean => {
    return hasPermissionForDocument(getDocumentTypeCodeFromEntityType(entity), companyPermissions);
};

export const hasCorrectiveDocument = (storage: FormStorage): boolean => {
    return (storage.data.entity.OppositeDocumentLinks ?? []).some((docLink: IOppositeDocumentLinkEntity) => {
        return correctiveDocumentLinkTypes.includes(docLink.TypeCode as DocumentLinkTypeCode);
    });
};

export const loadExchangeRate = async (storage: Model, currencyCode: string, date: Date): Promise<number> => {
    if (currencyCode === getCompanyCurrency(storage.context)) {
        return 1;
    }
    const res = await customFetch(`${GET_EXCHANGE_RATE_URL}/${formatDateToDateString(date)}/${currencyCode}`);
    const exchangeRate = (await res.json()) as IExchangeRateEntity;

    return exchangeRate.ExchangeRatePerUnit;
};

export const getExchangeRate = async (storage: FormStorage, dateProp = "DateAccountingTransaction"): Promise<number> => {
    const dateIssued = storage.data.entity[dateProp];
    const code = storage.data.entity.TransactionCurrency?.Code;
    return await loadExchangeRate(storage, code, dateIssued);
};

export const refreshExchangeRateWithoutDefault = async (storage: FormStorage, currencyCode: string, date: Date): Promise<void> => {
    let rate = null;
    if (currencyCode && date && currencyCode !== getCompanyCurrency(storage.context)) {
        rate = await loadExchangeRate(storage, currencyCode, date);
    }

    storage.clearAndSetValueByPath("ExchangeRatePerUnit", rate);
    storage.refreshFields();
};

export const refreshExchangeRate = async (storage: FormStorage, currencyCode?: string, datePropPath?: string): Promise<void> => {
    const date = storage.getValueByPath(datePropPath ?? "DateAccountingTransaction");
    const dateIssued = date ?? getUtcDayjs();
    const code = currencyCode ?? storage.data.entity.TransactionCurrency?.Code;

    let rate: number = code === getCompanyCurrency(storage.context) ? 1 : null;

    if (code && dateIssued && code !== getCompanyCurrency(storage.context)) {
        rate = await loadExchangeRate(storage, code, dateIssued);
    }

    storage.clearAndSetValueByPath("ExchangeRatePerUnit", rate);
    storage.refreshFields();
};


export const getLocks = (storage: FormStorage): IDocumentLockEntity[] => {
    return storage.data.entity[storage.data.definition.lockProperty] as IDocumentLockEntity[];
};

export const isFormLocked = (storage: FormStorage): boolean => {
    const locks = getLocks(storage);

    return locks && locks.length > 0 && !!locks.find(lock => lock.Type?.Code === LockTypeCode.User);
};

export function isDocumentLocked(args: IGetValueArgs): boolean {
    return isFormLocked(args.storage as FormStorage);
}

export const isDocumentNotCleared = (args: IGetValueArgs): boolean => {
    const document = args.storage.getEntity<IDocumentEntity>();


    return document.ClearedStatusCode === ClearedStatusCode.NotCleared;
};

interface IChangeLogsArgs {
    storage: FormStorage;
    onBeforeSave: () => void;
    onSaveFail: () => void;
    onAfterSave: (refresh: boolean, preventTableViewRefresh?: boolean) => void;
    onTableRefreshNeeded: (args?: ITableRefreshNeeded) => void;
    title: string;
    context: IAppContext;
}

export const changeLock = async (args: IChangeLogsArgs): Promise<void> => {
    const storage = args.storage;
    args.onBeforeSave();

    let lock;
    const isDocumentLocked = isFormLocked(storage);

    try {
        if (isDocumentLocked) {
            const locks = getLocks(storage);
            const userLock = locks.find(lock => lock.Type?.Code === LockTypeCode.User);
            const queryable = storage.oData.fromPath(storage.data.bindingContext.navigate(storage.data.definition.lockProperty).addKey(userLock.Id).toString());
            await queryable.delete(null);
            lock = null;
        } else {
            const queryable = storage.oData.fromPath(storage.data.bindingContext.navigate(storage.data.definition.lockProperty).toString());
            const res = await queryable.create({
                TypeCode: LockTypeCode.User
            });
            lock = (res as ODataQueryResult).value;
        }

        storage.data.entity[storage.data.definition.lockProperty] = lock ? [lock] : [];
        storage.data.locked = !!lock;
        args.onAfterSave(storage.data.bindingContext.isNew());

        await storage.reload();
    } catch (error) {
        const alert = getAlertFromError(error);
        alert.title = storage.t(`Document:Error.${isDocumentLocked ? "UnlockingFailed" : "LockingFailed"}`);
        storage.setFormAlert(alert);
        args.onSaveFail();
    }

    storage.refresh();
};

export const setLockBreadcrumbs = (args: IChangeLogsArgs): void => {
    const title = args.storage.data.definition.getItemBreadCrumbText(args.storage);
    const isCbaCompany = isCashBasisAccountingCompany(args.storage.context);

    const breadCrumbs: IBreadcrumb = {
        items: [{
            key: "selectedDocument",
            link: null,
            title
        }],
        lockable: () => {
            return getPageViewMode() !== PageViewMode.FormReadOnly;
        },
        isLockDisabled: () => {
            return args.storage.data.disabled;
        },
        lockAlert: {
            alert: () => {
                const isNew = args.storage.data.bindingContext.isNew();
                if (isNew || args.storage.isDirty()) {
                    return {
                        status: Status.Warning,
                        title: args.storage.t("Document:Error.WarningFormNotSaved"),
                        isSmall: true
                    };
                }

                return null;
            },
            popperOptions: {
                placement: "right"
            }
        },
        onLockClick: (event: React.MouseEvent) => {
            const isDirty = args.storage.isDirty();
            if (args.storage.data.bindingContext.isNew() || isDirty) {
                args.storage.refresh();
                return;
            }
            changeLock(args);
        }
    };

    args.context.setViewBreadcrumbs(breadCrumbs);
    args.storage.data.locked = isFormLocked(args.storage);
};


// Note: OtherReceivable is actually Issued document type!
export const ReceivedDocumentTypes = [DocumentTypeCode.InvoiceReceived, DocumentTypeCode.OtherLiability,
    DocumentTypeCode.CorrectiveInvoiceReceived, DocumentTypeCode.ProformaInvoiceReceived, DocumentTypeCode.BankTransaction, DocumentTypeCode.CashReceiptReceived];
export const IssuedDocumentTypes = [DocumentTypeCode.InvoiceIssued, DocumentTypeCode.OtherReceivable,
    DocumentTypeCode.CorrectiveInvoiceIssued, DocumentTypeCode.ProformaInvoiceIssued, DocumentTypeCode.CashReceiptIssued];
export const ProformaDocumentTypes = [DocumentTypeCode.ProformaInvoiceReceived, DocumentTypeCode.ProformaInvoiceIssued];
export const commonDocumentTranslations = [
    "Document", "Enums", "NumberRange", "Common", "Components", "Error",
    ...VatRuleTranslationFiles, "Banks", "Proforma", "Categories",
    getEnumNameSpaceName(EntityTypeName.CbaCategoryTaxImpact),
    ...StatusCodeEnumTranslations
];
export const correctiveDocumentLinkTypes = [DocumentLinkTypeCode.CorrectiveWithClearing, DocumentLinkTypeCode.CorrectiveWithoutClearing];

export const isDocumentType = (bc: BindingContext): boolean => {
    return !!bc.getEntityType()?.hasParentWithBaseType(EntityTypeName.Document);
};

export const isDocumentItemType = (bc: BindingContext): boolean => {
    return !!bc.getEntityType()?.hasParentWithBaseType(EntityTypeName.DocumentItem);
};


export const isReceived = (type: DocumentTypeCode): boolean => {
    return ReceivedDocumentTypes.includes(type);
};

export const isIssued = (type: DocumentTypeCode): boolean => {
    return IssuedDocumentTypes.includes(type);
};

export function isReceivedBc(bindingContext: BindingContext): boolean {
    const entityTypeName = bindingContext.getEntityType().getName();
    const docType = getDocumentTypeCodeFromEntityType(entityTypeName as EntityTypeName);
    return isReceived(docType);
}

export function isIssuedBc(bindingContext: BindingContext): boolean {
    const entityTypeName = bindingContext.getEntityType().getName();
    const docType = getDocumentTypeCodeFromEntityType(entityTypeName as EntityTypeName);
    return isIssued(docType);
}

export function isProformaBc(bindingContext: BindingContext): boolean {
    const entityTypeName = bindingContext.getEntityType().getName();
    const docType = getDocumentTypeCodeFromEntityType(entityTypeName as EntityTypeName);
    return ProformaDocumentTypes.includes(docType);
}

export const isDocumentWithDraft = (document: IEntityWithDraft, draftProperty: string): boolean => {
    if (!document || !draftProperty) {
        return false;
    }
    const draft = document[draftProperty as keyof IEntityWithDraft] as IEntity;
    return !!draft?.Id || !!draft?.[BindingContext.NEW_ENTITY_ID_PROP];
};

export const isSavedDocument = (args: IGetValueArgs): boolean => !args.storage.data.bindingContext.isNew();

export const draftInfoFormatter: TFormatterFn = (val, args) => {
    return {
        value: <ColoredText
            color={"C_ACT_der"}>{DateType.localFormat(args.entity.DateLastModified)}&nbsp;&nbsp;{args.entity.LastModifiedBy?.Name}</ColoredText>,
        tooltip: args.entity.LastModifiedBy?.Name
    };
};

export const draftNumberOursFormatter = (draftEntitySet: EntitySetName, draftProp: string): TFormatterFn => {
    return (val, args) => {
        const strValue = val?.toString() ?? "";
        return {
            value: strValue,
            tooltip: strValue,
            afterContent: !args.entity[draftProp]?.Id ? null :
                (<ColoredText
                    color={"C_SEM_text_warning"}><i>{i18next.t("Document:General.UnsavedChanges")}</i></ColoredText>)
        };
    };
};

export const loadVats = memoize(async (context: IAppContext, storage: FormStorage): Promise<IVatEntity []> => {
    const vats = context.getData().custom?.Vats;
    if (!vats) {
        const filter = `CountryCode eq '${getAgendaCountryCode(context)}'`;

        const vats = await storage.oData.getEntitySetWrapper(EntitySetName.Vats).query()
            .orderBy("Rate")
            .filter(filter)
            .fetchData();

        context.setCustomData({
            Vats: vats.value
        });

        return vats.value;
    }

    return vats;
}, (context) => `loadVats::${getAgendaCountryCode(context)}`);

export interface IGetExportFileNameProps {
    isFull?: string;
    queryParams: TRecordString;
}

/**
 * Returns filename of exported document / report, so it's same as it is on BE
 * // GET api/rest/FileName/document/{document.Id}
 * // GET api/rest/FileName/report/{reportTypeCode}/{companyId}/{inBasicview?}
 * @param context
 * @param type
 * @param identifier
 * @param opts
 */
export async function getDocumentExportFileName(context: IAppContext, type: "document" | "report", identifier: string, opts?: IGetExportFileNameProps): Promise<string> {
    const params: string[] = [identifier];
    if (type === "report") {
        params.push(context.getCompanyId().toString());
        if (isDefined(opts?.isFull)) {
            params.push(opts.isFull);
        }
    }
    let query = "";
    if (isDefined(opts?.queryParams)) {
        query = "?" + Object.entries(opts.queryParams).map(([key, value]) => `${key}=${value}`).join("&");
    }
    const url = `${GET_FILE_NAME_URL}/${type}/${params.join("/")}${query}`;
    const response = await customFetch(url, { method: "GET" });

    if (response.ok) {
        return await response.text();
    }
    return null;
}

export const setNotPostedStatusFilter = (storage: TSmartODataTableStorage): void => {
    if (isAccountAssignmentCompany(storage.context)) {
        storage.setFilterValueByPath(DocumentStatusLocalPath, [
            createFilterRow({
                type: ConditionType.Excluded,
                value: [getStatusFilterId(EntityTypeName.PostedStatus, PostedStatusCode.NotPosted)]
            })
        ]);
    }
};

export const SortedPaymentDocuments = [
    EntityTypeName.BankTransaction,
    EntityTypeName.CashReceiptReceived,
    EntityTypeName.CashReceiptIssued
];

export const SortedDocumentEntityTypeNames = [
    EntityTypeName.InvoiceReceived,
    EntityTypeName.InvoiceIssued,
    EntityTypeName.ProformaInvoiceReceived,
    EntityTypeName.ProformaInvoiceIssued,
    EntityTypeName.OtherLiability,
    EntityTypeName.OtherReceivable,
    EntityTypeName.CorrectiveInvoiceReceived,
    EntityTypeName.CorrectiveInvoiceIssued,
    EntityTypeName.InternalDocument,
    ...SortedPaymentDocuments
];

export const SortedDocumentTypeCodes = SortedDocumentEntityTypeNames.map(getDocumentTypeCodeFromEntityType);

export const getSortedDocumentEntityTypeNamesWithPermission = (context: IAppContext, withPaymentDocuments: boolean): EntityTypeName[] => {
    const companyPermissions = context.getCompanyPermissions();
    return SortedDocumentEntityTypeNames
        .filter(entityType => hasPermissionForDocumentEntityType(entityType, companyPermissions)
            && (withPaymentDocuments || !SortedPaymentDocuments.includes(entityType)));
};

export const _getId = (obj: TRecordAny) => {
    if (!obj) {
        return `${BindingContext.NEW_ENTITY_ID_PROP}=1`;
    }

    let id = obj.Id;
    if (!id) {
        id = obj[BindingContext.NEW_ENTITY_ID_PROP];
        return `${BindingContext.NEW_ENTITY_ID_PROP}=${id}`;
    }

    return id;
};

interface IGetDocumentNameTableCellArgs {
    addIcon?: boolean;
    addDDOPPSuffix?: boolean;
    showJustDDOPP?: boolean;
}

export function getDocumentNameTableCell(entityTypeName: EntityTypeName, args: IGetDocumentNameTableCellArgs): TCellValue {
    let key = entityTypeName as string;
    let isTaxDocument = false;
    if ([EntityTypeName.ProformaInvoiceIssued, EntityTypeName.ProformaInvoiceReceived].includes(entityTypeName)) {
        // proformas has special handling - sometimes we connect name, sometimes not, sometimes we show just DDOPP
        if (args.addDDOPPSuffix) {
            key = `${entityTypeName}OrDDOPP`;
        } else if (args.showJustDDOPP) {
            isTaxDocument = true;
            key = `${entityTypeName}DDOPP`;
        }
    }
    const name = i18next.t(`Document:Types.${key}`, { count: 10 /*uses plural*/ }).toString();
    if (args.addIcon) {
        const Icon = getIconForDocument(entityTypeName, isTaxDocument);
        return {
            tooltip: name,
            value: (<>
                {Icon && (
                    <InlineIconWrapper>
                        <Icon width={IconSize.S} height={IconSize.S}/>
                    </InlineIconWrapper>
                )}
                <DocumentNameStyled>{name}</DocumentNameStyled>
            </>)
        };
    }
    return name;
}


// validate decisive date for cashBasis company here, instead of definition, because we have info about decisive
// date prop only here
export const validateDecisiveDate = (value: TValue, args: IGetValueArgs): boolean | ValidationError => {
    if (isCashBasisAccountingCompany(args.storage.context)) {
        const storage = args.storage;
        const date = storage.getValue(args.bindingContext) as Date;
        if (!DateType.isValid(date)) {
            return new ValidationError(ValidationMessage.NotADate, false, args.bindingContext.getNavigationPath(true));
        }
        if (!getActiveFiscalYears(storage.context).some(fy => isInFYorPeriod(fy, date))) {
            return new ValidationError(storage.t("Document:Form.WrongCalendarYear"), false, args.bindingContext.getPath(true));
        }
    }
    return true;
};

/** Retrieve date which is used to select correct vat items in Items/Vat */
export const getVatItemsDecisiveDateName = (storage: FormStorage): string => {
    const entityType = storage.data.bindingContext.getEntityType().getName() as EntityTypeName;

    if (!isVatRegisteredCompany(storage.context) ||
        ([EntityTypeName.ProformaInvoiceIssued, EntityTypeName.ProformaInvoiceReceived].includes(entityType)
            && !storage.getEntity<IProformaInvoiceReceivedEntity | IProformaInvoiceIssuedEntity>().IsTaxDocument)) {
        return DocumentDraftEntity.DateIssued;
    }

    return DocumentDraftEntity.DateTaxableSupply;
};

export const setDefaultLabelsFromEntityToItems = (storage: FormStorage): void => {
    const itemsBc = storage.data.bindingContext.navigate("Items");
    for (const item of (storage.data.entity.Items ?? [])) {
        const itemBc = itemsBc.addKey(item);
        const labelSelectionBc = itemBc.navigate(DocumentItemEntity.LabelSelection);
        if (storage.getValue(labelSelectionBc)?.SelectionCode === SelectionCode.Default) {
            storage.setValue(labelSelectionBc, {
                Selection: {
                    Code: SelectionCode.Default
                },
                SelectionCode: SelectionCode.Default,
                Labels: [...storage.data.entity.Labels]
            });
            storage.addActiveField(labelSelectionBc.navigate(LabelSelectionEntity.Labels));
            storage.refresh();
        }
    }
};

export const getItemLabelTitleFromSelectionCode = (labelSelection: ILabelSelectionEntity): string => {
    const selectionCode = labelSelection?.SelectionCode;
    if (selectionCode === SelectionCode.Default) {
        if (labelSelection?.Labels?.length > 0) {
            return `(${i18next.t("Document:Labels.Default")})`;
        }
        return i18next.t("Document:Labels.Default");
    } else if (selectionCode === SelectionCode.Own && labelSelection.Labels?.length) {
        return `(${i18next.t("Document:Labels.Own")})`;
    }
    return "";
};

export const handleAttachmentClick = (storage: Model, entity: IDocumentEntity, documentTypeCode: DocumentTypeCode): (e: React.SyntheticEvent) => void => {
    return (e: React.SyntheticEvent): void => {
        e.stopPropagation();
        const id = !!getQueryParameters()[QueryParam.Drafts] ? NEW_ITEM_DETAIL : entity.Id;
        const url = `${getRouteByDocumentType(documentTypeCode)}/${id}`;

        if (getQueryParameters()[QueryParam.Drafts]) {
            setQueryParams({
                history: storage.history,
                newParams: {
                    [QueryParam.DraftId]: entity.Id.toString()
                }
            });
        } else {
            removeQueryParam(storage.history, QueryParam.DraftId, true);
        }

        const origQueryParams = getQueryParameters();

        storage.history.push(url);

        setQueryParams({
            history: storage.history,
            newParams: {
                ...origQueryParams,
                [QueryParam.PaneStatus]: [PaneStatus.Normal, PaneStatus.Collapsed, PaneStatus.Normal].join("")
            }
        });
    };
};

export function hasSavedDraft(storage: FormStorage): boolean {
    return storage.data.entity[DocumentEntity.DocumentDraft]?.Id !== undefined;
}

// we often use "isNew()" to check if document is saved or not, but it's not always correct
// new document can be a copy of another document or has saved draft, which is filled manually by user,
// but the fields are not dirty (as it was saved and reloaded), so we most likely want in such cases use this function
export function isNotSavedDocument(storage: FormStorage): boolean {
    return storage.data.bindingContext.isNew() && !storage.getCustomData().isCopy && !hasSavedDraft(storage);
}

export const getDocumentAttachmentsFilterDef = () => {
    return {
        type: FieldType.MultiSelect,
        valueType: ValueType.Boolean,
        isValueHelp: false,
        label: i18next.t("Document:Table.Attachments"),
        fieldSettings: {
            items: getSimpleBoolSelectItems()
        },
        filter: {
            buildFilter(item: IChangedFilter, settings: ICreateFilterStringSettings): string | IFilterQuery {
                const values = item.value as boolean[];
                const hasTrue = values.some(v => v);
                const hasFalse = values.some(v => !v);

                if (hasTrue === hasFalse) {
                    return "";
                }

                if (hasTrue) {
                    return `${DocumentEntity.Attachments}/any()`;
                }

                if (hasFalse) {
                    return `not(${DocumentEntity.Attachments}/any())`;
                }

                return "";
            }
        }
    };
};

export const getDocumentAttachmentsColumnDef = (docType: DocumentTypeCode) => {
    return {
        textAlign: TextAlign.Center,
        formatter: (val: TValue, formatArgs: IFormatOptions) => {
            const document = formatArgs.entity as IDocumentEntity;
            const hasAttachment = document.Attachments?.length > 0;

            if (!hasAttachment) {
                return null;
            }

            const tooltip = i18next.t(`Document:Table.WithAttachment`);
            return {
                value: (<Clickable
                    onClick={handleAttachmentClick(formatArgs.storage, document, docType)}>
                    <AttachmentIndicatorIcon title={tooltip}
                                             isLightHover
                                             width={IconSize.XL}
                                             height={IconSize.M}/>
                </Clickable>),
                tooltip: tooltip
            };
        }
    }
}