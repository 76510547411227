import { getIntentLink, getTableDrillDownLink } from "@components/drillDown/DrillDown.utils";
import { SwitchType } from "@components/inputs/switch/Switch";
import { IGetValueArgs, TGetValueFn } from "@components/smart/FieldInfo";
import {
    FilterBarGroup,
    getDefaultFilterGroupDef,
    IFilterGroupDef
} from "@components/smart/smartFilterBar/SmartFilterBar.types";
import {
    IReportColumnFormatterArgs,
    IReportHierarchy,
    IReportRowDef,
    TReportColumnFormatter
} from "@components/smart/smartTable";
import { DimmedValue } from "@components/smart/smartTable/SmartReportTable.styles";
import { createBindingContextFromValidatorPath } from "@odata/Data.utils";
import { getRouteByDocumentType } from "@odata/EntityTypes";
import { EntitySetName, IReceivableAdjustmentTypeEntity, IRequiredAccountEntity } from "@odata/GeneratedEntityTypes";
import {
    AccountCategoryCode,
    AccountTypeCode,
    DocumentTypeCode,
    ReceivableAdjustmentTypeCode,
    TaxApplicabilityCode,
    WriteOffTypeCode
} from "@odata/GeneratedEnums";
import { IFormatOptions } from "@odata/OData.utils";
import { getCompanyCurrency } from "@utils/CompanyUtils";
import i18next from "i18next";
import React from "react";
import { ValidationError } from "yup";

import {
    BasicInputSizes,
    ConfigListItemBoundType,
    FieldType,
    LabelStatus,
    QueryParam,
    ReportTableRowType,
    Sort,
    TextAlign,
    ValidatorType
} from "../../../enums";
import { TRecordAny, TValue } from "../../../global.types";
import { ValidationMessage } from "../../../model/Validator.types";
import BindingContext from "../../../odata/BindingContext";
import { ROUTE_BUSINESS_PARTNER, ROUTE_DOCUMENT_JOURNAL } from "../../../routes";
import { formatCurrency } from "../../../types/Currency";
import DateType, { getUtcDate } from "../../../types/Date";
import { IFormDef } from "../../../views/formView/Form";
import { FormStorage } from "../../../views/formView/FormStorage";
import {
    getAccAssignmentGroup,
    getAccountSelectionFieldsDef,
    IGetAccAssignmentGroupArgs
} from "../../accountAssignment/AccountAssignment.utils";
import { DOCUMENT_DATE_CHANGE_DEBOUNCE } from "../../documents/DocumentDef";
import { getFiscalYearByDate, getOldestActiveFY } from "../../fiscalYear/FiscalYear.utils";
import { setDefForTesting } from "../../getDefByEntityType";
import { IDefinition, IGetDefinition } from "../../PageUtils";
import { getDocumentNumberOursOverride } from "../CommonDefs";
import { DocumentJournalVariant, getReportDocumentStatusDef } from "../documentJournal/DocumentJournalDef";
import {
    IReportTableDefinition,
    ReportConfigGroup,
    TGetReportConfigListItemOverride,
    TReportColumnOverrides
} from "../Report.utils";
import { getWholeDateRangeFilter } from "../ReportFilter.utils";
import { ReportId } from "../ReportIds";
import {
    getDefaultAccAss,
    IReceivableAdjustmentsCustomData,
    REC_ADJ_TYPES_WITH_PARTIAL_AMOUNTS,
    WRITE_OFF_REC_ADJ_TYPES
} from "./ReceivableAdjustments.utils";

export const ReceivableAdjustmentsProps = {
    dueDateOption: BindingContext.localContext("DueDateOption")
};

enum DueDateOption {
    All = "All",
    OlderThan12Months = "OlderThan12Months"
}

export const getDefinition = (): IReportTableDefinition => {
    const tableId = ReportId.ReceivableAdjustments;
    const title = i18next.t("Reporting:ReceivableAdjustments.Title");
    const path = "ReceivableAdjustments";
    const initialSortBy = [{ id: "BusinessPartner_Name", sort: Sort.Asc }];
    const filterBarDef: IFilterGroupDef[] = [
        {
            ...getDefaultFilterGroupDef(FilterBarGroup.Parameters),
            defaultFilters: [
                ReceivableAdjustmentsProps.dueDateOption
            ],
            filterDefinition: {
                [ReceivableAdjustmentsProps.dueDateOption]: {
                    type: FieldType.ComboBox,
                    defaultValue: DueDateOption.OlderThan12Months,
                    width: BasicInputSizes.L,
                    label: i18next.t("ReceivableAdjustments:Parameters.DueDate"),
                    fieldSettings: {
                        items: [
                            {
                                label: i18next.t("ReceivableAdjustments:Parameters.DueDateAll"),
                                id: DueDateOption.All
                            },
                            {
                                label: i18next.t("ReceivableAdjustments:Parameters.DueDate12"),
                                id: DueDateOption.OlderThan12Months
                            }
                        ]
                    }
                }
            }
        },
        {
            ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
            allowCustomFilters: false,
            defaultFilters: [],
            filterDefinition: {}
        }
    ];
    const disableAggregateButton = true;
    const updateFiltersFromResponse = true;

    const parameters: string[] = [
        ReceivableAdjustmentsProps.dueDateOption
    ];
    const defaultReportHierarchy: IReportHierarchy = {
        "Aggregate": true,
        "Groups": [
            {
                "ColumnAlias": "BusinessPartner_Name"
            }
        ],
        "Columns": [
            {
                "ColumnAlias": "DocumentStatus"
            },
            {
                "ColumnAlias": "ReceivableAdjustment_NumberOurs"
            },
            {
                "ColumnAlias": "ReceivableAdjustment_Amount"
            },
            {
                "ColumnAlias": "ReceivableAdjustment_AmountDue"
            }
        ],
        "Aggregations": []
    };

    const enhanceRowsWithDocumentIds = (rowsData: IReportRowDef[]): { total: string[], active: string[] } => {
        const docIdsTotal: string[] = [];
        const docIdsActive: string[] = [];

        for (const row of rowsData) {
            if (row.Rows?.length > 0 && (row.Type === ReportTableRowType.Group || row.Type === ReportTableRowType.MergedGroup)) {
                const docIds = enhanceRowsWithDocumentIds(row.Rows);
                docIdsTotal.push(...docIds.total);
                docIdsActive.push(...docIds.active);

                row.Value.ReceivableAdjustmentInternalDocumentIds_Total = docIds.total;
                row.Value.ReceivableAdjustmentInternalDocumentIds_Active = docIds.active;
            } else if (row.Type === ReportTableRowType.Value) {
                docIdsTotal.push(...row.Value.ReceivableAdjustmentInternalDocumentIds_Total as string[]);
                docIdsActive.push(...row.Value.ReceivableAdjustmentInternalDocumentIds_Active as string[]);
            } else if (row.Type === ReportTableRowType.Total || row.Type === ReportTableRowType.GrandTotal) {
                row.Value.ReceivableAdjustmentInternalDocumentIds_Total = docIdsTotal;
                row.Value.ReceivableAdjustmentInternalDocumentIds_Active = docIdsActive;
            }
        }

        return {
            total: docIdsTotal,
            active: docIdsActive
        };
    };

    // backend doesn't send ReceivableAdjustmentInternalDocumentIds for Groups and Totals, only for Values
    // we need to enhance all the rows with it
    const rowsDataFactory = (rowsData: IReportRowDef[]): IReportRowDef[] => {
        enhanceRowsWithDocumentIds(rowsData);

        return rowsData;
    };

    const getCommonDrilldownSettings = (documentIds: string[]) => {
        const wholeDateRangeFilter = getWholeDateRangeFilter();
        const customQueryString = `${QueryParam.Variant}=${DocumentJournalVariant.ReceivableAdjustment}&${wholeDateRangeFilter.customQueryString}`;

        const filters: TRecordAny = {
            ...wholeDateRangeFilter.filters
        };

        filters[BindingContext.localContext("DocumentType_Name")] = [DocumentTypeCode.InternalDocument];
        filters[BindingContext.localContext("Document_NumberOurs")] = documentIds;

        return { filters, customQueryString };
    };

    const getTotalFormatter = (property: string) => {
        return (val: TValue, args: IFormatOptions) => {
            const formattedVal = formatCurrency(val, args.entity["ReceivableAdjustment_CurrencyCode"] ?? getCompanyCurrency(args.context));

            if (!val || val === 0) {
                return formattedVal;
            }

            const documentIds: string[] = args.entity[property];

            return getTableDrillDownLink(formattedVal, {
                route: ROUTE_DOCUMENT_JOURNAL,
                context: args.storage.context,
                storage: args.storage,
                ...getCommonDrilldownSettings(documentIds)
            });
        };
    };

    const columnOverrides: TReportColumnOverrides = {
        ReceivableAdjustment_NumberOurs: getDocumentNumberOursOverride({
            documentTypeProperty: "ReceivableAdjustment_DocumentTypeCode",
            documentIdProperty: "ReceivableAdjustment_Id"
        }),
        ReceivableAdjustment_AdjustmentAmount_Total: {
            formatter: getTotalFormatter("ReceivableAdjustmentInternalDocumentIds_Total")
        },
        ReceivableAdjustment_AdjustmentAmount_Active: {
            formatter: getTotalFormatter("ReceivableAdjustmentInternalDocumentIds_Active")
        },
        DocumentStatus: getReportDocumentStatusDef("ReceivableAdjustment"),
        MonthsAfterDateDue: {
            formatter: (val: TValue, args: IFormatOptions): string => {
                if (!val) {
                    return null;
                }

                return `${val} ${args.storage.t("Common:Time.Month", { count: val as number })}`;
            }
        },
        ReceivableAdjustment_WriteOffName: {
            formatter: (val: TValue, args: IFormatOptions) => {
                if (!args.entity.hasOwnProperty("ReceivableAdjustment_WriteOffCode")) {
                    return "";
                }

                const value = args.entity["ReceivableAdjustment_WriteOffName"];
                const code = args.entity["ReceivableAdjustment_WriteOffCode"];

                if (!code || code === WriteOffTypeCode.No) {
                    return {
                        tooltip: value,
                        value: (
                            <DimmedValue>
                                {value}
                            </DimmedValue>
                        )
                    };
                }

                return getTableDrillDownLink(value, {
                    route: ROUTE_DOCUMENT_JOURNAL,
                    context: args.storage.context,
                    storage: args.storage,
                    ...getCommonDrilldownSettings(args.entity["ReceivableAdjustment_WriteOffNumberOurs"])
                });
            }
        }
    };

    const columnFormatter: TReportColumnFormatter = (args: IReportColumnFormatterArgs) => {
        if (args.column.id !== "MonthsAfterDateDue") {
            return args.column;
        }

        const column = { ...args.column, disableSort: true };
        const fiscalYearEnd = args.columnDef?.AdditionalInfo?.ActiveFiscalYear_DateEnd;

        column.info = `${i18next.t("Reporting:ReceivableAdjustments.ByDate")} ${DateType.format(getUtcDate(fiscalYearEnd))}`;

        return column;
    };

    const configListItemsOverride: TGetReportConfigListItemOverride = {
        BusinessPartner_Name: {
            isRequired: true,
            isDisabled: true,
            group: ReportConfigGroup.Groups
        },
        ReceivableAdjustment_Amount: {
            isRequired: true,
            boundTo: ConfigListItemBoundType.Group,
            isCopyOnly: false,
            group: ReportConfigGroup.Columns
        },
        ReceivableAdjustment_AmountDue: {
            isRequired: true,
            boundTo: ConfigListItemBoundType.Group,
            isCopyOnly: false,
            group: ReportConfigGroup.Columns
        }
    };


    return {
        title, path, id: tableId, initialSortBy, filterBarDef,
        columnOverrides, columnFormatter,
        configListItemsOverride, rowsDataFactory,
        parameters, defaultReportHierarchy,
        disableAggregateButton, updateFiltersFromResponse
    };
};

const getAdjustmentTypeCode = (args: IGetValueArgs): ReceivableAdjustmentTypeCode => {
    return (args.storage as FormStorage<unknown, IReceivableAdjustmentsCustomData>).getCustomData().adjustmentTypeCode;
};

const getAdjustmentTypes = (args: IGetValueArgs): IReceivableAdjustmentTypeEntity[] => {
    return (args.storage as FormStorage<unknown, IReceivableAdjustmentsCustomData>).getCustomData().adjustmentTypes;
};

const getRequiredAccounts = (args: IGetValueArgs): IRequiredAccountEntity[] => {
    return (args.storage as FormStorage<unknown, IReceivableAdjustmentsCustomData>).getCustomData().requiredAccounts;
};

export const getDialogDefinition: IGetDefinition = (): IDefinition => {
    const entitySet = EntitySetName.InternalDocuments;

    const getAccountSettings: IGetAccAssignmentGroupArgs = {
        skipCreateCustom: true,
        skipNoneItem: true,
        skipNoRecordText: true,
        isLabelWithoutDefaultPrefix: true,
        transactionDatePath: "DateAccountingTransaction"
    };
    const accAssDefs = getAccountSelectionFieldsDef(DocumentTypeCode.InternalDocument, getAccountSettings);

    for (const path of Object.keys(accAssDefs)) {
        accAssDefs[path].isRequired = true;
    }

    const accAssGroup = getAccAssignmentGroup(getAccountSettings);
    const isPartialCancelPath = BindingContext.localContext("partialCancellation");

    const form: IFormDef = {
        id: "ReceivableAdjustmentsDialogSpecialForm",
        translationFiles: getDialogDefinition.translationFiles,
        fieldDefinition: {
            "DateAccountingTransaction": {
                width: BasicInputSizes.M,
                label: i18next.t("ReceivableAdjustments:Form.DateAccountingTransaction"),
                isRequired: true,
                fieldSettings: {
                    debouncedWait: DOCUMENT_DATE_CHANGE_DEBOUNCE
                },
                validator: {
                    type: ValidatorType.Custom,
                    settings: {
                        customValidator: (value: TValue, args: IGetValueArgs) => {
                            const fy = getFiscalYearByDate(args.storage.context, value as Date);
                            const oldestActiveFy = getOldestActiveFY(args.storage.context);

                            if (!fy || oldestActiveFy.Id !== fy.Id) {
                                return new ValidationError(i18next.t("ReceivableAdjustments:Error.DateAccountingTransactionValidation"), false, args.bindingContext.getNavigationPath(true));
                            }

                            return true;
                        }
                    }
                }
            },
            "Explanation": {
                // width: BasicInputSizes.M,
                isRequired: true,
                isReadOnly: true,
                type: FieldType.Input
            },
            [isPartialCancelPath]: {
                labelStatus: LabelStatus.Removed,
                type: FieldType.Switch,
                fieldSettings: {
                    type: SwitchType.YesNo
                },
                isDisabled: (args: IGetValueArgs) => {
                    // disable any of the amounts doesn't correspond to the full amount
                    return args.storage.data.entity[BindingContext.localContext("selectedDocuments")].reduce((shouldDisable: boolean, adjustment: any) => {
                        return shouldDisable || (adjustment[BindingContext.localContext("Amount")] !== adjustment[BindingContext.localContext("AmountDue")]);
                    }, false);
                }
            },
            ...accAssDefs,
            "AccountAssignmentSelection/AccountAssignment": {
                ...accAssDefs["AccountAssignmentSelection/AccountAssignment"],
                filter: {
                    // enhance original filter with another filter
                    select: (args, other) => {
                        const originalFilter = (accAssDefs["AccountAssignmentSelection/AccountAssignment"].filter.select as TGetValueFn<string>)(args, other);
                        const adjustmentType = getAdjustmentTypeCode(args);
                        const adjustmentTypes = getAdjustmentTypes(args);
                        const requiredAccounts = getRequiredAccounts(args);
                        const defaultAccounts = getDefaultAccAss({
                            requiredAccounts, adjustmentType, adjustmentTypes
                        });

                        if (!defaultAccounts) {
                            return originalFilter;
                        }

                        return `${originalFilter} AND startswith(DebitAccount/Number,'${defaultAccounts.DebitAccount.slice(0, 3)}') AND startswith(CreditAccount/Number,'${defaultAccounts.CreditAccount}')`;
                    }
                }
            },
            "AccountAssignmentSelection/AccountAssignment/DebitAccount": {
                ...accAssDefs["AccountAssignmentSelection/AccountAssignment/DebitAccount"],
                fieldSettings: {
                    ...accAssDefs["AccountAssignmentSelection/AccountAssignment/DebitAccount"].fieldSettings,
                    preloadItems: true
                },
                filter: {
                    // enhance original filter with another filter
                    select: (args, other) => {
                        const originalFilter = (accAssDefs["AccountAssignmentSelection/AccountAssignment/DebitAccount"].filter.select as string);
                        const adjustmentType = getAdjustmentTypeCode(args);
                        const adjustmentTypes = getAdjustmentTypes(args);
                        const requiredAccounts = getRequiredAccounts(args);
                        const defaultAccounts = getDefaultAccAss({
                            requiredAccounts, adjustmentType, adjustmentTypes
                        });

                        if (!defaultAccounts) {
                            return originalFilter;
                        }

                        let extendFilter = `${originalFilter} AND (startswith(Number,'${defaultAccounts.DebitAccount.slice(0, 3)}')`;

                        if (adjustmentType === ReceivableAdjustmentTypeCode.TaxWriteOff) {
                            extendFilter += " OR startswith(Number,'548')";
                        }

                        extendFilter += ")";

                        if (WRITE_OFF_REC_ADJ_TYPES.includes(adjustmentType)) {
                            extendFilter += ` AND CategoryCode eq '${AccountCategoryCode.IncomeStatement}' AND TypeCode eq '${AccountTypeCode.Expense}'`;
                            extendFilter += ` AND TaxApplicabilityCode eq '${adjustmentType === ReceivableAdjustmentTypeCode.TaxWriteOff ? TaxApplicabilityCode.TaxApplicable : TaxApplicabilityCode.NotTaxApplicable}'`;
                        }

                        return extendFilter;
                    }
                }
            },
            "AccountAssignmentSelection/AccountAssignment/CreditAccount": {
                ...accAssDefs["AccountAssignmentSelection/AccountAssignment/CreditAccount"],
                fieldSettings: {
                    ...accAssDefs["AccountAssignmentSelection/AccountAssignment/DebitAccount"].fieldSettings
                },
                filter: {
                    // enhance original filter with another filter
                    select: (args, other) => {
                        const originalFilter = (accAssDefs["AccountAssignmentSelection/AccountAssignment/CreditAccount"].filter.select as string);
                        const adjustmentType = getAdjustmentTypeCode(args);
                        const adjustmentTypes = getAdjustmentTypes(args);
                        const requiredAccounts = getRequiredAccounts(args);
                        const defaultAccounts = getDefaultAccAss({
                            requiredAccounts, adjustmentType, adjustmentTypes
                        });
                        if (!defaultAccounts) {
                            return originalFilter;
                        }

                        return `${originalFilter} AND (startswith(Number,'${defaultAccounts.CreditAccount}'))`;
                    }
                }
            },
            [`${BindingContext.localContext("selectedDocuments")}/NumberOurs`]: {},
            [`${BindingContext.localContext("selectedDocuments")}/BusinessPartner_Name`]: {},
            [`${BindingContext.localContext("selectedDocuments")}/AmountDue`]: {},
            [`${BindingContext.localContext("selectedDocuments")}/Amount`]: {}
        },
        additionalProperties: [
            // add as additionalProperty to force its settings into field info
            // (its not by default loaded for togglePropPath)
            { id: isPartialCancelPath }
        ],
        groups: [
            {
                ...accAssGroup,
                title: null,
                isSmallBottomMargin: true,
                rows: [[
                    { id: "DateAccountingTransaction" },
                    ...accAssGroup.rows[0]
                ]]
            },
            {
                id: "explanation",
                rows: [
                    [{ id: "Explanation" }]
                ]
            },
            {
                id: "partialAmounts",
                title: (args) => {
                    const adjustmentType = getAdjustmentTypeCode(args);

                    if (WRITE_OFF_REC_ADJ_TYPES.includes(adjustmentType)) {
                        return i18next.t("ReceivableAdjustments:Form.PartialWriteOff");
                    } else if (adjustmentType === ReceivableAdjustmentTypeCode.ReceivableAdjustmentCancellationPer8ZOR) {
                        return i18next.t("ReceivableAdjustments:Form.PartialCancel");
                    }

                    return "";
                },
                showGroupDivider: true,
                togglePropPath: isPartialCancelPath,
                lineItems: {
                    collection: BindingContext.localContext("selectedDocuments"),
                    order: "",
                    isItemRemovable: false,
                    isItemCloneable: false,
                    canAdd: false,
                    canReorder: false,
                    isItemVisible: (args: IGetValueArgs) => {
                        return !!args.storage.getValueByPath(isPartialCancelPath);
                    },
                    columns: [
                        {
                            id: "NumberOurs",
                            label: i18next.t("ReceivableAdjustments:Form.Document"),
                            width: BasicInputSizes.S,
                            isReadOnly: true,
                            formatter: (val, args) => {
                                const recAdjDoc = args.storage.getValue(args.bindingContext.getParent());
                                const docType: DocumentTypeCode = recAdjDoc[BindingContext.localContext("DocumentTypeCode")];
                                const docId = recAdjDoc[BindingContext.localContext("Id")];

                                return getIntentLink(val as string, {
                                    route: `${getRouteByDocumentType(docType)}/${docId}`,
                                    context: args.storage.context,
                                    storage: args.storage
                                }) as any;
                            }
                        },
                        {
                            id: "BusinessPartner_Name",
                            label: i18next.t("ReceivableAdjustments:Form.BusinessPartner"),
                            width: BasicInputSizes.M,
                            isReadOnly: true,
                            formatter: (val, args) => {
                                const recAdjDoc = args.storage.getValue(args.bindingContext.getParent());
                                const businessPartnerId = recAdjDoc[BindingContext.localContext("BusinessPartner_Id")];

                                return getIntentLink(val as string, {
                                    route: `${ROUTE_BUSINESS_PARTNER}/${businessPartnerId}`,
                                    context: args.storage.context,
                                    storage: args.storage
                                }) as any;
                            }
                        },
                        {
                            id: "AmountDue",
                            label: i18next.t("ReceivableAdjustments:Form.Total"),
                            textAlign: TextAlign.Right,
                            width: BasicInputSizes.S,
                            isReadOnly: true,
                            formatter: (val, args) => {
                                return formatCurrency(val, args.storage.getValue(args.bindingContext.getParent().navigate("CurrencyCode")));
                            }
                        },
                        {
                            id: "Amount",
                            label: i18next.t("ReceivableAdjustments:Form.Amount"),
                            textAlign: TextAlign.Right,
                            width: BasicInputSizes.S,
                            type: FieldType.NumberInput,
                            affectedFields: [
                                { id: isPartialCancelPath }
                            ],
                            validator: {
                                type: ValidatorType.Custom,
                                settings: {
                                    customValidator: (value, args, testContext) => {
                                        // Validator can't tell when the local context should be treated as collection
                                        // => the schema doesn't apply correctly for this local context case
                                        // => get the value using testContext.path
                                        const bc = createBindingContextFromValidatorPath(testContext.path, args.storage.data.bindingContext, args.storage.data.entity);
                                        const val = args.storage.getValue(bc);
                                        const item = args.storage.getValue(bc.getParent());

                                        if (Number.isNaN(val) || typeof val !== "number") {
                                            return new ValidationError(ValidationMessage.NotANumber, val, testContext.path);
                                        }

                                        if (val < 1) {
                                            const error = new ValidationError(ValidationMessage.SmallNumber, val, testContext.path);

                                            error.params = { min: 1 };

                                            return error;
                                        }

                                        if (val > item[BindingContext.localContext("AmountDue")]) {
                                            const error = new ValidationError(ValidationMessage.BigNumber, val, testContext.path);

                                            error.params = { max: item[BindingContext.localContext("AmountDue")] };

                                            return error;
                                        }

                                        return true;
                                    }
                                }
                            }

                        }
                    ]
                },
                isVisible: (args: IGetValueArgs) => {
                    const adjustmentType = getAdjustmentTypeCode(args);

                    return REC_ADJ_TYPES_WITH_PARTIAL_AMOUNTS.includes(adjustmentType);
                }
            }
        ]
    };

    return {
        entitySet,
        form
    };
};

getDialogDefinition.translationFiles = ["ReceivableAdjustments", "Document", "Common"];
setDefForTesting("ReceivableAdjustmentsDef", getDialogDefinition);