import { getTableIntentLink } from "@components/drillDown/DrillDown.utils";
import { SearchSmallIcon } from "@components/icon";
import { SwitchType } from "@components/inputs/switch/Switch";
import { ifAll, ifAny, IGetValueArgs, isVisibleByPath, not, TValidatorFnResult } from "@components/smart/FieldInfo";
import {
    AttachmentsAdditionalProperties,
    AttachmentsDef,
    getCommonFilterDefs,
    getCommonTableColumnsDefs,
    getLabelsDef,
    getLabelsFilterDef,
    LabelsTableColumnDef,
    NoteFieldDef,
    withDisplayName
} from "@components/smart/GeneralFieldDefinition";
import { FilterBarGroup, getDefaultFilterGroupDef } from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { ISummaryItem } from "@components/smart/smartSummaryItem/SmartSummaryItem";
import { TCellValue } from "@components/table";
import { getRouteByDocumentType } from "@odata/EntityTypes";
import {
    AssetEntity,
    EntitySetName,
    EntityTypeName,
    IAssetEntity,
    IAssetItemEntity,
    IAssetStatusEntity,
    IAssetTypeEntity,
    IDepreciationPolicyItemEntity,
    IDocumentEntity,
    IFiscalYearEntity,
    ITaxDepreciationCoefficientEntity
} from "@odata/GeneratedEntityTypes";
import {
    AssetItemTypeCode,
    AssetStatusCode,
    AssetTypeCode,
    DepreciationTypeCode,
    DocumentTypeCode,
    TaxDepreciationCategoryCode
} from "@odata/GeneratedEnums";
import { getEnumSelectItems } from "@odata/GeneratedEnums.utils";
import { FormattingTarget, IFormatOptions, isEmptyValue } from "@odata/OData.utils";
import { getCompanyCurrency, isAccountAssignmentCompany, isCashBasisAccountingCompany } from "@utils/CompanyUtils";
import { arrayInsert } from "@utils/general";
import i18next from "i18next";
import React from "react";
import styled from "styled-components/macro";
import { ValidationError } from "yup";

import { DASH_CHARACTER } from "../../../constants";
import { IAppContext } from "../../../contexts/appContext/AppContext.types";
import {
    BasicInputSizes,
    FieldType,
    FormMode,
    GroupedField,
    LabelStatus,
    QueryParam,
    Sort,
    Status,
    TextAlign,
    ValidatorType
} from "../../../enums";
import { TValue } from "../../../global.types";
import { Model } from "../../../model/Model";
import { StorageModel } from "../../../model/StorageModel";
import BindingContext from "../../../odata/BindingContext";
import { getQueryParameters } from "../../../routes/Routes.utils";
import CurrencyType, { formatCurrency, formatCurrencyVariableDecimals } from "../../../types/Currency";
import DateType, { getUtcDayjs } from "../../../types/Date";
import { IFormDef } from "../../../views/formView/Form";
import { isFormReadOnly } from "../../../views/formView/Form.utils";
import { FormStorage } from "../../../views/formView/FormStorage";
import { ISplitPageTableDef } from "../../../views/table/TableView.utils";
import { getSingleAccountFieldDef } from "../../accountAssignment/AccountAssignment.utils";
import {
    getCurrencyUnit,
    getFiscalDataCorrespondingToDateAccountingTransaction,
    readOnlyCurrencyItemFormatter
} from "../../documents/Document.utils";
import { DOCUMENT_DATE_CHANGE_DEBOUNCE } from "../../documents/DocumentDef";
import {
    getFiscalYearByChartOfAccount,
    getFiscalYearByDate,
    getOldestActiveFY,
    getSortedFYs,
    hasClosedFY,
    isFiscalYearActive,
    isFiscalYearClosed
} from "../../fiscalYear/FiscalYear.utils";
import { setDefByEntityType } from "../../getDefByEntityType";
import {
    getNumberOursSummaryDef,
    getNumberRangeFieldDefs,
    NumberRangeAdditionalProperties
} from "../../numberRange/NumberRange.utils";
import { getItemBreadCrumbsText, IDefinition, IGetDefinition } from "../../PageUtils";
import {
    getAsset,
    getAssetTabs,
    getBeforeFirstFiscalYearValidation,
    getInOpenFYValidation,
    getSameOrLaterItemOfTypeValidation,
    getStatusColor,
    getValidationResult,
    statusFormatter
} from "../Asset.utils";
import { AssetTranslations, getLowPriceAssetLimitSync, IFixedAssetCustomData } from "./FixedAsset.utils";
import FixedAssetFormView from "./FixedAssetFormView";

export const TaxDepreciationPolicyGroup = "TaxDepreciationPolicy";
export const AccountingDepreciationPolicyGroup = "AccountingDepreciationPolicy";

export const DateFirstPutInUsePath = "DateFirstPutInUse";
export const TaxDepreciationPolicyTypePath = BindingContext.localContext("TaxDepreciationPolicyDepreciationType");
export const TaxDepreciationPolicyCategoryPath = BindingContext.localContext("TaxDepreciationPolicyCategory");
export const TaxDepreciationPolicyFirstYearValueIncreasePath = BindingContext.localContext("TaxDepreciationPolicyFirstYearValueIncrease");

export const AccountingDepreciationPolicyTypePath = "AccountingDepreciationPolicy/DepreciationType";

export const FixedAssetFormViewActionStorageKey = "FixedAssetFormViewAction";
export const FixedAssetEditableWindowActionFinish = "EventFixedAssetEditableWindowActionFinish";

export const INTANGIBLE_ACCOUNT_PREFIX = "01";
export const TANGIBLE_ACCOUNT_PREFIX = ["02", "03"];
export const INTANGIBLE_DEPRECIATION_ACCOUNT_PREFIX = "07";
export const TANGIBLE_DEPRECIATION_ACCOUNT_PREFIX = "08";

const IsInUseGroupId = "IsInUse";

export enum FixedAssetType {
    RegularAsset = "RegularAsset",
    ImportedAsset = "ImportedAsset"
}

export const isInUse = (args: IGetValueArgs): boolean => {
    return !!getAsset(args)?.IsInUse;
};

export const hasEmptyItems = (args: IGetValueArgs): boolean => {
    return !getAsset(args)?.Items?.length;
};

export const isIntangible = (args: IGetValueArgs): boolean => {
    return getAsset(args)?.Type?.Code === AssetTypeCode.Intangible;
};

export const isTangible = (args: IGetValueArgs): boolean => {
    return getAsset(args)?.Type?.Code === AssetTypeCode.Tangible;
};

export const isDisposed = (args: IGetValueArgs): boolean => {
    return hasItemType(AssetItemTypeCode.Disposal)(args);
};

export const hasItemType = (itemType: AssetItemTypeCode): ((args: IGetValueArgs) => boolean) => {
    return (args: IGetValueArgs) => !!getAsset(args)?.Items?.find(item => item.ItemType?.Code === itemType);
};

export const isAssetUsedInClosedFy = (args: IGetValueArgs): boolean => {
    const asset = getAsset(args, "origEntity");
    if (!asset?.DateFirstPutInUse) {
        return false;
    }
    let fiscalYear: IFiscalYearEntity;
    if (asset.ChartOfAccounts?.Id) {
        fiscalYear = getFiscalYearByChartOfAccount(args.storage.context, asset.ChartOfAccounts?.Id);
    } else {
        const fiscalData = getFiscalDataCorrespondingToDateAccountingTransaction(args.storage as FormStorage, asset.DateFirstPutInUse);
        fiscalYear = fiscalData.fiscalYear;
    }

    return isFiscalYearClosed(fiscalYear);
};

export const isInSecondAndNextTaxPeriod = (args: IGetValueArgs): boolean => {
    const asset = getAsset(args);
    const firstTaxDepreciationItem = asset.TaxDepreciationPolicy?.Items?.[0];
    if (firstTaxDepreciationItem?.DateDepreciation) {
        return !isFiscalYearActive(getFiscalYearByDate(args.context, firstTaxDepreciationItem.DateDepreciation));
    }
    return false;
};

const isLastDepreciationInClosedFY = (args: IGetValueArgs, items: IDepreciationPolicyItemEntity[]): boolean => {
    const lastItem = items?.find(item => item.EndValue === 0);
    const FY = lastItem?.DateDepreciation && getFiscalYearByDate(args.storage.context, lastItem.DateDepreciation);
    return FY && isFiscalYearClosed(FY);
};

export const isAccountingDepreciated = (args: IGetValueArgs): boolean => {
    const asset = getAsset(args);
    if (isImportedAssetInUse(args) && asset.RemainingAccountingPrice === 0) {
        return true;
    }
    return isNotInCreatedState(args) &&
        isFiscalYearClosed(getFiscalYearByDate(args.context, asset.AccountingDepreciationPolicy?.DateLastDepreciation));
};

export const isTaxDepreciated = (args: IGetValueArgs): boolean => {
    const asset = getAsset(args);
    if (isImportedAssetInUse(args) && asset.RemainingTaxPrice === 0) {
        return true;
    }
    return isNotInCreatedState(args) && isLastDepreciationInClosedFY(args, asset.TaxDepreciationPolicy?.Items);
};

export const canBeDepreciated = (args: IGetValueArgs): boolean => {
    const asset = getAsset(args);
    return asset.CanBeDepreciated !== false;
};

const isDepreciatedMinorAsset = (args: IGetValueArgs): boolean => getAsset(args).IsLowPriceAsset;

export const hasTaxDepreciation = (args: IGetValueArgs): boolean => !isIntangible(args) && !isDepreciatedMinorAsset(args) && canBeDepreciated(args);

/**
 * ---- Import Old Asset functionality ----
 */
export const ADD_IMPORTED_ASSET_ACTION_ID = "AddOldImportedAsset";
export const isImportedAssetEntity = (asset: IAssetEntity): boolean => !!asset?.IsImportedAsset;
export const isImportedAsset = (args: IGetValueArgs): boolean => isImportedAssetEntity(getAsset(args));

export const isAssetEntityInCreatedState = (asset: IAssetEntity): boolean =>
    !asset?.StatusCode || asset?.StatusCode === AssetStatusCode.Created;
export const isInCreatedState = (args: IGetValueArgs): boolean => isAssetEntityInCreatedState(getAsset(args));
export const isNotInCreatedState = not(isInCreatedState);

export const isImportedAssetInCreatedState = ifAll(isImportedAsset, isInCreatedState);
const isImportedAssetInUse = ifAll(isImportedAsset, isNotInCreatedState);

export const hasLinearTaxDepreciation = ({ storage }: IGetValueArgs): boolean => {
    return storage.getValueByPath(TaxDepreciationPolicyTypePath) === DepreciationTypeCode.Linear;
};

export function getCalculatedPrice(asset: IAssetEntity): number {
    if (isImportedAssetEntity(asset) && isAssetEntityInCreatedState(asset)) {
        return asset.ImportedAssetPrice;
    }
    return asset.CalculatedPrice;
}

export function getTaxDepreciationCoefficient(storage: FormStorage<IAssetEntity, IFixedAssetCustomData>): ITaxDepreciationCoefficientEntity {
    const type = storage.getValueByPath(TaxDepreciationPolicyTypePath);
    const category = storage.getValueByPath(TaxDepreciationPolicyCategoryPath);
    let firstYearValue = storage.getValueByPath(TaxDepreciationPolicyFirstYearValueIncreasePath);

    if (isEmptyValue(firstYearValue)) {
        firstYearValue = null;
    }

    return storage.getCustomData()?.coefficients?.find(c => c.TaxDepreciationCategory.Code === category && c.DepreciationType.Code === type && firstYearValue === c.FirstYearValueIncrease);
}

export function isSpecialCalculationCoeff({ storage }: IGetValueArgs): boolean {
    const coeff = getTaxDepreciationCoefficient(storage as FormStorage<IAssetEntity, IFixedAssetCustomData>);
    return !!coeff?.IsSpecialCalculation;
}

/**
 * Flag about imported asset for default value - from URL param as it's not in entity yet
 * @param args
 */
function isImportedAssetForDefaultValue(args: IGetValueArgs) {
    if (hasClosedFY(args.context)) {
        return false;
    }
    return getQueryParameters()[QueryParam.Type] === FixedAssetType.ImportedAsset;
}

const Preformatted = styled.span`
    white-space: pre;
`;

function getAccountingDepreciationPolicyGroupTooltip(storage: FormStorage): React.ReactElement {
    if (isDepreciatedMinorAsset({ storage })) {
        const limit = getLowPriceAssetLimitSync(storage);
        const formattedLimit = formatCurrency(limit, getCompanyCurrency(storage.context));
        return (
            <Preformatted>{i18next.t("FixedAsset:Form.LowPriceAssetLimitTooltip", { limit: formattedLimit })}</Preformatted>);
    }
    return null;
}

function getUsefulTooltip(args: IGetValueArgs): string {
    const entity = args.storage.getEntity<IAssetEntity>();
    if (!entity.DateForUsefulLife) {
        return null;
    }
    const date = DateType.format(entity.DateForUsefulLife);
    return i18next.t("FixedAsset:Form.UsefulLifeFrom", { date });
}

function getDepreciationTypeDef(hasOwn = true) {
    const items = [{
        id: DepreciationTypeCode.Linear,
        label: i18next.t("FixedAsset:DepreciationType.Linear")
    }, {
        id: DepreciationTypeCode.Accelerated,
        label: i18next.t("FixedAsset:DepreciationType.Accelerated")
    }];
    if (hasOwn) {
        // items.push({
        //     id: DepreciationType.Own,
        //     label: i18next.t("FixedAsset:DepreciationType.Own")
        // });
    }
    return {
        type: FieldType.SegmentedButton,
        labelStatus: LabelStatus.Removed,
        isRequired: isInUse,
        defaultValue: DepreciationTypeCode.Linear,
        fieldSettings: {
            items
        }
    };
}

export enum FixedAssetFormViewAction {
    Tax = "ShowTaxDepreciationPolicy",
    Accounting = "ShowAccountingDepreciationPolicy",
    AddFromUnsorted = "AddFromUnsorted",
    ChangePrice = "ChangePrice",
    Discard = "Discard",
}

export function getAssetCurrency(entity: IAssetEntity, context: IAppContext): string {
    return entity?.CurrencyCode ?? getCompanyCurrency(context);
}

export function assetPriceFormatter(val: TValue, args: IFormatOptions<IAssetEntity>): string {
    const formatterMethod = args.target === FormattingTarget.Summary ? formatCurrencyVariableDecimals : formatCurrency;
    return formatterMethod(val ?? 0, getAssetCurrency(args.entity, args.context ?? args.storage?.context));
}

function taxDepreciationCategoryValidator(storage: FormStorage, value?: TaxDepreciationCategoryCode): true | ValidationError {
    const entity = getAsset({ storage });
    const bindingContext = storage.data.bindingContext.navigate(TaxDepreciationPolicyCategoryPath);
    // Special Tax Depreciation Category validation for COVID-19 coefficients, which can't be interrupted,
    // therefore we need to check in case of remaining price that there are some depreciations after first FY starts
    const categoryCode = value ?? storage.getValue(bindingContext) as TaxDepreciationCategoryCode;
    const coeffTypes = [TaxDepreciationCategoryCode.C2cv_CZ_20, TaxDepreciationCategoryCode.C1cv_CZ_20];
    if (coeffTypes.includes(categoryCode) && isImportedAssetEntity(entity) && entity.RemainingTaxPrice > 0) {
        const info = storage.getInfo(bindingContext);
        const { items } = info.fieldSettings;
        const coeff = items.find(i => i.id === categoryCode);
        const { YearsOfDepreciation } = coeff?.additionalData ?? {};

        const DateFirstPutInUse = getUtcDayjs(entity.DateFirstPutInUse);
        const sortedFYs = getSortedFYs(storage?.context);
        const firstFY = sortedFYs[0];
        const min = getUtcDayjs(firstFY.DateStart).diff(DateFirstPutInUse, "years", true);
        if (YearsOfDepreciation < min) {
            const path = bindingContext.getEntityPath(true);
            return new ValidationError(i18next.t("FixedAsset:Validation.MinTaxDepreciations", { count: Math.ceil(min) }), categoryCode, path);
        }
    }
    return true;
}

function isAssetItemRemovable({ storage, item }: IGetValueArgs): boolean {
    const assetStorage = storage as FormStorage<IAssetEntity, IFixedAssetCustomData>;
    // No rows are removable in read-only mode
    if (assetStorage.isReadOnly) {
        return false;
    }

    const _entityContainsType = (...types: AssetItemTypeCode[]): boolean => {
        const entity = storage.data.entity as IAssetEntity;
        return entity.Items.some(item => types.includes(item.ItemType?.Code as AssetItemTypeCode));
    };

    // Every item type has different condition
    const assetItem = (item as IAssetItemEntity);
    const type = assetItem.ItemType?.Code as AssetItemTypeCode;
    switch (type) {
        case AssetItemTypeCode.Acquisition:
            const { origEntity } = assetStorage.data;
            const { rootItems } = assetStorage.getCustomData();
            return !origEntity.IsInUse && !!rootItems[assetItem.Id];

        case AssetItemTypeCode.ImportOfOldAsset:
        case AssetItemTypeCode.PutInUse:
            // If there is Item of another type than Acquisition or PutInUse, we cannot remove
            const hasFurtherItems = _entityContainsType(AssetItemTypeCode.Disposal, AssetItemTypeCode.TechnicalImprovement,
                AssetItemTypeCode.IncreaseOfPrice, AssetItemTypeCode.ReductionOfPrice);
            const isInClosedFy = isAssetUsedInClosedFy({ storage });
            return !(hasFurtherItems || isInClosedFy);

        case AssetItemTypeCode.TechnicalImprovement:
        case AssetItemTypeCode.IncreaseOfPrice:
        case AssetItemTypeCode.ReductionOfPrice:
            const entity = storage.data.entity as IAssetEntity;
            const hasDisposalItemOrNewerOne = entity.Items
                .some(item => item.Order > assetItem.Order && [AssetItemTypeCode.Disposal, AssetItemTypeCode.TechnicalImprovement,
                    AssetItemTypeCode.IncreaseOfPrice, AssetItemTypeCode.ReductionOfPrice].includes(item.ItemType?.Code as AssetItemTypeCode));
            const fyOfAssetItem = getFiscalDataCorrespondingToDateAccountingTransaction(storage as StorageModel, item.DateOfTransaction);
            const isImprovedInClosedFY = isFiscalYearClosed(fyOfAssetItem.fiscalYear);
            return !(hasDisposalItemOrNewerOne || isImprovedInClosedFY);

        case AssetItemTypeCode.Disposal:
            // If there is asset disposal item, it's always removable
            return true;

        default:
            return false;
    }
}

const remainingPriceCustomValidator = (value: TValue, {
    storage,
    bindingContext
}: IGetValueArgs): TValidatorFnResult => {
    const asset = getAsset({ storage });
    if (asset.ImportedAssetPrice < value) {
        const path = bindingContext.getEntityPath(true);
        return new ValidationError(i18next.t(`FixedAsset:Validation.${bindingContext.getPath(true)}`), value, path);
    }
    return true;
};

export const getDefinitionsFactory = (status?: AssetStatusCode, type?: AssetTypeCode, preventStoreVariant?: boolean): IGetDefinition => {
    const defaultFilters: string[] = [];
    if (status) {
        defaultFilters.push(`StatusCode eq '${status}'`);
    }
    if (type) {
        // show only related types or assets without type
        defaultFilters.push(`(TypeCode eq '${type}' OR Type eq null)`);
    }

    const getDefinitions: IGetDefinition = (context): IDefinition => {
        const isCbaCompany = isCashBasisAccountingCompany(context);
        const hasAccounting = isAccountAssignmentCompany(context);

        const table: ISplitPageTableDef = {
            filterBarDef: [{
                ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
                isValueHelp: true,
                defaultFilters: [
                    "Type", "NumberOurs", "Name", "Status", "CalculatedPrice"
                ],
                filterDefinition: {
                    NumberOurs: {},
                    Name: {},
                    ...withDisplayName("Status"),
                    CalculatedPrice: {},
                    TaxBalance: {},
                    DateDisposed: {},
                    DateFirstPutInUse: {},
                    Note: {},
                    ...withDisplayName("Type"),
                    "TaxDepreciationPolicy/DateInterruptedFrom": {},
                    "TaxDepreciationPolicy/ReductionRate": {},
                    ...withDisplayName("TaxDepreciationPolicy/Coefficient/TaxDepreciationCategory", {
                        descriptionPath: AssetEntity.TaxDepreciationPolicy
                    }),
                    ...getLabelsFilterDef("Labels"),
                    ...getCommonFilterDefs()
                }
            }],
            preventStoreVariant,
            id: `${EntityTypeName.Asset}Table`,
            tabs: getAssetTabs(),
            tabsSettings: {
                filterFieldName: "StatusCode"
            },
            massEditableDef: {
                Note: { ...NoteFieldDef },
                Labels: getLabelsDef("FixedAsset")
            },
            columns: [
                "Type", "NumberOurs", "Name", "Status", "DateFirstPutInUse", "CalculatedPrice", "TaxBalance"
            ],
            columnDefinition: {
                ...withDisplayName("Type"),
                NumberOurs: {},
                Name: {},
                Note: {},
                ...withDisplayName("Status", "Name", { formatter: statusFormatter }),
                CalculatedPrice: {},
                TaxBalance: {},
                ...LabelsTableColumnDef,
                DateDisposed: {},
                DateFirstPutInUse: {},
                "TaxDepreciationPolicy/DateInterruptedFrom": {},
                "TaxDepreciationPolicy/ReductionRate": {},
                "TaxDepreciationPolicy/Coefficient/TaxDepreciationCategory": {
                    fieldSettings: {
                        displayName: "Name"
                    },
                    descriptionPath: AssetEntity.TaxDepreciationPolicy,
                    additionalProperties: [{ id: "YearsOfDepreciation" }],
                    formatter: (val: TValue, args?: IFormatOptions<IAssetEntity, IAssetEntity>): TCellValue => {
                        if (val) {
                            const count = args.item.TaxDepreciationPolicy?.Coefficient?.TaxDepreciationCategory?.YearsOfDepreciation;
                            return [val, i18next.t("FixedAsset:Form.YearsCnt", { count })].join(" | ");
                        }
                        return "";
                    }
                },
                ...getCommonTableColumnsDefs(),
                // imported fixed asset
                IsImportedAsset: {}
            },
            additionalProperties: [{ id: "StatusCode" }],
            title: i18next.t("FixedAsset:Title"),
            filter: defaultFilters.length ? defaultFilters.join(" AND ") : undefined,
            initialSortBy: [{ id: "DateCreated", sort: Sort.Desc }]
        };

        const summary: ISummaryItem[] = [
            {
                ...getNumberOursSummaryDef("FixedAsset"),
                isReadOnly: isNotInCreatedState
            },
            {
                id: "Status",
                label: i18next.t("MinorAsset:Form.Status"),
                additionalProperties: [{ id: "Name" }],
                formatter: (val: TValue) => {
                    if (val) {
                        const status = val as IAssetStatusEntity;
                        return (status.Name ?? i18next.t(`MinorAsset:Status.${status?.Code}`).toString());
                    }
                    return DASH_CHARACTER;
                },
                colorFormatter: (val: TValue) => {
                    const status = val as IAssetStatusEntity;
                    return getStatusColor(status?.Code as AssetStatusCode);
                },
                defaultValue: AssetStatusCode.Created
            }, {
                id: "Type",
                label: i18next.t("FixedAsset:Form.Type"),
                additionalProperties: [{ id: "Name" }],
                formatter: (val: TValue) => {
                    if (val) {
                        const type = val as IAssetTypeEntity;
                        return type.Name ?? i18next.t(`FixedAsset:Type.${type?.Code}`).toString();
                    }
                    return DASH_CHARACTER;
                }
            }, {
                id: "CalculatedPrice",
                label: i18next.t("FixedAsset:Form.CalculatedPrice"),
                formatter: assetPriceFormatter,
                isReadOnly: true,
                isRequired: false
            }, {
                id: "TaxBalance",
                label: i18next.t("FixedAsset:Form.TaxBalance"),
                formatter: assetPriceFormatter,
                isVisible: ifAll(isNotInCreatedState, isTangible),
                isRequired: false
            }, {
                id: "AccountingBalance",
                label: i18next.t("FixedAsset:Form.AccountingBalance"),
                formatter: assetPriceFormatter,
                isVisible: ifAll(isNotInCreatedState, !isCbaCompany),
                isRequired: false
            }
        ];

        const form: IFormDef = {
            id: `${EntityTypeName.Asset}Form`,
            getItemBreadCrumbText: (storage: Model) =>
                getItemBreadCrumbsText(storage, i18next.t("FixedAsset:New"), storage.data.entity?.NumberOurs),
            summary,
            isDeletable: true,
            files: AttachmentsDef,
            formControl: FixedAssetFormView,
            translationFiles: getDefinitions.translationFiles,
            customHeaderActions: [
                {
                    id: AssetItemTypeCode.TechnicalImprovement,
                    label: i18next.t("FixedAsset:Form.TechnicalImprovement"),
                    iconName: "TechnicalImprovement",
                    isVisible: (args) => !isFormReadOnly(args) && isNotInCreatedState(args) && !isDisposed(args),
                    isDisabled: isSpecialCalculationCoeff
                }, {
                    id: AssetItemTypeCode.IncreaseOfPrice,
                    label: i18next.t("FixedAsset:Form.IncreaseOfPrice"),
                    iconName: "IncreaseOfPrice",
                    isVisible: (args) => !isFormReadOnly(args) && isNotInCreatedState(args) && !isDisposed(args),
                    isDisabled: isSpecialCalculationCoeff
                }, {
                    id: AssetItemTypeCode.ReductionOfPrice,
                    label: i18next.t("FixedAsset:Form.ReductionOfPrice"),
                    iconName: "ReductionOfPrice",
                    isVisible: (args) => !isFormReadOnly(args) && isNotInCreatedState(args) && !isDisposed(args),
                    isDisabled: isSpecialCalculationCoeff
                }, {
                    id: FixedAssetFormViewAction.Discard,
                    label: i18next.t("FixedAsset:Form.Discard"),
                    iconName: "Discard",
                    isVisible: (args) => !isFormReadOnly(args) && isNotInCreatedState(args) && !isDisposed(args)
                }
            ],
            additionalProperties: [
                { id: "Company" },
                { id: "ChartOfAccounts" },
                { id: "DateForUsefulLife" },
                { id: "CanBeDepreciated" },
                { id: "IsLowPriceAsset" },
                { id: AssetEntity.IsInUse },
                { id: "IsImportedAsset" },
                {
                    id: "TaxDepreciationPolicy",
                    additionalProperties: [
                        {
                            id: "Items",
                            additionalProperties: [{ id: "DateDepreciation" }, { id: "ReductionRate" }, { id: "DepreciationExpense" }, { id: "AccumulatedDepreciation" }, { id: "EndValue" }, { id: "Order" }, { id: "IsForReportOnly" }]
                        },
                        {
                            id: "Coefficient",
                            additionalProperties: [
                                { id: "DepreciationTypeCode" },
                                { id: "FirstYearValueIncrease" },
                                { id: "TaxDepreciationCategoryCode" },
                                { id: "IsSpecialCalculation" }
                            ]
                        }
                    ]
                },
                ...AttachmentsAdditionalProperties,
                ...NumberRangeAdditionalProperties
            ],
            groups: [{
                id: "Note",
                title: i18next.t("Common:General.Note"),
                rows: [[{ id: "Note" }]]
            }, {
                id: "Asset",
                title: i18next.t("MinorAsset:Form.Asset"),
                rows: [
                    [{ id: "Type" }],
                    [{ id: "Name" }, { id: "Labels" }]
                ]
            }, {
                id: IsInUseGroupId,
                title: (args) => i18next.t(`FixedAsset:Form.${isImportedAsset(args) ? "Use" : (isNotInCreatedState(args) ? "InUse" : "PutInUse")}`),
                togglePropPath: AssetEntity.IsInUse,
                rows: [
                    [{ id: "DateFirstPutInUse" }],
                    [{ id: "ImportedAssetPrice" }]
                ],
                customizationData: {
                    dependents: [TaxDepreciationPolicyGroup],
                    isLocked: true
                }
            }, {
                id: TaxDepreciationPolicyGroup,
                title: i18next.t("FixedAsset:Form.TaxDepreciationPolicy"),
                showGroupDivider: false,
                rows: [
                    [{ id: "RemainingTaxPrice" }, { id: "RemainingTaxYears" }, { id: "IsImproved" }],
                    [{ id: TaxDepreciationPolicyTypePath }],
                    [{ id: TaxDepreciationPolicyCategoryPath }, { id: TaxDepreciationPolicyFirstYearValueIncreasePath }, { id: "TaxDepreciationPolicy/ReductionRate" }],
                    [{ id: "TaxDepreciationPolicy/DateInterruptedFrom" }]
                ],
                actions: [{
                    id: FixedAssetFormViewAction.Tax,
                    icon: <SearchSmallIcon/>,
                    title: i18next.t("FixedAsset:Form.ShowPlan"),
                    isDisabled: (args) => {
                        const asset = getAsset(args);
                        const isRemTaxPriceValid = !isVisibleByPath(args.storage as FormStorage, AssetEntity.RemainingTaxPrice) || !Number.isNaN(asset.RemainingTaxPrice);
                        const isRemTaxYearsValid = !isVisibleByPath(args.storage as FormStorage, AssetEntity.RemainingTaxYears) || Number.isInteger(asset.RemainingTaxYears);
                        return !getCalculatedPrice(args.storage.data.entity as IAssetEntity) || !isRemTaxPriceValid || !isRemTaxYearsValid
                                || taxDepreciationCategoryValidator(args.storage as FormStorage) !== true;
                    },
                    isVisible: ifAll(isInUse, hasTaxDepreciation)
                }]
            }, {
                /**
                 * Items are readonly - we just show items from BE with actions - user is able to remove some, etc.
                 * they can be added only by actions - created from existing items from documents
                 */
                id: "Items",
                title: i18next.t("MinorAsset:Form.Movements"),
                isVisible: ifAny(not(isImportedAsset), isNotInCreatedState),
                lineItems: {
                    collection: "Items",
                    order: "Order",
                    orderDirection: Sort.Desc,
                    canReorder: false,
                    showLineNumbers: false,
                    // User cannot add manually lineItems, they are added by actions on BE
                    isReadOnly: true,
                    // Used for pairing dialog, where in read-only mode, we would like to show only some
                    // rows (the newly added one) removable
                    isItemRemovable: isAssetItemRemovable,
                    isItemVisible: ({ item }) => {
                        if (isCbaCompany && (item as IAssetItemEntity).ItemType?.Code === AssetItemTypeCode.TaxDepreciation) {
                            return false;
                        }
                        return (item as IAssetItemEntity).ItemType?.Code !== AssetItemTypeCode.AccountingDepreciation;
                    },
                    columns: [{ id: "ItemType" }, { id: "DateOfTransaction" }, { id: "Document" }, { id: "DocumentItem/Description" }, { id: "Amount" }],
                    additionalFields: [{ id: "Order" }, { id: "DocumentItem" }]
                },
                actions: [
                    {
                        id: FixedAssetFormViewAction.AddFromUnsorted,
                        isTransparent: false,
                        title: i18next.t("FixedAsset:Form.AddFromUnsorted"),
                        isVisible: not(ifAny(isFormReadOnly, isNotInCreatedState, isImportedAsset, ifAll(isIntangible, isCbaCompany))),
                        isDisabled: ({ storage }) => {
                            const { bindingContext, entity } = storage.data;
                            return bindingContext.isNew() && (!entity.Name || !entity.NumberOurs);
                        },
                        disabledAlert: {
                            status: Status.Warning,
                            title: i18next.t("FixedAsset:Form.SaveRecordFirst")
                        }
                    }
                ],
                actionsAlignment: TextAlign.Left
            }],
            fieldDefinition: {
                ...getNumberRangeFieldDefs("MinorAsset"),
                Note: {
                    isConfirmable: true,
                    type: FieldType.EditableText,
                    labelStatus: LabelStatus.Removed
                },
                Name: {
                    width: BasicInputSizes.XL,
                    isDisabled: isDisposed
                },
                Type: {
                    type: FieldType.SegmentedButton,
                    labelStatus: LabelStatus.Removed,
                    defaultValue: (args) => {
                        if (isImportedAssetForDefaultValue(args)) {
                            return null;
                        }
                        return AssetTypeCode.Tangible;
                    },
                    fieldSettings: {
                        displayName: "Name",
                        items: getEnumSelectItems(EntityTypeName.AssetType)
                            .sort((a, b) => b.id === AssetTypeCode.Tangible ? 1 : -1)
                    },
                    disabledText: i18next.t("Components:NotInBeta.Description"),
                    isDisabled: isCbaCompany,
                    isVisible: ifAll(hasEmptyItems, not(isImportedAsset)),
                    clearIfInvisible: false
                },
                Labels: getLabelsDef("FixedAsset"),
                DateFirstPutInUse: {
                    fieldSettings: {
                        debouncedWait: DOCUMENT_DATE_CHANGE_DEBOUNCE
                    },
                    validator: {
                        type: ValidatorType.Date,
                        settings: {
                            customValidator: (value, args) => {
                                if (isImportedAsset(args)) {
                                    // the date must be before first FY, unless it should be added as normal asset
                                    return getBeforeFirstFiscalYearValidation(value, args);
                                }
                                if (isNotInCreatedState(args) || !value) {
                                    return true;
                                }
                                return getValidationResult(
                                    getInOpenFYValidation(value, args),
                                    getSameOrLaterItemOfTypeValidation(value, args)
                                );
                            }
                        }
                    },
                    affectedFields: [
                        { id: "AccountingDepreciationPolicy/UsefulLife", revalidate: true }
                    ],
                    isRequired: isInUse,
                    isVisible: isInUse,
                    isDisabled: isNotInCreatedState
                },
                IsInUse: {
                    labelStatus: LabelStatus.Removed,
                    type: FieldType.Switch,
                    fieldSettings: {
                        type: SwitchType.YesNo
                    },
                    defaultValue: isImportedAssetForDefaultValue,
                    isVisible: not(ifAny(isImportedAsset, ifAll(isIntangible, isCbaCompany))),
                    clearIfInvisible: false,
                    isDisabled: ifAny(isNotInCreatedState, not(hasItemType(AssetItemTypeCode.Acquisition))),
                    customizationData: {
                        useForCustomization: false
                    }
                    /* affectedFields: [{ id: "DateFirstPutInUse" }, { id: "InUseAccount" }]
                     + we need to refresh DeprecationPolicy groups, whole form has to be refreshed,
                     so we don't need to specify affectedFields at all */
                },
                [TaxDepreciationPolicyTypePath]: {
                    ...getDepreciationTypeDef(false),
                    label: i18next.t("FixedAsset:Form.TaxDepreciationType"), // variant mngmnt label
                    isVisible: ifAll(hasTaxDepreciation, isInUse),
                    isDisabled: ifAny(isInSecondAndNextTaxPeriod, isTaxDepreciated, isDisposed, isImportedAssetInUse)

                },
                [TaxDepreciationPolicyCategoryPath]: {
                    type: FieldType.ComboBox,
                    label: i18next.t("FixedAsset:Form.TaxDepreciationCategory"),
                    isRequired: true,
                    isVisible: ifAll(hasTaxDepreciation, isInUse),
                    isDisabled: ifAny(isInSecondAndNextTaxPeriod, isTaxDepreciated, isDisposed, isImportedAssetInUse),
                    fieldSettings: {
                        shouldDisplayAdditionalColumns: true,
                        showTabularHeader: true,
                        items: []
                    },
                    columns: [{
                        id: "Category",
                        label: i18next.t("FixedAsset:Form.Category")
                    }, {
                        id: "Years",
                        label: i18next.t("FixedAsset:Form.Years")
                    }],
                    validator: {
                        type: ValidatorType.Custom,
                        settings: {
                            customValidator: (value, { storage, bindingContext }) => {
                                return taxDepreciationCategoryValidator(storage as FormStorage, value as TaxDepreciationCategoryCode);
                            }
                        }
                    },
                },
                [TaxDepreciationPolicyFirstYearValueIncreasePath]: {
                    type: FieldType.ComboBox,
                    label: i18next.t("FixedAsset:Form.FirstOwner"),
                    fieldSettings: {
                        items: []
                    },
                    isDisabled: ifAny(isInSecondAndNextTaxPeriod, isTaxDepreciated, isDisposed, isImportedAssetInUse),
                    isVisible: ifAll(isInUse, hasTaxDepreciation),
                    width: BasicInputSizes.S
                },
                "TaxDepreciationPolicy/ReductionRate": {
                    type: FieldType.NumberInput,
                    fieldSettings: {
                        unit: "%",
                        showSteppers: false,
                        min: 0,
                        max: 100
                    },
                    isRequired: false,
                    validator: {
                        type: ValidatorType.Integer,
                        settings: {
                            min: 0,
                            max: 100
                        }
                    },
                    isDisabled: ifAny(isTaxDepreciated, isDisposed),
                    isVisible: ifAll(isInUse, hasTaxDepreciation),
                    width: BasicInputSizes.XS
                },
                "TaxDepreciationPolicy/DateInterruptedFrom": {
                    type: FieldType.Switch,
                    fieldSettings: {
                        type: SwitchType.YesNo
                    },
                    isDisabled: ifAny(isTaxDepreciated, isSpecialCalculationCoeff, isDisposed),
                    isVisible: ifAll(isNotInCreatedState, hasTaxDepreciation),
                    useForValidation: false
                },
                "Items/ItemType": {
                    type: FieldType.ComboBox, // because of auditTrail view, mayBe temporary
                    isRequired: false,
                    fieldSettings: {
                        displayName: "Name"
                    }
                },
                "Items/Document": {
                    textAlign: TextAlign.Left,
                    isRequired: false,
                    formatter: (val: TValue, args?: IFormatOptions<IAssetEntity, IAssetItemEntity>) => {
                        const { storage, item } = args;
                        const isAuditTrail = (storage as FormStorage).formMode === FormMode.AuditTrail;

                        if (isAuditTrail) {
                            // format for input -> document number only (note: params are different when formatter
                            //  is called for table or input in form, todo: we may change this
                            return (item as IDocumentEntity)?.NumberOurs;
                        }
                        const { Id, NumberOurs, DocumentTypeCode } = item?.Document ?? {};

                        return Id ? getTableIntentLink(NumberOurs, {
                            route: `${getRouteByDocumentType(DocumentTypeCode as DocumentTypeCode)}/${Id}`,
                            context: args.storage.context,
                            storage: args.storage
                        }) : DASH_CHARACTER;
                    },
                    additionalProperties: [{ id: "NumberOurs" }, { id: "DocumentTypeCode" }]
                },
                "Items/Amount": {
                    isRequired: false
                },
                "Items/DocumentItem/Description": {
                    isRequired: false,
                    formatter: (val: TValue, args?: IFormatOptions<IAssetEntity, IAssetItemEntity>) => {
                        const { item } = args;

                        if (item?.ItemType?.Code === AssetItemTypeCode.Acquisition) {
                            return val as string;
                        }

                        return DASH_CHARACTER;
                    }
                },
                // optional fields
                DateDisposed: {
                    isVisible: isDisposed,
                    isDisabled: true
                },
                // imported old asset fields
                IsImportedAsset: {
                    isVisible: false,
                    clearIfInvisible: false,
                    defaultValue: isImportedAssetForDefaultValue,
                    customizationData: {
                        useForCustomization: false
                    }
                },
                ImportedAssetPrice: {
                    fieldSettings: {
                        unit: CurrencyType.getCurrencyUnit(getCompanyCurrency(context))
                    },
                    clearIfInvisible: false,
                    isVisible: ifAll(isImportedAsset, isInCreatedState),
                    isRequired: true,
                    validator: {
                        type: ValidatorType.Number,
                        settings: {
                            min: 0,
                            excludeMin: true
                        }
                    },
                    affectedFields: [
                        { id: "RemainingAccountingPrice", revalidate: true },
                        { id: "RemainingTaxPrice", revalidate: true }
                    ]
                },
                RemainingTaxPrice: {
                    fieldSettings: {
                        unit: CurrencyType.getCurrencyUnit(getCompanyCurrency(context))
                    },
                    clearIfInvisible: false,
                    isVisible: ifAll(isInUse, hasTaxDepreciation, isImportedAsset, isInCreatedState),
                    isRequired: true,
                    validator: {
                        type: ValidatorType.Number,
                        settings: {
                            min: 0,
                            customValidator: remainingPriceCustomValidator
                        }
                    },
                    affectedFields: [
                        { id: "RemainingTaxYears", revalidate: true },
                        { id: TaxDepreciationPolicyCategoryPath, revalidate: true }
                    ]
                },
                RemainingTaxYears: {
                    clearIfInvisible: false,
                    isVisible: ifAll(isInUse, hasTaxDepreciation, isImportedAsset, isInCreatedState, not(hasLinearTaxDepreciation)),
                    isRequired: true,
                    validator: {
                        type: ValidatorType.Integer,
                        settings: {
                            min: 0,
                            customValidator: (value, { storage, bindingContext }) => {
                                const asset = getAsset({ storage });
                                if (!hasLinearTaxDepreciation({ storage }) && ((asset.RemainingTaxPrice > 0) !== (value > 0))) {
                                    // RemainingTaxYears must correspond to RemainingTaxPrice - both must have value greater than one or both should be 0
                                    const path = bindingContext.getEntityPath(true);
                                    return new ValidationError(i18next.t("FixedAsset:Validation.RemainingTaxYears"), value, path);
                                }
                                return true;
                            }
                        }
                    }
                },
                IsImproved: {
                    clearIfInvisible: false,
                    isVisible: ifAll(isInUse, hasTaxDepreciation, isImportedAsset, isInCreatedState)
                }
            }
        };

        const itemsGroup = form.groups.find(g => g.id === "Items");
        const isInUseGroup = form.groups.find(g => g.id === IsInUseGroupId);
        if (hasAccounting) {
            table.filterBarDef[0].filterDefinition = {
                ...table.filterBarDef[0].filterDefinition,
                AccountingBalance: {},
                ...withDisplayName("InUseAccount", "Number"),
                ...withDisplayName("DepreciationAccount", "Number", {
                    descriptionPath: AssetEntity.AccountingDepreciationPolicy
                }),
                ...withDisplayName("AccumulatedDepreciationsAccount", "Number", {
                    descriptionPath: AssetEntity.AccountingDepreciationPolicy
                }),
                "AccountingDepreciationPolicy/UsefulLife": {}
            };

            table.columns = [...table.columns, "AccountingBalance"];

            table.columnDefinition = {
                ...table.columnDefinition,
                AccountingBalance: {},
                ...withDisplayName("InUseAccount", "Number"),
                ...withDisplayName("DepreciationAccount", "Number", {
                    descriptionPath: AssetEntity.AccountingDepreciationPolicy
                }),
                ...withDisplayName("AccumulatedDepreciationsAccount", "Number", {
                    descriptionPath: AssetEntity.AccountingDepreciationPolicy
                }),
                "AccountingDepreciationPolicy/UsefulLife": {}
            };

            form.additionalProperties = [
                ...form.additionalProperties,
                {
                    id: "AccountingDepreciationPolicy",
                    additionalProperties: [{ id: "DateLastDepreciation" }]
                }
            ];

            form.fieldDefinition = {
                ...form.fieldDefinition,
                InUseAccount: {
                    ...getSingleAccountFieldDef(AssetEntity.DateFirstPutInUse),
                    filter: {
                        select: (args: IGetValueArgs) => {
                            let filter: string;
                            const intangible = `startsWith(Number,'${INTANGIBLE_ACCOUNT_PREFIX}')`;
                            const tangible = TANGIBLE_ACCOUNT_PREFIX.map(p => `startsWith(Number,'${p}')`).join(" OR ");

                            if (isImportedAssetInCreatedState(args)) {
                                // for new imported asset, we set asset type according to the selected account
                                filter = [intangible, tangible].join(" OR ");
                            } else if (isIntangible(args)) {
                                filter = intangible;
                            } else {
                                filter = tangible;
                            }
                            return `IsActive eq true AND (${filter})`;
                        }
                    },
                    tooltipAfter: (args) => canBeDepreciated(args) ? undefined : i18next.t("FixedAsset:Form.NonDepreciatedAsset"),
                    width: BasicInputSizes.L,
                    isRequired: isInUse,
                    isVisible: isInUse,
                    clearIfInvisible: false, // todo: remove when DEV-20166 is fixed
                    isDisabled: isNotInCreatedState
                },
                DepreciationAccount: {
                    ...getSingleAccountFieldDef(AssetEntity.DateFirstPutInUse),
                    groupedField: GroupedField.MultiStart,
                    filter: {
                        select: () => `IsActive eq true AND startsWith(Number,'551')`
                    },
                    isRequired: ifAll(isInUse, canBeDepreciated),
                    isVisible: ifAll(isInUse, canBeDepreciated),
                    isDisabled: ifAny(isAssetUsedInClosedFy, isDisposed, isAccountingDepreciated, isImportedAssetInUse)
                },
                AccumulatedDepreciationsAccount: {
                    ...getSingleAccountFieldDef(AssetEntity.DateFirstPutInUse),
                    groupedField: GroupedField.MultiEnd,
                    filter: {
                        select: (args: IGetValueArgs) => {
                            const InUseAccountNumber = (args.storage.data.entity as IAssetEntity).InUseAccount?.Number;
                            const x = InUseAccountNumber?.[2] ?? "";
                            let prefixes = [INTANGIBLE_DEPRECIATION_ACCOUNT_PREFIX, TANGIBLE_DEPRECIATION_ACCOUNT_PREFIX];
                            if (isIntangible(args)) {
                                prefixes = prefixes.filter(p => p === INTANGIBLE_DEPRECIATION_ACCOUNT_PREFIX);
                            }
                            if (isTangible(args)) {
                                prefixes = prefixes.filter(p => p === TANGIBLE_DEPRECIATION_ACCOUNT_PREFIX);
                            }
                            return `IsActive eq true AND (${prefixes.map(p => `startsWith(Number,'${p}${x}')`).join(" OR ")})`;
                        }
                    },
                    isRequired: ifAll(isInUse, canBeDepreciated),
                    isVisible: ifAll(isInUse, canBeDepreciated),
                    isDisabled: ifAny(isAssetUsedInClosedFy, isDisposed, isAccountingDepreciated, isImportedAssetInUse)
                },
                RemainingAccountingPrice: {
                    fieldSettings: {
                        unit: CurrencyType.getCurrencyUnit(getCompanyCurrency(context))
                    },
                    clearIfInvisible: false,
                    isVisible: ifAll(isInUse, canBeDepreciated, isImportedAsset, isInCreatedState),
                    isRequired: true,
                    validator: {
                        type: ValidatorType.Number,
                        settings: {
                            min: 0,
                            customValidator: remainingPriceCustomValidator
                        }
                    },
                    affectedFields: [
                        { id: "AccountingDepreciationPolicy/UsefulLife", revalidate: true }
                    ]
                },
                [AccountingDepreciationPolicyTypePath]: {
                    ...getDepreciationTypeDef(),
                    isDisabled: ifAny(isAccountingDepreciated, isDisposed),
                    isVisible: ifAll(isInUse, canBeDepreciated)
                },
                "AccountingDepreciationPolicy/UsefulLife": {
                    type: FieldType.NumberInput,
                    fieldSettings: {
                        placeholder: i18next.t("FixedAsset:Form.InYears"),
                        showSteppers: true,
                        min: 0
                    },
                    validator: {
                        type: ValidatorType.Integer,
                        settings: {
                            min: 0,
                            customValidator: (value, { storage, bindingContext }) => {
                                const { DateStart } = getOldestActiveFY(storage.context);
                                const origAsset = getAsset({ storage }, "origEntity");
                                const entity = getAsset({ storage });
                                const DateForUsefulLife = getUtcDayjs(entity.DateForUsefulLife ?? entity.DateFirstPutInUse);
                                if (isImportedAssetEntity(entity) && entity.RemainingAccountingPrice === 0) {
                                    const sortedFYs = getSortedFYs(storage?.context);
                                    const firstFY = sortedFYs[0];
                                    const max = Math.ceil(getUtcDayjs(firstFY.DateStart).diff(DateForUsefulLife, "years", true));
                                    // Asset is already depreciated -> we check only maximum, minimum is not known
                                    if (value !== origAsset.AccountingDepreciationPolicy?.UsefulLife && value > max) {
                                        const path = bindingContext.getEntityPath(true);
                                        return new ValidationError(i18next.t("FixedAsset:Validation.MaxUsefulLife", { count: max }), value, path);
                                    }
                                    return true;
                                }
                                const dateDiff = getUtcDayjs(DateStart).diff(DateForUsefulLife, "years", true);
                                if (value !== origAsset.AccountingDepreciationPolicy?.UsefulLife && value <= dateDiff) {
                                    const path = bindingContext.getEntityPath(true);
                                    // we need to round up the number with added epsilon in case dateDiff is same
                                    // as value, it should suggest next higher
                                    const min = dateDiff === value ? dateDiff + 1 : Math.ceil(dateDiff);
                                    return new ValidationError(i18next.t("FixedAsset:Validation.MinUsefulLife", { count: min }), value, path);
                                }
                                return true;
                            }
                        }
                    },
                    tooltip: getUsefulTooltip,
                    isRequired: true,
                    isDisabled: ifAny(isAccountingDepreciated, isDisposed),
                    isVisible: ifAll(isInUse, canBeDepreciated),
                    width: BasicInputSizes.S,
                    customizationData: {
                        useForCustomization: false // part of localContext field
                    }
                },
                "Items/DateOfTransaction": {
                    isRequired: false
                },
                "Items/CreditAccount": {
                    fieldSettings: {
                        placeholder: DASH_CHARACTER
                    }
                },
                "Items/DebitAccount": {
                    fieldSettings: {
                        placeholder: DASH_CHARACTER
                    }
                }
            };

            form.groups = arrayInsert(form.groups, {
                id: AccountingDepreciationPolicyGroup,
                title: i18next.t("FixedAsset:Form.AccountingDepreciationPolicy"),
                tooltip: getAccountingDepreciationPolicyGroupTooltip,
                showGroupDivider: false,
                rows: [
                    [{ id: "RemainingAccountingPrice" }],
                    [{ id: AccountingDepreciationPolicyTypePath }],
                    [{ id: "AccountingDepreciationPolicy/UsefulLife" }, { id: "DepreciationAccount" }, { id: "AccumulatedDepreciationsAccount" }]
                ],
                actions: [{
                    id: FixedAssetFormViewAction.Accounting,
                    icon: <SearchSmallIcon/>,
                    title: i18next.t("FixedAsset:Form.ShowPlan"),
                    isDisabled: (args) => {
                        const asset = getAsset(args);
                        return !Number.isInteger(asset.AccountingDepreciationPolicy?.UsefulLife) || !getCalculatedPrice(asset)
                            || (isImportedAsset(args) && Number.isNaN(asset.RemainingAccountingPrice));
                    },
                    isVisible: ifAll(isInUse, canBeDepreciated)
                }]
            }, 4);

            itemsGroup.lineItems.columns = [
                ...itemsGroup.lineItems.columns,
                { id: "DebitAccount" },
                { id: "CreditAccount" }
            ];

            isInUseGroup.rows[0].push({ id: "InUseAccount" });
            isInUseGroup.customizationData.dependents.push(AccountingDepreciationPolicyGroup);
        }

        if (isCbaCompany) {
            form.fieldDefinition = {
                ...form.fieldDefinition,
                "CalculatedPrice": {
                    isVisible: ifAll(isIntangible, isCbaCompany, isInUse, hasEmptyItems),
                    isReadOnly: false,
                    isRequired: true,
                    formatter: readOnlyCurrencyItemFormatter,
                    fieldSettings: {
                        unit: getCurrencyUnit
                    }
                }
            };

            isInUseGroup.rows[0].push({ id: "CalculatedPrice" });
        }

        return {
            entitySet: EntitySetName.Assets,
            table,
            form
        };
    };
    getDefinitions.translationFiles = AssetTranslations;

    return getDefinitions;
};
setDefByEntityType(EntityTypeName.Asset, getDefinitionsFactory());