import { WithConfirmationDialog, withConfirmationDialog } from "@components/dialog/withConfirmationDialog";
import { CopyIcon } from "@components/icon";
import { getHourDifference, isValidDate } from "@components/inputs/date/utils";
import { IFieldDef } from "@components/smart/FieldInfo";
import { ISmartFieldChange } from "@components/smart/smartField/SmartField";
import {
    IPrWorkingPatternDayEntity,
    IPrWorkingPatternDayIntervalEntity,
    IPrWorkingPatternEntity,
    PrWorkingPatternDayEntity,
    PrWorkingPatternDayIntervalEntity
} from "@odata/GeneratedEntityTypes";
import { PrWorkIntervalTypeCode } from "@odata/GeneratedEnums";
import { roundToDecimalPlaces } from "@utils/general";
import { cloneDeep } from "lodash";
import React, { Component } from "react";

import { Button } from "../../../components/button";
import SmartFastEntryList, { ISmartFastEntriesActionEvent } from "../../../components/smart/smartFastEntryList";
import BindingContext from "../../../odata/BindingContext";
import { getUtcDayjs } from "../../../types/Date";
import NumberType from "../../../types/Number";
import { FormStorage } from "../../../views/formView/FormStorage";
import { WorkDayIntervalsFastEntryHeader, WorkDayIntervalsFastEntryWrapper } from "./WorkingPattern.styles";
import { WORKING_HOURS_DECIMAL_PLACES } from "./WorkingPatterns.utils";
import { timeDiffPath } from "./WorkingPatternsDef";
import { IWorkingPatternCustomData } from "./WorkingPatternsFormView";

interface IProps extends WithConfirmationDialog {
    storage: FormStorage<IPrWorkingPatternEntity, IWorkingPatternCustomData>
}

class WorkDayIntervalsFastEntry extends Component<IProps> {
    get selectedDay(): IPrWorkingPatternDayEntity {
        const selectedDay = this.props.storage.getCustomData().selectedDay;
        return selectedDay ? this.props.storage.getValue(selectedDay) : null;
    }

    get selectedDayBc(): BindingContext {
        return this.props.storage.getCustomData().selectedDay;
    }

    get columns(): IFieldDef[] {
        return [{
            id: PrWorkingPatternDayIntervalEntity.Type
        }, {
            id: PrWorkingPatternDayIntervalEntity.TimeStart
        }, {
            id: PrWorkingPatternDayIntervalEntity.TimeEnd
        }, {
            id: timeDiffPath
        }];
    }

    handleChange = (e: ISmartFieldChange): void => {
        const storage = this.props.storage;
        storage.handleLineItemsChange(e);
        storage.refreshFields();

        if (isValidDate(e.value as Date) || e.bindingContext.getPath() === PrWorkingPatternDayIntervalEntity.Type) {
            this.recalculateWorkingHours();
            // refresh local context field with hours diff
            this.props.storage.refresh();
        }
    };

    recalculateWorkingHours = (): void => {
        const workingHours = this.selectedDay.Intervals?.filter(i => {
            return isValidDate(i.TimeStart) && isValidDate(i.TimeEnd) && i.Type?.Code === PrWorkIntervalTypeCode.Work;
        })?.reduce((acc, i) => {
            const timeDiff = getHourDifference(getUtcDayjs(i.TimeStart), getUtcDayjs(i.TimeEnd));
            return acc + timeDiff;
        }, 0) ?? 0;
        this.selectedDay.WorkingHours = roundToDecimalPlaces(WORKING_HOURS_DECIMAL_PLACES, workingHours);
        this.props.storage.refresh();
    }

    handleClone = async (): Promise<void> => {
        const isConfirmed = await this.props.confirmationDialog.open({
            content: this.props.storage.t("WorkingPatterns:ConfirmationText", { hours: this.selectedDay.WorkingHours })
        });

        if (isConfirmed) {
            const entity = this.props.storage.data.entity as IPrWorkingPatternEntity;
            const daysWithSameWH = entity.Days.filter(d => d.WorkingHours === this.selectedDay.WorkingHours);
            for (const day of daysWithSameWH) {
                day.Intervals = cloneDeep(this.selectedDay.Intervals);
            }
            this.props.storage.refresh();
        }
    };

    get isCloningDisabled(): boolean {
        return false;
        // return !this.selectedDay.Intervals.length;
    }

    handleAction = (e: ISmartFastEntriesActionEvent<IPrWorkingPatternDayIntervalEntity>) => {
        this.props.storage.handleLineItemsAction(e);
        this.props.storage.refresh();
    };

    render() {
        if (!this.selectedDay) {
            return null;
        }
        return <WorkDayIntervalsFastEntryWrapper>
            <WorkDayIntervalsFastEntryHeader>
                <b>{`${this.props.storage.t("WorkingPatterns:Interval")} ${NumberType.format(this.selectedDay.WorkingHours ?? 0)} ${this.props.storage.t("Components:Calendar.HourPlaceholder")}`}</b>
                <Button
                        title={this.props.storage.t("WorkingPatterns:UseForAll")}
                        isDisabled={this.isCloningDisabled}
                        isDecorative
                        onClick={this.handleClone}
                        icon={<CopyIcon/>}
                />
            </WorkDayIntervalsFastEntryHeader>
            <SmartFastEntryList
                storage={this.props.storage}
                bindingContext={this.selectedDayBc.navigate(PrWorkingPatternDayEntity.Intervals)}
                columns={this.columns}
                onChange={this.handleChange}
                useLabelWrapping={true}
                canAdd={true}
                isItemCloneable={false}
                isAddButtonTransparent={true}
                onAction={this.handleAction}
                order={PrWorkingPatternDayIntervalEntity.Order}
            />
        </WorkDayIntervalsFastEntryWrapper>;
    }
}

export default withConfirmationDialog(WorkDayIntervalsFastEntry);