import React from "react";
import { FormViewForExtend, IFormViewProps } from "../../views/formView/FormView";
import { withPermissionContext } from "../../contexts/permissionContext/withPermissionContext";
import { ICommunicationEntryEntity } from "@odata/GeneratedEntityTypes";
import { KeyboardShortcut } from "@utils/keyboardShortcutsManager/KeyboardShortcutsManager";

interface IProps extends IFormViewProps<ICommunicationEntryEntity> {}

class MessagesFormView extends FormViewForExtend<ICommunicationEntryEntity, IProps> {
    static defaultProps = {
        formProps: {
            shouldHideAuditTrail: true
        }
    };

    handleKeyboardShortcut(shortcut: KeyboardShortcut, event: KeyboardEvent): boolean {
        return false;
    }

    renderButtons = (): React.ReactElement => {
        return null;
    };
}

export default withPermissionContext(MessagesFormView);
