import { SwitchType } from "@components/inputs/switch/Switch";
import { DataBoxEntity, EntitySetName, EntityTypeName } from "@odata/GeneratedEntityTypes";
import i18next from "i18next";

import { BasicInputSizes, FieldType, LabelStatus } from "../../enums";
import { IFormDef } from "../../views/formView/Form";
import { setDefForTesting } from "../getDefByEntityType";
import { IDefinition, IGetDefinition } from "../PageUtils";

export const getDefinitions: IGetDefinition = (): IDefinition => {
    const form: IFormDef = {
        id: `${EntityTypeName.DataBox}Form`,
        translationFiles: getDefinitions.translationFiles,
        additionalProperties: [{ id: DataBoxEntity.UserName, useForValidation: true }, {
            id: DataBoxEntity.PasswordSetter,
            useForValidation: true
        },
            { id: DataBoxEntity.AllowReadingNewMessages }, { id: DataBoxEntity.CanCheckMessages }, { id: DataBoxEntity.CanReadMessages },
            { id: DataBoxEntity.CanSendMessages }, { id: DataBoxEntity.CompanyAddress },
            { id: DataBoxEntity.CompanyName }, { id: DataBoxEntity.DataBoxId }, { id: DataBoxEntity.DataBoxUserName },
            { id: DataBoxEntity.PasswordId }, { id: DataBoxEntity.DateCreated }, { id: DataBoxEntity.DateLastModified },
            { id: DataBoxEntity.IsActive }, { id: DataBoxEntity.IsActive }, { id: DataBoxEntity.LegalNumber }],
        fieldDefinition: {
            UserName: {
                type: FieldType.Input,
                label: i18next.t("DataBox:UserName"),
                width: BasicInputSizes.M
            },
            PasswordSetter: {
                type: FieldType.Password,
                label: i18next.t("DataBox:Password"),
                width: BasicInputSizes.M
            },
            AllowReadingNewMessages: {
                type: FieldType.Switch,
                label: "",
                labelStatus: LabelStatus.Hidden,
                defaultValue: false,
                fieldSettings: {
                    type: SwitchType.Icons
                }
            },
            IsActive: {
                defaultValue: true
            }
        },
        groups: []
    };

    return {
        form,
        entitySet: EntitySetName.DataBoxes
    };
};

getDefinitions.translationFiles = ["DataBox", "Common"];
setDefForTesting("DataboxDef", getDefinitions);